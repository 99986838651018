import React from "react";
import { FaCheck, FaTimes } from "react-icons/fa";
import toast from "react-hot-toast";
import { patch } from "utils/api";
import { ENDPOINTS } from "utils/constants";

const ExpensePaidStatusToggle = ({ rowId, isPaid, refreshData }) => {
  const togglePaidStatus = async () => {
    try {
      var formData = new FormData();
      formData.append("is_paid", !isPaid);
      const response = await patch(ENDPOINTS.EXPENSES + rowId + "/", formData);
      if (response.status === 200) {
        toast.success("Ödeme durumu başarıyla güncellendi.");
        refreshData();
      } else {
        toast.error("Güncelleme sırasında bir hata oluştu.");
      }
    } catch (error) {
      console.error("Güncelleme hatası:", error);
      toast.error("Bir hata oluştu.");
    }
  };

  return (
    <div
      onClick={togglePaidStatus}
      style={{ cursor: "pointer" }}
      title={isPaid ? "Ödendi" : "Ödenmedi"}
    >
      {isPaid ? <FaCheck className="text-green-600"/> : <FaTimes className="text-gray-600" />}
    </div>
  );
};

export default ExpensePaidStatusToggle;
