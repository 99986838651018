import Dropdown from "components/dropdown";
import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import SubTabHeader from "../SubTabHeader";
import FormikCheckbox from "components/checkbox/FormikCheckbox";
import { Form, Formik } from "formik";
import { get } from "utils/api";
import { ENDPOINTS } from "utils/constants";
import { useAppSelector } from "store";
import { put } from "utils/api";
import { patch } from "utils/api";
import toast from "react-hot-toast";
import { setCurrentUser } from "store/authSlice";
import { useDispatch } from "react-redux";
const AccessSecurity = () => {
  // Dropdown içerikleri için basit örnekler
  const currentUser = useAppSelector((state) => state.auth.currentUser); 
  const dispatch = useDispatch();
  const dropdownContent = <div>Buraya istediğiniz içerik gelebilir</div>;
  const [editingItem, setEditingItem] = useState({});
  const getEditItem = async () => {
    const response = await get(ENDPOINTS.COMPANY + currentUser?.company?.id + "/");
    setEditingItem(response?.settings);
  };
  useEffect(() => {
    getEditItem();
  }, []);
  
  const editSubmit = async (values) => {
    var formData = new FormData();
    formData.append("show_widget_upcoming_leave_days_to_employees", values.show_widget_upcoming_leave_days_to_employees);
    formData.append("show_widget_upcoming_birthdays_to_employees", values.show_widget_upcoming_birthdays_to_employees);
    formData.append("show_widget_company_events_to_employees", values.show_widget_company_events_to_employees);
    formData.append("show_others_profile_cards_to_employees", values.show_others_profile_cards_to_employees);
    formData.append("show_phone_numbers_to_employees", values.show_phone_numbers_to_employees);
    formData.append("show_personal_files_to_employees", values.show_personal_files_to_employees);
    formData.append("show_assets_to_employees", values.show_assets_to_employees);
    formData.append("show_calendar_page_to_employees", values.show_calendar_page_to_employees);
    formData.append("show_all_leave_days_on_calendar_to_employees", values.show_all_leave_days_on_calendar_to_employees);
    const response = await patch(ENDPOINTS.COMPANY_SETTINGS_UPDATE.replace(':company_id', currentUser?.company?.id), formData);
    if (response.status === 200) {
      toast.success("Başarıyla güncellendi");
      dispatch(
        setCurrentUser({
          ...currentUser,
          company: { ...currentUser.company, settings: { ...response.data } },
        })
      );
      getEditItem();
    } else {
      toast.error("Bir hata oluştu");
    }
    
  };
  return (
    <div>
      <Formik
        initialValues={{
          show_widget_upcoming_leave_days_to_employees: editingItem.show_widget_upcoming_leave_days_to_employees ,
          show_widget_upcoming_birthdays_to_employees: editingItem.show_widget_upcoming_birthdays_to_employees ,
          show_widget_company_events_to_employees: editingItem.show_widget_company_events_to_employees ,
          show_others_profile_cards_to_employees: editingItem.show_others_profile_cards_to_employees ,
          show_phone_numbers_to_employees: editingItem.show_phone_numbers_to_employees ,
          show_personal_files_to_employees: editingItem.show_personal_files_to_employees ,
          show_assets_to_employees: editingItem.show_assets_to_employees ,
          show_calendar_page_to_employees: editingItem.show_calendar_page_to_employees,
          show_all_leave_days_on_calendar_to_employees: editingItem.show_all_leave_days_on_calendar_to_employees ,
        }}
        onSubmit={(values, { resetForm }) => {
          editSubmit(values);
          resetForm();
        }}
        enableReinitialize
      >
        {({
          isSubmitting,
          handleChange,
          handleBlur,
          values,
          setFieldValue,
        }) => (
          <Form className="space-y-4">
            <SubTabHeader
              title="Erişim Ayarları"
              backUrl="/app/ayarlar/sistem-ayarlari"
              headerButtonVisible={false}
            />
            <div className="dark:text-white container mx-auto bg-white p-4 dark:bg-navy-800">
              <div className="ml-5 p-6">
                {/* Ana Sayfa Bölümü */}
                <div className="mb-6">
                  <h2 className="mb-2 text-lg font-bold">Ana Sayfa</h2>
                  <p className="mb-4 text-sm">
                    Çalışanlar ana sayfada nelere erişebilirler?
                  </p>
                  {/* <FormikCheckbox
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="is_repeat"
                    color="blue"
                    label="Çalışan dağılımı"
                  /> bu anasayfada kaldırılan dağılımı gösteren chartın ayarı*/}
                  <FormikCheckbox
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="show_widget_upcoming_leave_days_to_employees"
                    color="blue"
                    label="Yaklaşan izinler"
                  />
                  <FormikCheckbox
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="show_widget_upcoming_birthdays_to_employees"
                    color="blue"
                    label="Yaklaşan doğum günleri"
                  />
                  <FormikCheckbox
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="show_widget_company_events_to_employees"
                    color="blue"
                    label="Yaklaşan takvim etkinlikleri"
                  />
                </div>

                {/* Çalışanlar Bölümü */}
                <div className="mb-6">
                  <h2 className="mb-2 text-lg font-bold">Çalışanlar</h2>
                  <p className="mb-4 text-sm">
                    Çalışanlar, çalışanlar bölümünde nelere erişebilir?
                  </p>
                  {/* <FormikCheckbox
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="show_others_profile_cards_to_employees"
                    color="blue"
                    label="Çalışan iş arkadaşlarının profil kartlarını görebilir"
                  /> */}
                  <FormikCheckbox
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="show_phone_numbers_to_employees"
                    color="blue"
                    label="Çalışanlar iş arkadaşlarının iş telefon numaralarını görebilir"
                  />
                </div>
                {/*Hesabım Bölümü */}
                <div className="mb-6">
                  <h2 className="mb-2 text-lg font-bold">Hesap Erişim Ayarları</h2>
                  <p className="mb-4 text-sm">
                    Çalışanlar, kendi profilinde nelere erişebilir?
                  </p>
                  <FormikCheckbox
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="show_personal_files_to_employees"
                    color="blue"
                    label="Çalışanlar dosyalar alanına erişebilirler"
                  />
                  <FormikCheckbox
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="show_assets_to_employees"
                    color="blue"
                    label="Çalışanlar zimmetlerine erişebilirler"
                  />
                </div>
                {/* Takvim Bölümü */}
                {/* <div className="mb-6">
                  <h2 className="mb-2 text-lg font-bold">Takvim</h2>
                  <p className="mb-4 text-sm">
                    Çalışanlar takvimde nelere erişebilirler?
                  </p>
                  <FormikCheckbox
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="show_calendar_page_to_employees"
                    color="blue"
                    label="Çalışanlara takvim sayfasını göster"
                  />
                  <FormikCheckbox
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="show_all_leave_days_on_calendar_to_employees"
                    color="blue"
                    label="Çalışanlara takvimdeki izin günlerini göster"
                  />
                </div> */}
              </div>
              <div className="flex justify-end space-x-2">
              <button
                type="submit"
                disabled={isSubmitting}
                className="rounded-md bg-blue-600 px-4 py-2 text-sm text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                Kaydet
              </button>
            </div>
            </div>
   
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AccessSecurity;
