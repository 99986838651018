import React, { useEffect } from "react";

// Admin Imports
import MainDashboard from "views/app/default";
import NFTMarketplace from "views/app/marketplace";
import Profile from "views/app/profile";
import DataTables from "views/app/tables";
import RTLDefault from "views/rtl/default";

// Auth Imports
import SignIn from "views/auth/SignIn";

// Icon Imports
import {
  MdHome,
  MdOutlineShoppingCart,
  MdBarChart,
  MdPerson,
  MdLock,
  MdOutlineSpeakerGroup,
  MdGroupWork,
  MdSupervisedUserCircle,
  MdSupervisorAccount,
  MdCalendarViewDay,
  MdOutlinePermContactCalendar,
  MdEditCalendar,
  MdViewList,
  MdMoney,
  MdTimeline,
  MdSettings,
  MdFilePresent,
  MdCheckBoxOutlineBlank,
  MdChecklist,
  MdTimer,
} from "react-icons/md";
import Leaves from "views/app/leaves";
import NewLeave from "views/app/leaves/NewLeave";
import NotificationSettings from "views/app/profile/NotificationSettings";
import Employees from "views/app/employees";
import { IoMdWallet } from "react-icons/io";
import Settings from "views/app/settings";
import Details from "views/app/employees/details";
import BCalendar from "views/app/calendar";
import ApprovalProcess from "views/app/settings/components/Rules/ApprovalProcess";
import WorkingDays from "views/app/settings/components/Rules/WorkingDays";
import AccessSecurity from "views/app/settings/components/SystemSettings/AccessSecurity";
import CompanyNotificationSettings from "views/app/settings/components/SystemSettings/CompanyNotificationSettings";
import RequestSettings from "views/app/settings/components/SystemSettings/RequestSettings";
import Expense from "views/app/expense";
import Reports from "views/app/reports";
import Assets from "views/app/settings/components/Rules/Assets";
import EmploymentTerminationReason from "views/app/settings/components/Rules/EmploymentTerminationReason";
import Signup from "views/auth/Signup";
import RuleDetails from "views/app/leaves/components/RuleDetails";
import ResetPassword from "views/auth/ResetPassword";
import VerifyEmail from "views/auth/VerifyEmail";
import store from "store";
import ChangePassword from "views/app/profile/ChangePassword";
import ForgotPassword from "views/auth/ForgotPassword";
import TimeSheets from "views/app/timesheets";
import VerifyPhone from "views/auth/VerifyPhone";
import Detail from "views/app/reports/components/detail";
import Files from "views/app/files";
import FileView from "views/app/files/components/FileView";
import Bulk from "views/app/bulk";
import TimeLog from "views/app/timelog";
const getCurrentUserRole = () => {
  const currentState = store.getState();
  return currentState.auth.currentUser?.role?.name;
};
const currentUserRoleName = getCurrentUserRole();

const routes = [
  {
    name: "Anasayfa",
    layout: "/app",
    path: "default",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
    display: true,
    scope: "dashboard",
  },

  {
    name: "Çalışanlar",
    layout: "/app",
    icon: <MdSupervisorAccount className="h-6 w-6" />,
    path: "calisanlar",
    component: <Employees />,
    display: true,
    scope: "user.list",
  },
  {
    name: "Bildirim Ayarları",
    layout: "/app",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "bildirim-ayarlari",
    component: <NotificationSettings />,
    display: false,
    scope: "user.settings",
  },
  {
    name: "Takvim",
    layout: "/app",
    path: "takvim",
    icon: <MdEditCalendar className="h-6 w-6" />,
    component: <BCalendar />,
    display: currentUserRoleName === "CompanyAdmin",
    scope: "leave.list",
  },
  {
    name: "Raporlar",
    layout: "/app",
    path: "raporlar",
    icon: <MdViewList className="h-6 w-6" />,
    component: <Reports />,
    display: currentUserRoleName === "CompanyAdmin",
    scope: "settings",
  },
  {
    name: "Rapor Detayları",
    layout: "/app",
    path: "rapor-detay/:reportType",
    icon: <MdViewList className="h-6 w-6" />,
    component: <Detail />,
    display: false,
    scope: "settings",
  },
  {
    name: "Hesabım",
    layout: "/app",
    path: "hesabim",
    icon: <MdViewList className="h-6 w-6" />,
    component: <Profile />,
    display: false,
    scope: "user",
  },
  {
    name: "Giriş Yap",
    layout: "/auth",
    path: "giris-yap",
    icon: <MdEditCalendar className="h-6 w-6" />,
    component: <SignIn />,
    display: false,
  },
  {
    name: "Şifre Sıfırla",
    layout: "/auth",
    path: "reset-password/:reset_token",
    icon: <MdEditCalendar className="h-6 w-6" />,
    component: <ResetPassword />,
    display: false,
  },
  {
    name: "Telefon Doğrula",
    layout: "/auth",
    path: "verify-phone",
    icon: <MdEditCalendar className="h-6 w-6" />,
    component: <VerifyPhone />,
    display: false,
  },
  {
    name: "Şifremi Unuttum",
    layout: "/auth",
    path: "sifremi-unuttum",
    icon: <MdEditCalendar className="h-6 w-6" />,
    component: <ForgotPassword />,
    display: false,
  },
  {
    name: "Kayıt Ol",
    layout: "/auth",
    path: "kayit-ol",
    icon: <MdEditCalendar className="h-6 w-6" />,
    component: <Signup />,
    display: false,
  },
  {
    name: "Email Doğrula",
    layout: "/auth",
    path: "email-dogrula/:token",
    icon: <MdEditCalendar className="h-6 w-6" />,
    component: <VerifyEmail />,
    display: false,
  },
  {
    name: "Parola Değiştir",
    layout: "/app",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "parola-degistir",
    component: <ChangePassword />,
    display: false,
    scope: "settings",
  },
  {
    name: "İzinler",
    layout: "/app",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "izinler",
    component: <Leaves />,
    display: currentUserRoleName === "CompanyAdmin",
    scope: "leave",
  },
  {
    name: "Mesai Yönetimi",
    layout: "/app",
    icon: <MdTimeline className="h-6 w-6" />,
    path: "mesailer",
    component: <TimeLog />,
    display: currentUserRoleName === "CompanyAdmin",
    scope: "timelog",
  },
  {
    name: "Zaman Yönetimi",
    layout: "/app",
    icon: <MdTimer className="h-6 w-6" />,
    path: "zaman-yonetimi",
    component: <TimeSheets />,
    display: currentUserRoleName === "CompanyAdmin",
    scope: "leave",
  },
  {
    name: "Harcamalar",
    layout: "/app",
    path: "harcamalar",
    icon: <IoMdWallet className="h-6 w-6" />,
    component: <Expense />,
    display: currentUserRoleName === "CompanyAdmin",
    scope: "expenses",
  },
  {
    name: "Dosyalar",
    layout: "/app",
    path: "dosyalar",
    icon: <MdFilePresent className="h-6 w-6" />,
    component: <Files />,
    display: true,
    scope: "company.list",
  },
  {
    name: "Dosya Ayrıntıları",
    layout: "/app",
    path: "dosyalar/:folderId",
    icon: <MdFilePresent className="h-6 w-6" />,
    component: <FileView />,
    display: true,
    scope: "company.list",
  },
  {
    name: "Toplu İşlemler",
    layout: "/app",
    icon: <MdChecklist className="h-6 w-6" />,
    path: "toplu-islemler",
    component: <Bulk />,
    display: currentUserRoleName === "CompanyAdmin",
    scope: "",
  },
  {
    name: "Ayarlar",
    layout: "/app",
    path: "ayarlar/*",
    icon: <MdSettings className="h-6 w-6" />,
    component: <Settings />,
    display: currentUserRoleName === "CompanyAdmin",
    scope: "settings",
  },
  {
    name: "Onay Süreçleri",
    layout: "/app",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "ayarlar/kurallar/onay-surecleri",
    component: <ApprovalProcess />,
    display: false,
    scope: "settings",
  },
  {
    name: "Zimmet Kategorileri",
    layout: "/app",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "ayarlar/kurallar/zimmet-kategorileri",
    component: <Assets />,
    display: false,
    scope: "settings",
  },
  {
    name: "İşten Ayrılma Nedenleri",
    layout: "/app",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "ayarlar/kurallar/isten-ayrilma-nedenleri",
    component: <EmploymentTerminationReason />,
    display: false,
    scope: "settings",
  },
  {
    name: "Çalışma Takvimleri",
    layout: "/app",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "ayarlar/kurallar/calisma-zamanlari",
    component: <WorkingDays />,
    display: false,
    scope: "settings",
  },
  {
    name: "Erişim Ayarları",
    layout: "/app",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "ayarlar/sistem-ayarlari/erisim-ayarlari",
    component: <AccessSecurity />,
    display: false,
    scope: "settings",
  },
  {
    name: "Bildirim Ayarları",
    layout: "/app",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "ayarlar/sistem-ayarlari/bildirim-ayarlari",
    component: <CompanyNotificationSettings />,
    display: false,
    scope: "settings",
  },
  {
    name: "Talep Ayarları",
    layout: "/app",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "ayarlar/sistem-ayarlari/talep-ayarlari",
    component: <RequestSettings />,
    display: false,
    scope: "settings",
  },

  {
    name: "İzin Kuralları",
    layout: "/app",
    path: "izinler/izin-kurallari/:id",
    icon: <MdPerson className="h-6 w-6" />,
    component: <RuleDetails />,
    display: false,
    scope: "user.detail",
  },
  {
    name: "Çalışan Detayları",
    layout: "/app",
    path: "/calisanlar/:id",
    icon: <MdPerson className="h-6 w-6" />,
    component: <Details />,
    display: false,
    scope: "user.detail",
  },
  {
    name: "RTL",
    layout: "/rtl",
    path: "rtl",
    icon: <MdHome className="h-6 w-6" />,
    component: <RTLDefault />,
    display: false,
  },
  {
    name: "Nft Market",
    layout: "/app",
    path: "sayfa1",
    icon: <MdOutlineShoppingCart className="h-6 w-6" />,
    component: <NFTMarketplace />,
    secondary: true,
    display: false,
  },
  {
    name: "Sayfa 2",
    layout: "/app",
    path: "sayfa2",
    icon: <MdPerson className="h-6 w-6" />,
    component: <Profile />,
    display: false,
  },
];
export default routes;
