import React from 'react'

const PaymentInfo = () => {
  return (
    <>
    <div className='p-3 text-md text-navy-600'>
      Aktif abonelik: ÜcretsizPlan
    </div>
    <div className='p-3 text-sm text-gray-400'>
      Diğer abonelik paketleri için: <a href="https://beyazik.com/magaza">https://beyazik.com/magaza</a> 
      <br/> 
      İletişim: <a href="tel:+905400335000">0540 033 50 00</a>
      <br />

    </div>
    </>
  )
}

export default PaymentInfo