import React, { useEffect, useState } from "react";
import DataTable from "components/datatable";
import { positionColumsData, salaryColumsData } from "../variables/columnsData";
import { get } from "utils/api";
import { ENDPOINTS } from "utils/constants";
import { useParams } from "react-router-dom";

import Modal from "react-modal";
import FormikDatePicker from "components/datepicker";
import { Field, Form, Formik } from "formik";
import AsyncSelect from "react-select/async";
import { post } from "utils/api";
import toast from "react-hot-toast";
import { formatISODate } from "utils/helpers";
import { useDispatch } from "react-redux";
import { useAppSelector } from "store";
import { customDateInputStyles } from "utils/constants";
import {
  positionAddValidationSchema,
  salaryAddValidationSchema,
} from "../validations/EmployeeValidationSchema";
import Dropdown from "components/dropdown";
import { FaEllipsisV } from "react-icons/fa";
import { del } from "utils/api";
import { patch } from "utils/api";
import { put } from "utils/api";
import { fetchCurrency } from "services/shareService";
import InputField from "components/fields/InputField";
import FormikCheckbox from "components/checkbox/FormikCheckbox";
import { formatCurrency } from "utils/helpers";
import CurrencyField from "components/fields/CurrencyField";
import Switch from "components/switch";
import useCustomModalStyles from "utils/useCustomModalStyles";

const Salary = () => {
  const modalStyle = useCustomModalStyles();
  const dropdownButton = <FaEllipsisV className="cursor-pointer" />;
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const currentUserRoleName = useAppSelector(
    (state) => state.auth.currentUser?.role?.name === "CompanyAdmin"
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenModal = () => {
    setDisabledInputs(false);
    setIsModalOpen(true);
    setMinumumWageSelected(false);
  };
  const handleCloseModal = () => setIsModalOpen(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const [disabledInputs, setDisabledInputs] = useState(false);
  const [selectedPayPeriod, setSelectedPayPeriod] = useState("");
  const [minumumWageSelected, setMinumumWageSelected] = useState(false);
  const [originalMinWageAmount, setOriginalMinWageAmount] = useState(null);
  const [originalMinWageCurrency, setOriginalMinWageCurrency] = useState("");
  const deleteHandle = async (id) => {
    const result = window.confirm(`Silmek istediğinize emin misiniz?`);
    if (result) {
      const response = await del(ENDPOINTS.SALARYS, id);
      if (response.status === 204) {
        getDatas();
        toast.success("Başarıyla silindi");
      } else {
        toast.error("Bir hata oluştu");
      }
    }
  };
  const getDatas = async () => {
    setLoading(true);
    const response = await get(
      ENDPOINTS.SALARYS + "?user__id=" + id + "&ordering=-create_time"
    );
    const updatedData = response?.results.map((data) => ({
      ...data,
      actions: (
        <div className="whitespace-nowrap px-6 py-4 text-right text-sm font-medium">
          <div className="flex items-center justify-end">
            <Dropdown button={dropdownButton} classNames="right-0">
              <div className="rounded bg-white p-2 shadow-lg dark:bg-navy-700 dark:text-white">
                <ul className="text-sm">
                  {/* <li
                    onClick={() => editHandle(data.id)}
                    className="cursor-pointer p-2 hover:bg-gray-100"
                  >
                    Düzenle
                  </li> */}
                  <li
                    onClick={() => deleteHandle(data.id)}
                    className="cursor-pointer p-2 hover:bg-gray-100 dark:hover:bg-navy-600"
                  >
                    Sil
                  </li>
                </ul>
              </div>
            </Dropdown>
          </div>
        </div>
      ),
    }));
    setData(updatedData);
    setLoading(false);
  };
  useEffect(() => {
    getDatas();
  }, [id]);

  const handleMinumumWageChange = async (event, setFieldValue) => {
    const isChecked = event.target.checked;
    setFieldValue("minumum_wage", isChecked);
    setMinumumWageSelected(isChecked);
    if (isChecked) {
      setFieldValue("is_gross", true);
      const response = await get(
        ENDPOINTS.MINUMUM_WAGE + "?period=" + new Date().getFullYear()
      );
      if (response?.results.length > 0) {
        const data = response?.results[0];
        const monthlyAmount = parseFloat(data.amount).toString();
        const dailyAmount = parseFloat(data.amount / 30)
          .toFixed(2)
          .toString();
        const amount =
          selectedPayPeriod === "Günlük" ? dailyAmount : monthlyAmount;
        setOriginalMinWageAmount(data.amount);
        setOriginalMinWageCurrency(data.currency);
        console.log("amount", amount);
        let numericValue = parseFloat(amount);
        let formattedValue = numericValue.toLocaleString("tr-TR", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        console.log("formattedValue", formattedValue);
        setFieldValue("amount", formattedValue);
        setFieldValue("currency", data.currency);
        setDisabledInputs(true);
      }
    } else {
      setFieldValue("is_gross", false);
      setDisabledInputs(false); // Inputların disable durumunu kaldır
    }
  };
  const handlePayPeriodChange = (option, setFieldValue) => {
    setSelectedPayPeriod(option);
    if (option && minumumWageSelected) {
      let amount = originalMinWageAmount;
      if (option.label === "Günlük") {
        amount = (parseFloat(originalMinWageAmount) / 30).toFixed(2);
      }
      setFieldValue("amount", amount.toString());
    }
    setFieldValue("pay_period", option ? option.value : ""); // Formik state'inde pay_period'ı güncelleyin
  };
  const loadPayPeriodOptions = async (inputValue) => {
    if (minumumWageSelected) {
      // Eğer minimum wage seçili ise, sadece Aylık ve Günlük seçeneklerini döndür
      return [
        { label: "Aylık", value: "1" },
        { label: "Günlük", value: "3" },
      ];
    } else {
      const response = await get(
        ENDPOINTS.PAY_PERIOD + "?search=" + inputValue
      );
      const data = response?.results.map((payPeriod) => ({
        label: payPeriod.name,
        value: payPeriod.id,
      }));
      return data;
    }
  };
  const initialValues = {
    effective_on: new Date(),
    pay_period: "",
    currency: "",
    amount: "",
    is_gross: true,
    agi_included: false,
    minumum_wage: false,
  };
  const handleSubmit = async (values) => {
    var formData = new FormData();
    let cleanedAmount;

    // Giriş verisini doğru formata dönüştürme
    if (values.amount.includes(",") && values.amount.includes(".")) {
      // Türkiye formatı (örn: 20.000,99)
      cleanedAmount = values.amount.replace(/\./g, "").replace(/,/g, ".");
    } else {
      // Uluslararası format (örn: 20000.99)
      cleanedAmount = values.amount.replace(/,/g, "");
    }

    // Ondalık sayıya dönüştürme ve iki basamak yuvarlama
    let amountValue = parseFloat(cleanedAmount).toFixed(2);

    // Form verilerini eklemek
    formData.append("pay_period", values.pay_period);
    formData.append("effective_on", formatISODate(values.effective_on));
    formData.append("currency", values.currency);
    formData.append("amount", amountValue);
    formData.append("is_gross", values.is_gross);
    formData.append("agi_included", values.agi_included);
    formData.append("minumum_wage", values.minumum_wage);
    formData.append("user", id);
    formData.append("company", currentUser?.company?.id);

    // API'ye göndermek
    const response = await post(ENDPOINTS.SALARYS, formData);
    if (response.status === 201) {
      handleCloseModal();
      getDatas();
    } else {
      console.log(response);
      toast.error("Bir hata oluştu");
    }
  };
  const columnsDataWithActions = currentUserRoleName
    ? [...salaryColumsData, { label: "Eylemler", accessor: "actions" }]
    : salaryColumsData;
  return (
    <div className="p-4">
      {currentUserRoleName && (
        <button
          onClick={handleOpenModal}
          className="flex flex-col items-center justify-center rounded-lg bg-blue-500 p-2 text-sm text-white hover:bg-blue-600"
        >
          + Maaş Bilgisi Ekle
        </button>
      )}

      <DataTable
        columnsData={columnsDataWithActions}
        tableData={data}
        title="Maaşlar"
        hiddenColumns={["id"]}
        loading={loading}
      />
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => {
          setIsModalOpen(false);
        }}
        style={modalStyle}
        contentLabel={"Maaş Ekle"}
        ariaHideApp={false}
      >
        <h2 className="mb-10 rounded border-b-2 bg-blue-600 p-2 text-center text-xl text-white">
          Maaş Ekle
        </h2>
        <Formik
          initialValues={initialValues}
          onSubmit={(values, { resetForm }) => {
            handleSubmit(values);
            resetForm();
          }}
          validationSchema={salaryAddValidationSchema}
        >
          {({
            isSubmitting,
            handleChange,
            handleBlur,
            values,
            setFieldValue,
            errors,
            touched,
          }) => (
            <Form className="space-y-4">
              <div className="-mx-2 flex flex-wrap">
                <div className="mb-4 w-1/3 px-2">
                  <CurrencyField
                    label="Maaş"
                    name="salary"
                    onChange={(e) => {
                      setFieldValue("amount", e.target.value);
                    }}
                    onBlur={handleBlur}
                    value={values.amount}
                    disabled={disabledInputs}
                  />
                  {errors.amount && touched.amount ? (
                    <div className="mt-1 text-xs text-red-500">
                      {errors.amount}
                    </div>
                  ) : null}
                </div>
                <div className="mb-4 w-1/3 px-4 py-10">
                  <Switch
                    name="minumum_wage"
                    label="Asgari Ücret"
                    onChange={(e) => handleMinumumWageChange(e, setFieldValue)}
                    onBlur={handleBlur}
                    value={values.minumum_wage}
                  />
                  {errors.minumum_wage && touched.minumum_wage ? (
                    <div className="mt-1 text-xs text-red-500">
                      {errors.minumum_wage}
                    </div>
                  ) : null}
                </div>
                <div className="mb-4 w-1/3 px-4 py-10">
                  <Switch
                    disabled={minumumWageSelected}
                    name="is_gross"
                    label={values.is_gross ? "Brüt" : "Net"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.is_gross}
                    checked={values.is_gross}
                  />
                  {errors.is_gross && touched.is_gross ? (
                    <div className="mt-1 text-xs text-red-500">
                      {errors.is_gross}
                    </div>
                  ) : null}
                </div>
                {/* <div className="mb-4 w-1/3 px-2 py-1">
                  <label className="mb-2 block capitalize" htmlFor="manager">
                    Para Birimi
                  </label>
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    loadOptions={loadCurrencyOptions}
                    onChange={(option) =>
                      setFieldValue("currency", option ? option.value : "")
                    }
                    name="currency"
                    isClearable
                    placeholder="Seçiniz"
                  />
                  {errors.currency && touched.currency ? (
                    <div className="mt-1 text-xs text-red-500">
                      {errors.currency}
                    </div>
                  ) : null}
                </div> */}
              </div>
              <div className="-mx-2 flex flex-wrap">
                <div className="mb-4 w-1/2 px-2">
                  <label className="mb-2 block capitalize" htmlFor="manager">
                    Geçerlilik Tarihi
                  </label>
                  <FormikDatePicker
                    name="effective_on"
                    selected={initialValues.effective_on}
                  />
                  {errors.effective_on && touched.effective_on ? (
                    <div className="mt-1 text-xs text-red-500">
                      {errors.effective_on}
                    </div>
                  ) : null}
                </div>
                <div className="mb-4 w-1/2 px-2 py-1">
                  <label className="mb-2 block capitalize" htmlFor="manager">
                    Maaş Periyodu
                  </label>
                  <AsyncSelect
                    key={
                      minumumWageSelected
                        ? "minWageSelected"
                        : "minWageNotSelected"
                    }
                    cacheOptions
                    defaultOptions
                    loadOptions={loadPayPeriodOptions}
                    onChange={(option) =>
                      handlePayPeriodChange(option, setFieldValue)
                    }
                    value={selectedPayPeriod} // Seçili değeri burada kullanın
                    name="pay_period"
                    isClearable
                    placeholder="Seçiniz"
                  />
                  {errors.pay_period && touched.pay_period ? (
                    <div className="mt-1 text-xs text-red-500">
                      {errors.pay_period}
                    </div>
                  ) : null}
                </div>
                
              </div>
              <div className="flex justify-end space-x-2">
                <button
                  type="button"
                  onClick={handleCloseModal}
                  className="rounded-md bg-gray-300 px-4 py-2 text-sm text-gray-700 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                >
                  İptal
                </button>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="rounded-md bg-blue-600 px-4 py-2 text-sm text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  Kaydet
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default Salary;
