import React, { useState } from "react";
import Position from "./Position";
import Salary from "./Salary";
import WorkSchedule from "./WorkSchedule";
import Performance from "./Performance";

const JobInfo = () => {
  const [activeTab, setActiveTab] = useState("Pozisyon");
  const tabs = [
    { name: "Pozisyon", component: <Position /> },
    { name: "Maaş", component: <Salary /> },
    {name: "Çalışma Takvimi", component: <WorkSchedule />}
    // { name: "Performans", component: <Performance /> },
  ];

  return (
    <div className="bg-white shadow-lg dark:bg-navy-700">
      <div className="border-b border-gray-200 dark:border-navy-700">
        <ul
          className="flex flex-wrap text-center text-sm font-medium"
          id="myTab"
          data-tabs-toggle="#myTabContent"
          role="tablist"
        >
          {tabs.map((tab, index) => (
            <li className="mr-2" key={index}>
              <button
                className={`border-transparent inline-block rounded-t-lg border-b-2 p-4 hover:border-gray-300 hover:text-gray-600 dark:hover:text-gray-300 ${
                  tab.name === activeTab
                    ? "border-blue-600 text-blue-600 dark:border-blue-500 dark:text-blue-500"
                    : "text-gray-500 dark:text-gray-400"
                }`}
                onClick={() => setActiveTab(tab.name)}
                role="tab"
              >
                {tab.name}
              </button>
            </li>
          ))}
        </ul>
      </div>
      <div id="myTabContent" className="dark:bg-navy-800">
        {tabs.find((tab) => tab.name === activeTab)?.component}
      </div>
    </div>
  );
};

export default JobInfo;
