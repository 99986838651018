import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-hot-toast";
import { FaArrowLeft } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { get } from "utils/api";
import { post } from "utils/api";
import { ENDPOINTS } from "utils/constants";
import InputMask from 'react-input-mask';

const VerifyPhone = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [code, setCode] = useState("");
  const [phone, setPhone] = useState("");
  const [isSendSms, setIsSendSms] = useState(false);
  const handleSendSms = async () => {
    if (!phone) {
      toast.error("Lütfen geçerli bir telefon numarası giriniz.");
    }
    try {
      const response = await post(ENDPOINTS.RESEND_VERIFY_PHONE, { phone });
      if (response.status === 200) {
        setIsSendSms(true);
        toast.success("Doğrulama kodu telefonunuza gönderilmiştir.");
      }
    } catch (error) {
      console.log("error", error);
      if (error?.error !== undefined) {
        toast.error(error.error);
      }
    }
  };
  const handleVerifyPhone = async () => {
    if (!code || !phone) {
      toast.error("Tüm alanları doldurun.");
      return;
    }
    var formData = new FormData();
    formData.append("phone", phone);
    formData.append("code", code);
    try{
      const result = await post(ENDPOINTS.VERIFY_PHONE, formData);
      if (result.status === 200) {
        toast.success("Telefon numaranız başarıyla doğrulandı.");
        navigate("/app");
      } else {
        toast.error("Doğrulama kodu hatalı. Lütfen tekrar deneyin.");
      }
    }
    catch (error) {
      console.log("error", error);
      if (error) {
        toast.error(error[0]);
      }
    }
    
  };

  return (
    <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign in section */}
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Telefon Doğrulama
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Telefon numaranızı doğrulayın, eğer telefon numaranızı hatırlamıyorsanız lütfen bize ulaşın. <a href="mailto:info@beyazik.com">info@beyazik.com</a>
        </p>

        {isSendSms ? (
          <>
            <input
              type="text"
              placeholder="Doğrulama Kodunu Girin"
              value={code}
              className="mt-1 flex h-12 w-full items-center justify-center rounded-xl border bg-white p-3 text-sm outline-none dark:bg-navy-700 dark:text-white"
              onChange={(e) => setCode(e.target.value)}
            />
            <button
              className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              onClick={handleVerifyPhone}
            >
              Doğrula
            </button>
          </>
        ) : (
          <>
            <InputMask
              mask="05999999999"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className="mt-1 flex h-12 w-full items-center justify-center rounded-xl border bg-white p-3 text-sm outline-none dark:bg-navy-700 dark:text-white"
              placeholder="Telefon Numaranız"
            />
           
            <button
              onClick={handleSendSms}
              className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            >
              Doğrulama kodu al
            </button>
          </>
        )}
        <Link
          to={"/auth/giris-yap"}
          className="text-primary _600 mt-4 flex items-center text-sm font-medium text-navy-700 dark:text-gray-600"
        >
          <FaArrowLeft /> Giriş sayfasına git
        </Link>
      </div>
    </div>
  );
};

export default VerifyPhone;
