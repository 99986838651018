// Loading.js
import React from "react";
import Logo from "../../assets/img/loading.png";

const Loading = () => {
  return (
    <div className="flex items-center justify-center h-full">
      <img src={Logo} alt="Loading" className="animate-spin w-16 h-16" />
    </div>
  );
};

export default Loading;