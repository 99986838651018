import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  format,
  addDays,
  isWeekend,
  differenceInCalendarDays,
  setHours,
  setMinutes,
  startOfDay,
  startOfToday,
} from "date-fns";
import { get } from "utils/api";
import { ENDPOINTS } from "utils/constants";
import capitalizeString from "utils/helpers";
import { post } from "utils/api";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import Switch from "components/switch";
import { put } from "utils/api";
import AsyncSelect from "react-select/async";
import InputField from "components/fields/InputField";
import FormikDatePicker from "components/datepicker";
import useCustomSelectStyles from "utils/useCustomSelectStyles";

const NewUserTrainings = ({ userId, onClose, onRefresh, trainingId }) => {
  const selectStyle = useCustomSelectStyles();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const [loading, setLoading] = useState(false);

  const [isEditMode, setIsEditMode] = useState(false);
  const [formData, setFormData] = useState(null);

  const loadUserTrainingOptions = async (inputValue) => {
    const response = await get(ENDPOINTS.TRAININGS + "?search=" + inputValue);
    return response.results.map((item) => ({
      label: item.name,
      value: item.id,
    }));
  };
  useEffect(() => {
    if (trainingId) {
      setIsEditMode(true);
      fetchData();
    } else {
      setIsEditMode(false);
      setDefaultValues();
    }
  }, [trainingId]);

  const fetchData = async () => {
    const response = await get(`${ENDPOINTS.USER_TRAININGS}${trainingId}/`);

    const data = await response;
    setFormData({
      training: data.training,
      status: data.status,
      completed_date: format(new Date(data.completed_date), "yyyy-MM-dd"),
      rating: data.rating,
      description: data.description,
    });
  };
  const setDefaultValues = () => {
    setFormData({
      training: "",
      status: "",
      completed_date: format(startOfToday(), "yyyy-MM-dd"),
      rating: "",
      description: "",
    });
  };

  const validationSchema = Yup.object().shape({
    status: Yup.string().required("Durum zorunludur"),
    completed_date: Yup.date().required("Tamamlanma tarihi zorunludur"),
    rating: Yup.string().required("Puan zorunludur"),
    description: Yup.string().required("Açıklama zorunludur"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    const data = {
      training: values.training?.id,
      status: values.status,
      completed_date: format(new Date(values.completed_date), "yyyy-MM-dd"),
      rating: values.rating,
      description: values.description,
      user: userId,
      company: currentUser?.company?.id,
    };

    let response;
    if (isEditMode) {
      response = await put(`${ENDPOINTS.USER_TRAININGS}${trainingId}/`, data);
    } else {
      response = await post(ENDPOINTS.USER_TRAININGS, data);
    }

    if (response.status === 201 || response.status === 200) {
      toast.success(`Başarıyla ${isEditMode ? "güncellendi" : "oluşturuldu"}`);
      onClose();
      onRefresh();
    } else {
      toast.error(
        `Eğitim ${
          isEditMode ? "güncellenirken" : "oluşturulurken"
        } bir hata oluştu`
      );
    }
    setSubmitting(false);
  };
  if (!formData) return <div>Yükleniyor...</div>;

  return (
    <div className="mx-auto  rounded-lg bg-white dark:bg-navy-700 dark:text-white p-7 shadow-lg">
      <button
        onClick={onClose}
        className="absolute top-0 right-0 m-2 text-2xl font-semibold"
      >
        ×
      </button>
      <h2 className="mb-4 text-center text-xl font-semibold text-gray-800 dark:text-white">
        {isEditMode ? "Eğitim Atamasını Düzenle" : "Eğitim Ataması Oluştur"}
      </h2>
      <Formik
        initialValues={formData}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({
          values,
          setFieldValue,
          isSubmitting,
          handleBlur,
          handleChange,
        }) => (
          <Form className="space-y-4">
            <div className="flex justify-between">
              <div className="flex-1">
                <label
                  htmlFor="training"
                  className="block text-sm font-medium text-gray-700 dark:text-white"
                >
                  Eğitim
                </label>
                <AsyncSelect
                 styles={selectStyle}
                  cacheOptions
                  defaultOptions
                  loadOptions={loadUserTrainingOptions}
                  onChange={(option) =>
                    setFieldValue("training", {
                      id: option.value,
                      name: option.label,
                    })
                  }
                  name="training"
                  isClearable
                  placeholder="Seçiniz"
                  value={
                    values.training
                      ? {
                          value: values.training.id,
                          label: values.training.name,
                        }
                      : null
                  }
                />
                <ErrorMessage
                  name="training"
                  component="div"
                  className="text-sm text-red-600"
                />
              </div>
            </div>
            <div className="flex justify-between">
              <div className="flex-1">
                <label
                  htmlFor="status"
                  className="ml-3 text-sm font-bold text-navy-700 dark:text-white"
                >
                  Durumu
                </label>
                <select
                  name="status"
                  value={values.status}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="mt-1 block h-12 w-full rounded-md border border-gray-300 p-2 shadow-sm dark:bg-navy-700 dark:text-white"
                >
                  <option value="">Seçiniz</option>
                  <option value="Atlandı">Atlandı</option>
                  <option value="Devam Ediyor">Devam Ediyor</option>
                  <option value="Beklemede">Beklemede</option>
                  <option value="İptal Edildi">İptal Edildi</option>
                  <option value="Tamamlandı">Tamamlandı</option>
                  <option value="Katılmadı">Katılmadı</option>
                  <option value="Muaf">Muaf</option>
                </select>
              </div>
            </div>
            <div className="flex justify-between">
              <div className="flex-1">
                <label
                  htmlFor="completed_date"
                  className="block text-sm font-medium text-gray-700"
                >
                  Tamamlanma Tarihi
                </label>
                <FormikDatePicker
                  name="completed_date"
                  selected={values.completed_date}
                />
                <ErrorMessage
                  name="completed_date"
                  component="div"
                  className="text-sm text-red-600"
                />
              </div>
              <div className="ml-2 flex-1">
                <label
                  htmlFor="raiting"
                  className="ml-3 text-sm font-bold text-navy-700"
                >
                  Değerlendirme
                </label>
                <select
                  name="rating"
                  value={values.rating}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="mt-1 block h-12 w-full rounded-md border border-gray-300 p-2 shadow-sm dark:bg-navy-700 dark:text-white"
                >
                  <option value="">Seçiniz</option>
                  {[...Array(10).keys()].map((number) => (
                    <option key={number} value={number + 1}>
                      {number + 1}
                    </option>
                  ))}
                </select>
                <ErrorMessage
                name="rating"
                component="div"
                className="text-sm text-red-600"
              />
              </div>
            </div>
            {/* Açıklama */}
            <div>
              <label
                htmlFor="description"
                className="block text-sm font-medium text-gray-700"
              >
                Açıklama
              </label>
              <Field
                as="textarea"
                name="description"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 dark:bg-navy-700 dark:text-white"
              />
              <ErrorMessage
                name="description"
                component="div"
                className="text-sm text-red-600"
              />
            </div>
            <button
              type="submit"
              disabled={isSubmitting}
              className="border-transparent flex w-full justify-center rounded-md border bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 dark:border-none"
            >
              {isEditMode ? "Güncelle" : "Kaydet"}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default NewUserTrainings;
