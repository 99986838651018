import React from 'react';
import { Field } from 'formik';
// Saat seçeneklerini oluşturan fonksiyon
const createTimeOptions = () => {
    const options = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const time = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
        options.push(<option key={time} value={time}>{time}</option>);
      }
    }
    return options;
  };
  
  // Formik formunda kullanmak üzere InputField yerine özel bir saat seçim komponenti
  const TimeSelectField = ({ label, name }) => (
    <div>
      <label htmlFor={name} className="text-sm text-navy-700 dark:text-white">{label}</label>
      <Field as="select" name={name} className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none">
        {createTimeOptions()}
      </Field>
    </div>
  );
  export default TimeSelectField;