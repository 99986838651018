import React, { useEffect, useRef, useState } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { Form, Formik } from "formik";
import ReCAPTCHA from "react-google-recaptcha";
import InputField from "components/fields/InputField";
import { createUser } from "../../services/userService";
import { signupSchema } from "./validaitons/authValidations";
import { useAppDispatch } from "store";
import { useAppSelector } from "store";
import PhoneField from "components/fields/PhoneField";
import { post } from "utils/api";
import { ENDPOINTS } from "utils/constants";

function Signup() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { accessToken, currentUser } = useAppSelector((state) => state.auth);
  const [captchaValue, setCaptchaValue] = useState(null);
  const [isVerifying, setIsVerifying] = useState(false);
  const recaptchaRef = useRef(null);
  const [timer, setTimer] = useState(0);
  const timerRef = useRef(null);
  const [verificationCode, setVerificationCode] = useState("");
  const [phoneToVerify, setPhoneToVerify] = useState("");
  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timerRef.current) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const onCaptchaChange = (value) => {
    setCaptchaValue(value);
  };
  const resetCaptcha = () => {
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
  };

  const handleSubmit = async (values) => {
    if (!captchaValue) {
      toast.error("Lütfen reCAPTCHA'yı doğrulayın.");
      return;
    }
    values.captcha = captchaValue;
    values.phone = values.company_phone;
    var response = await createUser(values);
    if (response.status === "success") {
      toast.success(
        "Hesabınız başarılı bir şekilde oluşturuldu. Lütfen telefon numaranızı doğrulayın."
      );
      setIsVerifying(true);
      onPhoneSubmit(values.phone); 
      setTimer(120); 
    } else {
      toast.error("Kayıt işlemi sırasında bir hata oluştu.");
    }
    resetCaptcha();
  };

  useEffect(() => {
    if (currentUser && !currentUser.company) {
      toast.error(
        "Lütfen şirket bilgilerinizi tamamlayınız. Bizimle iletişime geçiniz."
      );
    }
  }, [currentUser]);

  if (accessToken && currentUser && currentUser.company) {
    return (
      <Navigate to={location?.state?.returnUrl || "/app"} replace={true} />
    );
  }


  const handleVerification = async (event) => {
    event.preventDefault();
    if(!verificationCode) {
      toast.error("Doğrulama kodunu girmeniz gerekmektedir.");
      return;
    }
    var formData = new FormData();
    formData.append("phone", phoneToVerify);
    formData.append("code", verificationCode);
    const result = await post(ENDPOINTS.VERIFY_PHONE, formData);
    if (result.status === 200) {
      toast.success("Telefon numaranız başarıyla doğrulandı.");
      setIsVerifying(false);
      navigate("/app"); // Kullanıcıyı anasayfaya yönlendir
    } else {
      toast.error("Doğrulama kodu hatalı. Lütfen tekrar deneyin.");
    }
  };

  const handleResendCode = async () => {
    const result = await post(ENDPOINTS.RESEND_VERIFY_PHONE, {
      phone: phoneToVerify,
    });
    if (result.status === 200) {
      toast.success("Doğrulama kodu yeniden gönderildi.");
    } else {
      toast.error(
        "Kod yeniden gönderilirken bir hata oluştu. Lütfen tekrar deneyin."
      );
    }
  };

  // Telefon numarasını almak için kullanıcı giriş yaparken
  const onPhoneSubmit = (phone) => {
    setPhoneToVerify(phone);
    setIsVerifying(true);
  };

  return (
    <div className="mt-10 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      <div className="w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Kayıt Ol
        </h4>
        <p className="mb-2 ml-1 text-base text-gray-600">
          İnsan Kaynakları’nın basit haliyle tanışın.
        </p>
        {isVerifying ? (
          <div>
            <input
              type="text"
              placeholder="Doğrulama Kodunu Girin"
              required
              className="mt-1 flex h-12 w-full items-center justify-center rounded-xl border bg-white p-3 text-sm outline-none dark:bg-navy-700 dark:text-white"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
            />
            <button onClick={handleVerification} className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">Doğrula</button>
            <button className="linear mt-2 w-full rounded-xl bg-gray-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-gray-600 active:bg-gray-700 dark:bg-gray-400 dark:text-white dark:hover:bg-gray-300 dark:active:bg-gray-200" disabled={timer > 0} onClick={handleResendCode}>
            {timer > 0 ? `Kodu Yeniden Gönder (${timer})` : "Kodu Yeniden Gönder"}
          </button>
          </div>
        ) : (
          <Formik
            initialValues={{
              first_name: "",
              last_name: "",
              email: "",
              password: "",
              company_name: "",
              company_phone: "",
              phone: "",
              company_website: "",
            }}
            validationSchema={signupSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, errors, touched }) => (
              <Form className="text-sm">
                <div className="-mx-3 mb-3 flex flex-wrap">
                  <div className="mb-3 w-full px-3 md:mb-0 md:w-1/2">
                    <InputField
                      name="first_name"
                      label="Ad*"
                      placeholder="Adınız"
                    />
                  </div>
                  <div className="w-full px-3 md:w-1/2">
                    <InputField
                      name="last_name"
                      label="Soyad*"
                      placeholder="Soyadınız"
                    />
                  </div>
                </div>
                <InputField
                  name="email"
                  extra="mb-3"
                  label="Eposta*"
                  placeholder="Şirket eposta adresiniz"
                />
                <InputField
                  name="password"
                  extra="mb-3"
                  label="Parola*"
                  type="password"
                  placeholder="Şifreniz"
                />
                <InputField
                  name="company_name"
                  extra="mb-3"
                  label="Şirket Adı*"
                  placeholder="Şirket adı"
                />
                <PhoneField
                  name="company_phone"
                  extra="mb-3"
                  label="Şirket Telefonu*"
                  placeholder="05551234567"
                />
                <InputField
                  name="company_website"
                  extra="mb-3"
                  label="Şirket Web Sitesi"
                  placeholder="Şirket web sitesi"
                />
                <ReCAPTCHA
                  sitekey="6LczMMYpAAAAAD7rzAe0I9TPeV7gMgpWYj389SzT"
                  onChange={onCaptchaChange}
                  ref={recaptchaRef}
                />
                <button
                  disabled={isSubmitting}
                  className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                  onClick={handleSubmit}
                >
                  Kayıt Ol
                </button>
                <div className="mt-4">
                  <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
                    Zaten bir hesabınız var mı?
                  </span>
                  <Link
                    to="/auth/giris-yap"
                    className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
                  >
                    Giriş Yapın
                  </Link>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </div>
  );
}
export default Signup;
