import * as Yup from 'yup';

export const signupSchema = Yup.object().shape({
    first_name: Yup.string()
    .max(30, 'Çok uzun!')
    .required('Gerekli'),
  last_name: Yup.string()
    .max(30, 'Çok uzun!')
    .required('Gerekli'),
  email: Yup.string().email('Geçersiz e-posta').required('Gerekli'),
  password: Yup.string()
    .required('Gerekli')
    .min(8, 'Parola en az 8 karakter olmalıdır')
    .matches(/[a-zA-Z]/, 'Parola en az bir harf içermelidir'),
  company_name: Yup.string().required('Gerekli'),
  company_phone: Yup.string().required('Gerekli'),
});