import { format } from "date-fns";
import { tr } from "date-fns/locale";
import formatDate from "utils/formatDate";
export let timelogColumsData = [
  {
    Header: "Başlangıç",
    accessor: (row) => formatDate(row.start_datetime),
  },
  {
    Header: "Süre",
    accessor: (row) => row.hour + " saat " + row.minute + " dakika",
  },
  {
    Header: "Açıklama",
    accessor: "description",
  },
  {
    Header: "Durum",
    accessor: (row) => row.status,
  },
  {
    Header: "Oluşturma Tarihi",
    accessor: (row) => formatDate(row.create_time),
  },
];