import InputField from "components/fields/InputField";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { FaArrowLeft, FaEllipsisV, FaPlus } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import Modal from "react-modal";

import { get } from "utils/api";
import { ENDPOINTS } from "utils/constants";
import { post } from "utils/api";
import toast from "react-hot-toast";
import Dropdown from "components/dropdown";
import { del } from "utils/api";
import { put } from "utils/api";
import { getById } from "utils/api";
import FormikCheckbox from "components/checkbox/FormikCheckbox";
import TextField from "components/fields/TextField";
import formatDate from "utils/formatDate";
import FormikDateTimePicker from "components/datepicker/FormikDateTimePicker";
import { format, parseISO } from "date-fns";
import { formatDateWithTime } from "utils/formatDate";
import { formatISODateTime } from "utils/helpers";
import { addTrainingFormValidationSchema } from "../validations/SettingsValidationSchema";
import useCustomModalStyles from "utils/useCustomModalStyles";
const Trainings = () => {
  const modalStyle = useCustomModalStyles();
  const formattedDateTime = new Date();
  const formattedDate = formattedDateTime.toISOString().split("T")[0]; // Tarihi alır: YYYY-MM-DD
  const hours = formattedDateTime.getHours().toString().padStart(2, '0'); // Saati alır ve iki basamaklı yapar
  const minutes = formattedDateTime.getMinutes().toString().padStart(2, '0'); // Dakikayı alır ve iki basamaklı yapar
  const today = `${formattedDate} ${hours}:${minutes}`; // Tüm bilgileri birleştirir
  
  const [isEdit, setIsEdit] = useState(false);
  const [editingItem, setEditingItem] = useState({ id: null, name: "" });
  const [name, setName] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [datas, setDatas] = useState([]);

  const handleOpenModal = (isEditing = false, itemDetails = null) => {
    setIsEdit(isEditing);
    if (itemDetails) {
      setEditingItem(itemDetails);
    } else {
      setEditingItem({
        id: null,
        name: "",
        start_datetime: today,
        end_datetime: today,
        instructor_company_details: "",
        instructor_name: "",
        location: "",
        repeat_frequency: 0,
        description: "",
      });
    }
    setIsModalOpen(true);
  };
  const handleCloseModal = () => setIsModalOpen(false);

  const editHandle = (name, id) => {
    handleOpenModal(true, { id, name });
    const itemToEdit = datas.find((item) => item.id === id);

    if (itemToEdit) {
      // Çalışma günü detaylarını düzenleme formunda kullanılmak üzere hazırlama
      const itemDetails = {
        id: itemToEdit.id,
        name: itemToEdit.name,
        start_datetime: new Date(itemToEdit.start_datetime),
        end_datetime: new Date(itemToEdit.end_datetime),
        instructor_company_details: itemToEdit.instructor_company_details,
        instructor_name: itemToEdit.instructor_name,
        location: itemToEdit.location,
        repeat_frequency: itemToEdit.repeat_frequency,
        description: itemToEdit.description,
      };

      // Düzenleme modu ve ilgili detaylar ile modalı açma
      handleOpenModal(true, itemDetails);
    } else {
      toast.error("Düzenlemek istediğiniz veri bulunamadı.");
    }
  };

  const deleteHandle = async (title, id) => {
    const result = window.confirm(`${title} silmek istediğinize emin misiniz?`);
    if (result) {
      const response = await del(ENDPOINTS.TRAININGS, id);
      if (response.status === 204) {
        getDatas();
        toast.success("Başarıyla silindi");
      } else {
        toast.error("Bir hata oluştu");
      }
    }
  };

  const editSubmit = async (values) => {
    console.log("values: ", values);

    var formData = new FormData();
    formData.append("name", values.name);
    formData.append("start_datetime", formatISODateTime(values.start_datetime));
    formData.append("end_datetime", formatISODateTime(values.end_datetime));
    formData.append(
      "instructor_company_details",
      values.instructor_company_details
    );
    formData.append("instructor_name", values.instructor_name);
    formData.append("location", values.location);
    formData.append("repeat_frequency", values.repeat_frequency);
    formData.append("description", values.description);

    const response = await put(ENDPOINTS.TRAININGS + values.id + "/", formData);
    if (response.status === 200) {
      getDatas();
      handleCloseModal();
      toast.success("Başarıyla düzenlendi");
    } else {
      toast.error("Bir hata oluştu");
    }
  };
  const getDatas = async () => {
    const response = await get(ENDPOINTS.TRAININGS);
    setDatas(response.results);
  };
  useEffect(() => {
    getDatas();
  }, []);

  const handleSubmit = async (values) => {
    var formData = new FormData();
    formData.append("name", values.name);
    formData.append("start_datetime", formatISODateTime(values.start_datetime));
    formData.append("end_datetime", formatISODateTime(values.end_datetime));
    formData.append(
      "instructor_company_details",
      values.instructor_company_details
    );
    formData.append("instructor_name", values.instructor_name);
    formData.append("location", values.location);
    formData.append("repeat_frequency", values.repeat_frequency);
    formData.append("description", values.description);

    const response = await post(ENDPOINTS.TRAININGS, formData);
    if (response.status === 201) {
      getDatas();
      handleCloseModal();
      toast.success("Eğitim başarıyla eklendi");
    } else {
      toast.error("Bir hata oluştu");
    }
  };
  const dropdownButton = <FaEllipsisV className="cursor-pointer" />;

  return (
    <div className="bg-white dark:bg-navy-800 dark:text-white">
      <div className="flex items-center justify-between bg-white p-4 shadow dark:bg-navy-800 dark:text-white">
        <div className="flex items-center">
          <span className="text-lg font-semibold">Eğitimler</span>
        </div>
        <button
          onClick={() => handleOpenModal()}
          className="flex items-center rounded bg-blue-500 py-2 px-4 text-white hover:bg-blue-700"
        >
          <FaPlus className="col-span-1" />
          <span className="col-span-1 ml-1">Yeni Ekle</span>
        </button>
      </div>
      <div className="p-1 pt-8">
        <div className="rounded-lg shadow">
          <table className="w-full text-left text-sm text-gray-700">
            <thead className="border-b-2 border-gray-200 bg-gray-50 dark:bg-gray-800 dark:text-white">
              <tr>
                <th className="px-6 py-3">Eğitim Adı</th>
                <th className="px-6 py-3">Eğitim Veren Şirket</th>
                <th className="px-6 py-3">Eğitmen</th>
                <th className="px-6 py-3">Geçerlilik Süresi (ay)</th>
                <th className="px-6 py-3">Başlangıç</th>
                <th className="px-6 py-3">Bitiş</th>
                <th className="px-6 py-3">Verildiği Yer</th>
                <th className="px-6 py-3">Detaylar</th>
                <th className="px-6 py-3 text-right">İşlemler</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white dark:bg-navy-700 dark:text-white">
              {/* Örnek satır */}
              {datas.length > 0 ? (   
              datas.map((data) => (
                <tr>
                  <td className="whitespace-nowrap px-6 py-4 text-gray-900 dark:text-white">
                    {data.name}
                  </td>
                  <td className="whitespace-nowrap px-6 py-4 text-gray-900 dark:text-white">
                    {data.instructor_company_details}
                  </td>
                  <td className="whitespace-nowrap px-6 py-4 text-gray-900 dark:text-white">
                    {data.instructor_name}
                  </td>
                  <td className="whitespace-nowrap px-6 py-4 text-gray-900 dark:text-white">
                    {data.repeat_frequency}
                  </td>
                  <td className="whitespace-nowrap px-6 py-4 text-gray-900 dark:text-white">
                    {formatDateWithTime(data.start_datetime)}
                  </td>
                  <td className="whitespace-nowrap px-6 py-4 text-gray-900 dark:text-white">
                    {formatDateWithTime(data.end_datetime)}
                  </td>
                  <td className="whitespace-nowrap px-6 py-4 text-gray-900 dark:text-white">
                    {data.location}
                  </td>
                  <td className="whitespace-nowrap px-6 py-4 text-gray-900 dark:text-white">
                    {data.description}
                  </td>
                  <td className="whitespace-nowrap px-6 py-4 text-right text-sm font-medium">
                    <div className="flex items-center justify-end">
                      <Dropdown button={dropdownButton} classNames="right-0">
                        <div className="rounded bg-white p-2 shadow-lg dark:bg-navy-800 dark:text-white">
                          <ul className="text-sm">
                            <li
                              onClick={() => editHandle(data.name, data.id)}
                              className="cursor-pointer p-2 hover:bg-gray-100 dark:hover:bg-navy-900"
                            >
                              Düzenle
                            </li>
                            <li
                              onClick={() => deleteHandle(data.name, data.id)}
                              className="cursor-pointer p-2 hover:bg-gray-100 dark:hover:bg-navy-900"
                            >
                              Sil
                            </li>
                          </ul>
                        </div>
                      </Dropdown>
                    </div>
                  </td>
                </tr>
              ))) : (
                <tr>
                  <td colSpan="9" className="text-center p-4">
                    Gösterilecek kayıt bulunamadı
                  </td>
                </tr>
              )} 
           

              {/* Diğer satırlar benzer şekilde eklenir */}
            </tbody>
          </table>
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        style={modalStyle}
        contentLabel={isEdit ? "Süreci Düzenle" : "Yeni Süreç Ekle"}
        ariaHideApp={false}
      >
        <Formik
          initialValues={{
            id: editingItem.id,
            name: editingItem.name,
            start_datetime: editingItem.start_datetime,
            end_datetime: editingItem.end_datetime,
            instructor_company_details: editingItem.instructor_company_details,
            instructor_name: editingItem.instructor_name,
            location: editingItem.location,
            repeat_frequency: editingItem.repeat_frequency,
            description: editingItem.description,
          }}
          validationSchema={addTrainingFormValidationSchema}
          onSubmit={(values, { resetForm }) => {
            if (isEdit) {
              editSubmit(values);
            } else {
              handleSubmit(values);
            }
            resetForm();
            setIsModalOpen(false);
            setIsEdit(false);
          }}
        >
          {({
            isSubmitting,
            handleChange,
            handleBlur,
            values,
            setFieldValue,
          }) => (
            <Form className="space-y-4">
              <div className="mt-4">
                <InputField
                  label="Eğitim Adı"
                  id="name"
                  name="name"
                  type="text"
                  placeholder="Eğitim Adı"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                />
              </div>
              <div className="-mx-2 flex flex-wrap">
                <div className="mb-4 w-1/2 px-2">
                  <InputField
                    label="Geçerlilik süresi (ay)"
                    id="repeat_frequency"
                    name="repeat_frequency"
                    type="text"
                    placeholder="Geçerlilik süresi (ay)"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.repeat_frequency}
                  />
                </div>
                <div className="mb-4 w-1/2 px-2">
                  <InputField
                    label="Eğitim Veren Şirket"
                    id="instructor_company_details"
                    name="instructor_company_details"
                    type="text"
                    placeholder="Eğitim Veren Şirket"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.instructor_company_details}
                  />
                </div>
              </div>
              <div className="-mx-2 flex flex-wrap">
                <div className="mb-4 w-1/2 px-2">
                  <InputField
                    label="Eğitmen"
                    id="instructor_name"
                    name="instructor_name"
                    type="text"
                    placeholder="Eğitmen"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.instructor_name}
                  />
                </div>
                <div className="mb-4 w-1/2 px-2">
                  <InputField
                    label="Verildiği yer"
                    id="location"
                    name="location"
                    type="text"
                    placeholder="Eğitimin verildiği yer"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.location}
                  />
                </div>
              </div>
              <div className="mt-4">
                {/* Başlangıç Tarihi */}
                <FormikDateTimePicker
                  name="start_datetime"
                  selected={values.start_datetime}
                  label="Başlangıç Tarihi"
                  onChange={(date) => setFieldValue("start_datetime", date)}
                />
                <FormikDateTimePicker
                  name="end_datetime"
                  selected={values.end_datetime}
                  label="Bitiş Tarihi"
                  onChange={(date) => setFieldValue("end_datetime", date)}
                />
              </div>
              <div className="mt-4">
                <TextField
                  label="Açıklama"
                  id="description"
                  name="description"
                  type="text"
                  placeholder="Açıklama ekleyin..."
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.description}
                />
              </div>
              <div className="flex justify-end space-x-2">
                <button
                  type="button"
                  onClick={() => setIsModalOpen(false)}
                  className="rounded-md bg-gray-300 px-4 py-2 text-sm text-gray-700 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                >
                  İptal
                </button>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="rounded-md bg-blue-600 px-4 py-2 text-sm text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  {isEdit ? "Kaydet" : "Ekle"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default Trainings;
