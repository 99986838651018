import React from "react";
import Notification from "./components/Notification";

const NotificationSettings = () => {
  return (
    <div className="flex w-full flex-col gap-5">
      <div className="w-ful mt-3 flex h-fit flex-col gap-5 lg:grid lg:grid-cols-1">
        <Notification />
      </div>
    </div>
  );
};

export default NotificationSettings;
