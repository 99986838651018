import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useRef,
} from "react";
import Modal from "react-modal";
import { useTable } from "react-table";
import { FaFileImport, FaMagic, FaPlus } from "react-icons/fa";
import { Formik, Form } from "formik";
import InputField from "components/fields/InputField";
import useCustomModalStyles from "utils/useCustomModalStyles";
import toast from "react-hot-toast";
import TimeRangeSlider from "./components/TimeRangeSlider";
import {
  format,
  startOfMonth,
  endOfMonth,
  subDays,
  subWeeks,
  addMonths,
  startOfToday,
  endOfToday,
} from "date-fns";
import { tr } from "date-fns/locale";
import { get, post } from "utils/api";
import { ENDPOINTS } from "utils/constants";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import trLocale from "date-fns/locale/tr";
import { useAppSelector } from "store";
import { put } from "utils/api";
import { patch } from "utils/api";
import Loading from "components/loading";
import { Tooltip } from "react-tooltip";

registerLocale("tr", trLocale);

Modal.setAppElement("#root");

const TimeSheets = () => {
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const [loading, setLoading] = useState(false);
  const [loadingAnalysis, setLoadingAnalysis] = useState(false);
  const modalStyle = useCustomModalStyles();
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);
  const [isTimeSheetModalOpen, setIsTimeSheetModalOpen] = useState(false);
  const [timeRange, setTimeRange] = useState({ startTime: 510, endTime: 1050 });
  const [users, setUsers] = useState([]);
  const [timeSheetData, setTimeSheetData] = useState([]);
  const [dateRange, setDateRange] = useState([
    startOfMonth(new Date()),
    endOfMonth(new Date()),
  ]);
  const [step, setStep] = useState(5); // Default step is 1 minute
  const [defaultStart, setDefaultStart] = useState(510); // 08:30
  const [defaultEnd, setDefaultEnd] = useState(1050); // 17:30
  const tableRef = useRef(null);
  const [editMode, setEditMode] = useState(false);
  const [currentTimesheetId, setCurrentTimesheetId] = useState(null);
  const [initialFormValues, setInitialFormValues] = useState({
    start_datetime: new Date(),
    end_datetime: new Date(),
    description: "",
  });
  const getTimeSheets = async () => {
    setLoading(true);
    try {
      const response = await get(
        ENDPOINTS.TIMESHEET + "?ordering=-start_datetime&limit=10000"
      );
      setTimeSheetData(response.results);
    } catch (error) {
      toast.error("Çalışma saatleri yüklenirken hata oluştu.");
    }
    setLoading(false);
  };

  const getUsers = async () => {
    setLoading(true);
    try {
      const response = await get(ENDPOINTS.USERS);
      setUsers(response.results);
    } catch (error) {
      toast.error("Kullanıcılar yüklenirken hata oluştu.");
    }
    setLoading(false);
  };
  const isLate = (startTime, shiftStart) => {
    const [shiftStartHour, shiftStartMinute] = shiftStart
      .split(":")
      .map(Number);
    const shiftStartMinutes = shiftStartHour * 60 + shiftStartMinute;
    return startTime > shiftStartMinutes;
  };

  const isEarlyLeave = (endTime, shiftEnd) => {
    const [shiftEndHour, shiftEndMinute] = shiftEnd.split(":").map(Number);
    const shiftEndMinutes = shiftEndHour * 60 + shiftEndMinute;
    return endTime < shiftEndMinutes;
  };
  const getDefaultTimes = async () => {
    const todayIndex = new Date().getDay(); // 0: Pazar, 1: Pazartesi, ..., 6: Cumartesi
    const days = [
      "Pazar",
      "Pazartesi",
      "Salı",
      "Çarşamba",
      "Perşembe",
      "Cuma",
      "Cumartesi",
    ];
    const workingDayDetails =
      currentUser?.working_days?.working_day_details || [];

    const todayName = days[todayIndex];
    let todayShift = workingDayDetails.find(
      (day) => day.day === todayName && day.is_visible
    );
    if (todayShift === undefined) {
      todayShift = workingDayDetails.find(
        (day) => day.day === "Pazartesi" && day.is_visible
      );
    }
    const shiftStart = todayShift
      ? todayShift.shift_start.split(":")
      : ["09", "00"];
    const shiftEnd = todayShift
      ? todayShift.shift_end.split(":")
      : ["18", "00"];
    const startMinutes =
      parseInt(shiftStart[0], 10) * 60 + parseInt(shiftStart[1], 10);
    const endMinutes =
      parseInt(shiftEnd[0], 10) * 60 + parseInt(shiftEnd[1], 10);

    setDefaultStart(startMinutes);
    setDefaultEnd(endMinutes);
  };

  useEffect(() => {
    getUsers();
    getTimeSheets();
    getDefaultTimes();
  }, []);

  const addTimeSheet = async (values) => {
    const startDateTime = new Date(
      selectedDay.getFullYear(),
      selectedDay.getMonth(),
      selectedDay.getDate(),
      Math.floor(timeRange.startTime / 60),
      timeRange.startTime % 60
    );
    const endDateTime = new Date(
      selectedDay.getFullYear(),
      selectedDay.getMonth(),
      selectedDay.getDate(),
      Math.floor(timeRange.endTime / 60),
      timeRange.endTime % 60
    );
    if (startDateTime >= endDateTime) {
      toast.error("Başlangıç saati bitiş saatinden büyük olamaz.");
      return;
    }
    const formData = new FormData();
    formData.append("user", selectedUser.id);
    formData.append("start_datetime", startDateTime.toISOString());
    formData.append("end_datetime", endDateTime.toISOString());
    formData.append("description", values.description);

    try {
      const response = await post(ENDPOINTS.TIMESHEET, formData);
      if (response.status === 201) {
        toast.success("Çalışma süresi başarıyla eklendi.");
        getTimeSheets(); // Refresh time sheet data
        setIsTimeSheetModalOpen(false);
      }
    } catch (error) {
      toast.error("Çalışma süresi eklenirken hata oluştu.");
    }
  };
  const updateTimeSheet = async (values) => {
    const startDateTime = new Date(
      selectedDay.getFullYear(),
      selectedDay.getMonth(),
      selectedDay.getDate(),
      Math.floor(timeRange.startTime / 60),
      timeRange.startTime % 60
    );
    const endDateTime = new Date(
      selectedDay.getFullYear(),
      selectedDay.getMonth(),
      selectedDay.getDate(),
      Math.floor(timeRange.endTime / 60),
      timeRange.endTime % 60
    );
    if (startDateTime >= endDateTime) {
      toast.error("Başlangıç saati bitiş saatinden büyük olamaz.");
      return;
    }
    const formData = new FormData();
    formData.append("start_datetime", startDateTime.toISOString());
    formData.append("end_datetime", endDateTime.toISOString());
    formData.append("description", values.description);

    try {
      const response = await patch(
        `${ENDPOINTS.TIMESHEET}${currentTimesheetId}/`,
        formData
      );
      if (response.status === 200) {
        toast.success("Çalışma süresi başarıyla güncellendi.");
        getTimeSheets(); // Refresh time sheet data
        setIsTimeSheetModalOpen(false);
      }
    } catch (error) {
      toast.error("Çalışma süresi güncellenirken hata oluştu.");
    }
  };
  const openTimeSheetModal = useCallback(
    (userId, day, timesheet = null) => {
      const selectedUserObject = users.find((u) => u.id === userId);
      setSelectedUser(selectedUserObject);
      // Güncellenmiş tarih ayarlaması: Doğru yıl, ay ve gün ile yeni bir Date nesnesi oluşturuluyor
      setSelectedDay(
        new Date(dateRange[0].getFullYear(), dateRange[0].getMonth(), day)
      );

      if (timesheet) {
        const startDate = new Date(timesheet.start_datetime);
        const endDate = new Date(timesheet.end_datetime);
        setTimeRange({
          startTime: startDate.getHours() * 60 + startDate.getMinutes(),
          endTime: endDate.getHours() * 60 + endDate.getMinutes(),
        });
        setInitialFormValues({
          start_datetime: startDate,
          end_datetime: endDate,
          description: timesheet.description || "",
        });
        setEditMode(true);
        setCurrentTimesheetId(timesheet.id);
      } else {
        setTimeRange({ startTime: defaultStart, endTime: defaultEnd });
        setInitialFormValues({
          start_datetime: new Date(),
          end_datetime: new Date(),
          description: "",
        });
        setEditMode(false);
        setCurrentTimesheetId(null);
      }

      setIsTimeSheetModalOpen(true);
    },
    [users, defaultStart, defaultEnd]
  );

  const closeModal = useCallback(() => {
    setIsTimeSheetModalOpen(false);
  }, []);

  const handleTimeChange = useCallback((field, value) => {
    setTimeRange((prev) => ({ ...prev, [field]: value }));
  }, []);

  const formatTime = (value) => {
    const hours = Math.floor(value / 60);
    const minutes = value % 60;
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}`;
  };

  const filteredDays = useMemo(() => {
    const days = [];
    let current = new Date(dateRange[0]);
    while (current <= dateRange[1]) {
      days.push(new Date(current));
      current.setDate(current.getDate() + 1);
    }
    return days;
  }, [dateRange]);

  const columns = useMemo(
    () => [
      {
        Header: "Çalışanlar",
        accessor: (row) => row.first_name + " " + row.last_name,
      },
      ...filteredDays.map((day) => ({
        Header: format(day, "d MMM", { locale: tr }),
        Cell: ({ row }) => {
          const dayShiftDetails =
            currentUser?.working_days?.working_day_details.find(
              (d) =>
                format(day, "EEEE", { locale: tr }) === d.day && d.is_visible
            );
          const shiftStart = dayShiftDetails
            ? dayShiftDetails.shift_start
            : "09:00";
          const shiftEnd = dayShiftDetails
            ? dayShiftDetails.shift_end
            : "18:00";

          const userTimeSheet = timeSheetData.find(
            (ts) =>
              ts.user === row.original.id &&
              new Date(ts.start_datetime).toDateString() === day.toDateString()
          );

          const startTime = userTimeSheet
            ? new Date(userTimeSheet.start_datetime)
            : null;
          const endTime = userTimeSheet
            ? new Date(userTimeSheet.end_datetime)
            : null;
          const late = startTime
            ? isLate(
                startTime.getHours() * 60 + startTime.getMinutes(),
                shiftStart
              )
            : false;
          const earlyLeave = endTime
            ? isEarlyLeave(
                endTime.getHours() * 60 + endTime.getMinutes(),
                shiftEnd
              )
            : false;

          const textStyle =
            late || earlyLeave ? "text-red-600" : "text-green-600";

          return userTimeSheet ? (
            <div
              className={`cursor-pointer text-xs font-bold ${textStyle}`}
              onClick={() =>
                openTimeSheetModal(
                  row.original.id,
                  day.getDate(),
                  userTimeSheet
                )
              }
            >
              {format(startTime, "HH:mm")} - {format(endTime, "HH:mm")}
            </div>
          ) : (
            <button
              className={`text-blue-500 ${
                new Date().toDateString() === day.toDateString()
                  ? "bg-yellow-100"
                  : ""
              }`}
              onClick={() => openTimeSheetModal(row.original.id, day.getDate())}
            >
              <FaPlus />
            </button>
          );
        },
      })),
    ],
    [filteredDays, openTimeSheetModal, timeSheetData]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: users });

  useEffect(() => {
    if (tableRef.current) {
      const todayIndex = filteredDays.findIndex(
        (day) => day.toDateString() === new Date().toDateString()
      );
      const columnWidth = 100;
      tableRef.current.scrollLeft =
        todayIndex * columnWidth - window.innerWidth / 2;
    }
  }, [filteredDays]);

  const predefinedRanges = [
    { label: "Bugün", range: [startOfToday(), endOfToday()] },
    {
      label: "Dün",
      range: [subDays(startOfToday(), 1), subDays(endOfToday(), 1)],
    },
    {
      label: "Son 1 Hafta",
      range: [subWeeks(startOfToday(), 1), endOfToday()],
    },
    { label: "Son 1 Ay", range: [subDays(startOfToday(), 30), endOfToday()] },
  ];
  const handleAnalysis = async () => {
    const result = window.confirm(
      `Emin misiniz?`
    );
    if (result) {
      setLoadingAnalysis(true);
      const formData = new FormData();
      formData.append("analysis_date", new Date().toISOString());
      try {
        const response = await post(ENDPOINTS.TIMESHEET_ANALYSIS, formData);
        if (response.status === 200) {
          toast.success("Çalışma saatleri otomatik çözümlendi.");
          getTimeSheets();
          setLoadingAnalysis(false);
        }
      } catch (error) {
        setLoadingAnalysis(false);
        toast.error("Çalışma saatleri otomatik çözümlenirken hata oluştu.");
      }
    }
  };
  return (
    <div className="rounded-lg bg-white">
      {loading ? (
        <Loading /> // Loading bileşenini göster
      ) : (
        <div className="p-4">
          <div className="mb-4 flex items-center">
            <DatePicker
              selectsRange
              startDate={dateRange[0]}
              endDate={dateRange[1]}
              onChange={(update) => {
                if (update[1] && update[1] > addMonths(update[0], 1)) {
                  update[1] = addMonths(update[0], 1);
                }
                setDateRange(update);
              }}
              withPortal
              isClearable
              locale="tr"
              dateFormat="dd.MM.yyyy"
              className="w-60 rounded border p-2"
              maxDate={addMonths(dateRange[0], 1)}
              showMonthDropdown
              showYearDropdown
              dropdownMode="scroll"
            />
            <button
              onClick={() =>
                setDateRange([startOfMonth(new Date()), endOfMonth(new Date())])
              }
              className="ml-2 rounded bg-blue-500 py-2 px-4 text-sm font-bold text-white hover:bg-blue-700"
            >
              Bu Ay
            </button>
            {predefinedRanges.map((range) => (
              <button
                key={range.label}
                onClick={() => setDateRange(range.range)}
                className="ml-2 rounded bg-blue-500 py-2 px-4 text-sm font-bold text-white hover:bg-blue-700"
              >
                {range.label}
              </button>
            ))}
            <button
              disabled={loadingAnalysis}
              className="ml-auto flex items-center rounded bg-red-500 py-2 px-4 text-sm font-bold text-white hover:bg-red-700 disabled:cursor-not-allowed disabled:opacity-50"
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Tüm çalışanlarınıza bugün için otomatik çözümleme yapar."
              onClick={handleAnalysis}
            >
              <FaMagic className="mr-2" /> Otomatik Çözümle
            </button>
            <Tooltip id="my-tooltip" />
            {/* <button
            className="flex items-center ml-2 rounded ml-auto bg-red-700 py-2 px-4 text-sm font-bold text-white hover:bg-blue-700"
          >
            <FaFileImport className="mr-2"/> İçeri Aktar (excel)
          </button> */}
          </div>

          <div className="overflow-x-auto" ref={tableRef}>
            <table
              {...getTableProps()}
              className="min-w-full table-fixed bg-white"
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr
                    {...headerGroup.getHeaderGroupProps()}
                    className="border-b"
                  >
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps()}
                        className="px-4 py-2"
                        style={
                          column.id === "Çalışanlar"
                            ? {
                                position: "sticky",
                                left: 0,
                                background: "white",
                              }
                            : {}
                        }
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr
                      {...row.getRowProps()}
                      className="border-b hover:bg-gray-100"
                    >
                      {row.cells.map((cell) => (
                        <td
                          {...cell.getCellProps()}
                          className="px-4 py-2 text-center text-sm capitalize text-navy-700"
                          style={
                            cell.column.id === "Çalışanlar"
                              ? {
                                  position: "sticky",
                                  left: 0,
                                  background: "white",
                                }
                              : {}
                          }
                        >
                          {cell.render("Cell")}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <Modal
            isOpen={isTimeSheetModalOpen}
            onClose={closeModal}
            style={modalStyle}
            contentLabel="Time Sheet Modal"
          >
            <div className="p-4">
              <button
                onClick={closeModal}
                className="absolute top-0 right-0 m-2 text-lg font-semibold"
              >
                X
              </button>
              <h2 className="mb-4 text-xl font-semibold capitalize">
                {selectedUser &&
                  `${selectedUser.first_name} ${selectedUser.last_name}`}{" "}
                -{" "}
                {selectedDay && format(selectedDay, "dd MMMM", { locale: tr })}{" "}
                Çalışma Saati
              </h2>
              <div className="mb-4">
                <label
                  htmlFor="step"
                  className="block text-sm font-medium text-gray-700"
                >
                  Çubuk Zaman Aralığı:
                </label>
                <select
                  id="step"
                  name="step"
                  value={step}
                  onChange={(e) => setStep(Number(e.target.value))}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 dark:bg-navy-700 dark:text-white sm:text-sm"
                >
                  <option value={1}>1 Dakika</option>
                  <option value={5}>5 Dakika</option>
                  <option value={15}>15 Dakika</option>
                </select>
              </div>
              <TimeRangeSlider
                startTime={timeRange.startTime}
                endTime={timeRange.endTime}
                onTimeChange={handleTimeChange}
                step={step}
              />
              <div className="mb-4 mt-4 flex items-center justify-between text-sm text-orange-400">
                <span>{`Başlangıç: ${formatTime(
                  timeRange.startTime
                )} - Bitiş: ${formatTime(timeRange.endTime)}`}</span>
              </div>
              <Formik
                initialValues={initialFormValues}
                enableReinitialize
                onSubmit={(values) =>
                  editMode ? updateTimeSheet(values) : addTimeSheet(values)
                }
              >
                {({ setFieldValue, values }) => (
                  <Form className="mt-4">
                    <div className="mt-2">
                      <InputField
                        label="Açıklama (opsiyonel)"
                        name="description"
                        type="text"
                        placeholder="Özel bir not ekleyin... (Hasta, izinli, vb.)"
                        value={values.description}
                      />
                    </div>{" "}
                    <div className="mt-4 flex justify-end">
                      <button
                        type="button"
                        onClick={closeModal}
                        className="mr-2 rounded bg-gray-500 py-2 px-4 font-bold text-white hover:bg-gray-700"
                      >
                        İptal
                      </button>
                      <button
                        type="submit"
                        className="rounded bg-blue-500 py-2 px-4 font-bold text-white hover:bg-blue-700"
                      >
                        Kaydet
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default TimeSheets;
