import DataTable from "components/datatable";
import React, { useEffect, useState } from "react";
import { holidayCategoryColumsData } from "../variables/columnsData";
import { ENDPOINTS } from "utils/constants";
import { get } from "utils/api";
import Dropdown from "components/dropdown";
import { FaArrowLeft, FaEllipsisV, FaPlus } from "react-icons/fa";
import { del } from "utils/api";
import toast from "react-hot-toast";
import { patch } from "utils/api";
import { post } from "utils/api";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import { Form, Formik } from "formik";
import InputField from "components/fields/InputField";

import useCustomModalStyles from "utils/useCustomModalStyles";


const PublicHolidays = () => {
  const modalStyle = useCustomModalStyles();
  const dropdownButton = <FaEllipsisV className="cursor-pointer" />;
  const [holidayData, setHolidayData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [editingItem, setEditingItem] = useState({ id: null, name: "" });

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = (
    isEditing = false,
    item = { id: null, name: "" }
  ) => {
    setIsEdit(isEditing);
    setEditingItem(item);
    setIsModalOpen(true);
  };
  const handleCloseModal = () => setIsModalOpen(false);

  const generateLinkPath = (rowData) => {
    // rowData üzerinden dinamik link yolu oluştur
    return `/app/ayarlar/tatiller/${rowData.id}`;
  };
  const editHandle = (name, id) => {
    handleOpenModal(true, { id, name });
  };

  const deleteHandle = async (name, id) => {
    const result = window.confirm(`${name} silmek istediğinize emin misiniz?`);
    if (result) {
      const response = await del(ENDPOINTS.HOLIDAY_CATEGORY, id);
      if (response.status === 204) {
        getHolidays();
        toast.success("Başarıyla silindi");
      } else {
        toast.error("Bir hata oluştu");
      }
    }
  };
  const editSubmit = async (values) => {
    var formData = new FormData();
    formData.append("name", values.name);
    const response = await patch(
      ENDPOINTS.HOLIDAY_CATEGORY + values.id + "/",
      formData
    );
    if (response.status === 200) {
      getHolidays();
      handleCloseModal();
      toast.success("Başarıyla düzenlendi");
    } else {
      toast.error("Bir hata oluştu");
    }
  };
  const handleSubmit = async (values) => {
    var formData = new FormData();
    formData.append("name", values.name);
    const response = await post(ENDPOINTS.HOLIDAY_CATEGORY, formData);
    if (response.status === 201) {
      getHolidays();
      handleCloseModal();
      toast.success("Başarıyla eklendi");
    } else {
      toast.error("Bir hata oluştu");
    }
  };
  const getHolidays = async () => {
    setLoading(true);
    const response = await get(ENDPOINTS.HOLIDAY_CATEGORY + "?ordering=-id");
    const updatedData = response?.results.map((data) => ({
      ...data,
      actions: (
        <div className="whitespace-nowrap px-6 py-4 text-right text-sm font-medium">
          <div className="flex items-center justify-end">
            <Dropdown button={dropdownButton} classNames="right-0">
              <div className="rounded bg-white dark:bg-navy-700 dark:text-white p-2 shadow-lg">
                <ul className="text-sm">
                  <li
                    onClick={() => editHandle(data.name, data.id)}
                    className="cursor-pointer p-2 hover:bg-gray-100 dark:hover:bg-navy-800"
                  >
                    Düzenle
                  </li>
                  <li
                    onClick={() => deleteHandle(data.name, data.id)}
                    className="cursor-pointer p-2 hover:bg-gray-100 dark:hover:bg-navy-800"
                  >
                    Sil
                  </li>
                </ul>
              </div>
            </Dropdown>
          </div>
        </div>
      ),
    }));
    setHolidayData(updatedData);
    setLoading(false);
  };

  useEffect(() => {
    getHolidays();
  }, []);
  const columnsDataWithActions = [
    ...holidayCategoryColumsData,
    { label: "Eylemler", accessor: "actions" },
  ];

  return (
    <div className="bg-white dark:bg-navy-800 dark:text-white">
      <div className="flex items-center justify-between bg-white p-4 dark:bg-navy-800 dark:text-white">
        <div className="flex items-center">
          <span className="text-lg font-semibold">
            Tatil Takvimleri
          </span>
        </div>
        <button
          onClick={() => handleOpenModal()}
          className="flex items-center rounded bg-blue-500 py-2 px-4 text-white hover:bg-blue-700"
        >
          <FaPlus className="col-span-1" />
          <span className="col-span-1 ml-1">Yeni Ekle</span>
        </button>
      </div>
      <DataTable
        columnsData={columnsDataWithActions}
        tableData={holidayData}
        title="Tatil Takvimleri"
        hiddenColumns={["id"]}
        linkPath={generateLinkPath}
        linkableColumn="name"
      />
         <Modal
        isOpen={isModalOpen}
        onRequestClose={() => {
          setIsModalOpen(false);
          setIsEdit(false);
          setEditingItem({ id: null, name: "" }); // Modal kapatıldığında editingItem'ı sıfırla
        }}
        style={modalStyle}
        contentLabel={isEdit ? "Süreci Düzenle" : "Yeni Süreç Ekle"}
        ariaHideApp={false}
      >
        <Formik
          initialValues={{ id:editingItem.id, name: editingItem.name }}
          onSubmit={(values, { resetForm }) => {
            if (isEdit) {
              editSubmit(values);
            } else {
              handleSubmit(values);
            }
            resetForm();
            setIsModalOpen(false);
            setIsEdit(false);
            setEditingItem({ id: null, name: "" }); // İşlem sonrası editingItem'ı sıfırla
          }}
        >
          {({ isSubmitting, handleChange, handleBlur, values }) => (
            <Form className="space-y-4">
              <div className="-mx-2 flex flex-wrap">
                <div className="mb-4 w-full px-2">
                  <InputField
                    label="Tatil Takvimi Adı"
                    id="name"
                    name="name"
                    type="text"
                    placeholder="Tatil Takvimi Adı Giriniz"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                  />
                </div>
              </div>

              <div className="flex justify-end space-x-2">
                <button
                  type="button"
                  onClick={() => setIsModalOpen(false)}
                  className="rounded-md bg-gray-300 px-4 py-2 text-sm text-gray-700 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                >
                  İptal
                </button>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="rounded-md bg-blue-600 px-4 py-2 text-sm text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  {isEdit ? "Kaydet" : "Ekle"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default PublicHolidays;
