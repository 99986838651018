import { Link } from "react-router-dom";
import { useAppSelector } from "store";
import NewLeave from "views/app/leaves/NewLeave";
import Modal from "react-modal";
import { useState } from "react";
import { MdClose } from "react-icons/md";
import NewExpense from "views/app/expense/components/NewExpense";
import { useSelector } from "react-redux";
import NewTimeLog from "views/app/employees/components/NewTimeLog";
import AdvanceDebtModal from "views/app/employees/components/PaymentModals/AdvanceDebtModal";
import ExpenseAddModal from "views/app/employees/components/PaymentModals/ExpenseAddModal";
import CommonAidModal from "views/app/employees/components/PaymentModals/CommonAidModal";
import useCustomModalStyles from "utils/useCustomModalStyles";
const FreeCard = () => {
  const modalStyle = useCustomModalStyles();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");

  const openMyModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedOption("");
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const renderModalContent = () => {
    switch (selectedOption) {
      case "leave":
        return (
          <>
            <NewLeave userId={currentUser?.id} onClose={closeModal} isOpen={true}/>
          </>
        );
      case "advance":
        return (
          <>
            <AdvanceDebtModal
              isOpen={modalIsOpen}
              userId={currentUser?.id}
              onClose={closeModal}
            />
          </>
        );
      case "timelog":
        return (
          <>
            <NewTimeLog userId={currentUser?.id} isOpen={true} onClose={closeModal} />
          </>
        );
      case "expense":
        return (
          <>
            <ExpenseAddModal
              isOpen={modalIsOpen}
              userId={currentUser?.id}
              onClose={closeModal}
            />
          </>
        );
      default:
        return (
          <>
            <div className="grid grid-cols-2 gap-3 p-3 text-gray-800">
              {currentUser?.company?.settings?.is_leave_request && (
                <button
                  className="flex flex-col items-center justify-center rounded-lg bg-blue-200 p-4 hover:bg-blue-300"
                  onClick={() => handleOptionClick("leave")}
                >
                  <span>İzin Talep Et</span>
                </button>
              )}
              {currentUser?.company?.settings?.is_advance_request && (
                <button
                  className="flex flex-col items-center justify-center rounded-lg bg-green-200 p-4 hover:bg-green-300"
                  onClick={() => handleOptionClick("advance")}
                >
                  <span>Avans Talep Et</span>
                </button>
              )}
              {currentUser?.company?.settings?.is_expense_request && (
                <button
                  className="flex flex-col items-center justify-center rounded-lg bg-red-200 p-4 hover:bg-red-300"
                  onClick={() => handleOptionClick("expense")}
                >
                  <span>Harcama Talep Et</span>
                </button>
              )}
              {currentUser?.company?.settings?.is_shift_request && (
                <button
                  className="flex flex-col items-center justify-center rounded-lg bg-gray-200 p-4 hover:bg-gray-300"
                  onClick={() => handleOptionClick("timelog")}
                >
                  <span>Fazla Mesai Talep Et</span>
                </button>
              )}
              {/* <button
                className="flex flex-col items-center justify-center rounded-lg bg-purple-200 p-4 hover:bg-purple-300"
                onClick={() => handleOptionClick("expense")}
              >
                <span>Vize Belgesi Talep Et</span>
              </button> */}
              {/* Diğer butonlar benzer şekilde */}
            </div>
          </>
        );
    }
  };

  const handleFormSubmit = (values, { setSubmitting }) => {
    console.log(values);
    setSubmitting(false);
  };
  return (
    <div className="relative mt-14 flex w-[256px] justify-center rounded-[20px] bg-gradient-to-br from-[#868CFF] via-[#432CF3] to-brand-500 pb-4">
      {/* <div className="absolute -top-12 flex h-24 w-24 items-center justify-center rounded-full border-[4px] border-white bg-gradient-to-b from-[#868CFF] to-brand-500 dark:!border-navy-800">
        <svg
          width="41"
          height="41"
          viewBox="0 0 41 41"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.0923 27.3033H30.8176V36.3143H10.0923V27.3033Z"
            fill="white"
          />
          <path
            d="M31.5385 29.1956C31.5385 26.2322 30.3707 23.3901 28.2922 21.2947C26.2136 19.1992 23.3945 18.022 20.4549 18.022C17.5154 18.022 14.6963 19.1992 12.6177 21.2947C10.5392 23.3901 9.37143 26.2322 9.37143 29.1956L20.4549 29.1956H31.5385Z"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M20.5 31.989C26.8452 31.989 31.989 26.8452 31.989 20.5C31.989 14.1548 26.8452 9.01099 20.5 9.01099C14.1548 9.01099 9.01099 14.1548 9.01099 20.5C9.01099 26.8452 14.1548 31.989 20.5 31.989ZM20.5 41C31.8218 41 41 31.8218 41 20.5C41 9.17816 31.8218 0 20.5 0C9.17816 0 0 9.17816 0 20.5C0 31.8218 9.17816 41 20.5 41Z"
            fill="white"
          />
        </svg>
    
      </div> */}

      <div className="mt-6 flex h-fit flex-col items-center">
        <p className="text-lg font-bold capitalize text-white">
          👋 Merhaba, {currentUser?.first_name}
        </p>
        <p className="mt-1 px-4 text-center text-sm text-white">
          Taleplerinizi hızlı bir şekilde oluşturun.
        </p>

        <Link
          onClick={openMyModal}
          className="text-medium mt-7 block rounded-full bg-gradient-to-b from-white/50 to-white/10 py-[12px] px-11 text-center text-base text-white hover:bg-gradient-to-b hover:from-white/40 hover:to-white/5 "
        >
          Talep Oluştur
        </Link>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={modalStyle}
        >
          {renderModalContent()}
        </Modal>
      </div>
    </div>
  );
};

export default FreeCard;
