import React from "react";
import Modal from "react-modal";
import { Formik, Form, Field, ErrorMessage } from "formik";

import { post } from "utils/api"; // API istekleri için
import { ENDPOINTS } from "utils/constants";
import * as Yup from "yup";
import InputField from "components/fields/InputField";
import Switch from "components/switch";
import toast from "react-hot-toast";
import { patch } from "utils/api";
import FormikDatePicker from "components/datepicker";
import { formatISODate } from "utils/helpers";
import useCustomModalStyles from "utils/useCustomModalStyles";

const PersonalInfoEducationStatusModal = ({
  isOpen,
  onClose,
  onRefresh,
  userId,
  currentData,
}) => {
  const modalStyle = useCustomModalStyles();
  const handleSubmit = async (values) => {
    var formData = new FormData();
    formData.append("educational_status", values.educational_status);
    formData.append("education_level", values.education_level);
    formData.append(
        "last_completed_educational_institution",
        values.last_completed_educational_institution
        );

    const response = await patch(ENDPOINTS.USERS + userId + "/", formData);
    if (response.status === 200) {
      onRefresh();
      onClose();
      toast.success("Başarılı bir şekilde değiştirildi");
    } else {
      toast.error("Bir hata oluştu");
    }
  };
  const initialValues = {
    educational_status: currentData?.educational_status || "",
    education_level: currentData?.education_level || "",
    last_completed_educational_institution: currentData?.last_completed_educational_institution || "",
  };

  const validationSchema = Yup.object().shape({
    // birth_date: Yup.string().required("Bu alan zorunludur"),
  });
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={modalStyle}>
      <div className="mx-auto rounded-lg bg-white p-4 shadow-lg">
        <button
          onClick={onClose}
          className="absolute top-0 right-0 m-2 text-2xl font-semibold"
        >
          ×
        </button>
        <h2 className="mb-4 text-center text-xl font-semibold text-gray-800">
          Eğitim
        </h2>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({
            values,
            setFieldValue,
            isSubmitting,
            handleBlur,
            handleChange,
            errors,
            touched,
          }) => (
            <Form className="space-y-4">
              <div className="flex flex-col md:flex-row md:items-center md:space-x-4">
                <div className="mb-4 flex-1">
                  <label
                    htmlFor="educational_status"
                    className="ml-3 text-sm font-bold text-navy-700 dark:text-white"
                  >
                    Eğitim Durumu
                  </label>
                  <select
                    name="educational_status"
                    value={values.educational_status}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="mt-1 block h-12 w-full rounded-md border border-gray-300 p-2 shadow-sm"
                  >
                    <option value="">Seçiniz</option>
                    <option value="Mezun">Mezun</option>
                    <option value="Öğrenci">Öğrenci</option>
                  </select>
                </div>
                <div className="mb-4 flex-1">
                  <label
                    htmlFor="education_level"
                    className="ml-3 text-sm font-bold text-navy-700 dark:text-white"
                  >
                    Tamamlanan En Yüksek Eğitim Seviyesi
                  </label>
                  <select
                    name="education_level"
                    value={values.education_level}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="mt-1 block h-12 w-full rounded-md border border-gray-300 p-2 shadow-sm"
                  >
                    <option value="">Seçiniz</option>
                    <option value="İlkokul">İlkokul</option>
                    <option value="Ortaokul">Ortaokul</option>
                    <option value="Lise">Lise</option>
                    <option value="Ön Lisans">Ön Lisans</option>
                    <option value="Lisans">Lisans</option>
                    <option value="Yüksek Lisans">Yüksek Lisans</option>
                    <option value="Doktora">Doktora</option>
                    <option value="Yok">Yok</option>
                  </select>
                </div>
              </div>
              <div className="flex flex-col md:flex-row md:items-center md:space-x-4">
        
                <div className="mb-4 flex-1">
                  <InputField
                    name="last_completed_educational_institution"
                    label="Son tamamlanan eğitim kurumu"
                    type="text"
                    placeholder="Son tamamlanan eğitim kurumu"
                  />
                </div>
              </div>
              <button
                type="submit"
                disabled={isSubmitting}
                className="border-transparent mt-2 flex w-full justify-center rounded-md border bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Kaydet
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default PersonalInfoEducationStatusModal;
