import axios from "axios";
import { get, post, put, del } from "../utils/api";
import BASE_URL, { ENDPOINTS } from "../utils/constants";
import toast from "react-hot-toast";

// GET isteği örneği:
export const fetchUsers = async () => {
  const users = await get(ENDPOINTS.USERS);
  return users;
};
export const fetchUserByUsername = async (username) => {
  const users = await get(ENDPOINTS.USERS + "?user__username=" + username);
  return users;
};
export const fetchUserByEmail = async (email) => {
  const users = await get(ENDPOINTS.USERS + "?user__email=" + email);
  return users;
};
export const getCurrentUser = async () => {
  const users = await get(ENDPOINTS.CURRENT_USER);
  users.permissions = await _getScopeTree(users.scope);
  users.scope = null;
  return users;
};
export const getCurrentCompany = async (id) => {
  const company = await get(ENDPOINTS.COMPANY + id + "/");
  return company;
};
export const _getScopeTree = async (fullList) => {
  let tree = {};
  let scopeArr = fullList.split(' ');
  for (let i in scopeArr) {
    if (i == "unique") continue;

    let scopeParts = scopeArr[i].split('.');
    let prev = tree;
    for (let j in scopeParts) {
      if (j == "unique") continue;

      if (!(scopeParts[j] in prev))
        prev[scopeParts[j]] = {};

      if (parseInt(j) < scopeParts.length - 1) {
        prev[scopeParts[j]] = prev[scopeParts[j]] === true ? {} : prev[scopeParts[j]];
        prev = prev[scopeParts[j]];
      }
      else {
        prev[scopeParts[j]] = true;
        prev = tree;
      }
    }
  }

  return tree;
}

export const checkPermission = (currentUserPermissions,scope) => {
  if(!currentUserPermissions) return false;
  if(!scope) return false;
  const scopeParts = scope.split('.');
  let permissions = currentUserPermissions;
  let prev = permissions;
  for (let i in scopeParts) {
    if (i == "unique") continue;

    if (prev[scopeParts[i]]) {
      prev = prev[scopeParts[i]];
      continue;
    }
    return false;
  }
  return true;
}

export const hasPermission = (currentUserPermissions,scopes) => {
  if(!currentUserPermissions) return false;
  if (!Array.isArray(scopes)) {
    scopes = [scopes];
  }
  for (let scope of scopes) {
    if (checkPermission(currentUserPermissions,scope)) return true;
  }

  return false;
}
// POST isteği örneği:
export const createUser = async (data) => {
  const api = axios.create({
    baseURL: BASE_URL,
  });

  try {
    const response = await api.post(ENDPOINTS.SIGNUP, data);
    const result = {
      status : "success",
      data : response?.data
    };
    return result;
  } catch (error) {
    if (error?.response?.status === 400) {
      const errorData = error?.response?.data;

      const result = {
        error: error?.response?.data,
        status: "error"
      }
      let errorMessage = "Kayıt sırasında bir hata oluştu. Lütfen bilgilerinizi kontrol edip yeniden deneyiniz.";
      if (errorData?.email?.includes("Bu alan benzersiz olmalıdır.")) {
        errorMessage = "Bu e-posta adresi zaten kullanımda. Lütfen başka bir e-posta adresi kullanın.";
      }
      if (errorData?.phone?.includes("Bu alan benzersiz olmalıdır.")) {
        errorMessage = "Bu telefon numarası ile daha önce kayıt olunmuş. Şifrenizi unuttuysanız şifrenizi sıfırlayabilirsiniz.";
      }
      if(errorData?.phone?.includes("Telefon numarası 05551234567 formatında olmalıdır.")){
        errorMessage = "Telefon numarası 05551234567 formatında olmalıdır.";
      }
      if(errorData?.password?.includes("Parola")){
        errorMessage = "Geçerli bir parola giriniz. Parolanız en az 8 karakterden oluşmalıdır, en az bir harf ve bir rakam içermelidir.";
      }
      if(errorData?.new_password?.includes("Bu parola çok geneldir")){
        errorMessage = "Bu parola çok geneldir. Lütfen daha güçlü bir parola seçiniz.";
      }
      toast.error(errorMessage, {duration: 7000});
      return result;
    }
    return Promise.reject(error);
  }
};
// export const createUser = async (data) => {
//   const api = axios.create({
//     baseURL: BASE_URL,
//   });
//   api
//     .post(ENDPOINTS.SIGNUP + "/", data)
//     .then((response) => {
//       return response?.data;
//     })
//     .catch((error) => {
//       if (error?.response?.status === 400) {
//         console.log("error.response.data", JSON.stringify(error.response.data));
//         Promise.resolve();
//         return error?.response?.data;
//       }
//       return Promise.reject(error);
//     });
// };
