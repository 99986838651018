import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import Cookies from "js-cookie";
import PieChartCard from "views/app/default/components/PieChartCard";

import {
  upComingLeavesColumnsData,
  holidaysColumnData,
  eventsColumnsData,
  birthdayColumnsData,
  myLeavesColumnsData,
  myEventsColumnsData,
} from "./variables/columnsData";

import UpcomingLeaves from "./components/UpcomingLeaves";
import UpcomingBirthdays from "./components/UpcomingBirthdays";
import ProfilCard from "./components/ProfilCard";
import Holidays from "./components/Holidays";
import { useEffect, useMemo, useState } from "react";
import { get } from "utils/api";
import { useAppSelector } from "../../../store";
import { ENDPOINTS } from "../../../utils/constants";
import EventCalendar from "./components/EventCalendar";
import MyLeaves from "./components/MyLeaves";
import { formatDateString } from "utils/helpers";
import MyEvents from "./components/MyEvents";
import PendingLeaves from "./components/PendingLeaves";

const Dashboard = () => {
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const defaultCards = [
    { id: "ProfilCard", content: "ProfilCard" },
    { id: "Holidays", content: "Holidays" },
    { id: "MyLeaves", content: "MyLeaves" },
    { id: "MyEvents", content: "MyEvents" },
  ];
  const getApprovalProcess = async () => {
    const response = await get(
      ENDPOINTS.APPROVAL_PROCESS + "?user__id__in=" + currentUser?.id
    );
    if (
      response.count > 0 ||
      currentUser?.role?.name === "CompanyAdmin"
    ) {
      if (!defaultCards.some(card => card.id === "PendingLeaves"))
        defaultCards.push({ id: "PendingLeaves", content: "PendingLeaves" });
    }
  };
 
  if (
    currentUser?.company?.settings?.show_widget_upcoming_leave_days_to_employees
  ) {
    defaultCards.push({ id: "UpcomingLeaves", content: "UpcomingLeaves" });
    defaultCards.push({
      id: "UpcomingBirthdays",
      content: "UpcomingBirthdays",
    });
    defaultCards.push({ id: "EventCalendar", content: "EventCalendar" });
  }
  if (currentUser?.role?.name === "CompanyAdmin") {
    defaultCards.push({ id: "PieChartCard", content: "PieChartCard" });
  }
 
  const savedCardsOrder = Cookies.get("cardsOrder");
  const initialCards = savedCardsOrder
    ? JSON.parse(savedCardsOrder)
    : defaultCards;

  const [cards, setCards] = useState(initialCards);

  const [leaveData, setLeaveData] = useState([]);
  const [leaveDataPending, setLeaveDataPending] = useState([]);
  const [holidayData, setHolidayData] = useState([]);
  const [eventsData, setEventsData] = useState([]);
  const [birthDayData, setBirthDayData] = useState([]);
  const [myLeavesData, setMyLeavesData] = useState([]);
  const [myEventsData, setMyEventsData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getMyLeaves = async () => {
    setLoading(true);
    const response = await get(
      `${ENDPOINTS.LEAVE}?ordering=-id&user__id=${currentUser?.id}`
    );
    setMyLeavesData(response?.results);
    setLoading(false);
  };
  const getMyEvents = async () => {
    setLoading(true);
    const response = await get(
      `${ENDPOINTS.USER_TRAININGS}?ordering=id&user__id=${currentUser?.id}`
    );
    setMyEventsData(response?.results);
    setLoading(false);
  };
  const getBirthdays = async () => {
    setLoading(true);
    const response = await get(ENDPOINTS.USER_BIRTHDAYS);
    setBirthDayData(response);
    setLoading(false);
  };
  const getEvents = async () => {
    setLoading(true);
    var today = new Date();
    const response = await get(
      ENDPOINTS.EVENTS +
        "?start_datetime_after=" +
        formatDateString(today) +
        "&ordering=start_datetime"
    );
    setEventsData(response?.results);
    setLoading(false);
  };
  const getLeaves = async () => {
    setLoading(true);
    const response = await get(ENDPOINTS.LEAVE_UPCOMING);
    setLeaveData(response?.results);
    setLoading(false);
  };
  const getPendingLeaves = async () => {
    setLoading(true);
    const response = await get(
      ENDPOINTS.LEAVE + "?status=Onay Bekliyor&ordering=-create_time"
    );
    setLeaveDataPending(response?.results);
    setLoading(false);
  };
  const getHolidays = async () => {
    setLoading(true);
    const response = await get(ENDPOINTS.HOLIDAYS + "?ordering=start_date");
    setHolidayData(response?.results);
    setLoading(false);
  };
  useEffect(() => {
    getApprovalProcess();
    getLeaves();
    getPendingLeaves();
    getHolidays();
    getEvents();
    getBirthdays();
    getMyLeaves();
    getMyEvents();
  }, []);
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const newCards = Array.from(cards);
    const [reorderedItem] = newCards.splice(result.source.index, 1);
    newCards.splice(result.destination.index, 0, reorderedItem);

    setCards(newCards);
    Cookies.set("cardsOrder", JSON.stringify(newCards));
  };
  const renderCard = (item) => {
    switch (item.content) {
      case "PendingLeaves":
        return (
          <PendingLeaves
            columnsData={upComingLeavesColumnsData}
            tableData={leaveDataPending}
            refreshData={getPendingLeaves}
            isEdit={true}
          />
        );
      case "ProfilCard":
        return <ProfilCard />;
      case "MyEvents":
        return (
          <MyEvents
            columnsData={myEventsColumnsData}
            tableData={myEventsData}
          />
        );
      case "EventCalendar":
        if (
          currentUser?.company?.settings
            ?.show_widget_upcoming_leave_days_to_employees
        ) {
          return (
            <EventCalendar
              columnsData={eventsColumnsData}
              tableData={eventsData}
            />
          );
        }
        return null;
      case "UpcomingLeaves":
        if (
          currentUser?.company?.settings
            ?.show_widget_upcoming_leave_days_to_employees
        ) {
          return (
            <UpcomingLeaves
              columnsData={upComingLeavesColumnsData}
              tableData={leaveData}
            />
          );
        }
        return null;
      case "UpcomingBirthdays":
        if (
          currentUser?.company?.settings
            ?.show_widget_upcoming_leave_days_to_employees
        ) {
          return (
            <UpcomingBirthdays
              columnsData={birthdayColumnsData}
              tableData={birthDayData}
            />
          );
        }
        return null;
      case "PieChartCard":
        if (currentUser?.role?.name === "CompanyAdmin") {
          return <PieChartCard />;
        }
        return null;
      case "Holidays":
        return (
          <Holidays columnsData={holidaysColumnData} tableData={holidayData} />
        );
      case "MyLeaves":
        return (
          <MyLeaves
            columnsData={myLeavesColumnsData}
            tableData={myLeavesData}
          />
        );
      default:
        return null;
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" direction="horizontal">
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 3xl:grid-cols-4"
          >
            {cards.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    {renderCard(item)}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default Dashboard;

// <div>
//                       <div className="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-2">
//                         <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
//                           <ProfilCard />
//                           <div className="grid grid-cols-1 rounded-[20px]">
//                             <EventCalendar
//                               columnsData={eventsColumnsData}
//                               tableData={eventsData}
//                             />
//                           </div>
//                         </div>

//                         <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
//                           <UpcomingLeaves
//                             columnsData={upComingLeavesColumnsData}
//                             tableData={leaveData}
//                           />
//                           <UpcomingBirthdays
//                             columnsData={birthdayColumnsData}
//                             tableData={birthDayData}
//                           />
//                         </div>

//                         <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
//                           <PieChartCard />
//                           <Holidays
//                             columnsData={holidaysColumnData}
//                             tableData={holidayData}
//                           />
//                         </div>
//                       </div>
//                     </div>
