import React from "react";
import Modal from "react-modal";
import { Formik, Form, Field } from "formik";

import { post } from "utils/api"; // API istekleri için
import { ENDPOINTS } from "utils/constants";
import * as Yup from "yup";
import InputField from "components/fields/InputField";
import Switch from "components/switch";
import toast from "react-hot-toast";
import { patch } from "utils/api";
import AsyncSelect from "react-select/async";
import { get } from "utils/api";
import useCustomModalStyles from "utils/useCustomModalStyles";
import useCustomSelectStyles from "utils/useCustomSelectStyles";
const ProfileSetManagerModal = ({ isOpen, onClose, onRefresh, userId, currentData}) => {
  const selectStyle = useCustomSelectStyles();
  const modalStyle = useCustomModalStyles();
  const loadUserOptions = async (inputValue) => {
    const response = await get(`${ENDPOINTS.USER_PROFILE}?search=${inputValue}`);
    const data = response?.results.map((payPeriod) => ({
      label: payPeriod.first_name + " " + payPeriod.last_name,
      value: payPeriod.id,
    }));
    return data;
  };

  const handleSubmit = async (values) => {
    var formData = new FormData();
    if(values.manager)
    {
      formData.append("manager", values.manager.value);
    }
    else {
      formData.append("manager", "");
    }
    const response = await patch(ENDPOINTS.USERS + userId + "/", formData);
    if (response.status === 200) {
      onRefresh();
      onClose();
      toast.success("Başarılı bir şekilde değiştirildi");
    } else {
      toast.error("Bir hata oluştu");
    }
  };
  const initialValues = {
    manager: currentData?.manager ? { label: currentData.manager.first_name + currentData.manager.last_name, value: currentData?.manager?.id } : null,
  };
  const validationSchema = Yup.object().shape({
  });
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={modalStyle}>
      <div className="mx-auto  rounded-lg bg-white p-6 shadow-lg dark:bg-navy-700 dark:text-white">
        <button
          onClick={onClose}
          className="absolute top-0 right-0 m-2 text-2xl font-semibold"
        >
          ×
        </button>
        <h2 className="mb-4 text-center text-xl font-semibold text-gray-800 dark:text-white">
          Yönetici Ata
        </h2>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({
            values,
            setFieldValue,
            isSubmitting,
            handleBlur,
            handleChange,
            errors,
            touched,
          }) => (
            <Form className="space-y-4">
              <div className="flex flex-col md:flex-row md:items-center md:space-x-4">
                <div className="mb-4 flex-1">
                  <label className="mb-2 block capitalize" htmlFor="manager">
                    Yönetici
                  </label>
                  <AsyncSelect
                  styles={selectStyle}
                    cacheOptions
                    defaultOptions
                    loadOptions={loadUserOptions}
                    onChange={(option) =>
                      setFieldValue("manager", option ? option : null)
                    }
                    name="manager"
                    isClearable
                    placeholder="Seçiniz"
                    value={values.manager ? { label: values.manager.label, value: values.manager.value } : null}
                    />
                  {errors.manager && touched.manager ? (
                    <div className="mt-1 text-xs text-red-500">
                      {errors.manager}
                    </div>
                  ) : null}
                </div>
              </div>
              <button
                type="submit"
                disabled={isSubmitting}
                className="border-transparent mt-2 flex w-full justify-center rounded-md border bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Kaydet
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default ProfileSetManagerModal;
