import React from "react";
import Modal from "react-modal";
import { Formik, Form, Field, ErrorMessage } from "formik";

import { post } from "utils/api"; // API istekleri için
import { ENDPOINTS } from "utils/constants";
import * as Yup from "yup";
import InputField from "components/fields/InputField";
import Switch from "components/switch";
import toast from "react-hot-toast";
import { patch } from "utils/api";
import FormikDatePicker from "components/datepicker";
import { formatISODate } from "utils/helpers";
import useCustomModalStyles from "utils/useCustomModalStyles";

const PersonalInfoBankInfoModal = ({
  isOpen,
  onClose,
  onRefresh,
  userId,
  currentData,
}) => {
  const modalStyle = useCustomModalStyles();
  const handleSubmit = async (values) => {
    var formData = new FormData();
    formData.append("bank_name", values.bank_name);
    formData.append("bank_account_type", values.bank_account_type);
    formData.append("bank_account_number", values.bank_account_number);
    formData.append("bank_iban", values.bank_iban);
    const response = await patch(ENDPOINTS.USERS + userId + "/", formData);
    if (response.status === 200) {
      onRefresh();
      onClose();
      toast.success("Başarılı bir şekilde değiştirildi");
    } else {
      toast.error("Bir hata oluştu");
    }
  };
  const initialValues = {
    bank_name: currentData?.bank_name || "",
    bank_account_type: currentData?.bank_account_type || "",
    bank_account_number: currentData?.bank_account_number || "",
    bank_iban: currentData?.bank_iban || "",
  };

  const validationSchema = Yup.object().shape({
    // birth_date: Yup.string().required("Bu alan zorunludur"),
  });
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={modalStyle}>
      <div className="mx-auto rounded-lg bg-white p-4 shadow-lg">
        <button
          onClick={onClose}
          className="absolute top-0 right-0 m-2 text-2xl font-semibold"
        >
          ×
        </button>
        <h2 className="mb-4 text-center text-xl font-semibold text-gray-800">
          Banka Bilgileri
        </h2>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({
            values,
            setFieldValue,
            isSubmitting,
            handleBlur,
            handleChange,
            errors,
            touched,
          }) => (
            <Form className="space-y-4">
              <div className="flex flex-col md:flex-row md:items-center md:space-x-4">
              <div className="mb-4 flex-1">
                  <InputField
                    name="bank_name"
                    label="Banka Adı"
                    type="text"
                    value={values.bank_name}
                  />
                </div>
                <div className="mb-4 flex-1">
                  <label
                    htmlFor="bank_account_type"
                    className="ml-3 text-sm font-bold text-navy-700 dark:text-white"
                  >
                    Hesap Tipi
                  </label>
                  <select
                    name="bank_account_type"
                    value={values.bank_account_type}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="mt-1 block h-12 w-full rounded-md border border-gray-300 p-2 shadow-sm"
                  >
                    <option value=""></option>
                    <option value="Vadesiz">Vadesiz</option>
                    <option value="Vadeli">Vadeli</option>
                    <option value="Çek">Çek</option>
                    <option value="Diğer">Diğer</option>
                  </select>
                </div>
              </div>
              <div className="flex flex-col md:flex-row md:items-center md:space-x-4">
        
                <div className="mb-4 flex-1">
                  <InputField
                    name="bank_account_number"
                    label="Hesap Numarası"
                    type="text"
                    value={values.bank_account_number}
                  />
                </div>
                <div className="mb-4 flex-1">
                  <InputField
                    name="bank_iban"
                    label="IBAN"
                    type="text"
                    value={values.bank_iban}
                  />
                </div>
              </div>
              <button
                type="submit"
                disabled={isSubmitting}
                className="border-transparent mt-2 flex w-full justify-center rounded-md border bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Kaydet
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default PersonalInfoBankInfoModal;
