import Tabs from "components/tabs";
import React from "react";
import { Link, Route, Routes } from "react-router-dom";
import SystemSettings from "./components/SystemSettings/SystemSettings";
import CompanyInfo from "./components/CompanyInfo";
import DataImport from "./components/DataImport";
import Rules from "./components/Rules/Rules";
import PublicHolidays from "./components/PublicHolidays";
import Trainings from "./components/Trainings";
import PaymentInfo from "./components/PaymentInfo";
import DeveloperSettings from "./components/DeveloperSettings";
import ApprovalProcess from "./components/Rules/ApprovalProcess";
import WorkingDays from "./components/Rules/WorkingDays";
import PublicHolidaysDetail from "./components/PublicHolidaysDetail";
// Örnek bileşenler
// Diğer bileşenlerinizi burada tanımlayın
const tabs = [
  {
    name: "sistem-ayarlari",
    label: "Sistem Ayarları",
    content: <SystemSettings />,
  },
  {
    name: "sirket-bilgileri",
    label: "Şirket Bilgileri",
    content: <CompanyInfo />,
  },
  // { name: "veri-aktar", label: "Veri Aktar", content: <DataImport /> },
  { name: "kurallar", label: "Kurallar", content: <Rules /> },
  {
    name: "tatiller",
    label: "Tatil Takvimleri",
    content: <PublicHolidays />,
  },
  { name: "egitimler", label: "Eğitimler", content: <Trainings />},
  {
    name: "odeme-bilgileri",
    label: "Ödeme Bilgileri",
    content: <PaymentInfo />,
  },
  // {
  //   name: "gelistirici-ayarlari",
  //   label: "Geliştirici Ayarları",
  //   content: <DeveloperSettings />,
  // },
  // Diğer tablarınızı buraya ekleyin
];
const Settings = () => {
  return (
    <div>
      {/* Tables & Charts */}

      <div className="text-navy-700 text-md mt-2 grid grid-cols-1 gap-5 rounded-[20px] bg-white bg-clip-border p-3 shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:text-white dark:shadow-none xl:grid-cols-1">
        <div>
          <Tabs tabs={tabs} />
          <Routes>
            {tabs.map((tab) => (
              <Route key={tab.name} path={tab.name} element={tab.content} />
            ))}
            <Route path="tatiller/:id" element={<PublicHolidaysDetail />} />

          </Routes>
        </div>
      </div>
    </div>
  );
};

export default Settings;
