import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { MdAdd } from 'react-icons/md'; // "+" ikonu için
import Modal from 'react-modal';
import useCustomModalStyles from 'utils/useCustomModalStyles';
import NewLeave from 'views/app/leaves/NewLeave';
import AdvanceDebtModal from 'views/app/employees/components/PaymentModals/AdvanceDebtModal';
import NewTimeLog from 'views/app/employees/components/NewTimeLog';
import ExpenseAddModal from 'views/app/employees/components/PaymentModals/ExpenseAddModal';

const MobileSidebarCard = () => {
  const modalStyle = useCustomModalStyles();
  const currentUser = useSelector(state => state.auth.currentUser);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");

  const openMyModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedOption("");
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    openMyModal();
  };

  const renderModalContent = () => {
    switch (selectedOption) {
      case "leave":
        return <NewLeave userId={currentUser?.id}  isOpen={modalIsOpen} onClose={closeModal} />;
      case "advance":
        return <AdvanceDebtModal isOpen={modalIsOpen} userId={currentUser?.id} onClose={closeModal} />;
      case "timelog":
        return <NewTimeLog userId={currentUser?.id} isOpen={modalIsOpen} onClose={closeModal} />;
      case "expense":
        return <ExpenseAddModal isOpen={modalIsOpen} userId={currentUser?.id} onClose={closeModal} />;
      default:
        return (
          <div className="flex flex-col gap-3 text-gray-800">
            {currentUser?.company?.settings?.is_leave_request && (
              <button
                className="flex flex-col items-center justify-center rounded-lg bg-blue-200 p-4 hover:bg-blue-300 w-full"
                onClick={() => handleOptionClick("leave")}
              >
                <span>İzin Talep Et</span>
              </button>
            )}
            {currentUser?.company?.settings?.is_advance_request && (
              <button
                className="flex flex-col items-center justify-center rounded-lg bg-green-200 p-4 hover:bg-green-300 w-full"
                onClick={() => handleOptionClick("advance")}
              >
                <span>Avans Talep Et</span>
              </button>
            )}
            {currentUser?.company?.settings?.is_expense_request && (
              <button
                className="flex flex-col items-center justify-center rounded-lg bg-red-200 p-4 hover:bg-red-300 w-full"
                onClick={() => handleOptionClick("expense")}
              >
                <span>Harcama Talep Et</span>
              </button>
            )}
            {currentUser?.company?.settings?.is_shift_request && (
              <button
                className="flex flex-col items-center justify-center rounded-lg bg-gray-200 p-4 hover:bg-gray-300 w-full"
                onClick={() => handleOptionClick("timelog")}
              >
                <span>Fazla Mesai Talep Et</span>
              </button>
            )}
            {/* Diğer butonlar benzer şekilde yerleştirilebilir */}
          </div>
        );
    }
  };
  
  

  return (
    <div className="fixed bottom-0 left-0 m-4 z-50 lg:hidden"> {/* Sadece mobilde göster */}
      <button
        className="flex items-center justify-center h-12 w-12 rounded-full bg-blue-500 text-white text-3xl shadow hover:bg-blue-600"
        onClick={() => handleOptionClick('default')} // Varsayılan olarak modal açılsın
      >
        <MdAdd />
      </button>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={modalStyle}
      >
        {renderModalContent()}
      </Modal>
    </div>
  );
};

export default MobileSidebarCard;
