import React from 'react';
import { MdAccessibility, MdAdd, MdNotifications } from 'react-icons/md';
import { Link } from 'react-router-dom';

const SystemSettings = () => {
  const cardData = [
    {
      icon: <MdAccessibility />,
      title: "Erişim Ayarları",
      description: "Kullanıcıların erişebileceği alanları ve güvenlik seviyesini düzenleyin",
      link: "/app/ayarlar/sistem-ayarlari/erisim-ayarlari",
    },
    {
      icon: <MdNotifications />,
      title: "Bildirim Ayarları",
      description: "Eposta, mobil ve üçüncü parti uygulamalar için bildirim ayarlarını düzenleyin.",
      link: "/app/ayarlar/sistem-ayarlari/bildirim-ayarlari",
    },
    {
      icon: <MdAdd />,
      title: "Talep Ayarları",
      description: "Çalışanların talep ayarlarını düzenleyin.",
      link: "/app/ayarlar/sistem-ayarlari/talep-ayarlari",
    },
  ];

  return (
    <div className="container mx-auto px-4">
      <div className="flex flex-wrap -mx-2">
        {cardData.map((card, index) => (
          <div key={index} className="mb-4 w-full md:w-1/2 px-2">
            <div className="flex flex-col md:flex-row items-center md:space-x-4 bg-white dark:bg-navy-800 dark:text-white rounded-lg border p-4">
              <div className="text-2xl text-blue-500 mb-2 md:mb-0">{card.icon}</div>
              <div className="flex-1">
                <div className="font-bold text-base md:text-lg">{card.title}</div>
                <p className="text-sm md:text-base">{card.description}</p>
              </div>
              <Link
                to={card.link}
                className="text-blue-500 transition duration-300 hover:text-blue-600 mt-2 md:mt-0"
              >
                Yönet
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SystemSettings;
