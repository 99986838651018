import { getTokenApi,} from '../utils/api';
import { fetchUserByUsername } from './userService';

export async function getToken(userName, password) {
  try {
    const response = await getTokenApi(`/o/token/`, `grant_type=password&username=${userName}&password=${password}`);
    if (response && response.token) {
      return { token: response.token };  // Token varsa döndür
    } else if (response && response.error) {
      return { error: response.error };  // Hata varsa hata mesajını döndür
    }
    return null;  // Token yoksa null döndür
  } catch (error) {
    console.error('Error while getting token:', error);
    return null;  // Beklenmeyen bir hata durumunda null döndür
  }
};


// export async function getAuthenticatedUser() {

//   const defaultReturnObject = { authenticated: false, user: null };
//   if(localStorage.getItem('access_token') === null){
//     return defaultReturnObject;
//   }
//   else{
//     const data = {
//       authenticated:true,
//       user: {token: localStorage.getItem('access_token')}
//     }
//     const {authenticated, user} = data;
//     return { authenticated, user };
//   }
// };