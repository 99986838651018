import React, { useEffect, useState } from "react";
import Profile from "./components/Profile";
import PersonalInfo from "./components/PersonalInfo";
import JobInfo from "./components/JobInfo";
import Leaves from "./components/Leaves";
import Payment from "./components/Payment";
import TimeLog from "./components/TimeLog";
import Assets from "./components/Assets";
import Trainings from "./components/Trainings";
import Files from "./components/Files";
import VisaRequests from "./components/VisaRequests";
import BasicTabs from "components/tabs/BasicTabs";
import { useParams } from "react-router-dom";
import { getById } from "utils/api";
import { ENDPOINTS } from "utils/constants";
import { useAppSelector } from "store";

const Details = () => {
  const currentUser = useAppSelector((state) => state.auth.currentUser);

  const [data, setData] = useState({});

  const { id } = useParams();
  const getUser = async () => {
    const response = await getById(id, ENDPOINTS.USERS);
    setData(response);
  };

  useEffect(() => {
    getUser();
  }, [id]);

  if (currentUser?.role?.name !== "CompanyAdmin") {
    if (currentUser?.id !== +id) {
      return <div>Yetkiniz bulunmamaktadır.</div>;
    }
  }
  const tabs = [
    { name: "Profil", content: <Profile data={data} onRefresh={getUser} /> },
    {
      name: "Kişisel Bilgiler",
      content: <PersonalInfo data={data} onRefresh={getUser} />,
    },
    { name: "Kariyer", content: <JobInfo /> },
    { name: "İzinler", content: <Leaves /> },
    { name: "Ödemeler", content: <Payment /> },
    { name: "Mesailer", content: <TimeLog /> },
    { name: "Eğitimler", content: <Trainings /> },
    // { name: "Vize Belgesi Talepleri", content: <VisaRequests /> },
  ];
  if (
    currentUser?.company?.settings?.show_personal_files_to_employees ||
    currentUser?.role?.name === "CompanyAdmin"
  ) {
    tabs.push({ name: "Dosyalar", content: <Files /> });
  }
  if (
    currentUser?.company?.settings?.show_assets_to_employees ||
    currentUser?.role?.name === "CompanyAdmin"
  ) {
    tabs.push({ name: "Zimmetler", content: <Assets /> });
  }
  return (
    <div>
      {/* Tables & Charts */}
      <h2 className="bg-white p-2 rounded-[50px] shadow-3xl shadow-shadow-500 text-center text-xl font-bold capitalize text-navy-700 dark:text-white dark:bg-navy-800">
          {data?.first_name} {data?.last_name}
        </h2>
      <div className="mt-2 grid grid-cols-1 gap-5 rounded-[20px] bg-white bg-clip-border p-3 shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:text-white dark:shadow-none xl:grid-cols-1">
        <div>
          <BasicTabs tabs={tabs} />
        </div>
      </div>
    </div>
  );
};

export default Details;
