import React, { useEffect, useState } from "react";
import {
  FaArrowLeft,
  FaSearch,
  FaEllipsisV,
  FaChevronDown,
  FaChevronRight,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import SubTabHeader from "../SubTabHeader";
import { get } from "utils/api";
import { ENDPOINTS } from "utils/constants";
import toast from "react-hot-toast";
import { post } from "utils/api";
import { patch } from "utils/api";
import { del } from "utils/api";

import { Field, Form, Formik } from "formik";
import InputField from "components/fields/InputField";
import Modal from "react-modal";
import TimeSelectField from "components/select/TimeSelectField";
import FormikCheckbox from "components/checkbox/FormikCheckbox";
import Dropdown from "components/dropdown";
import { put } from "utils/api";
import useCustomModalStyles from "utils/useCustomModalStyles";

const AccordionItem = ({ day }) => {
  const isWorkingDay = day.message !== "Çalışma günü değil";
  const formatTime = (time) => {
    if (!time) return "Belirtilmemiş";
    const [hours, minutes] = time.split(":");
    return `${hours}:${minutes}`; // Saniyeyi kaldırıp, saat:dakika formatında döndür
  };
  return (
    <div className="border-b p-4">
      <p>{day.day}</p>
      {isWorkingDay ? (
        <div>
          <p>
            <span>
              <b>Mesai Saatleri:</b> {formatTime(day.shift_start)} -{" "}
              {formatTime(day.shift_end)}
            </span>
            <span className="ml-4">
              <b>Yemek Saatleri:</b> {formatTime(day.lunch_start)} -{" "}
              {formatTime(day.lunch_end)}
            </span>
          </p>
        </div>
      ) : (
        <p>{day.message}</p> // Çalışma günü değil mesajını göster
      )}
    </div>
  );
};

const Accordion = ({ id, title, items, getDatas, editHandle, is_default }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const setDefault = async () => {
    const result = window.confirm(`Tüm çalışanlarınız için varsayılan çalışma takvimi olarak ayarlamak istediğinize emin misiniz? Daha sonra çalışanınızın profilinden spesifik olarak başka bir takvim ayarlayabilirsiniz.`);
    if (result) {
    const response = await patch(ENDPOINTS.WORKING_DAYS + id + "/set-default/", {
      is_default: true,
    })
    if (response.status === 200) {
      getDatas();
      toast.success("Varsayılan çalışma takvimi olarak ayarlandı");
    } else {
      toast.error("Bir hata oluştu");
    }}
  };
  const deleteHandle = async () => {
    const result = window.confirm(`${title} silmek istediğinize emin misiniz?`);
    if (result) {
      const response = await del(ENDPOINTS.WORKING_DAYS, id);
      if (response.status === 204) {
        toast.success("Çalışma takvimi başarıyla silindi");
        getDatas(); // Refresh the data after successful deletion
      } else {
        toast.error("Bir hata oluştu");
      }
    }
  };

  const dropdownActions = (
    <div className="rounded bg-white p-2 shadow-lg dark:bg-navy-700 dark:text-white">
      <ul className="text-sm">
        <li
          onClick={() => setDefault()}
          className="cursor-pointer p-2 hover:bg-gray-100 dark:hover:bg-gray-800"
        >
          Varsayılan Yap
        </li>
        <li
          onClick={() => editHandle(id)} // Assuming editHandle is properly defined to handle editing logic
          className="cursor-pointer p-2 hover:bg-gray-100 dark:hover:bg-gray-800"
        >
          Düzenle
        </li>
        <li
          onClick={() => deleteHandle()}
          className="cursor-pointer p-2 hover:bg-gray-100 dark:hover:bg-gray-800"
        >
          Sil
        </li>
      </ul>
    </div>
  );

  return (
    <div className="mb-4 rounded border">
      <div className="flex cursor-pointer items-center justify-between p-4">
        <div className="flex-grow cursor-pointer" onClick={toggleAccordion}>
          <h2 className="text-lg font-semibold">{title} 
          {is_default && <span className="text-sm text-gray-500"> (Varsayılan)</span>}
          </h2>
        </div>
        {/* Adjust positioning and interaction of dropdown and accordion toggle icon */}
        <div className="flex items-center">
          <Dropdown
            button={<FaEllipsisV className="mx-2 cursor-pointer" />}
            classNames="right-0"
          >
            {dropdownActions}
          </Dropdown>
          <div onClick={toggleAccordion} className="cursor-pointer">
            {isOpen ? <FaChevronDown /> : <FaChevronRight />}
          </div>
        </div>
      </div>
      {isOpen && (
        <div>
          {items.map((item, index) => (
            <AccordionItem key={index} day={item} />
          ))}
        </div>
      )}
    </div>
  );
};
const daysOfWeek = [
  "Pazartesi",
  "Salı",
  "Çarşamba",
  "Perşembe",
  "Cuma",
  "Cumartesi",
  "Pazar",
];

const getDefaultSchedule = () => {
  let schedule = {};
  daysOfWeek.forEach((day) => {
    if (["Pazartesi", "Salı", "Çarşamba", "Perşembe", "Cuma"].includes(day)) {
      schedule[day] = {
        shift_start: "09:00",
        shift_end: "18:00",
        lunch_start: "12:30",
        lunch_end: "13:30",
        enabled: true,
      };
    } else {
      schedule[day] = {
        shift_start: "",
        shift_end: "",
        lunch_start: "",
        lunch_end: "",
        enabled: false,
      };
    }
  });
  return schedule;
};
const WorkingDays = () => {
  const modalStyle = useCustomModalStyles();

  const [openDays, setOpenDays] = useState({});

  const toggleDay = (day) => {
    setOpenDays((prevOpenDays) => ({
      ...prevOpenDays,
      [day]: !prevOpenDays[day],
    }));
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editingItem, setEditingItem] = useState({ id: null, name: "" });
  const [datas, setDatas] = useState([]);
  const handleOpenModal = (isEditing = false, itemDetails = null) => {
    setIsEdit(isEditing);
    if (itemDetails) {
      setEditingItem(itemDetails);
    } else {
      setEditingItem({
        id: null,
        name: "",
        description: "",
        schedule: getDefaultSchedule(),
        friday_double: false,
        friday_double_if_single: false,
      });
    }
    setIsModalOpen(true);
  };
  const handleCloseModal = () => setIsModalOpen(false);
  const editHandle = (id) => {
    // İlgili çalışma gününü datas listesinden bulma
    const itemToEdit = datas.find((item) => item.id === id);

    if (itemToEdit) {
      // Çalışma günü detaylarını düzenleme formunda kullanılmak üzere hazırlama
      const itemDetails = {
        id: itemToEdit.id,
        name: itemToEdit.name,
        description: itemToEdit.description || "", // Varsayılan değer atama
        schedule: getDefaultSchedule(), // Bu örnek varsayılan bir takvim yapılandırmasını kullanıyor, gerçek verilerle güncellemeniz gerekebilir.
        friday_double: itemToEdit.friday_double || false,
        friday_double_if_single: itemToEdit.friday_double_if_single || false,
        // Diğer gerekli alanlar buraya eklenebilir
      };

      // Düzenleme modu ve ilgili detaylar ile modalı açma
      handleOpenModal(true, itemDetails);
    } else {
      toast.error("Düzenlemek istediğiniz veri bulunamadı.");
    }
  };
  const editSubmit = async (values) => {
    var formData = new FormData();
    let workingDayDetails = Object.entries(values.schedule)
      .map(([day, details]) => {
        return details.enabled
          ? {
              day,
              shift_start: details.shift_start,
              shift_end: details.shift_end,
              lunch_start: details.lunch_start,
              lunch_end: details.lunch_end,
              is_visible: true,
            }
          : null;
      })
      .filter((detail) => detail !== null);
    formData.append("name", values.name);
    formData.append("description", values.description);
    formData.append("friday_double", values.friday_double);
    formData.append("friday_double_if_single", values.friday_double_if_single);
    formData.append("working_day_details", JSON.stringify(workingDayDetails));

    const response = await put(
      ENDPOINTS.WORKING_DAYS + values.id + "/",
      formData
    );
    if (response.status === 200) {
      getDatas();
      handleCloseModal();
      toast.success("Çalışma takvimi başarıyla düzenlendi");
    } else {
      toast.error("Bir hata oluştu");
    }
  };
  const getDatas = async () => {
    const response = await get(ENDPOINTS.WORKING_DAYS);
    setDatas(response.results);
  };
  useEffect(() => {
    getDatas();
  }, []);

  const handleSubmit = async (values) => {
    let workingDayDetails = Object.entries(values.schedule)
      .map(([day, details]) => {
        return details.enabled
          ? {
              day,
              shift_start: details.shift_start,
              shift_end: details.shift_end,
              lunch_start: details.lunch_start,
              lunch_end: details.lunch_end,
              is_visible: true,
            }
          : null;
      })
      .filter((detail) => detail !== null);

    let formData = new FormData();
    formData.append("name", values.name);
    formData.append("description", values.description);
    formData.append("friday_double", values.friday_double);
    formData.append("friday_double_if_single", values.friday_double_if_single);
    formData.append("working_day_details", JSON.stringify(workingDayDetails));

    try {
      const response = await post(ENDPOINTS.WORKING_DAYS, formData);
      if (response.status === 201) {
        getDatas();
        handleCloseModal();
        toast.success("Çalışma günü başarıyla eklendi.");
      } else {
        toast.error("Bir hata oluştu.");
      }
    } catch (error) {
      console.error(error);
      toast.error("Bir hata oluştu.");
    }
  };

  return (
    <div>
      <SubTabHeader
        title="Çalışma Takvimleri"
        backUrl="/app/ayarlar/kurallar"
        headerButtonText="Çalışma takvimi ekle"
        headerButtonOnClick={() => handleOpenModal()}
      />
      <div className="container mx-auto bg-white p-4 dark:bg-navy-700 dark:text-white">
        {datas.map((workingDay, index) => (
          <Accordion
            key={index}
            is_default={workingDay.is_default}
            id={workingDay.id}
            title={workingDay.name}
            items={workingDay.working_day_details}
            getDatas={getDatas} // Fonksiyonu prop olarak geçirme
            editHandle={editHandle} // Fonksiyonu prop olarak geçirme
          />
        ))}
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        style={modalStyle}
        contentLabel={isEdit ? "Süreci Düzenle" : "Yeni Süreç Ekle"}
        ariaHideApp={false}
      >
        <Formik
          initialValues={editingItem}
          onSubmit={(values, { resetForm }) => {
            if (isEdit) {
              editSubmit(values);
            } else {
              handleSubmit(values);
            }
            resetForm();
            setIsModalOpen(false);
            setIsEdit(false);
          }}
        >
          {({
            isSubmitting,
            handleChange,
            handleBlur,
            values,
            setFieldValue,
          }) => (
            <Form className="space-y-4">
              <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                <InputField
                  label="Takvim Adı"
                  id="name"
                  name="name"
                  type="text"
                  placeholder="Takvim Adı Giriniz"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                />
                <InputField
                  label="Açıklama"
                  id="description"
                  name="description"
                  type="text"
                  placeholder="Açıklama Giriniz"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.description}
                />
              </div>
              <div className="mt-4">
                {daysOfWeek.map((day, index) => (
                  <div key={index} className="mb-4">
                    <div className="flex items-center justify-between">
                      <div
                        className="flex cursor-pointer items-center"
                        onClick={() => toggleDay(day)}
                      >
                        <Field
                          type="checkbox"
                          name={`schedule.${day}.enabled`}
                          className="mr-2 h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                        />
                        <span className="text-sm font-medium text-gray-700">
                          {day}
                        </span>
                      </div>
                      {openDays[day] && values.schedule[day].enabled ? (
                        <div className="flex flex-col items-end">
                          <span className="text-sm text-gray-500">
                            {`${
                              values.schedule[day].shift_start || "09:00"
                            } - ${values.schedule[day].shift_end || "18:00"}`}
                          </span>
                          <div className="mt-2 grid grid-cols-4 gap-4">
                            <TimeSelectField
                              label="Mesai Baş."
                              name={`schedule.${day}.shift_start`}
                            />
                            <TimeSelectField
                              label="Mesai Bit."
                              name={`schedule.${day}.shift_end`}
                            />
                            <TimeSelectField
                              label="Öğle Baş."
                              name={`schedule.${day}.lunch_start`}
                            />
                            <TimeSelectField
                              label="Öğle Bit."
                              name={`schedule.${day}.lunch_end`}
                            />
                          </div>
                        </div>
                      ) : (
                        <span className="text-sm text-gray-500">
                          {day === "Cumartesi" || day === "Pazar"
                            ? "Kapalı"
                            : "09:00 - 18:00"}
                        </span>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              <div className="mt-4">
                <FormikCheckbox
                  name="friday_double"
                  label="Pazartesiden cumaya kadar alınan izinlere cumartesiyide ekle"
                  color="blue"
                />
              </div>
              <div className="mt-4">
                <FormikCheckbox
                  name="friday_double_if_single"
                  label="Cuma gününü içeren izinlere cumartesi'yi de ekle."
                  color="blue"
                />
              </div>
              <div className="flex justify-end space-x-2">
                <button
                  type="button"
                  onClick={() => setIsModalOpen(false)}
                  className="rounded-md bg-gray-300 px-4 py-2 text-sm text-gray-700 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                >
                  İptal
                </button>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="rounded-md bg-blue-600 px-4 py-2 text-sm text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  {isEdit ? "Kaydet" : "Ekle"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default WorkingDays;
