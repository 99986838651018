import React from "react";
import ExpenseList from "./components/ExpenseList";
import ReceiptList from "./components/ReceiptList";
import ExpenseSettings from "./components/ExpenseSettings";
import BasicTabs from "components/tabs/BasicTabs";

const tabs = [
  { name: "Harcamalar", content: <ExpenseList /> },
  { name: "Fişler", content: <ReceiptList /> },
  { name: "Ayarlar", content: <ExpenseSettings /> },
];
const Expense = () => {
  return (
    <div>
      {/* Tables & Charts */}

      <div className="mt-2 grid grid-cols-1 gap-5 rounded-[20px] bg-white bg-clip-border p-3 shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:text-white dark:shadow-none xl:grid-cols-1">
        <div>
          <BasicTabs tabs={tabs} />
        </div>
      </div>
    </div>
  );
};

export default Expense;
