import axios from "axios";
import BASE_URL, { PUBLIC_KEY, SECRET_KEY } from "./constants";
import store from "../store";
import { setAccessToken } from "../store/authSlice";
import { toast } from "react-hot-toast";

const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    Authorization: "Bearer " + store.getState().auth.accessToken?.access_token,
  },
});

export const get = async (url, params) => {
  const response = await api.get(url, { params });
  return response?.data;
};
export const getDownload = async (url, params) => {
  const response = await api.get(url, { params, responseType: "blob" });
  return response;
};
export const getById = async (id, url, params) => {
  const response = await api.get(url + id + "/", { params });
  return response.data;
};

export const post = async (url, data) => {
  try {
    const response = await api.post(url, data);
    return response;
  } catch (error) {
    if (error.response) {
      // Server tarafından özel bir hata mesajı döndürüldüyse bu mesajı kullan
      throw error.response.data;
    } else if (error.request) {
      // İstek yapıldı ama yanıt alınamadı
      throw new Error("Sunucu yanıt vermiyor. Lütfen daha sonra tekrar deneyin.");
    } else {
      // İstek yapılırken bir hata oluştu
      throw new Error("İstek yapılırken bir hata oluştu: " + error.message);
    }
  }
};

export const getTokenApi = async (url, data) => {
  const api2 = axios.create({
    baseURL: BASE_URL,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    auth: {
      username: PUBLIC_KEY,
      password: SECRET_KEY,
    },
  });
  try {
    const response = await api2.post(url, data);
    return {token: response.data};  // Başarılı yanıtı döndür
  } catch (error) {
    if (error.response && error.response.status === 400) {
      // Burada DRF'den gelen spesifik hataları işle
      const description = error.response.data.error_description;
      if (description.includes("Telefon numaranız henüz doğrulanmadı")) {
        return { error: "phone_verification_required" };  // Telefon doğrulama hatası döndür
      } else {
        toast.error(description || "Bir hata oluştu.", {
          duration: 7000,
          position: "top-right",
        });
      }
    }
    return null;  // Hata durumunda null döndür
  }
};

// Genel PUT isteği yapmak için:
export const put = (url, data) => {
  const response = api.put(url, data);
  return response;
};

// Genel DELETE isteği yapmak için:
export const del = (url, id) => {
  const response = api.delete(url + id + "/");
  return response;
};

// Genel PATCH isteği yapmak için:
export const patch = async (url, data) => {
  const response = api.patch(url, data);
  return response;
};

async function refreshToken() {
  const refreshToken = store.getState().auth.accessToken?.refresh_token;
  if (!refreshToken) {
    return Promise.reject(new Error("Refresh token bulunamadı."));
  }
  const tokenData = `grant_type=refresh_token&refresh_token=${refreshToken}`;
  try {
    const response = await axios.post(`${BASE_URL}/o/token/`, tokenData, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      auth: {
        username: PUBLIC_KEY,
        password: SECRET_KEY,
      },
    });
    store.dispatch(setAccessToken(response.data));
    return response.data;
  } catch (error) {
    // Token yenileme başarısızsa kullanıcıyı çıkış yapma işlemi
    store.dispatch(setAccessToken(false));
    return Promise.reject(error);
  }
}

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const newAccessToken = await refreshToken();
        originalRequest.headers["Authorization"] = `Bearer ${newAccessToken.access_token}`;
        return api(originalRequest);
      } catch (_error) {
        return Promise.reject(_error);
      }
    }
    return Promise.reject(error);
  }
);
api.interceptors.request.use(
  async (config) => {
    const accessToken = store.getState().auth.accessToken?.access_token;
    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// api.interceptors.response.use(
//   function (response) {
//     return response;
//   },
//   async function (error) {
//     if (error.response?.status === 401) {
//       const originalRequest = error.config;
//       if (!originalRequest._retry) {
//         originalRequest._retry = true;
//         if (store.getState().auth.accessToken?.refresh_token) {
//           const response = await getTokenApi(
//             `/o/token/`,
//             `grant_type=refresh_token&refresh_token=${
//               store.getState().auth.accessToken?.refresh_token
//             }`
//           );
//           if (response !== undefined) {
//             store.dispatch(setAccessToken(response.data));
//             return Promise.resolve();
//           }
//           return Promise.resolve();
//         } else {
//           store.dispatch(setAccessToken(false));
//           return Promise.resolve();
//         }
//       } else {
//         store.dispatch(setAccessToken(false));
//         return Promise.resolve();
//       }
//       return Promise.resolve();
//     }
//     if (error.response?.status === 400) {
//       console.log("400", error.response);
//       toast.error("Bir hata oluştu.");
//       return Promise.resolve();
//     }
//     if (error.response?.status === 500) {
//       console.log("500",error.response);
//       if (error.response.data.detail.includes("source_refresh_token_id")) {
//         window.location.reload();
//       }

//       toast.error("Bir hata oluştu.");
//       return Promise.resolve();
//     }
//     return Promise.reject(error);
//   }
// );

// api.interceptors.request.use(function (config) {
//   config.headers.Authorization =
//     "Bearer " + store.getState().auth.accessToken?.access_token;
//   return config;
// });
export const postContact = async (url, data) => {
  const api = axios.create({
    baseURL: BASE_URL,
    // headers: { Authorization: "Bearer i8dgRII0uCQwPPMHEVljgLwNxYqVmz" },
  });
  const response = await api.post(url, data).catch((error) => {
    if (error?.response?.status === 400) {
      toast.error(
        "Bir hata oluştu. info@muzisyen.app adresinden ulaşabilirsiniz."
      );
      return Promise.resolve();
    }
    return Promise.reject(error);
  });
  return response;
};
