import DataTable from "components/datatable";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTable } from "react-table";
import { useAppSelector } from "store";
import { get } from "utils/api";
import { ENDPOINTS } from "utils/constants";
import { leaveColumsData, timelogColumsData } from "../variables/columnsData";
import Dropdown from "components/dropdown";
import { FaEllipsisV, FaPlus } from "react-icons/fa";
import { del } from "utils/api";
import toast from "react-hot-toast";
import NewLeave from "views/app/leaves/NewLeave";
import Modal from "react-modal";

import NewTimeLog from "./NewTimeLog";
import useCustomModalStyles from "utils/useCustomModalStyles";
import { patch } from "utils/api";
const TimeLog = () => {
  const modalStyle = useCustomModalStyles();
  const dropdownButton = <FaEllipsisV className="cursor-pointer" />;
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const currentUserRoleName = useAppSelector(
    (state) => state.auth.currentUser?.role?.name === "CompanyAdmin"
  );
  const [userData, setUserData] = useState([]);
  const [timeLogData, setTimeLogData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);

  const { id } = useParams();
  const closeModal = () => {
    setIsOpenModal(false);
    setSelectedItemId(null);
  };
  const refreshData = () => getTimeLogData();
  const editHandle = (id) => {
    setSelectedItemId(id);
    setIsOpenModal(true);
  };
  const changeStatusLeave = (id, status) => async () => {
    var formData = new FormData();
    formData.append("status", status);
    if (status === "Onaylandı") formData.append("is_approved", true);
    const response = await patch(ENDPOINTS.TIMELOG + `${id}/`, formData);
    if (response.status === 200) {
      getTimeLogData();
      toast.success("Başarıyla güncellendi");
    } else {
      toast.error("Bir hata oluştu");
    }
  };
  const getTimeLogData = async () => {
    setLoading(true);
    const response = await get(
      ENDPOINTS.TIMELOG + `?user__id=${id}&ordering=-create_time`
    );
    const updatedData = response?.results.map((data) => ({
      ...data,
      actions: (
        <div className="whitespace-nowrap px-6 py-4 text-right text-sm font-medium">
          <div className="flex items-center justify-end">
            <Dropdown button={dropdownButton} classNames="right-0">
              <div className="rounded bg-white dark:bg-navy-700 dark:text-white p-2 shadow-lg">
                <ul className="text-sm">
                  {/* buraya ileride izne çevir ve ödemeye çevir seçenekleri eklenecek */}
                  <li
                    onClick={changeStatusLeave(data.id, "Onaylandı")}
                    className="cursor-pointer p-2 hover:bg-gray-100 dark:hover:bg-navy-800"
                  >
                    Onayla
                  </li>
                  <li
                    onClick={changeStatusLeave(data.id, "Reddedildi")}
                    className="cursor-pointer p-2 hover:bg-gray-100 dark:hover:bg-navy-800"
                  >
                    Reddet
                  </li>
                  <li
                    onClick={() => editHandle(data.id)}
                    className="cursor-pointer p-2 hover:bg-gray-100 dark:hover:bg-navy-600"
                  >
                    Düzenle
                  </li>
                  <li
                    onClick={() => deleteHandle(data.id)}
                    className="cursor-pointer p-2 hover:bg-gray-100 dark:hover:bg-navy-600"
                  >
                    Sil
                  </li>
                </ul>
              </div>
            </Dropdown>
          </div>
        </div>
      ),
    }));
    setTimeLogData(updatedData);
    setLoading(false);
  };

  const getUserDatas = async () => {
    const response = await get(ENDPOINTS.USERS + `${id}/`);
    setUserData(response);
  };
  useEffect(() => {
    getUserDatas();
    getTimeLogData();
  }, [id]);

  const deleteHandle = async (id) => {
    const result = window.confirm(`Silmek istediğinize emin misiniz?`);
    if (result) {
      const response = await del(ENDPOINTS.TIMELOG, id);
      if (response.status === 204) {
        getTimeLogData();
        toast.success("Başarıyla silindi");
      } else {
        toast.error("Bir hata oluştu");
      }
    }
  };

  const columnsDataWithActions = currentUserRoleName
  ? [...timelogColumsData, { label: "Eylemler", accessor: "actions" }]
  : timelogColumsData;
  return (
    <>
      {currentUserRoleName && (
        <div className="mt-6 flex gap-5 text-sm">
          <button
            onClick={() => setIsOpenModal(true)}
            className="flex flex-col items-center justify-center rounded-lg bg-blue-500 p-3 text-white hover:bg-blue-600"
          >
            Mesai Kaydı Ekle
          </button>
        </div>
      )}
      <div className="flex flex-col">
        <DataTable
          columnsData={columnsDataWithActions}
          tableData={timeLogData}
          title="Mesai Kayıtları"
          hiddenColumns={["id"]}
          loading={loading}
        />
      </div>

      <Modal
        isOpen={isOpenModal}
        onRequestClose={() => {
          setIsOpenModal(false);
          setSelectedItemId(null);
        }}
        style={modalStyle}
        contentLabel={"Mesai Kaydı Ekle"}
        ariaHideApp={false}
      >
        <NewTimeLog
        isOpen={true}
          userId={id}
          onClose={closeModal}
          onRefresh={refreshData}
          timeLogId={selectedItemId}
          displayAdminButtons="true"
        />
      </Modal>
    </>
  );
};

export default TimeLog;
