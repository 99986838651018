import InputField from "components/fields/InputField";
import Checkbox from "components/checkbox";
import { Link, Navigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../store";
import { useLocation } from "react-router-dom/dist";
import { useEffect, useState } from "react";
import { post } from "utils/api";
import { ENDPOINTS } from "utils/constants";

function VerifyEmail() {
  const location = useLocation();
  const { accessToken, currentUser } = useAppSelector((state) => state.auth);
  const { token } = useParams();
  const [status, setStatus] = useState(false);
  const verifyEmail = async () => {
    const response = await post(ENDPOINTS.VERIFY_EMAIL, { token });
    setStatus(response?.status === 200);
  };
  useEffect(() => {
    verifyEmail();
  }, [token]);

  if (accessToken && currentUser && currentUser.company) {
    return (
      <Navigate to={location?.state?.returnUrl || "/app"} replace={true} />
    );
  }
  return (
    <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign in section */}
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Hesabını onayla
        </h4>
        <div className="center-block w-xxl w-auto-xs p-y-md text-center">
          <div className="p-a-md">
            <div>
             { status ? (
              <Link
                to="/auth/giris-yap"
                className="bg-blue-600 text-white mb-3"
              >
                <i className="fa fa-check pull-left" />
                Hesabınız onaylandı giriş yapabilirsiniz.
              </Link>
              ) : (
              <Link to="/" className="bg-red-600 text-white mb-3">
                <i className="fa fa-remove pull-left" />
                Hesabınız onaylanamadı.
              </Link>
              )}
            </div>
            <div>
              <br />
              <Link to="/auth/giris-yap" className="text-primary _600">
                Giriş sayfasına git
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default VerifyEmail;
