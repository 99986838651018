import React, { useEffect, useState } from "react";
import EmployeeCard from "./components/EmployeeCard";
import { useAppSelector } from "store";
import { get } from "utils/api";
import { ENDPOINTS } from "utils/constants";
import { CDN_URL } from "utils/constants";
import InputField from "components/fields/InputField";
import Dropdown from "components/dropdown";
import { FaArrowLeft, FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import AddEmployeeModal from "./components/AddEmployeeModal";
import Loading from "components/loading";
const Employees = () => {
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [departmentFilter, setDepartmentFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [isCreateEmployeeModal, setIsCreateEmployeeModal] = useState(false);
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleDepartmentChange = (event) => {
    setDepartmentFilter(event.target.value);
  };

  const handleStatusChange = (event) => {
    setStatusFilter(event.target.value);
  };
  // Filtreleme ve arama için userData'yı işle
  const filteredUserData = userData.filter((user) => {
    return (
      user.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.last_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const getEmployees = async () => {
    setLoading(true);
    const response = await get(`${ENDPOINTS.USERS}?ordering=-id`);
    setUserData(response?.results);
    setLoading(false);
  };
  useEffect(() => {
    getEmployees();
  }, []);

  return (
    <div className="bg-white dark:bg-navy-800 dark:text-white">
      <div className="flex flex-wrap items-center justify-between bg-white p-4 shadow dark:bg-navy-800">
        <div className="w-full lg:w-1/6">
          <input
            type="text"
            placeholder="Filtrele..."
            value={searchTerm}
            onChange={handleSearchChange}
            className={`mt-1 flex h-12 w-full items-center justify-center rounded-xl border bg-white p-3 text-sm outline-none dark:bg-navy-700 dark:text-white`}
          />
        </div>
        <div className="flex w-full justify-end lg:w-1/5">
          {currentUser?.role?.name === "CompanyAdmin" && (
            <button
              onClick={() => setIsCreateEmployeeModal(true)}
              className="btn mt-4 flex w-full items-center justify-center bg-blue-500 px-4 py-2 text-white hover:bg-blue-700 lg:mt-0 lg:w-auto"
            >
              <FaPlus className="mr-2" />
              Yeni Çalışan Ekle
            </button>
          )}
        </div>
      </div>
      {loading ? (
        <Loading />
      ) : (
      <div className="mt-5 grid h-full gap-5 pb-5">
        <div className="col-span-1 h-fit w-full xl:col-span-1 2xl:col-span-2">
          <div className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-5">
            {filteredUserData.map((user) => (
              <EmployeeCard
              is_leave={user?.is_leave}
                key={user?.id}
                id={user?.id}
                name={user?.first_name + " " + user?.last_name}
                email={user?.email}
                image={user?.avatar?.image ? CDN_URL + user.avatar.image : ""}
                phone={user?.phone}
              />
            ))}
          </div>
        </div>
      </div>
      )}
      {isCreateEmployeeModal && (
        <AddEmployeeModal
          isOpen={isCreateEmployeeModal}
          onClose={() => setIsCreateEmployeeModal(false)}
          onRefresh={() => {
            getEmployees();
          }}
        />
      )}
    </div>
  );
};

export default Employees;
