import Card from "components/card";
import Progress from "components/progress";
import React, { useMemo, useState } from "react";
import {
  MdCalendarToday,
  MdCalendarViewDay,
  MdCalendarViewMonth,
  MdCalendarViewWeek,
  MdOutlineCalendarViewMonth,
} from "react-icons/md";
import { Link } from "react-router-dom";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { momentTime } from "utils/helpers";
import Modal from "react-modal";

import { FaCalendar } from "react-icons/fa";
import { formatDateWithTime } from "utils/formatDate";
import useCustomModalStyles from "utils/useCustomModalStyles";

function EventCalendar(props) {
  const modalStyle = useCustomModalStyles();
  const { columnsData, tableData } = props;
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const handleRowClick = (eventData) => {
    setSelectedEvent(eventData);
    setIsOpenModal(true);
  };
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    tableInstance;

  return (
    <Card extra={"h-[400px] w-full"} droppableId="EventCalendar" index="7">
      {/* Top Creator Header */}
      <div className="flex h-fit w-full items-center justify-between rounded-t-2xl px-4 pt-4 pb-[20px]">
        <div className="flex items-center">
          <MdCalendarToday className="mr-2 text-left text-lg" />
          <h4 className="text-lg font-bold text-navy-700 dark:text-white">
            Etkinlik Takvimi
          </h4>
        </div>
      </div>

      {/* Top Creator Heading */}
      <div className="w-full overflow-x-scroll px-4 md:overflow-x-hidden">
        <table
          {...getTableProps()}
          className="w-full min-w-[300px] overflow-x-scroll"
        >
          <tbody {...getTableBodyProps()} className="px-4">
            {page.length > 0 ? (page.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let data = cell.render("Cell");
                    if (cell.column.Header === "Name") {
                      data = (
                        <div className="text-overflow-ellipsis flex max-w-[150px] items-center gap-2 overflow-hidden whitespace-nowrap">
                          <p className="text-sm font-medium text-navy-700 dark:text-white">
                            <Link
                              className="text-blue-700 dark:text-white"
                              onClick={() => handleRowClick(row.original)}
                            >
                              {" "}
                              {cell.value}{" "}
                            </Link>
                          </p>
                        </div>
                      );
                    }
                    if (cell.column.Header === "EventsDayCount") {
                      data = (
                        <div className="flex items-center gap-2">
                          <p className="text-sm font-medium text-gray-700 dark:text-white">
                            {cell.value}
                          </p>
                        </div>
                      );
                    }
                    return (
                      <td
                        className="px-2 py-2 text-sm"
                        {...cell.getCellProps()}
                        key={index}
                      >
                        {data}
                      </td>
                    );
                  })}
                </tr>
              );
            })) : (
              <tr>
              <td colSpan={columns.length} className="text-center py-5">
                <p className="text-sm italic text-gray-500">Kayıt yok</p>
              </td>
            </tr>
            )}
          </tbody>
        </table>
        <Modal
          isOpen={isOpenModal}
          onRequestClose={() => setIsOpenModal(false)}
          setIsOpen={setIsOpenModal}
          style={modalStyle}
        >
          {selectedEvent && (
            <>
              <div className="flex items-center justify-between border-b p-2">
                <h1 className="flex items-center text-lg font-bold text-navy-700 dark:text-white ">
                  <FaCalendar className="mr-1" /> Etkinlik Bilgileri
                </h1>
                <button
                  onClick={() => setIsOpenModal(false)}
                  className="text-lg font-bold text-navy-700 dark:text-white"
                >
                  X
                </button>
              </div>
              <div className="mt-2 flex items-center justify-between border-b">
                  <p className="text-sm font-medium text-gray-700 dark:text-white">
                    Etkinlik Adı:
                  </p>
                  <p className="text-sm font-medium text-gray-700 dark:text-white">
                    {selectedEvent.name}
                  </p>
              </div>
              <div className="mt-2 flex items-center justify-between border-b">
                <p className="text-sm font-medium text-gray-700 dark:text-white">
                  Başlangıç Tarihi
                </p>
                <p className="text-sm font-medium text-gray-700 dark:text-white">
                  {formatDateWithTime(selectedEvent.start_datetime)}
                </p>
              </div>
              <div className="mt-2 flex items-center justify-between border-b">
                <p className="text-sm font-medium text-gray-700 dark:text-white">
                  Bitiş Tarihi
                </p>
                <p className="text-sm font-medium text-gray-700 dark:text-white">
                  {formatDateWithTime(selectedEvent.end_datetime)}
                </p>
              </div>
              <div className="mt-2 flex items-center justify-between border-b mb-10">
                <p className="text-sm font-medium text-gray-700 dark:text-white">
                  Açıklama
                </p>
                <p className="text-sm font-medium text-gray-700 dark:text-white">
                  {selectedEvent.description}
                </p>
              </div>
            </>
          )}
        </Modal>
      </div>
    </Card>
  );
}

export default EventCalendar;
