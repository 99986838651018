// useCustomModalStyles.js
import { useTheme } from "ThemeContext";
import { useState, useEffect } from "react";

const useCustomSelectStyles = () => {
  const { isDarkMode } = useTheme();
  const [style, setStyle] = useState({});

  useEffect(() => {
    const selectStyle = {
      control: (provided) => ({
        ...provided,
        backgroundColor: isDarkMode ? "rgb(27 37 75 / var(--tw-bg-opacity))" : "white",
        borderColor: isDarkMode ? "gray" : "lightgray",
      }),
      menu: (provided) => ({
        ...provided,
        backgroundColor: isDarkMode ? "rgb(27 37 75 / var(--tw-bg-opacity))" : "white",
      }),
      option: (provided, state) => ({
        ...provided,
        color: isDarkMode ? "white" : "rgb(27 37 75 / var(--tw-bg-opacity))",
        backgroundColor: state.isFocused ? (isDarkMode ? "rgb(27 37 65 / var(--tw-bg-opacity))" : "lightgray") :
          (state.isSelected ? (isDarkMode ? "darkblue" : "#d6d6d6") : (isDarkMode ? "rgb(27 37 75 / var(--tw-bg-opacity))" : "white")),
      }),
      singleValue: (provided) => ({
        ...provided,
        color: isDarkMode ? "white" : "rgb(27 37 75 / var(--tw-bg-opacity))",
      }),
      multiValue: (styles, { data }) => ({
        ...styles,
        backgroundColor: data.color,
        // color: isDarkMode ? "white" : "rgb(27 37 75 / var(--tw-bg-opacity))",
        color: isDarkMode ? "white" : "rgb(27 37 75 / var(--tw-bg-opacity))",
      }),
      multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: isDarkMode ? "white" : "rgb(27 37 75 / var(--tw-bg-opacity))",
      }),
    };
    setStyle(selectStyle);
  }, [isDarkMode]);

  return style;
};

export default useCustomSelectStyles;
