import React, { useState } from "react";

const BasicTabs = (props) => {
  const { tabs } = props;
  const [activeTab, setActiveTab] = useState(tabs && tabs.length > 0 ? tabs[0].name : '');

  // tabs dizisinin boş olup olmadığını burada kontrol ediyoruz
  if (!tabs || tabs.length === 0) {
    return <div>Tablar yüklenemedi.</div>;
  }


  return (
    <div className="pb-4">
    <div className="flex overflow-x-auto no-scrollbar">
        {tabs.map((tab) => (
          <button
            key={tab.name}
            className={`px-4 py-2 whitespace-nowrap ${
              tab.name === activeTab ? "border-b-2 border-blue-500" : ""
            }`}
            onClick={() => setActiveTab(tab.name)}
          >
            {tab.name}
          </button>
        ))}
      </div>
      <div className="mt-2 border-t-2">
        <div className="p-4">
          {tabs.find((tab) => tab.name === activeTab)?.content}
        </div>
      </div>
    </div>
  );
};

export default BasicTabs;