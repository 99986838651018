import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "store";
import { get } from "utils/api";
import { ENDPOINTS } from "utils/constants";
import { expensesFilesColumsData } from "../variables/columnsData";
import DataTable from "components/datatable";
const ReceiptList = () => {
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const [datas, setDatas] = useState([]);
  const [loading, setLoading] = useState(true);

  const getDatas = async () => {
    setLoading(true);
  const response = await get(ENDPOINTS.EXPENSES_FILES + "?ordering=-id");
    setDatas(response?.results);
    setLoading(false);
  };
  useEffect(() => {
    getDatas();
  }, []);

  return (
    <div>
      <div className="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-1">
        <div>
          <DataTable
            columnsData={expensesFilesColumsData}
            tableData={datas}
            title="Fişler"
            hiddenColumns={["id"]}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default ReceiptList;
