import Card from "components/card";
import Progress from "components/progress";
import React, { useEffect, useMemo, useState } from "react";
import {
  MdAir,
  MdAirlineSeatFlat,
  MdAirplanemodeActive,
  MdCheckCircle,
  MdRefresh,
  MdSchool,
} from "react-icons/md";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { useAppSelector } from "store";
import { get } from "utils/api";
import { ENDPOINTS } from "utils/constants";
import formatDate from "utils/formatDate";
import { momentTime } from "utils/helpers";

function MyEvents(props) {
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const { columnsData, tableData } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    tableInstance;

  
  return (
    <Card extra={"h-[400px] w-full"} droppableId="MyEvents" index="6">
      {/* Top Creator Header */}
      <div className="flex h-fit w-full items-center justify-between rounded-t-2xl px-4 pt-4 pb-[20px]">
        <div className="flex items-center">
          <MdSchool className="mr-2 text-left text-lg" />
          <div>
            <h4 className="text-lg font-bold text-navy-700 dark:text-white">
              Eğitimlerim
            </h4>
          </div>
        </div>
    
      </div>

      {/* Top Creator Heading */}
      <div className="w-full overflow-x-scroll px-4 md:overflow-x-hidden">
        <table
          {...getTableProps()}
          className="w-full min-w-[200px] overflow-x-scroll"
        >
         
          <tbody {...getTableBodyProps()} className="px-4">
          {page.length > 0 ? (page.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let cellData = cell.render("Cell");
                    if (cell.column.Header === "Avatar") {
                      cellData = (
                        <div className="flex items-center gap-2">
                          <div className="rounded-full">
                            <MdCheckCircle className={`text-lg ${cell.value}`} />
                          </div>
                        </div>
                      );
                    }
                    return (
                      <td
                        className="py-2 text-sm"
                        {...cell.getCellProps()}
                        key={index}
                      >
                        {cellData}
                      </td>
                    );
                  })}
                </tr>
              );
            })) : (
              <tr>
                <td colSpan={columns.length} className="py-5 text-center">
                  <p className="text-sm italic text-gray-500">Kayıt yok</p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Card>
  );
}

export default MyEvents;
