import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import InputField from "components/fields/InputField";
import { post } from "utils/api";
import { ENDPOINTS } from "utils/constants";
import toast from "react-hot-toast";

// Form için doğrulama şeması
const ChangePasswordSchema = Yup.object().shape({
  currentPassword: Yup.string().required("Mevcut şifre gereklidir"),
  newPassword: Yup.string()
    .min(6, "Şifre en az 6 karakter olmalıdır")
    .required("Yeni şifre gereklidir"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Şifreler eşleşmiyor")
    .required("Şifre tekrarı gereklidir"),
});

const submitChangePassword = async (values) => {
  var formData = new FormData();
  formData.append("current_password", values.currentPassword);
  formData.append("new_password", values.newPassword);
  try {
    const response = await post(ENDPOINTS.CHANGE_PASSWORD, formData);
    if (response.status === 200) {
      toast.success("Şifre başarıyla değiştirildi.");
    }
  } catch (error) {
    console.error("Hata:", error.error);
    if (error.error) {
      toast.error(error.error);
    } else {
      toast.error("Bir hata oluştu. Lütfen daha sonra tekrar deneyin.");
    }
  }
};

const ChangePassword = () => {
  return (
    <div className="mt-5 flex w-full flex-col gap-5 bg-white p-5 dark:bg-navy-700 dark:text-white lg:w-1/4">
      <div className="flex flex-col gap-5">
        <Formik
          initialValues={{
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
          }}
          validationSchema={ChangePasswordSchema}
          onSubmit={submitChangePassword}
        >
          {({ isSubmitting }) => (
            <Form className="space-y-4">
              <div>
                <InputField
                  label="Mevcut Şifre"
                  type="password"
                  name="currentPassword"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
              </div>
              <div>
                <InputField
                  type="password"
                  name="newPassword"
                  label="Yeni Şifre"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
              </div>
              <div>
                <InputField
                  label="Yeni Şifre Tekrar"
                  type="password"
                  name="confirmPassword"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
              </div>
              <button
                type="submit"
                disabled={isSubmitting}
                className="border-transparent flex w-full justify-center rounded-md border bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Şifreyi Değiştir
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ChangePassword;
