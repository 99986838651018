import Card from "components/card";
import Progress from "components/progress";
import React, { useMemo } from "react";
import { MdCake, MdWbSunny } from "react-icons/md";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { momentTime } from "utils/helpers";

function Holidays(props) {
  const { columnsData, tableData } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    tableInstance;

  return (
    <Card extra={"h-[400px] w-full text-navy-700"} droppableId="Holidays" index="5">
      {/* Top Creator Header */}
      <div className="flex h-fit w-full items-center justify-between rounded-t-2xl px-4 pt-4 pb-[20px]">
        <div className="flex items-center">
          <MdWbSunny className="mr-2 text-left text-lg" />
          <h4 className="text-lg font-bold text-navy-700 dark:text-white">
            Resmi Tatiller
          </h4>
        </div>
      </div>

      {/* Top Creator Heading */}
      <div className="w-full overflow-x-scroll px-6 md:overflow-x-hidden">
        <table
          {...getTableProps()}
          className="w-full min-w-[200px] overflow-x-scroll"
        >
      
          <tbody {...getTableBodyProps()} className="px-4">
            {page.length > 0 ? (page.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let data = cell.render("Cell");
                    return (
                      <td
                        className="py-2 text-sm"
                        {...cell.getCellProps()}
                        key={index}
                      >
                        {data}
                      </td>
                    );
                  })}
                </tr>
              );
            })): (
              <tr>
                <td colSpan={columns.length} className="py-5 text-center">
                  <p className="text-sm text-gray-500 italic">Kayıt yok</p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Card>
  );
}

export default Holidays;
