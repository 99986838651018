import React, { useEffect, useState } from "react";
import Card from "components/card";
import { useAppSelector } from "../../../../store";
import formatDate from "utils/formatDate";
import { CDN_URL } from "utils/constants";
import { DEFAULT_AVATAR } from "utils/constants";
import { get } from "utils/api";
import { ENDPOINTS } from "utils/constants";
import { getInitialsAndBackgroundColor } from "utils/helpers";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";
const ProfilCard = () => {
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const { initials, backgroundColor } = getInitialsAndBackgroundColor(
    currentUser?.first_name + " " + currentUser?.last_name
  );
  const [positionData, setPositionData] = useState([]);
  const getPostions = async () => {
    const response = await get(
      ENDPOINTS.POSITIONS_UNITS + `${currentUser?.id}/`
    );
    setPositionData(response);
  };
  useEffect(() => {
    getPostions();
  }, []);

  const backgroundImageUrl = currentUser?.avatar?.image
    ? CDN_URL + currentUser.avatar.image
    : null;

  return (
    <Card
      extra={"items-center w-full h-full bg-cover"}
      droppableId="ProfilCard"
      index="1"
    >
      {/* Background and profile */}
      {backgroundImageUrl ? (
        <div
          className="relative flex h-32 w-full justify-center rounded-xl bg-cover"
          style={{ backgroundImage: `url(${backgroundImageUrl})` }}
        >
          <div className="absolute -bottom-12 flex h-[87px] w-[87px] items-center justify-center rounded-full border-[4px] border-white bg-white dark:!border-navy-700">
            <img
              className="h-full w-full rounded-full"
              src={backgroundImageUrl}
              alt={`avatar ${backgroundImageUrl}`}
            />
          </div>
        </div>
      ) : (
        <div
          className="relative -bottom-12 flex h-[87px] w-[87px] items-center justify-center rounded-full"
          style={{ backgroundColor: backgroundColor }}
        >
          <span className="text-xl font-bold text-white">{initials}</span>
        </div>
      )}

      {/* Name and position */}
      <div className="mt-16 flex flex-col items-center text-navy-700">
        <h4 className="text-xl font-bold capitalize text-navy-700 dark:text-white">
          {currentUser?.first_name + " " + currentUser?.last_name}
        </h4>
        {positionData && (
          <>
            <p className="text-md mt-2 font-normal text-gray-600">
              {" "}
              {positionData.title
                ? positionData.title?.map((item) => item.name)
                : "Ünvan bilgisi bulunmamaktadır."}
            </p>
            <p className="text-sm font-normal text-gray-600">
              {" "}
              {positionData.company
                ? positionData.company?.map((item) => item.name)
                : "Departman bilgisi bulunmamaktadır."}
            </p>
          </>
        )}
      </div>

      {/* Post followers */}
      <div className="mt-6 mb-3 flex gap-4 md:!gap-14">
        {currentUser?.manager && (
          <div className="flex flex-col items-center justify-center">
            <p className="text-md font-bold capitalize text-navy-700 dark:text-white">
              {currentUser.manager.first_name +
                " " +
                currentUser.manager.last_name}
            </p>
            <p className="text-sm font-normal text-gray-600">Yöneticim</p>
          </div>
        )}
        <div className="flex items-center justify-center text-sm font-normal text-gray-700">
          <Link to={"/app/calisanlar/" + currentUser?.id}> Hesabım </Link>
          <FaArrowRight className="ml-1"/>
        </div>
      </div>
    </Card>
  );
};

export default ProfilCard;
