import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  accessToken: JSON.parse(localStorage.getItem("accessToken")) || null,
  currentUser: JSON.parse(localStorage.getItem("currentUser")) || null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAccessToken: (state, action) => {
      state.accessToken = action.payload;
      if (!action.payload) {
        localStorage.removeItem("accessToken");

        state.currentUser = null;
        localStorage.removeItem("currentUser");
      } else {
        localStorage.setItem("accessToken", JSON.stringify(action.payload));
      }
    },
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
      if (!action.payload) {
        localStorage.removeItem("currentUser");
      } else {
        localStorage.setItem("currentUser", JSON.stringify(action.payload));
      }
    },
  },
});

export default authSlice.reducer;
export const { setAccessToken, setCurrentUser } = authSlice.actions;
