import React, { useState } from "react";
import Modal from "react-modal";
import { Formik, Form, Field, ErrorMessage } from "formik";

import { post } from "utils/api"; // API istekleri için
import { ENDPOINTS } from "utils/constants";
import * as Yup from "yup";
import InputField from "components/fields/InputField";
import Switch from "components/switch";
import toast from "react-hot-toast";
import { patch } from "utils/api";
import FormikDatePicker from "components/datepicker";
import { formatISODate } from "utils/helpers";
import AsyncSelect from "react-select/async";
import { get } from "utils/api";
import useCustomModalStyles from "utils/useCustomModalStyles";
const PersonalInfoAddressModal = ({
  isOpen,
  onClose,
  onRefresh,
  userId,
  currentData,
}) => {
  const modalStyle = useCustomModalStyles();
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  const loadCountryOptions = async (inputValue) => {
    const response = await get(`${ENDPOINTS.COUNTRY}?search=${inputValue}`);
    return response.results.map((item) => ({
      label: item.name,
      value: item.id,
    }));
  };
  const loadCityOptionsWrapper = async (inputValue) => {
    if (!selectedCountry) return [];
    return loadCityOptions(selectedCountry.value, inputValue);
  };

  const loadCityOptions = async (countryId, inputValue) => {
    if (!countryId) return [];
    const response = await get(
      `${ENDPOINTS.CITY}?country=${countryId}&search=${inputValue}`
    );
    return response.results.map((item) => ({
      label: item.name,
      value: item.id,
    }));
  };
  // İlçe seçeneklerini yükleyen wrapper fonksiyon
  const loadTownOptionsWrapper = async (inputValue) => {
    if (!selectedCity) return [];
    return loadTownOptions(selectedCity.value, inputValue);
  };

  const loadTownOptions = async (cityId, inputValue) => {
    // Burada cityId ve inputValue kullanılmalıdır.
    if (!cityId) return [];
    const response = await get(
      `${ENDPOINTS.TOWN}?city=${cityId}&search=${inputValue}`
    );
    return response.results.map((item) => ({
      label: item.name,
      value: item.id,
    }));
  };

  const handleSubmit = async (values) => {
    var formData = new FormData();
    formData.append("address", values.address);
    formData.append("address2", values.address2);
    formData.append("town", values.town);
    formData.append("city", values.city);
    formData.append("country", values.country);
    formData.append("zip_code", values.zip_code);
    const response = await patch(ENDPOINTS.USERS + userId + "/", formData);
    if (response.status === 200) {
      onRefresh();
      onClose();
      toast.success("Başarılı bir şekilde değiştirildi");
    } else {
      toast.error("Bir hata oluştu");
    }
  };
  const initialValues = {
    address: currentData?.address || "",
    address2: currentData?.address2 || "",
    town: currentData?.town || "",
    city: currentData?.city || "",
    country: currentData?.country || "",
    zip_code: currentData?.zip_code || "",
  };

  const validationSchema = Yup.object().shape({
    // birth_date: Yup.string().required("Bu alan zorunludur"),
  });
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={modalStyle}>
      <div className="mx-auto  rounded-lg bg-white p-6 shadow-lg">
        <button
          onClick={onClose}
          className="absolute top-0 right-0 m-2 text-2xl font-semibold"
        >
          ×
        </button>
        <h2 className="mb-4 text-center text-xl font-semibold text-gray-800">
          Adres
        </h2>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({
            values,
            setFieldValue,
            isSubmitting,
            handleBlur,
            handleChange,
            errors,
            touched,
          }) => (
            <Form className="space-y-4">
              <div className="flex flex-col md:flex-row md:items-center md:space-x-4">
                <div className="mb-4 flex-1">
                  <label
                    htmlFor="address"
                    className="ml-3 text-sm font-bold text-navy-700 dark:text-white"
                  >
                    Adres
                  </label>
                  <InputField
                    name="address"
                    type="text"
                    placeholder="Açık adres giriniz"
                  />
                </div>
              </div>
              <div className="flex flex-col md:flex-row md:items-center md:space-x-4">
                <div className="mb-4 flex-1">
                  <label
                    htmlFor="address"
                    className="ml-3 text-sm font-bold text-navy-700 dark:text-white"
                  >
                    Adres (devam)
                  </label>
                  <InputField
                    name="address2"
                    type="text"
                    placeholder="Adres devamı"
                  />
                </div>
              </div>
              <div className="flex flex-col md:flex-row md:items-center md:space-x-4">
                <div className="mb-4 flex-1">
                  <label className="mb-2 block capitalize" htmlFor="manager">
                    Ülke
                  </label>
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    loadOptions={(inputValue) => loadCountryOptions(inputValue)}
                    onChange={(option) => {
                      setSelectedCountry(option);
                      setFieldValue("country", option ? option.value : "");
                      setSelectedCity(null); // Şehir ve ilçe seçimlerini sıfırla
                      setFieldValue("city", "");
                      setFieldValue("town", "");
                    }}
                    name="country"
                    isClearable
                    placeholder="Seçiniz"
                  />
                </div>
                <div className="mb-4 flex-1">
                  <label className="mb-2 block capitalize" htmlFor="manager">
                    Şehir
                  </label>
                  <AsyncSelect
                    key={selectedCountry ? selectedCountry.value : ""}
                    cacheOptions
                    defaultOptions
                    loadOptions={loadCityOptionsWrapper}
                    onChange={(option) => {
                      setSelectedCity(option);
                      setFieldValue("city", option ? option.value : "");
                      setFieldValue("town", ""); // İlçe seçimini sıfırla
                    }}
                    name="city"
                    isClearable
                    placeholder="Seçiniz"
                    isDisabled={!selectedCountry}
                  />
                </div>

                <div className="flex flex-col md:flex-row md:items-center md:space-x-4">
                  <div className="mb-4 flex-1">
                    <label className="mb-2 block capitalize" htmlFor="manager">
                      İlçe
                    </label>
                    <AsyncSelect
                      key={selectedCity ? selectedCity.value : ""}
                      cacheOptions
                      defaultOptions
                      loadOptions={loadTownOptionsWrapper}
                      onChange={(option) => {
                        setFieldValue("town", option ? option.value : "");
                      }}
                      isDisabled={!selectedCity}
                      name="town"
                      isClearable
                      placeholder="Seçiniz"
                    />
                  </div>
                  <div className="mb-4 flex-1">
                    <InputField
                      name="zip_code"
                      type="text"
                      label="Posta Kodu"
                    />
                  </div>
                </div>
              </div>
              <button
                type="submit"
                disabled={isSubmitting}
                className="border-transparent mt-2 flex w-full justify-center rounded-md border bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Kaydet
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default PersonalInfoAddressModal;
