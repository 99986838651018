import moment from "moment";
import trLocale from "moment/locale/tr";

const capitalizeString = (...str) => {
  return str
    .filter((item) => item && item.length > 0)
    .map((item) => item.charAt(0).toUpperCase() + item.slice(1).toLowerCase())
    .join(" ");
};

const momentTime = (time) => {
  return moment
    .utc(time)
    .subtract(0, "hours") //sunucuda test et
    .local()
    .startOf("seconds")
    .fromNow();
};

const createSlug = (str) => {
  const trMap = {
    ç: "c",
    ğ: "g",
    ş: "s",
    ü: "u",
    ı: "i",
    ö: "o",
    Ç: "C",
    Ğ: "G",
    Ş: "S",
    Ü: "U",
    İ: "I",
    Ö: "O",
    â: "a",
    Â: "A",
  };
  for (let key in trMap) str = str?.replace(new RegExp(key, "g"), trMap[key]);
  return str
    ?.toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "");
};

const checkInput = (input) => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (emailPattern.test(input)) {
    return "email";
  } else {
    return "username";
  }
};
const formatDateString = (date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Ayı iki haneli yapar.
  const day = date.getDate().toString().padStart(2, "0"); // Günü iki haneli yapar.
  return `${year}-${month}-${day}`; // YYYY-MM-DD formatında döndürür.
};

const getRoleName = (role) => {
  switch (role) {
    case "Employee":
      return "Çalışan";
    case "CompanyAdmin":
      return "Hesap Sahibi";
    default:
      return "";
  }
};
const getInitials = (name) => {
  if (!name) return ""; // Eğer name boşsa, boş string dön

  const parts = name.trim().split(" ");
  if (parts.length === 1) {
    return parts[0].charAt(0).toUpperCase(); // Tek kelime varsa, ilk harfi dön
  }

  return parts.map((part) => part.charAt(0).toUpperCase()).join(""); // Birden fazla kelime varsa, baş harfleri birleştir
};

// Rastgele arkaplan rengi oluşturmak için fonksiyon
const getRandomColor = () => {
  let letters = "0123456789ABCDEF";
  let color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const getInitialsAndBackgroundColor = (name) => {
  if (!name) return { initials: "", backgroundColor: "#636e72" }; // İsim boşsa varsayılan değerler dön

  const parts = name.trim().split(" ");
  let initials = "";

  if (parts.length === 1) {
    initials = parts[0].charAt(0).toUpperCase(); // Tek kelime varsa, ilk harfi dön
  } else {
    initials = parts.map((part) => part.charAt(0).toUpperCase()).slice(0, 2).join(""); // İlk iki kelimenin baş harflerini birleştir
  }

  // İlk iki harfe göre renk üret
  const backgroundColor = generateDistinctColorFromInitials(initials); // İlk iki harfi al

  return { initials, backgroundColor };
};
const generateDistinctColorFromInitials = (initials) => {
  // Harfleri sayısal değerlere dönüştür
  let seed = initials.charCodeAt(0) * 1000; // İlk harf için büyük bir değer
  if (initials.length > 1) {
    seed += initials.charCodeAt(1); // İkinci harfi ekleyerek çeşitliliği artır
  }

  // Seed değerini 360'a modlayarak renk tonu (hue) değerini bul
  const hue = seed % 360;

  // Doygunluk ve parlaklık değerlerini geniş bir aralıkta değişken tut
  const saturation = 50 + (seed % 51); // %50 - %100 arası doygunluk
  const lightness = 30 + (seed % 41); // %30 - %70 arası parlaklık

  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
};

const formatISODate = (date) => {
  if (!date) return ""; // Eğer tarih yoksa, boş string dön
  return date.toISOString().split("T")[0]; // 'T' karakterine göre böl ve sadece tarih kısmını al
};
const formatISODateTime = (date) => {
  return date.toISOString(); // Tarih ve saat bilgisini ISO 8601 formatında döndür
};
const formatCurrency = (value) => {
  const cleanedValue = value.replace(/\D/g, "");
  const numberValue = parseInt(cleanedValue, 10);
  const formattedValue = isNaN(numberValue)
    ? ""
    : numberValue.toLocaleString("tr-TR");
  return formattedValue;
};
function parseTurkishNumber(turkishNumberString) {
  const withoutThousandsSeparator = turkishNumberString.replace(/\./g, '');
  const withDotDecimalSeparator = withoutThousandsSeparator.replace(/,/g, '.');
  return parseFloat(withDotDecimalSeparator);
}

export default capitalizeString;
export { formatISODate };
export { formatISODateTime };
export { momentTime };
export { createSlug };
export { checkInput };
export { formatDateString };
export { getRoleName };
export { getInitials };
export { getRandomColor };
export { formatCurrency };
export { getInitialsAndBackgroundColor };
export { parseTurkishNumber };