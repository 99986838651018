import React from "react";
import BasicTabs from "components/tabs/BasicTabs";
import TimeLogList from "./components/TimeLogList";

const tabs = [
  { name: "Mesailer", content: <TimeLogList /> },
];
const TimeLog = () => {
  return (
    <div>
      {/* Tables & Charts */}

      <div className="mt-2 grid grid-cols-1 gap-5 rounded-[20px] bg-white bg-clip-border p-3 shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:text-white dark:shadow-none xl:grid-cols-1">
        <div>
          <BasicTabs tabs={tabs} />
        </div>
      </div>
    </div>
  );
};

export default TimeLog;
