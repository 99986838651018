import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useAppSelector } from "store";
import { get } from "utils/api";
import { ENDPOINTS } from "utils/constants";
import DataTable from "components/datatable";
import { userFilesColumnsData } from "../variables/columnsData";
import { post } from "utils/api";
import { patch } from "utils/api";
import toast from "react-hot-toast";
import Dropdown from "components/dropdown";
import { FaEllipsisV } from "react-icons/fa";
import { del } from "utils/api";
const Files = () => {
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const currentUserRoleName = useAppSelector(
    (state) => state.auth.currentUser?.role?.name === "CompanyAdmin"
  );
  const [datas, setDatas] = useState([]);
  const [loading, setLoading] = useState(true);
  const fileInputRef = useRef(null); // Dosya seçmek için ref
  const { id } = useParams();
  const dropdownButton = <FaEllipsisV className="cursor-pointer" />;
  const getDatas = async () => {
    setLoading(true);
    const response = await get(ENDPOINTS.USERS + id + "/");
    const updatedData = response?.files.map((data) => ({
      ...data,
      actions: (
        <div className="whitespace-nowrap px-6 py-4 text-right text-sm font-medium">
          <div className="flex items-center justify-end">
            <Dropdown button={dropdownButton} classNames="right-0">
              <div className="rounded bg-white dark:bg-navy-700 dark:text-white p-2 shadow-lg">
                <ul className="text-sm">
                  <li
                    onClick={() => deleteHandle(data.id)}
                    className="cursor-pointer p-2 hover:bg-gray-100 dark:hover:bg-navy-600"
                  >
                    Sil
                  </li>
                </ul>
              </div>
            </Dropdown>
          </div>
        </div>
      ),
    }));
    setDatas(updatedData);
    setLoading(false);
  };
  const deleteHandle = async (id) => {
    const result = window.confirm(`Silmek istediğinize emin misiniz?`);
    if (result) {
      const response = await del(ENDPOINTS.USER_FILES, id);
      if (response.status === 204) {
        getDatas();
        toast.success("Başarıyla silindi");
      } else {
        toast.error("Bir hata oluştu");
      }
    }
  };
  const handleFileUploadClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    getDatas();
  }, []);
  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append(`file`, file, file.name);
      formData.append("name", file.name);
      var addFileId = await post(ENDPOINTS.USER_FILES, formData);
      if (addFileId.status === 201) {
        const response = await patch(ENDPOINTS.USERS + id + "/", {
          file_ids: [addFileId.data.id, ...datas.map((data) => data.id)],
        });
        if (response.status === 200) {
          getDatas();
          toast.success("Dosya başarıyla yüklendi");
        }
      }
    }
  };
  const columnsDataWithActions = currentUserRoleName
  ? [...userFilesColumnsData, { label: "Eylemler", accessor: "actions" }]
  : userFilesColumnsData;
  return (
    <div>
      {currentUserRoleName && (
        <div className="mt-6 flex gap-5 text-sm">
          <button
            onClick={handleFileUploadClick}
            className="flex flex-col items-center justify-center rounded-lg bg-blue-500 p-3 text-white hover:bg-blue-600"
          >
            Dosya Yükle
          </button>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            style={{ display: "none" }} // Kullanıcıya gösterme
          />
        </div>
      )}

      <div className="flex flex-col">
        <DataTable
          columnsData={columnsDataWithActions}
          tableData={datas}
          title="Dosyalar"
          hiddenColumns={["id"]}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default Files;
