import { IoEllipsisVertical, IoHeart, IoHeartOutline } from "react-icons/io5";
import { useEffect, useRef, useState } from "react";
import Card from "components/card";
import { Link } from "react-router-dom";
import { getInitials } from "utils/helpers";
import { getRandomColor } from "utils/helpers";
import toast from "react-hot-toast";
import NewExpense from "views/app/expense/components/NewExpense";
import NewTimeLog from "./NewTimeLog";
import NewLeave from "views/app/leaves/NewLeave";
import { useSelector } from "react-redux";

import Modal from "react-modal";
import ExpenseAddModal from "./PaymentModals/ExpenseAddModal";
import AdvanceDebtModal from "./PaymentModals/AdvanceDebtModal";
import { post } from "utils/api";
import { ENDPOINTS } from "utils/constants";
import { getInitialsAndBackgroundColor } from "utils/helpers";
import { FaInbox, FaPhone, FaPlane } from "react-icons/fa";
import { del } from "utils/api";
import useCustomModalStyles from "utils/useCustomModalStyles";
import { MdAirplanemodeActive } from "react-icons/md";
const EmployeeCard = ({
  id,
  name,
  department,
  email,
  phone,
  image,
  extra,
  is_leave,
}) => {
  const modalStyle = useCustomModalStyles();
  const [showIcon, setShowIcon] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const currentUser = useSelector((state) => state.auth.currentUser);
  const { initials, backgroundColor } = getInitialsAndBackgroundColor(name);
  const avatarContent = image ? (
    <img
      src={image}
      className="mb-3 h-24 w-24 rounded-full"
      alt={`${name} avatar`}
    />
  ) : (
    <div
      className="mb-3 flex h-24 w-24 items-center justify-center rounded-full"
      style={{ backgroundColor: backgroundColor }}
    >
      <span className="text-xl font-bold text-white">{initials}</span>
    </div>
  );
  const openMyModal = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedOption("");
  };

  const handleOptionClick = (option) => {
    setModalIsOpen(true);
    setSelectedOption(option);
  };

  const renderModalContent = () => {
    switch (selectedOption) {
      case "leave":
        return (
          <>
            <NewLeave
              isOpen={true}
              userId={id}
              onClose={closeModal}
              displayAdminButtons="true"
            />
          </>
        );
      case "timelog":
        return (
          <>
            <AdvanceDebtModal
              isOpen={modalIsOpen}
              userId={id}
              onClose={closeModal}
              displayAdminButtons="true"
            />
          </>
        );
      case "expense":
        return (
          <>
            <ExpenseAddModal
              isOpen={modalIsOpen}
              userId={id}
              onClose={closeModal}
              displayAdminButtons="true"
            />
          </>
        );
      default:
        return (
          <>
            <NewLeave
              isOpen={true}
              userId={id}
              onClose={closeModal}
              displayAdminButtons="true"
            />
          </>
        );
    }
  };
  const deleteEmployee = async () => {
    const result = window.confirm("Çalışanı silmek istediğinize emin misiniz?");
    if (!result) return;
    if (currentUser.id === id) {
      toast.error(
        "Kendi hesabınızı silemezsiniz. Lütfen başka bir yönetici hesabı ile giriş yaparak tekrar deneyin."
      );
      return;
    }
    const response = await del(ENDPOINTS.USERS, id);
    if (response.status === 204) {
      toast.success("Çalışan başarıyla silindi");
      window.location.reload();
    } else {
      toast.error("Çalışan silinirken bir hata oluştu");
    }
  };
  const sendUserInfoMail = async () => {
    const result = window.confirm(
      "Çalışanın giriş bilgileri mail olarak gönderilsin mi?"
    );
    if (!result) return;
    var formData = new FormData();
    formData.append("email", email);
    const response = await post(ENDPOINTS.SENDMAIL_USERINFO, formData);
    if (response.status === 201) {
      toast.success("Çalışana giriş bilgileri mail olarak gönderildi");
    } else {
      toast.error("Mail gönderilirken bir hata oluştu");
    }
  };
  const handleFormSubmit = (values, { setSubmitting }) => {
    setSubmitting(false);
  };
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <Card
      extra={`flex flex-col items-center justify-center w-full h-full !p-4 3xl:p-![18px] bg-white dark:bg-navy-800 dark:text-white ${extra}`}
      onMouseEnter={() => setShowIcon(true)}
      onMouseLeave={() => {
        setShowIcon(false);
        setShowDropdown(false);
      }}
    >
      <div className="flex h-full w-full flex-col items-center justify-center">
        {currentUser?.role?.name === "CompanyAdmin" ? (
          <Link to={`/app/calisanlar/${id}`}>{avatarContent}</Link>
        ) : (
          avatarContent
        )}
        {is_leave && (
          <FaPlane className="absolute top-3 left-3 text-2xl text-navy-400" title="İzinde" />
        )}
        {currentUser?.role?.name === "CompanyAdmin" && showIcon && (
          <button
            ref={dropdownRef}
            onClick={toggleDropdown}
            className="absolute top-3 right-3 flex items-center justify-center rounded-full bg-white p-2 text-brand-500 hover:cursor-pointer"
          >
            <IoEllipsisVertical className="text-md" />
          </button>
        )}
        {showDropdown && (
          <div
            className="ring-black absolute right-0 z-10 mt-2 w-48 rounded-md bg-white shadow-lg ring-1 ring-opacity-5"
            style={{
              top: dropdownRef.current
                ? dropdownRef.current.offsetTop +
                  dropdownRef.current.offsetHeight
                : 0,
            }}
          >
            <div
              className="py-1"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              <Link
                to={`/app/calisanlar/${id}`}
                target="_blank"
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                role="menuitem"
              >
                Yeni sekmeden aç
              </Link>
              <Link
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                onClick={() => handleOptionClick("leave")}
                role="menuitem"
              >
                İzin Ekle
              </Link>
              <Link
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                onClick={() => handleOptionClick("timelog")}
                role="menuitem"
              >
                Avans Ekle
              </Link>
              <Link
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                onClick={() => handleOptionClick("expense")}
                role="menuitem"
              >
                Harcama Ekle
              </Link>
              <Link
                onClick={sendUserInfoMail}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                role="menuitem"
              >
                Çalışana Giriş Bilgilerini Gönder
              </Link>
              <Link
                onClick={deleteEmployee}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                role="menuitem"
              >
                Çalışanı Sil
              </Link>
            </div>
          </div>
        )}
        <div className="text-center">
          <p className="text-lg font-bold capitalize text-navy-700 dark:text-white">
            {currentUser?.role?.name === "CompanyAdmin" ? (
              <Link to={`/app/calisanlar/${id}`}> {name}</Link>
            ) : (
              name
            )}
          </p>
          {email && (
            <p className="mt-1 flex items-center text-sm font-bold text-navy-700 dark:text-white">
              <FaInbox className="mr-1" /> {email}
            </p>
          )}
          {currentUser?.company?.settings?.show_phone_numbers_to_employees &&
            phone && (
              <p className="mt-1 flex items-center text-sm font-bold text-navy-700 dark:text-white">
                <FaPhone className="mr-1" /> {phone}
              </p>
            )}
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={modalStyle}
      >
        {renderModalContent()}
      </Modal>
    </Card>
    // <Card
    //   extra={`flex flex-col w-full h-full !p-4 3xl:p-![18px] bg-white ${extra}`}
    //   onMouseEnter={() => setShowIcon(true)}
    //   onMouseLeave={() => { setShowIcon(false); setShowDropdown(false); }}
    // >
    //   <div className="h-full w-full">
    //     <div className="relative w-full">
    //     {image ? (
    //       <img
    //         src={image}
    //         className="mb-3 h-24 w-24 rounded-full"
    //         alt={`${title} avatar`}
    //       />
    //     ) : (
    //       <div
    //         className="mb-3 h-24 w-24 rounded-full flex items-center justify-center"
    //         style={{ backgroundColor: getRandomColor() }}
    //       >
    //         <span className="text-xl font-bold text-white">{getInitials(author)}</span>
    //       </div>
    //     )}
    //     {showIcon && (
    //         <button
    //           ref={dropdownRef}
    //           onClick={toggleDropdown}
    //           className="absolute top-3 right-3 flex items-center justify-center rounded-full bg-white p-2 text-brand-500 hover:cursor-pointer"
    //         >
    //           <IoEllipsisVertical className="text-md" />
    //         </button>
    //       )}
    //       {showDropdown && (
    //         <div className="ring-black absolute right-0 mt-2 w-48 rounded-md bg-white shadow-lg ring-1 ring-opacity-5"
    //         style={{ top: dropdownRef.current ? dropdownRef.current.offsetTop + dropdownRef.current.offsetHeight : 0 }}>
    //           <div
    //             className="py-1"
    //             role="menu"
    //             aria-orientation="vertical"
    //             aria-labelledby="options-menu"
    //           >
    //             <a
    //               href="#"
    //               className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
    //               role="menuitem"
    //             >
    //               Ekle
    //             </a>
    //             <a
    //               href="#"
    //               className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
    //               role="menuitem"
    //             >
    //               Sil
    //             </a>
    //             {/* Diğer menü öğeleri */}
    //           </div>
    //         </div>
    //       )}
    //     </div>

    //     <div className="mb-3 flex items-center justify-between px-1 md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col xl:items-start 3xl:flex-row 3xl:justify-between">
    //       <div className="mb-2">
    //         <p className="text-lg font-bold capitalize text-navy-700 dark:text-white">
    //           {" "}
    //           {title}{" "}
    //         </p>
    //         <p className="mt-1 text-sm font-medium text-gray-600 md:mt-2">
    //           {author}{" "}
    //         </p>
    //       </div>
    //     </div>

    //     <div className="flex items-center justify-between md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col 2xl:items-start 3xl:flex-row 3xl:items-center 3xl:justify-between">
    //       <div className="flex">
    //         <p className="mb-2 text-sm font-bold text-brand-500 dark:text-white">
    //           {email}
    //         </p>
    //       </div>
    //     </div>
    //   </div>

    // </Card>
  );
};

export default EmployeeCard;
