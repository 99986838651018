import avatar from "assets/img/avatars/default_avatar_256.png";

export const COLORS = {
  PRIMARY: "#007bff",
  SECONDARY: "#6c757d",
  SUCCESS: "#28a745",
  WARNING: "#ffc107",
  DANGER: "#dc3545",
  LIGHT: "#f8f9fa",
  DARK: "#343a40",
};

export const orderUnitTypes = (typeCode) => {
  switch (typeCode) {
    case "company":
      return "after_company";
    case "branch":
      return "after_branch";
    case "department":
      return "after_department";
    case "title":
      return "after_title";
    default:
      return "";
  }
}
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const CDN_URL = process.env.REACT_APP_CDN_URL;
//export const BASE_URL = "https://api.beyazik.com/api";

export const ROUTES_URL = {
  LOGIN: "/giris-yap",
  SIGNUP: "/kayit-ol",
  FORGOT_PASSWORD: "/sifremi-unuttum",
  RESET_PASSWORD: "/sifre-sifirla/:reset_token",
  VERIFY_EMAIL: "/email-dogrula/:token",
  ABOUT: "/hakkimizda",
  CONTACT: "/iletisim",
  DASHBOARD: "/app",
  PRIVACY_POLICY: "/gizlilik-politikasi",
  TERMS_OF_USE: "/kullanim-sartlari",
  SITEMAP:"/sitemap.xml",
};

export const aidTypes = {
  militaryAid: "Askerlik Yardımı",
  holidayAid: "Bayram Yardımı",
  garnishment: "İcra",
  overtimeRecord: "Mesai Kaydı",
  bonus: "Prim Ekle",
  fuelAid: "Yakacak Yardımı",
  advanceDebt: "Avans/Borç",
  expenseAdd: "Harcama Ekle",
};
export const customModalStyles = (isDarkMode = false) => {
  const width = "35%";
  const height = "";
  const isMobile = window.innerWidth < 768; // Mobil cihaz kontrolü
  
  // Dark mode için stil ayarları
  const darkModeStyles = {
    background: 'rgb(27 37 75 / var(--tw-bg-opacity))', // Dark mode arka plan rengi
    color: 'text-white', // Dark mode metin rengi
    border: '1px solid #444', // Dark mode için sınır rengi
  };

  // Normal tema için stil ayarları
  const lightModeStyles = {
    background: 'white',
    border: '1px solid #ccc',
    color: 'text-black', // Light mode metin rengi
  };

  // Temaya göre stil seçimi
  const themeStyles = isDarkMode ? darkModeStyles : lightModeStyles;

  return {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: isMobile ? '90%' : width, // Mobil cihazlarda genişliği %90 yapar
      height: height || (isMobile ? 'auto' : ''), // Mobil cihazlarda yüksekliği otomatik ayarlar
      maxHeight: isMobile ? '80%' : '85%', // Mobil cihazlarda maksimum yüksekliği ayarlar
      padding: '20px',
      overflow: 'auto', // İçerik çok fazlaysa kaydırmayı etkinleştirir
      ...themeStyles, // Tema bağlı stil ayarlarını uygula
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
      zIndex: 1000,
    },
  };
};



export const customDateInputStyles = {
  style: {
    width: '200px', // Tam genişlik
    padding: '10px', // Özelleştirilebilir padding
    
    // Diğer stil özellikleri...
  }
};
export const ENDPOINTS = {
  SIGNUP: "/signup/",
  VERIFY_EMAIL: "/users/verify-email/",
  CREATE_EMPLOYEE: "/create-employee/",
  USER_PROFILE: "/user-profile/",
  USERS: "/users/",
  CHANGE_PASSWORD: "/users/change-password/",
  USER_FILES: "/userfiles/",
  COMPANY: "/companies/",
  COMPANY_FILES: "/companyfiles/",
  COMPANY_FOLDER: "/companyfolder/",
  BRANCH: "/branches/",
  DEPARTMENT: "/departments/",
  SENIORITY: "/seniority/",
  TITLE: "/titles/",
  UNIT: "/units/",
  UNIT_TYPE: "/unit-types/",
  FORMFIELD: "/formfields/",
  FORMFIELD_GROUP: "/formfield-groups/",
  HOLDING: "/holdings/",
  CURRENT_USER: "/users/current/",
  WORKING_DAYS: "/workingdays/",
  WORKINGDAY_DETAILS: "/workingdaydetails/",
  EMPLOYMENTTERMINATIONREASON: "/employmentterminationreason/",
  ASSETS: "/assets/",
  ASSET_CATEGORY: "/assetcategory/",
  APPROVAL_PROCESS: "/approvalprocess/",
  TRAININGS: "/trainings/",
  TIMESHEET:"/timesheet/",
  TIMESHEET_ANALYSIS:"/timesheet/analysis/",
  USER_TRAININGS: "/usertrainings/",
  USER_BIRTHDAYS: "/users/birthdays/",
  EXPENSES: "/expenses/",
  EXPENSES_CATEGORY: "/expenses-categories/",
  EXPENSES_FILES: "/expensesfiles/",
  DOWNLOAD_EXPENSES: (expenseId) => `/expenses/${expenseId}/download/`,
  DOWNLOAD_LEAVE: (leaveId, docType) => `/leave/${leaveId}/download/${docType}/`,
  POSITIONS: "/positions/",
  POSITIONS_UNITS: "/positions/units/",
  SALARYS: "/salarys/",
  MODEOFOPERATION: "/modeofoperation/",
  PAY_PERIOD: "/payperiod/",
  IMAGES: "/images/",
  VIDEOS: "/videos/",
  AUDIOS: "/audios/",
  LEAVE: "/leave/",
  LEAVE_TYPE: "/leavetype/",
  LEAVE_PERIOD: "/leaveperiod/",
  LEAVE_UPCOMING: "/leave/upcoming_leaves/",
  TIMELOG: "/timelog/",
  EVENTS: "/events/",
  HOLIDAYS: "/holidays/",
  HOLIDAY_CATEGORY: "/holidaycategory/",
  COUNTRY: "/country/",
  CITY: "/city/",
  TOWN: "/town/",
  CURRENCY: "/currency/",
  MINUMUM_WAGE: "/minumumwage/",
  RESET_PASSWORD: "/users/reset-password/confirm/",
  FORGOT_PASSWORD: "/users/reset-password/",
  SENDMAIL_USERINFO: "/send-user-info/",
  COMPANY_SETTINGS_UPDATE: "/company/:company_id/settings/",
  NOTIFICATIONS: "/notifications/",
  UNIT_REPORTS: "/unit-reports/",
  VERIFY_PHONE: "/signup/verify-phone/",
  RESEND_VERIFY_PHONE: "/signup/resend-verification/",
  SMS_POST: "/smspost/",
  REPORT: "/reports/",
};
export const DEFAULT_AVATAR = avatar;
export const DEFAULT_BACKGROUND = "/images/bgdefault.jpg";
export const DEFAULT_AVATAR2 = "/images/default.png";
export const DEFAULT_CHAT_AVATAR = "/images/avatar.png";
export const PUBLIC_KEY = "Nkm7kFzUBTJDaZRtFIwMQFKtejNiBlrEj2QqSBjU";
export const SECRET_KEY =
  "nPLYg295WwYwapgdfzUd8THs0CBKHfUUpFeO6R3PSOyxlABwWtsqsXdl1BOCkZ1M5rtdmb2oMWcFmH4MtIIc6kNpx8qwLiuYowWkzz5EV2LChFofX9VffYnpmvKgOODc";
export const DEFAULT_PAGE_SIZE = 20;
export const MAX_UPLOAD_SIZE = 5242880; // 5 MB
export const RECAPTCHA_SITE_KEY = "6LeEP9IlAAAAAAJuqJjcNyr5uuxZEfMc6Ww_rPi-";
export const RECAPTCHA_SECRET_KEY = "6LeEP9IlAAAAANUx2qfLcdRiZtQbOsB9z-aN2AjI";
export default BASE_URL;
