import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";

const SubTabHeader = (props) => {
  const {
    title,
    backUrl,
    headerButtonVisible = true,
    headerButtonText,
    headerButtonOnClick,
  } = props;
  return (
    <div className="flex items-center justify-between bg-white p-4 shadow dark:bg-navy-800 dark:text-white">
      <div className="flex items-center">
        <Link to={backUrl}>
          {" "}
          <FaArrowLeft className="mr-2 cursor-pointer text-xl" />
        </Link>
        <span className="text-lg font-semibold">Ayarlar / {title}</span>
      </div>
      {headerButtonVisible && (
        <button
          onClick={headerButtonOnClick}
          className="rounded bg-blue-500 py-2 px-4 font-bold text-white hover:bg-blue-700"
        >
          {headerButtonText}
        </button>
      )}
    </div>
  );
};

export default SubTabHeader;
