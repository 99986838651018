import DataTable from "components/datatable";
import { formatDate } from "date-fns";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FaArrowLeft } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { get } from "utils/api";
import { ENDPOINTS } from "utils/constants";
import exportToExcel from "utils/exportToExcel";

const Detail = () => {
  const columnsConfig = {
    employeeListReport: [
      {
        Header: "Ad Soyad",
        accessor: (row) => `${row.user_first_name} ${row.user_last_name}`, // accessor tanımlamasını düzelt
      },
      {
        Header: "İşe Başlama Tarihi",
        accessor: "joined_date",
        Cell: (row) =>
          row.value ? new Date(row.value).toLocaleDateString() : "Bilgi Yok",
      },
      {
        Header: "Başlama Tarihi",
        accessor: "start_date",
        Cell: (row) =>
          row.value ? new Date(row.value).toLocaleDateString() : "Bilgi Yok",
      },
      {
        Header: "Çalışma Şekli",
        accessor: "mode_of_operation",
      },
      {
        Header: "Şirket Adı",
        accessor: "company_name",
      },
      {
        Header: "Birim Bilgileri",
        accessor: "unit_details",
        Cell: (row) =>
          row.value
            ? Object.values(row.value)
                .flat() // Düz bir liste oluşturur
                .join(", ") // Liste elemanlarını virgülle ayırarak birleştirir
            : "Bilgi Yok",
      },
      {
        Header: "Çalışma Durumu",
        accessor: "work_status",
      },
    ],
    timesheetReport: [
      {
        Header: "Ad Soyad",
        accessor: (row) => `${row.user_first_name} ${row.user_last_name}`,
      },
      {
        Header: "Kimlik Numarası",
        accessor: "user_identification_number",
      },
      {
        Header: "Başlangıç Zamanı",
        accessor: "start_datetime",
        Cell: ({ value }) => new Date(value).toLocaleString(),
      },
      {
        Header: "Bitiş Zamanı",
        accessor: "end_datetime",
        Cell: ({ value }) => new Date(value).toLocaleString(),
      },
      {
        Header: "Açıklama",
        accessor: "description",
        Cell: ({ value }) => value || "-",
      },
      {
        Header: "Durum",
        accessor: "status",
        Cell: ({ value }) => value || "-",
      },
      {
        Header: "Eksik mi?",
        accessor: "is_problem",
        Cell: ({ value }) => (
          <div style={{ color: value ? "red" : "green" }}>
            {value ? "Evet" : "Hayır"}
          </div>
        ),
      },
    ],
    salaryReport: [
      {
        Header: "Ad Soyad",
        accessor: (row) => `${row.user_first_name} ${row.user_last_name}`,
        sortable: true,
      },
      {
        Header: "Kimlik Numarası",
        accessor: "user_identification_number",
        sortable: true,
      },
      {
        Header: "Banka Adı",
        accessor: "user_bank_name",
        Cell: ({ value }) => value || "Bilgi Yok",
      },
      {
        Header: "Hesap Türü",
        accessor: "user_bank_account_type",
        Cell: ({ value }) => value || "Bilgi Yok",
      },
      {
        Header: "Hesap Numarası",
        accessor: "user_bank_account_number",
        Cell: ({ value }) => value || "Bilgi Yok",
      },
      {
        Header: "IBAN",
        accessor: "user_bank_iban",
        Cell: ({ value }) => value || "Bilgi Yok",
      },
      {
        Header: "Şirket Adı",
        accessor: "company_name",
        Cell: ({ value }) => value || "Bilgi Yok",
      },
      {
        Header: "Birim Bilgileri",
        accessor: "unit_details",
        Cell: (row) =>
          row.value
            ? Object.values(row.value)
                .flat() // Düz bir liste oluşturur
                .join(", ") // Liste elemanlarını virgülle ayırarak birleştirir
            : "Bilgi Yok",
      },
      {
        Header: "Maaş (TL)",
        accessor: "amount",
        Cell: ({ value }) => `${Number(value).toLocaleString()} TL`, // Maaşı biçimlendirerek TL olarak göster
      },
      {
        Header: "Etkin Tarih",
        accessor: "effective_on",
        Cell: ({ value }) => new Date(value).toLocaleDateString(),
      },
      {
        Header: "Brüt Maaş",
        accessor: "is_gross",
        Cell: ({ value }) => (value ? "Evet" : "Hayır"),
      },
      {
        Header: "Çalışma Durumu",
        accessor: "work_status",
      },
    ],
    expenseReport: [
      {
        Header: "Ad Soyad",
        accessor: (row) => `${row.user_first_name} ${row.user_last_name}`,
      },
      {
        Header: "Kimlik Numarası",
        accessor: "user_identification_number",
      },
      {
        Header: "Harcama Türü",
        accessor: "type_name",
      },
      {
        Header: "Tarih",
        accessor: "date",
        Cell: ({ value }) => new Date(value).toLocaleDateString(),
      },
      {
        Header: "Durum",
        accessor: "status",
      },
      {
        Header: "Açıklama",
        accessor: "description",
        Cell: ({ value }) => value || "Açıklama Yok",
      },
      {
        Header: "Oluşturulma Tarihi",
        accessor: "create_time",
        Cell: ({ value }) => new Date(value).toLocaleString(),
      },
      {
        Header: "Ödendi mi?",
        accessor: "is_paid",
        Cell: ({ value }) => (value ? "Evet" : "Hayır"),
      },
      {
        Header: "Tutar",
        accessor: "amount",
        Cell: ({ value }) => `${Number(value).toLocaleString()} TL`, // Tutarı Türk Lirası olarak göster
      },
      {
        Header: "Para Birimi",
        accessor: "currency",
      },
    ],

    leaveReport: [
      {
        Header: "Ad Soyad",
        accessor: (row) => `${row.user_first_name} ${row.user_last_name}`,
      },
      {
        Header: "Kimlik Numarası",
        accessor: "user_identification_number",
      },
      {
        Header: "İzin Türü",
        accessor: "leave_type_name",
      },
      {
        Header: "Başlangıç Zamanı",
        accessor: "start_datetime",
        Cell: ({ value }) => new Date(value).toLocaleString(),
        sortable: true,
      },
      {
        Header: "Bitiş Zamanı",
        accessor: "end_datetime",
        Cell: ({ value }) => new Date(value).toLocaleString(),
        sortable: true,
      },
      {
        Header: "Süre (Gün)",
        accessor: "duration",
        Cell: ({ value }) => `${value} gün`,
      },
      {
        Header: "Açıklama",
        accessor: "description",
        Cell: ({ value }) => value || "Açıklama Yok",
      },
      {
        Header: "İmzalandı mı?",
        accessor: "is_signed",
        Cell: ({ value }) => (value ? "Evet" : "Hayır"),
      },
      {
        Header: "Durum",
        accessor: "status",
      },
    ],
  };
  const { reportType } = useParams();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const handleDownloadExcel = () => {
    const currentConfig = columnsConfig[reportType];
    if (!currentConfig) {
      toast.error(
        "Excel'e aktarım için uygun sütun konfigürasyonu bulunamadı."
      );
      return;
    }
    // Excel'e aktarırken sadece görüntülenen sütunlar kullanılmalı
    const headers = currentConfig.filter((col) => !col.hidden);
    exportToExcel(
      headers,
      data,
      `${reportTitles[reportType] || reportType} - ${formatDate(
        new Date(),
        "yyyyMMdd"
      )}`
    );
  };
  const getReports = async () => {
    setLoading(true);
    try {
      const response = await get(
        `${ENDPOINTS.REPORT}?report_type=${reportType}`
      );
      setData(response || []); // Ensure data is always an array
      setLoading(false);
    } catch (e) {
      console.error("Fetching reports failed: ", e);
      setData([]); // Reset data on error
      setLoading(false);
      toast.error("Raporlar getirilirken bir hata oluştu.");
    }
  };
  console.log("response", data);
  useEffect(() => {
    getReports();
  }, [reportType]);
  const reportTitles = {
    employeeListReport: "Çalışan Listesi",
    salaryReport: "Maaş Raporu",
    leaveReport: "İzin Raporu",
    expenseReport: "Harcama Raporu",
    timesheetReport: "Çalışan Zaman Raporu",
  };
  return (
    <div className="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-1">
      <div className="flex items-center">
        <Link to={"/app/raporlar"}>
          {" "}
          <FaArrowLeft className="mr-2 cursor-pointer text-xl dark:text-white" />
        </Link>
        <span className="text-lg font-semibold dark:text-white">
          Raporlar / {reportTitles[reportType] || reportType}
        </span>
      </div>
      <div className="flex items-center">
        <button
          onClick={handleDownloadExcel}
          className="rounded bg-blue-500 py-2 px-4 font-bold text-white hover:bg-blue-700"
        >
          Excel'e Aktar
        </button>
      </div>
      {/* Tarih aralığı seçimi */}
      {/* <input
        type="date"
        value={startDate}
        onChange={(e) => setStartDate(e.target.value)}
      />
      <input
        type="date"
        value={endDate}
        onChange={(e) => setEndDate(e.target.value)}
      /> */}
      <div className="flex flex-col">
        <DataTable
          columnsData={columnsConfig[reportType] || []}
          tableData={data || []}
          title={`${reportType.replace("Report", "")} Raporu`}
          loading={loading}
          hiddenColumns={["id"]}
        />
      </div>
    </div>
  );
};

export default Detail;
