import DataTable from "components/datatable";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTable } from "react-table";
import { useAppSelector } from "store";
import { get } from "utils/api";
import { ENDPOINTS } from "utils/constants";
import { assetsColumsData, paymentColumsData } from "../variables/columnsData";
import Dropdown from "components/dropdown";
import { FaEllipsisV, FaPlus } from "react-icons/fa";
import { del } from "utils/api";
import toast from "react-hot-toast";
import Modal from "react-modal";

import NewTimeLog from "./NewTimeLog";
import NewAssets from "./NewAssets";
import { expensesColumsData } from "views/app/expense/variables/columnsData";
import CommonAidModal from "./PaymentModals/CommonAidModal";
import ExpenseAddModal from "./PaymentModals/ExpenseAddModal";
import AdvanceDebtModal from "./PaymentModals/AdvanceDebtModal";
import BASE_URL from "utils/constants";
import useCustomModalStyles from "utils/useCustomModalStyles";
import { patch } from "utils/api";

const Payment = () => {
  const modalStyle = useCustomModalStyles();
  const dropdownButton = <FaEllipsisV className="cursor-pointer" />;
  const expenseDropdownButton = (
    <>
      <div className="flex cursor-pointer items-center gap-1 rounded-lg bg-blue-500 p-3 text-sm text-white hover:bg-blue-600">
        {" "}
        <FaPlus className="cursor-pointer" /> Harcama Ekle
      </div>
    </>
  );
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const currentUserRoleName = useAppSelector(
    (state) => state.auth.currentUser?.role?.name === "CompanyAdmin"
  );
  const [expenseData, setExpenseData] = useState([]);
  const [expensesCategoryData, setExpensesCategoryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);

  const [commonModalInfo, setCommonModalInfo] = useState({
    isOpen: false,
    aidType: null,
  });
  const [isAdvanceDebtModalOpen, setIsAdvanceDebtModalOpen] = useState(false);
  const [isExpenseAddModalOpen, setIsExpenseAddModalOpen] = useState(false);
  const openCommonModal = (aidType) =>
    setCommonModalInfo({ isOpen: true, aidType });

  const { id } = useParams();
  const closeModal = () => {
    setIsOpenModal(false);
    setSelectedItemId(null);
  };
  const refreshData = () => getExpensesData();
  const paymentColumns = paymentColumsData(refreshData);
  const editHandle = (id) => {
    setSelectedItemId(id);
    setIsOpenModal(true);
  };
  const getExpensesCategoryData = async () => {
    const response = await get(ENDPOINTS.EXPENSES_CATEGORY);
    setExpensesCategoryData(response?.results);
  };
  const handleDownload = (expenseId) => {
    const url = BASE_URL + ENDPOINTS.DOWNLOAD_EXPENSES(expenseId);
    window.open(url, "_blank");
  };
  const changeStatusLeave = (id, status) => async () => {
    var formData = new FormData();
    formData.append("status", status);
    if (status === "Onaylandı") formData.append("is_approved", true);
    const response = await patch(ENDPOINTS.EXPENSES + `${id}/`, formData);
    if (response.status === 200) {
      getExpensesData();
      toast.success("Başarıyla güncellendi");
    } else {
      toast.error("Bir hata oluştu");
    }
  };
  const getExpensesData = async () => {
    const response = await get(
      ENDPOINTS.EXPENSES + `?user__id=${id}&ordering=-create_time`
    );
    const updatedData = response?.results.map((data) => ({
      ...data,
      actions: (
        <div className="whitespace-nowrap px-6 py-4 text-right text-sm font-medium">
          <div className="flex items-center justify-end">
            <Dropdown button={dropdownButton} classNames="right-0">
              <div className="rounded bg-white p-2 shadow-lg dark:bg-navy-700 dark:text-white">
                <ul className="text-sm">
                  <li
                    onClick={changeStatusLeave(data.id, "Onaylandı")}
                    className="cursor-pointer p-2 hover:bg-gray-100 dark:hover:bg-navy-800"
                  >
                    Onayla
                  </li>
                  <li
                    onClick={changeStatusLeave(data.id, "Reddedildi")}
                    className="cursor-pointer p-2 hover:bg-gray-100 dark:hover:bg-navy-800"
                  >
                    Reddet
                  </li>
                  {data.type === "expenseAdd" && (
                    <li
                      onClick={() => handleDownload(data.id)}
                      className="cursor-pointer p-2 hover:bg-gray-100 dark:hover:bg-navy-900"
                    >
                      Formu İndir
                    </li>
                  )}

                  <li
                    onClick={() => deleteHandle(data.id)}
                    className="cursor-pointer p-2 hover:bg-gray-100 dark:hover:bg-navy-900"
                  >
                    Sil
                  </li>
                </ul>
              </div>
            </Dropdown>
          </div>
        </div>
      ),
    }));
    setExpenseData(updatedData);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    getExpensesData();
    getExpensesCategoryData();
    setLoading(false);
  }, [id]);

  const deleteHandle = async (id) => {
    const result = window.confirm(`Silmek istediğinize emin misiniz?`);
    if (result) {
      const response = await del(ENDPOINTS.EXPENSES, id);
      if (response.status === 204) {
        getExpensesData();
        toast.success("Başarıyla silindi");
      } else {
        toast.error("Bir hata oluştu");
      }
    }
  };
  const columnsDataWithActions = currentUserRoleName
    ? [...paymentColumns, { label: "Eylemler", accessor: "actions" }]
    : paymentColumns;
  return (
    <>
      {currentUser?.role?.name === "CompanyAdmin" && (
        <div className="mt-6 items-center">
          <Dropdown button={expenseDropdownButton} className="right-0">
            <div className="mt-11 rounded-xl bg-white bg-navy-700 p-2 text-white shadow-lg">
              <ul className="text-sm">
                <li
                  onClick={() => openCommonModal("militaryAid")}
                  className="cursor-pointer p-2 hover:bg-navy-900"
                >
                  Askerlik Yardımı
                </li>
                <li
                  onClick={() => setIsAdvanceDebtModalOpen(true)}
                  className="cursor-pointer p-2 hover:bg-navy-900"
                >
                  Avans/Borç
                </li>
                <li
                  onClick={() => openCommonModal("holidayAid")}
                  className="cursor-pointer p-2 hover:bg-navy-900"
                >
                  Bayram Yardımı
                </li>
                <li
                  onClick={() => setIsExpenseAddModalOpen(true)}
                  className="cursor-pointer p-2 hover:bg-navy-900"
                >
                  Harcama Ekle
                </li>
                <li
                  onClick={() => openCommonModal("garnishment")}
                  className="cursor-pointer p-2 hover:bg-navy-900"
                >
                  İcra Ekle
                </li>
                <li
                  onClick={() => openCommonModal("overtimeRecord")}
                  className="cursor-pointer p-2 hover:bg-navy-900"
                >
                  Mesai Kaydı Ekle
                </li>
                <li
                  onClick={() => openCommonModal("bonus")}
                  className="cursor-pointer p-2 hover:bg-navy-900"
                >
                  Prim Ekle
                </li>
                <li
                  onClick={() => openCommonModal("fuelAid")}
                  className="cursor-pointer p-2 hover:bg-navy-900"
                >
                  Yakacak Yardımı
                </li>
              </ul>
            </div>
          </Dropdown>
        </div>
      )}

      <div className="flex flex-col">
        <DataTable
          columnsData={columnsDataWithActions}
          tableData={expenseData}
          title="Harcamalar"
          hiddenColumns={["id"]}
        />
      </div>

      {commonModalInfo.isOpen && (
        <CommonAidModal
          isOpen={commonModalInfo.isOpen}
          onClose={() => setCommonModalInfo({ isOpen: false, aidType: null })}
          onRefresh={() => {
            getExpensesData();
          }}
          userId={id} // Kullanıcı ID
          aidType={commonModalInfo.aidType}
        />
      )}
      {isAdvanceDebtModalOpen && (
        <AdvanceDebtModal
          isOpen={isAdvanceDebtModalOpen}
          onClose={() => setIsAdvanceDebtModalOpen(false)}
          onRefresh={() => {
            getExpensesData();
          }}
          userId={id}
          displayAdminButtons="true"
        />
      )}
      {isExpenseAddModalOpen && (
        <ExpenseAddModal
          isOpen={isExpenseAddModalOpen}
          onClose={() => setIsExpenseAddModalOpen(false)}
          onRefresh={() => {
            getExpensesData();
          }}
          userId={id}
          displayAdminButtons="true"
        />
      )}
    </>
  );
};

export default Payment;
