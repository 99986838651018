import Dropdown from "components/dropdown";
import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import SubTabHeader from "../SubTabHeader";
import FormikCheckbox from "components/checkbox/FormikCheckbox";
import { Form, Formik } from "formik";
import { get } from "utils/api";
import { ENDPOINTS } from "utils/constants";
import { useAppSelector } from "store";
import { put } from "utils/api";
import { patch } from "utils/api";
import toast from "react-hot-toast";
import { setCurrentUser } from "store/authSlice";
import { useDispatch } from "react-redux";

const RequestSettings = () => {
  // Dropdown içerikleri için basit örnekler
  const currentUser = useAppSelector((state) => state.auth.currentUser); 
  const dispatch = useDispatch();
  const dropdownContent = <div>Buraya istediğiniz içerik gelebilir</div>;
  const [editingItem, setEditingItem] = useState({});
  const getEditItem = async () => {
    const response = await get(ENDPOINTS.COMPANY + currentUser?.company?.id + "/");
    setEditingItem(response?.settings);
  };
  useEffect(() => {
    getEditItem();
  }, []);
  
  const editSubmit = async (values) => {
    var formData = new FormData();
    formData.append("is_advance_request", values.is_advance_request);
    formData.append("is_expense_request", values.is_expense_request);
    formData.append("is_leave_request", values.is_leave_request);
    formData.append("is_shift_request", values.is_shift_request);
    const response = await patch(ENDPOINTS.COMPANY_SETTINGS_UPDATE.replace(':company_id', currentUser?.company?.id), formData);
    if (response.status === 200) {
      toast.success("Başarıyla güncellendi");
      dispatch(
        setCurrentUser({
          ...currentUser,
          company: { ...currentUser.company, settings: { ...response.data } },
        })
      );
      getEditItem();
    } else {
      toast.error("Bir hata oluştu");
    }
    
  };
  return (
    <div>
      <Formik
        initialValues={{
          is_advance_request: editingItem.is_advance_request,
          is_expense_request: editingItem.is_expense_request ,
          is_leave_request: editingItem.is_leave_request ,
          is_shift_request: editingItem.is_shift_request ,
        }}
        onSubmit={(values, { resetForm }) => {
          editSubmit(values);
          resetForm();
        }}
        enableReinitialize
      >
        {({
          isSubmitting,
          handleChange,
          handleBlur,
          values,
          setFieldValue,
        }) => (
          <Form className="space-y-4">
            <SubTabHeader
              title="Talep Ayarları"
              backUrl="/app/ayarlar/sistem-ayarlari"
              headerButtonVisible={false}
            />
            <div className="dark:text-white container mx-auto bg-white p-4 dark:bg-navy-800">
              <div className="ml-5 p-6">
                {/* Ana Sayfa Bölümü */}
                <div className="mb-6">
                  <h2 className="mb-2 text-lg font-bold">Genel</h2>
                  <p className="mb-4 text-sm">
                    Talep ayarlarını buradan düzenleyebilirsiniz.
                  </p>
                  <FormikCheckbox
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="is_advance_request"
                    color="blue"
                    label="Çalışanlar avans talebinde bulunabilir"
                  />
                  <FormikCheckbox
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="is_expense_request"
                    color="blue"
                    label="Çalışanlar harcama talebinde bulunabilir"
                  />
                  <FormikCheckbox
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="is_leave_request"
                    color="blue"
                    label="Çalışanlar izin talebinde bulunabilir"
                  />
                  <FormikCheckbox
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="is_shift_request"
                    color="blue"
                    label="Çalışanlar mesai talebinde bulunabilir"
                  />
                </div>

              </div>
              <div className="flex justify-end space-x-2">
              <button
                type="submit"
                disabled={isSubmitting}
                className="rounded-md bg-blue-600 px-4 py-2 text-sm text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                Kaydet
              </button>
            </div>
            </div>
   
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default RequestSettings;
