// useCustomModalStyles.js
import { useTheme } from 'ThemeContext';
import { useState, useEffect } from 'react';

const useCustomModalStyles = () => {
  const { isDarkMode } = useTheme();
  const [style, setStyle] = useState({});

  useEffect(() => {
    const isMobile = window.innerWidth < 768; // Mobil cihaz kontrolü

    const themeStyles = isDarkMode ? {
      background: 'rgb(27 37 75 / var(--tw-bg-opacity))', // Dark mode arka plan rengi
      color: 'white', // Dark mode metin rengi
      border: '1px solid #444', // Dark mode için sınır rengi
    } : {
      background: 'white',
      border: '1px solid #ccc',
      color: 'black', // Light mode metin rengi
    };

    const modalStyle = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: isMobile ? '90%' : '35%', // Mobil cihazlarda genişliği %90 yapar
        height: isMobile ? 'auto' : '', // Mobil cihazlarda yüksekliği otomatik ayarlar
        maxHeight: isMobile ? '80%' : '85%', // Mobil cihazlarda maksimum yüksekliği ayarlar
        padding: '20px',
        overflow: 'auto', // İçerik çok fazlaysa kaydırmayı etkinleştirir
        ...themeStyles, // Tema bağlı stil ayarlarını uygula
      },
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        zIndex: 1000,
      },
    };

    setStyle(modalStyle);
  }, [isDarkMode]);

  return style;
};

export default useCustomModalStyles;
