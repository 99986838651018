import { format } from "date-fns";
import { tr } from "date-fns/locale";

export const leavesColumsData = [
  {
    Header: "Ad Soyad",
    accessor: (row) => `${row.user.first_name} ${row.user.last_name}`,
  },
  {
    Header: "Başlangıç",
    accessor: (row) => {
      const startDate = new Date(row.start_datetime);

      const formattedStartDate = format(startDate, "d MMMM yyyy HH:mm", {
        locale: tr,
      });

      return `${formattedStartDate}`;
    },
  },
  {
    Header: "Bitiş",
    accessor: (row) => {
      const endDate = new Date(row.end_datetime);

      const formattedEndDate = format(endDate, "d MMMM yyyy HH:mm", { locale: tr });

      return `${formattedEndDate}`;
    },
  },
  {
    Header: "İzin Türü",
    accessor: "leave_type.name",
  },
  {
    Header: "Açıklama",
    accessor: "description",
  },
  {
    Header: "Onay Durumu",
    accessor: "status",
  },
  {
    Header: "Gün",
    accessor: "duration",
  },
];

export const leaveTypeColumsData = [
  {
    Header: "İzin Türü",
    accessor: "name",
  },
  {
    Header: "Durum",
    accessor: (row) => {
      if (row.is_active) {
        return "Aktif";
      }
      return "Pasif";
    },
  },
  {
    Header: "Ücretli/Ücretsiz",
    accessor: (row) => {
      if (row.is_paid) {
        return "Ücretli";
      }
      return "Ücretsiz";
    }
  },
  {
    Header: "Hakediş",
    accessor: "",
  },
  {
    Header: "Hakediş Sıklığı",
    accessor: "accrual_frequency",
  },
  {
    Header: "En az izin süresi",
    accessor: "least_day_count",
  },
  {
    Header: "En çok izin süresi",
    accessor: "most_day_count",
  },
  {
    Header: "",
    id: "actions",
    accessor: "actions",
  },
];

export const leaveReportsColumnsData = [
  {
    Header: "Sıra",
    accessor: (row, i) => i + 1,
  },
  {
    Header: "Ad",
    accessor: "user.first_name",
  },
  {
    Header: "Soyad",
    accessor: "user.last_name",
  },
  {
    Header: "Kimlik Numarası",
    accessor: "user.identification_number",
  },
  {
    Header: "Şirket",
    accessor: (row) => {
      const companyNames = row.units?.map(unit => unit.company.name).filter(name => name).join(", ");
      return companyNames || "Bilgi Yok";
    },
  },
  {
    Header: "Şube",
    accessor: (row) => {
      const branchNames = row.units?.flatMap(unit => unit.subsidiaries.map(sub => sub.name)).filter(name => name).join(", ");
      return branchNames || "Bilgi Yok";
    },
  },
  {
    Header: "Departman",
    accessor: (row) => {
      const departmentNames = row.units?.map(unit => unit.unit_type ? unit.unit_type.name : "").filter(name => name).join(", ");
      return departmentNames || "Bilgi Yok";
    },
  },
  {
    Header: "Unvan",
    accessor: (row) => {
      // Unvan bilgisini çekmek için Unit modelinizde doğrudan bir alan olmadığından,
      // bu örnekte 'unit_type' alanını kullanıyoruz. Eğer 'titles' veya benzeri bir alanınız varsa,
      // burada o alanı kullanmanız gerekebilir.
      const titles = row.units?.map(unit => unit.unit_type ? unit.unit_type.name : "").filter(name => name).join(", ");
      return titles || "Bilgi Yok";
    },
  },
  {
    Header: "İşe Giriş Tarihi",
    accessor: (row) => row.user.joined_date ? new Date(row.user.joined_date).toLocaleDateString("tr-TR") : "",
  },
  {
    Header: "İzne Esas Hak Ediş Tarihi",
    accessor: "entitlement_date", // Bu alanın veri modelinizde tam olarak nereye bağlı olduğunu belirtmediniz. Gerekirse ayarlayın.
  },
  {
    Header: "Ekstra İzinler",
    accessor: "extra_leaves",
  },
  {
    Header: "Toplam Hak Edilen",
    accessor: "total_entitled",
  },
  {
    Header: "Devir Kayıpları",
    accessor: "carryover_losses",
  },
  {
    Header: "Güncel Döneme Devreden İzin Hakkı",
    accessor: "current_period_carryover",
  },
  {
    Header: "Kullanılabilir Devreden İzin Hakkı",
    accessor: "available_carryover",
  },
  {
    Header: "Son Dönemde Hak Edilen",
    accessor: "last_period_entitled",
  },
  {
    Header: "Güncel Hak Ediş Dönemi",
    accessor: "current_entitlement_period",
  },
  {
    Header: "Güncel Dönem Başındaki İzin Hakkı",
    accessor: "start_of_current_period_rights",
  },
  {
    Header: "Güncel Dönemde Kullanılan",
    accessor: "used_in_current_period",
  },
  {
    Header: "Toplam Kullanılan",
    accessor: "total_used",
  },
  {
    Header: "Güncel Bakiye",
    accessor: "current_balance",
  },
  {
    Header: "Güncel Dönemdeki İleri Tarihli İzinler",
    accessor: "future_leaves_in_current_period",
  },
  {
    Header: "Güncel Dönem Kullanılabilir Bakiye",
    accessor: "available_balance_in_current_period",
  },
  {
    Header: "Sonraki İzin Hak Ediş Tarihi",
    accessor: "next_entitlement_date",
  },
  {
    Header: "Sonraki Dönem İçin Onaylanmış İzinler",
    accessor: "approved_leaves_for_next_period",
  },
];
