import React from 'react';
import InputMask from 'react-input-mask';
import { useField } from 'formik';

function PhoneField(props) {
  const {
    label,
    id,
    extra,
    placeholder,
    variant,
    disabled,
    onKeyDown,
    required,
  } = props;

  let field, meta;
  try {
    [field, meta] = useField(props);
  } catch {
    field = {
      value: props.value || '',
      onChange: props.onChange,
      onBlur: props.onBlur
    };
    meta = { touched: false, error: undefined };
  }

  return (
    <div className={`${extra}`}>
      <label
        htmlFor={id}
        className={`text-sm text-navy-700 dark:text-white ${
          variant === "auth" ? "ml-1.5 font-medium" : "ml-3 font-bold"
        }`}
      >
        {label}
      </label>
      <InputMask
        mask="05999999999"
        maskChar={null}
        disabled={disabled}
        placeholder={placeholder}
        onKeyDown={onKeyDown}
        required={required}
        className={`mt-1 flex h-12 w-full items-center justify-center rounded-xl border bg-white p-3 text-sm outline-none dark:bg-navy-700 dark:text-white ${
          disabled === true
            ? "!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]"
            : meta.error && meta.touched
            ? "border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400"
            : "border-gray-200 dark:!border-white/10 dark:text-white"
        }`}
        {...field}
      />
      {/* Hata mesajı gösterimi */}
      {meta.touched && meta.error ? (
        <div className="text-sm text-red-500">{meta.error}</div>
      ) : null}
    </div>
  );
}

export default PhoneField;
