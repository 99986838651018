import React from "react";

function useOutsideAlerter(ref, setX) {
  React.useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setX(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, setX]);
}

const Dropdown = (props) => {
  const { button, children, classNames, animation } = props;
  const wrapperRef = React.useRef(null);
  const [openWrapper, setOpenWrapper] = React.useState(false);

  useOutsideAlerter(wrapperRef, setOpenWrapper);

  // Bu fonksiyon, dropdown içindeki herhangi bir öğeye tıklandığında çalışır
  const handleDropdownClick = (event) => {
    // Burada özel bir durum kontrolü yapılabilir, örneğin tıklanan öğenin belirli bir türde olup olmadığının kontrolü
    setOpenWrapper(false); // Dropdown'u kapat
  };

  return (
    <div ref={wrapperRef} className="relative flex">
      <div className="flex" onMouseDown={() => setOpenWrapper(!openWrapper)}>
        {button}
      </div>
      {openWrapper && (
        <div
          className={`${classNames} absolute z-10 ${
            animation
              ? animation
              : "origin-top-right transition-all duration-300 ease-in-out"
          }`}
          onClick={handleDropdownClick} // Dropdown içindeki tüm tıklamaları yakala
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
