import React from 'react';
import DatePicker, { registerLocale } from "react-datepicker";
import { useField, useFormikContext } from "formik";
import "react-datepicker/dist/react-datepicker.css";
import tr from "date-fns/locale/tr"; // Türkçe dil desteği için
import { useTheme } from 'ThemeContext';

registerLocale("tr", tr); // DatePicker için Türkçe dilini kaydet
const CustomInput = React.forwardRef(({ value, onClick,style }, ref) => (
  <button
    type="button"
    className="mt-1 flex h-12 w-full rounded-md border border-gray-300 bg-white text-left shadow focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
    onClick={onClick}
    ref={ref}
    style={style}
  >
    {value || "Tarih Seç"}
  </button>
));
const FormikDateTimePicker = ({ name, ...otherProps }) => {
  const {isDarkMode} = useTheme();
  const { setFieldValue } = useFormikContext();
  const [field, meta, helpers] = useField(name);
  const inputStyles = {
    width:'200px',
    padding:'10px',
    background: isDarkMode ? 'rgb(27 37 75 / var(--tw-bg-opacity))' : '#fff',
  };
  return (
    <DatePicker
      {...field}
      {...otherProps}
      locale="tr"
      selected={(field.value && new Date(field.value)) || null}
      onChange={(val) => {
        setFieldValue(name, val);
      }}
      showTimeSelect
      timeFormat="HH:mm"
      timeIntervals={15}
      timeCaption="Saat"
      dateFormat="dd.MM.yyyy HH:mm"
      isClearable
      customInput={<CustomInput style={inputStyles} />}
      showMonthDropdown
      showYearDropdown
      dropdownMode="scroll"
    />
  );
};

export default FormikDateTimePicker;