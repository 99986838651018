import Dropdown from "components/dropdown";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { MdAdd, MdDelete, MdMoreVert } from "react-icons/md";
import Modal from "react-modal";
import { useDispatch } from "react-redux";
import Switch from "react-switch";
import { useAppSelector } from "store";
import { setCurrentUser } from "store/authSlice";
import { get } from "utils/api";
import { patch } from "utils/api";
import { ENDPOINTS } from "utils/constants";

import { post } from "utils/api";
import UnitForm from "./Forms/UnitForm";
import { del } from "utils/api";
import InputField from "components/fields/InputField";
import { Formik } from "formik";
import { addUnitTypeFormValidationSchema } from "../validations/SettingsValidationSchema";
import { orderUnitTypes } from "utils/constants";
import { Link } from "react-router-dom";
import { put } from "utils/api";
import useCustomModalStyles from "utils/useCustomModalStyles";
const UnitCard = ({
  id,
  name,
  type_code,
  onRename,
  onDelete,
  fetchUnits,
  showSwitch = false,
  switchChangeEvent = () => {},
  switchCheckedValue = false,
}) => {
  const modalStyle = useCustomModalStyles();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [unitTypeName, setUnitTypeName] = useState("");
  function openModal() {
    setModalIsOpen(true);
  }

  function closeModal() {
    setModalIsOpen(false);
  }
  const onDeleteUnitType = async () => {
    var result = window.confirm(`Birimini silmek istediğinize emin misiniz?`);
    if (result) {
      var response = await del(ENDPOINTS.UNIT_TYPE, id);
      if (response.status === 204) {
        toast.success("Birim başarıyla silindi.");
        fetchUnits();
      } else {
        toast.error("Birim silinirken bir hata oluştu.");
      }
    }
  };

  const handleSubmit = async (values) => {
    // Kullanıcı tarafından girilen birim adını burada kullanabilirsiniz.
    console.log("Yeni birim adı:", values.name);
    var formData = new FormData();
    formData.append("name", values.name);
    var response = await patch(ENDPOINTS.UNIT_TYPE + id + "/", formData);
    if (response.status === 200) {
      toast.success("Birim başarıyla yeniden adlandırıldı.");
      fetchUnits();
    } else {
      toast.error("Birim yeniden adlandırılırken bir hata oluştu.");
    }
    closeModal();
  };
  const dropdownButton = (
    <button className="p-2 text-gray-600 hover:text-gray-800">
      <MdMoreVert />
    </button>
  );

  const dropdownContent = (
    <div className="w-48 rounded-md bg-white py-2 shadow-xl">
      <Link
        onClick={openModal}
        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
      >
        Yeniden Adlandır
      </Link>
      <Link
        onClick={onDeleteUnitType}
        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
      >
        Sil
      </Link>
    </div>
  );
  return (
    <div className="flex items-center justify-between border-t border-gray-200 p-4">
      <span>{name}</span>
      {name !== "Şirket" && (
        <Dropdown button={dropdownButton} classNames="right-0 mt-2">
          {dropdownContent}
        </Dropdown>
      )}

      {showSwitch && (
        <Switch
          onChange={switchChangeEvent}
          checked={switchCheckedValue}
          classNames="right-0 mt-2"
        />
      )}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Yeni Birim Adı"
        style={modalStyle}
      >
       <Formik
          initialValues={{ name: "", }}
          enableReinitialize={true}
          onSubmit={handleSubmit}
        >
          
          {({
            values,
            errors,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label
                  className="mb-2 block text-sm font-bold text-gray-700"
                  htmlFor="name"
                >
                  Birim Adı
                </label>
                <InputField
                  id="name"
                  name="name"
                  type="text"
                  placeholder="Birim Adı"
                  onChange={(e) => {
                    setFieldValue(e.target.name, e.target.value);
                    setUnitTypeName(e.target.value);
                  }}
                  error={errors.name}
                />
              </div>
              <div className="flex justify-end space-x-2">
                <button
                  type="button"
                  onClick={closeModal}
                  className="rounded-md bg-gray-300 px-4 py-2 text-sm text-gray-700 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                >
                  İptal
                </button>
                <button
                  disabled={isSubmitting}
                  type="submit"
                  className="rounded-md bg-blue-600 px-4 py-2 text-sm text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  {isSubmitting ? "Kaydediliyor..." : "Kaydet"}
                </button>
              </div>
            </form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};
const CompanyInfo = () => {
  const modalStyle = useCustomModalStyles();

  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const dispatch = useDispatch();

  const [isHolding, setIsHolding] = useState(currentUser?.company?.is_holding);
  const [isAddUnitModalOpen, setIsAddUnitModalOpen] = useState(false);
  const [isEditUnitModalOpen, setIsEditUnitModalOpen] = useState(false);
  const [isAddUnitTypeModalOpen, setIsAddUnitTypeModalOpen] = useState(false);
  const [currentTypeCode, setCurrentTypeCode] = useState("");

  const openAddUnitTypeModal = (typeCode) => {
    setCurrentTypeCode(typeCode);
    setIsAddUnitTypeModalOpen(true);
  };
  const closeAddUnitTypeModal = () => setIsAddUnitTypeModalOpen(false);
  const openAddUnitModal = () => setIsAddUnitModalOpen(true);
  const closeAddUnitModal = () => setIsAddUnitModalOpen(false);
  const openEditUnitModal = () => setIsEditUnitModalOpen(true);
  const closeEditUnitModal = () => {
    setIsEditUnitModalOpen(false);
    setEditingUnitDetails(null); // Düzenleme detaylarını temizle
  };

  const [loading, setLoading] = useState(false);
  const [unitDatas, setUnitDatas] = useState([]);
  const [selectedUnitTypeId, setSelectedUnitTypeId] = useState(null);
  const [selectedUnitId, setSelectedUnitId] = useState(null);
  const [selectedUnitType, setSelectedUnitType] = useState(null); // 'company', 'department' vb.
  const [selectedUnitTypeName, setSelectedUnitTypeName] = useState("");
  const [editingUnitDetails, setEditingUnitDetails] = useState(null);

  const getCommonUnitData = async (values) => {
    const formData = new FormData();
    Object.keys(values).forEach((key) => formData.append(key, values[key]));
    formData.append("unit_type", selectedUnitTypeId);
   
    try {
      const response = await post(ENDPOINTS.UNIT, formData);
      if (response.status === 201) {
        toast.success("Birim başarıyla eklendi.");
        fetchUnits(); // Birim listesini güncelleyin
      } else {
        toast.error("Birim eklenirken bir hata oluştu.");
      }
    } catch (error) {
      toast.error("Birim eklenirken bir hata oluştu.");
    }
    // API çağrısı yapın ve sonuçları işleyin
  };
  const unitStrategies = {
    holding: async (values) => {
      getCommonUnitData(values);
      setSelectedUnitTypeId(selectedUnitTypeId);
    },
    company: async (values) => {
      getCommonUnitData(values);
      setSelectedUnitTypeId(selectedUnitTypeId);
    },
    branch: async (values) => {
      getCommonUnitData(values);
      setSelectedUnitTypeId(selectedUnitTypeId);
    },
    department: async (values) => {
      const formData = new FormData();
      // department için özel form alanları
      const departmentSpecificFields = ["name", "phone", "holiday_category"];
      departmentSpecificFields.forEach((field) => {
        if (values[field] !== undefined) {
          formData.append(field, values[field]);
        }
      });

      // Seçilen birim tipini ekleyin
      formData.append("unit_type", selectedUnitTypeId);
      try {
        const response = await post(ENDPOINTS.UNIT, formData);
        if (response.status === 201) {
          toast.success("Department birimi başarıyla eklendi.");
          fetchUnits();
        } else {
          toast.error("Department birimi eklenirken bir hata oluştu.");
        }
      } catch (error) {
        toast.error("Department birimi eklenirken bir hata oluştu.");
      }
      setSelectedUnitTypeId(selectedUnitTypeId);
    },
    title: async (values) => {
      const formData = new FormData();
      const specificFields = ["name", "phone", "holiday_category"];
      specificFields.forEach((field) => {
        if (values[field] !== undefined) {
          formData.append(field, values[field]);
        }
      });

      // Seçilen birim tipini ekleyin
      formData.append("unit_type", selectedUnitTypeId);
      try {
        const response = await post(ENDPOINTS.UNIT, formData);
        if (response.status === 201) {
          toast.success("Title birimi başarıyla eklendi.");
          fetchUnits();
        } else {
          toast.error("Department birimi eklenirken bir hata oluştu.");
        }
      } catch (error) {
        toast.error("Department birimi eklenirken bir hata oluştu.");
      }
      setSelectedUnitTypeId(selectedUnitTypeId);
    },
  };

  const handleAddUnit = async (values) => {
    const strategy = unitStrategies[values.unitType.toLowerCase()];
    if (strategy) {
      await strategy(values);

      closeAddUnitModal(); // Modal'ı kapat
    } else {
      toast.error(
        `Bilinmeyen form tipi: ${values.unitType}. Lütfen konfigürasyonu kontrol edin.`
      );
    }
  };
  const handleEditUnit = async (values) => {
    values.unit_type = selectedUnitTypeId;
    setLoading(true); // Yükleniyor durumunu aktif et
    try {
      // API endpoint'i, `ENDPOINTS.UNIT` kullanılarak ve ilgili birim ID'si eklenerek oluşturulur
      const endpoint = `${ENDPOINTS.UNIT}${selectedUnitId}/`;
      const response = await patch(endpoint, values);

      if (response.status === 200) {
        toast.success("Birim başarıyla güncellendi.");
        fetchUnits(); // Birim listesini yeniden yükle
      } else {
        toast.error("Birim güncellenirken bir hata oluştu.");
      }
    } catch (error) {
      console.error("Birim güncelleme hatası:", error);
      toast.error("Birim güncellenirken bir hata oluştu.");
    }
    setLoading(false); // Yükleniyor durumunu pasif et
    closeEditUnitModal(); // Düzenleme modalını kapat
  };
  const toggleUnit = (id) => {
    setSelectedUnitTypeId((prevSelectedUnitTypeId) =>
      prevSelectedUnitTypeId === id ? null : id
    );
  };
  useEffect(() => {
    console.log("selectedUnitTypeId güncellendi:", selectedUnitTypeId);
  }, [selectedUnitTypeId]);

  const addItem = (unitType, unitId) => {
    const selectedUnit = unitDatas.find(
      (unit) => unit.type_code === unitType && unit.id === unitId
    );

    if (selectedUnit) {
      setSelectedUnitType(unitType);
      setSelectedUnitTypeName(selectedUnit.name);
      setSelectedUnitId(selectedUnit.id);
      setIsAddUnitModalOpen(true); // Modalı aç
    } else {
      console.error("Seçilen birim türü bulunamadı:", unitType);
    }
    console.log("selectedUnitTypeId", selectedUnitTypeId);
  };
  const editItem = (unitId, itemId) => {
    const selectedUnits = unitDatas
      .find((unit) => unit.id === unitId)
      ?.units.find((item) => item.id === itemId);
    if (selectedUnits) {
      setSelectedUnitType(selectedUnits.unit_type.type_code);
      setSelectedUnitTypeName(selectedUnits.name);
      setSelectedUnitId(selectedUnits.id);
      setEditingUnitDetails(selectedUnits); // Düzenlenecek birimin detaylarını ayarla
      openEditUnitModal(); // Düzenleme modalını aç
    } else {
      console.error("Seçilen birim bulunamadı.");
    }
  };
  const deleteItem = async (unitId, itemId) => {
    const selectedUnit = unitDatas
      .find((unit) => unit.id === unitId)
      ?.units.find((item) => item.id === itemId);
    if (selectedUnit) {
      var result = window.confirm(
        `${selectedUnit.name} birimini silmek istediğinize emin misiniz?`
      );
      if (result) {
        var response = await del(ENDPOINTS.UNIT, selectedUnit.id);
        if (response.status === 204) {
          toast.success("Birim başarıyla silindi.");
          fetchUnits();
        } else {
          toast.error("Birim silinirken bir hata oluştu.");
        }
      }
    } else {
      console.error("Seçilen birim bulunamadı.");
    }
  };

  const fetchUnits = async () => {
    setLoading(true);
    try {
      const response = await get(ENDPOINTS.UNIT_TYPE);
      const units = response.results; // Varsayılan olarak tüm birimler
      // Eğer isHolding false ise, holding tipindeki birimleri çıkar
      const filteredUnits = isHolding
        ? units
        : units.filter((unit) => unit.type_code !== "holding");
      const sortedUnits = filteredUnits.sort((a, b) => a.order - b.order);

      setUnitDatas(sortedUnits);
    } catch (error) {
      console.error("Birimler yüklenirken bir hata oluştu: ", error);
      toast.error("Birimler yüklenirken bir hata oluştu.");
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchUnits();
  }, [isHolding]);

  useEffect(() => {
    let dynamicUnits = [...unitDatas]; // unitDatas'dan tüm birimleri kopyala

    if (isHolding) {
      const holdingExists = dynamicUnits.some(
        (unit) => unit.type_code === "holding"
      );
      if (!holdingExists) {
        const holdingsData =
          dynamicUnits.find((unit) => unit.name === "Holding")?.units || [];
        dynamicUnits.unshift({
          id: 1,
          name: "Holding",
          type: "holding",
          count: holdingsData.length,
          items: holdingsData.map((h) => ({
            id: h.id,
            name: h.name,
            employeeCount: 0,
          })),
        });
      }
    } else {
      // 'isHolding' pasifse, 'Holding' birimlerini çıkar
      dynamicUnits = dynamicUnits.filter(
        (unit) => unit.type_code !== "holding"
      );
    }
    dynamicUnits.sort((a, b) => a.order - b.order);
    setUnitDatas(dynamicUnits);
  }, [isHolding]);

  const changeHolding = async (checked) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("is_holding", checked);
    try {
      const companyId = currentUser?.company?.id;
      const response = await patch(
        `${ENDPOINTS.COMPANY}${companyId}/`,
        formData
      );
      if (response.status === 200) {
        dispatch(
          setCurrentUser({
            ...currentUser,
            company: { ...currentUser.company, is_holding: checked },
          })
        );
        setIsHolding(checked);
        toast.success("Güncellendi.");
      } else {
        throw new Error("Güncelleme başarısız.");
      }
    } catch (error) {
      console.error("Güncelleme sırasında hata oluştu: ", error);
      toast.error("Güncelleme sırasında bir hata oluştu.");
    }
    setLoading(false);
  };

  const unitTypehandleSubmit = async (values, { setSubmitting, resetForm }) => {
    // API çağrısını burada yap
    try {
      const formData = new FormData();
      var orderUnitType = orderUnitTypes(values.type_code);
      formData.append("name", values.name);
      formData.append("type_code", values.type_code);
      formData.append("position", orderUnitType);
      formData.append("company", currentUser.company.id);
      const response = await post(ENDPOINTS.UNIT_TYPE, formData);
      if (response.status === 201) {
        toast.success("Birim tipi başarıyla eklendi.");
        fetchUnits();
        closeAddUnitTypeModal();
      } else {
        throw new Error("Birim tipi eklenirken bir hata oluştu.");
      }

      resetForm(); // Formu sıfırla
    } catch (error) {
      // Hata yönetimi
      // toast.error('Birim tipi eklenirken bir hata oluştu.');
      console.error("Form gönderme hatası", error);
    } finally {
      setSubmitting(false); // Gönderim durumunu false yap
    }
  };

  return (
    <div className="flex flex-col md:flex-row">
      {/* Şirket Yapısı Bölümü */}
      <div className="mb-4 w-full max-w-3xl rounded bg-white shadow-md dark:bg-navy-800 dark:text-white md:mb-0 md:mr-4">
        <div className="border-b border-gray-200 p-4">
          <h2 className="mb-4 font-bold">Şirket Yapısı</h2>
          {/* Holding ve Şube için switch'ler */}
          <div className="mt-4 flex items-center justify-between">
            <label className="mr-2">Holding</label>
            <Switch onChange={changeHolding} checked={isHolding} />
          </div>
        </div>

        {/* Diğer UnitCard bileşenleri */}
        {unitDatas.map(
          (unit) =>
            unit.type_code !== "holding" && (
              <>
                <UnitCard
                  id={unit.id}
                  key={unit.id}
                  name={unit.name}
                  type_code={unit.type_code}
                  onRename={() => {}}
                  onDelete={() => {}}
                  fetchUnits={fetchUnits}
                />
                <div className="flex justify-center">
                  <button
                    onClick={() => openAddUnitTypeModal(unit.type_code)}
                    className="p-2 text-green-600 hover:text-green-800"
                  >
                    <MdAdd />
                  </button>
                </div>
              </>
            )
        )}
      </div>

      {/* Şirket Birimleri Bölümü */}
      <div className="w-full">
        <div className="mb-4 font-bold">Şirket Birimleri</div>
        <div className="mb-4 text-gray-600">
          Şirket yapısındaki birimleri düzenleyin ve yeni birimler oluşturun
        </div>
        {loading ? (
          <div>Loading...</div>
        ) : (
          unitDatas.map((unit) => (
            <div key={unit.id}>
              <div
                className="m-2 flex items-center justify-between rounded border p-2 hover:cursor-pointer"
                onClick={() => toggleUnit(unit.id)}
              >
                <div className="">
                  {unit.name} -{" "}
                  <span className="text-sm text-gray-500">
                    ({unit.units?.length ?? 0})
                  </span>
                </div>
                <button
                  className="rounded bg-blue-500 py-1 px-2 text-white hover:bg-blue-700"
                  onClick={(e) => {
                    e.stopPropagation(); // toggleUnit fonksiyonunun çağrılmasını önleyin
                    addItem(unit.type_code, unit.id);
                  }}
                >
                  Yeni Ekle
                </button>
              </div>
              {selectedUnitTypeId === unit.id &&
                unit.units &&
                unit.units.length > 0 && (
                  <div className="ml-4 mb-2">
                    {unit.units?.map((item) => (
                      <div
                        key={item.id}
                        className="m-2 flex items-center justify-between rounded border p-2"
                      >
                        <div>
                          <div>{item.name}</div>
                          <div>Çalışan Sayısı: {item.employeeCount}</div>
                        </div>
                        <div>
                          <button
                            className="rounded bg-green-500 py-1 px-2 text-white hover:bg-green-700"
                            onClick={() => editItem(unit.id, item.id)}
                          >
                            Düzenle
                          </button>
                          <button
                            className="ml-2 rounded bg-red-500 py-1 px-2 text-white hover:bg-red-700"
                            onClick={() => deleteItem(unit.id, item.id)}
                          >
                            Sil
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
            </div>
          ))
        )}
      </div>
      <Modal
        isOpen={isAddUnitModalOpen}
        onRequestClose={closeAddUnitModal}
        contentLabel={`Yeni ${selectedUnitTypeName} Ekle`}
        appElement={document.getElementById("root")}
        style={modalStyle}
      >
        <h2 className="mb-4 border-b-2 pb-2 text-xl">
          Yeni {selectedUnitTypeName} Ekle
        </h2>
        {selectedUnitTypeId && (
          <UnitForm
            key={selectedUnitTypeId}
            onSubmit={handleAddUnit}
            onCancel={closeAddUnitModal}
            unitType={selectedUnitType}
            unitTypeId={selectedUnitTypeId}
            initialValues={editingUnitDetails}
          />
        )}
      </Modal>
      <Modal
        isOpen={isEditUnitModalOpen}
        onRequestClose={closeEditUnitModal}
        contentLabel="Birim Düzenle"
        appElement={document.getElementById("root")}
        style={modalStyle}
      >
        <h2 className="mb-4 border-b-2 pb-2 text-xl">Birim Düzenle</h2>
        {editingUnitDetails && (
          <UnitForm
            onSubmit={(values) => {
              // Düzenleme işlemini handle eden fonksiyon
              handleEditUnit(values);
              closeEditUnitModal();
            }}
            onCancel={closeEditUnitModal}
            unitType={selectedUnitType}
            unitTypeId={selectedUnitTypeId}
            initialValues={editingUnitDetails} // Düzenlenen birim için initial değerler
          />
        )}
      </Modal>
      <Modal
        isOpen={isAddUnitTypeModalOpen}
        onRequestClose={closeAddUnitTypeModal}
        contentLabel={`Yeni Birim Tipi Ekle`}
        appElement={document.getElementById("root")}
        style={modalStyle}
      >
        <h2 className="mb-4 border-b-2 pb-2 text-xl">Birim Tipi Ekle</h2>
        <Formik
          initialValues={{ name: "", type_code: currentTypeCode }}
          enableReinitialize={true}
          validationSchema={addUnitTypeFormValidationSchema}
          onSubmit={unitTypehandleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label
                  className="mb-2 block text-sm font-bold text-gray-700"
                  htmlFor="name"
                >
                  Birim Adı
                </label>
                <InputField
                  id="name"
                  name="name"
                  type="text"
                  placeholder="Birim Adı"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  error={errors.name}
                  touched={touched.name}
                />
              </div>
              <div className="flex justify-end space-x-2">
                <button
                  type="button"
                  onClick={closeAddUnitTypeModal}
                  className="rounded-md bg-gray-300 px-4 py-2 text-sm text-gray-700 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                >
                  İptal
                </button>
                <button
                  disabled={isSubmitting}
                  type="submit"
                  className="rounded-md bg-blue-600 px-4 py-2 text-sm text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  {isSubmitting ? "Kaydediliyor..." : "Kaydet"}
                </button>
              </div>
            </form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default CompanyInfo;
