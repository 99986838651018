import React, { useState } from "react";
import Modal from "react-modal";
import { Formik, Form, ErrorMessage } from "formik";

import * as Yup from "yup";
import InputField from "components/fields/InputField";
import toast from "react-hot-toast";
import { FaPlus, FaTrash } from "react-icons/fa";
import { ENDPOINTS } from "utils/constants";
import { get } from "utils/api";
import ReceiptAddModal from "./ReceiptAddModal";
import { parseTurkishNumber } from "utils/helpers";
import { post } from "utils/api";
import { formatISODate } from "utils/helpers";
import Switch from "components/switch";
import useCustomModalStyles from "utils/useCustomModalStyles";

const CurrencyTotals = ({ receipts }) => {
  // Para birimlerine göre gruplanmış toplamları hesapla
  const totalsByCurrency = receipts.reduce((acc, receipt) => {
    const amount = parseTurkishNumber(receipt.amount || "0");
    const currencyName = receipt.currencyName;

    if (!acc[currencyName]) {
      acc[currencyName] = 0; // Eğer bu para birimi için bir toplam yoksa, başlat
    }

    acc[currencyName] += amount; // Mevcut toplama bu fişin tutarını ekle
    return acc;
  }, {});

  // Gruplanmış toplamları render et
  return (
    <div>
      {Object.entries(totalsByCurrency).map(([currencyName, total]) => (
        <div key={currencyName} className="mt-4 rounded bg-green-100 p-2">
          <span className="text-sm font-semibold">Toplam: </span>
          {total.toFixed(2).replace(".", ",")} {currencyName}
        </div>
      ))}
    </div>
  );
};

const ExpenseAddModal = ({
  isOpen,
  onClose,
  onRefresh,
  userId,
  displayAdminButtons = false,
}) => {
  const modalStyle = useCustomModalStyles();

  const [isReceiptModalOpen, setIsReceiptModalOpen] = useState(false);
  const [files, setFiles] = useState([]);

  const initialValues = {
    user: userId,
    description: "",
    files: files,
    is_paid: false,
    is_approved: false,
    type: "expenseAdd",
  };

  const validationSchema = Yup.object().shape({
    description: Yup.string().required("Genel açıklama zorunludur"),
    files: Yup.array()
  });

  const addReceipt = (file) => {
    setFiles([...files, file]);
  };
  const removeReceipt = (indexToRemove) => {
    setFiles(files.filter((_, index) => index !== indexToRemove));
  };
  const handleSubmit = async (values) => {
    var formData = new FormData();

    // JSON verilerini FormData'ya ekleyin
    formData.append("description", values.description);
    formData.append("is_paid", values.is_paid);
    formData.append("is_approved", values.is_approved);
    formData.append("type", values.type);
    formData.append("user", values.user);
    if(files.length === 0) {
      toast.error("En az bir fiş eklemelisiniz");
      return;
    }
    // 'files' dizisindeki her bir öğeyi işleyin
    files.forEach((item, index) => {
      // Dosyalar hariç, diğer tüm alanları FormData'ya ekleyin
      formData.append(
        `files[${index}][amount]`,
        item.amount ? item.amount.replace(/\./g, "").replace(/,/g, ".") : ""
      );
      formData.append(
        `files[${index}][date]`,
        item.date ? formatISODate(item.date) : ""
      );
      formData.append(
        `files[${index}][expenses_category]`,
        item.expenses_category ? item.expenses_category.id : ""
      );
      formData.append(
        `files[${index}][tax_rate]`,
        item.tax_rate ? item.tax_rate : 0
      );
      formData.append(
        `files[${index}][description]`,
        item.description ? item.description : ""
      );
      formData.append(
        `files[${index}][currency]`,
        item.currency ? item.currency : ""
      );

      // Dosyayı FormData'ya ekleyin
      if (item.file && item.file instanceof File) {
        formData.append(`files[${index}][file]`, item.file, item.file.name);
      }
    });
    formData.append("amount", files.reduce((acc, item) => acc + parseTurkishNumber(item.amount), 0));
    formData.append("currency", files[0].currency);
    formData.append("date", files[0].date);
    const response = await post(ENDPOINTS.EXPENSES, formData);
    if (response.status === 201) {
      toast.success("Harcama kaydı başarıyla eklendi");
      onClose();
      onRefresh();
    } else {
      toast.error("Harcama kaydı eklenirken bir hata oluştu");
    }
    // onClose();
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        style={modalStyle}
        shouldCloseOnOverlayClick={false}
      >
        <div className="mx-auto max-w-4xl overflow-hidden rounded-lg bg-white p-6 shadow-lg dark:bg-navy-700 dark:text-white">
          <button
            onClick={onClose}
            className="absolute top-0 right-0 m-4 text-4xl font-semibold"
          >
            ×
          </button>
          <h2 className="mb-4 text-center text-2xl font-semibold text-gray-800 dark:text-white">
            Harcama Ekle
          </h2>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              setFieldValue,
              handleBlur,
              handleChange,
              isSubmitting,
            }) => (
              <Form className="space-y-4">
                {/* Fişlerin Listesi */}
                {files.length > 0 && (
                  <div className="space-y-2">
                    {files.map((receipt, index) => (
                      <div
                        key={index}
                        className="flex items-center justify-between rounded bg-gray-100 p-2"
                      >
                        <span>
                          {receipt.expenses_category?.name || "Kategori Yok"} -{" "}
                          {receipt.amount} {receipt.currencyName}
                        </span>
                        <button
                          onClick={() => removeReceipt(index)} // Bu fonksiyon ilgili fişi kaldıracak
                          className="text-red-500 hover:text-red-700"
                        >
                          <FaTrash />
                        </button>
                      </div>
                    ))}
                  </div>
                )}

                <button
                  type="button"
                  onClick={() => setIsReceiptModalOpen(true)}
                  className="flex w-full items-center justify-center rounded-md bg-navy-500 px-4 py-2 text-white hover:bg-navy-600"
                >
                  <FaPlus className="mr-2" />
                  Fiş Ekle
                </button>
                <ErrorMessage name="files" component="div" className="text-red-500" />
                {/* Toplam Tutar */}
                <CurrencyTotals receipts={files} />

                <InputField
                  name="description"
                  label="Genel Açıklama"
                  type="text"
                  placeholder="Genel açıklama giriniz..."
                />
               
                {displayAdminButtons && (
                  <>
                    <div className="mb-4 flex w-full">
                      <Switch
                        name="is_paid"
                        label="Ödendi"
                        checked={values.is_paid}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    <div className="mb-4 flex w-full">
                      <Switch
                        name="is_approved"
                        label="Onay sürecini atla ve onayla"
                        checked={values.is_approved}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </>
                )}

                <div className="flex justify-end space-x-2">
                  <button
                    type="button"
                    onClick={() => onClose()}
                    className="rounded-md bg-gray-300 px-4 py-2 text-sm text-gray-700 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                  >
                    İptal
                  </button>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="rounded-md bg-blue-600 px-4 py-2 text-sm text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                  >
                    Kaydet
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
      <ReceiptAddModal
        isOpen={isReceiptModalOpen}
        onClose={() => setIsReceiptModalOpen(false)}
        onSave={addReceipt}
      />
    </>
  );
};

export default ExpenseAddModal;
