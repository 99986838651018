import { Navigate } from "react-router-dom";
import { useLocation } from "react-router-dom/dist";
import { useAppSelector } from "store";

const PrivateRoute = ({ children }) => {
  const location = useLocation();

  const { accessToken, currentUser } = useAppSelector((state) => state.auth);
  if (!currentUser || !accessToken)
    return (
      <Navigate
        to="/auth/giris-yap"
        replace={true}
        state={{ returnUrl: location.pathname + location.search }}
      />
    );

  return children;
};

export default PrivateRoute;
