import InputField from "components/fields/InputField";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { FaArrowLeft, FaEllipsisV, FaPlus } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import Modal from "react-modal";

import { get } from "utils/api";
import { ENDPOINTS } from "utils/constants";
import { post } from "utils/api";
import toast from "react-hot-toast";
import Dropdown from "components/dropdown";
import { del } from "utils/api";
import { put } from "utils/api";
import { patch } from "utils/api";
import { getById } from "utils/api";
import FormikCheckbox from "components/checkbox/FormikCheckbox";
import calculateDaysDifference from "utils/calculateDaysDifference";
import useCustomModalStyles from "utils/useCustomModalStyles";
const PublicHolidaysDetail = () => {
  const modalStyle = useCustomModalStyles();
  const [leaveType, setLeaveType] = useState("Tam"); // Gün seçimini tutacak state
  const today = new Date().toISOString().split("T")[0]; // Bugünün tarihini YYYY-MM-DD formatında alır

  const [isEdit, setIsEdit] = useState(false);
  const [editingItem, setEditingItem] = useState({ id: null, name: "" });
  const [name, setName] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [datas, setDatas] = useState([]);
  const { id } = useParams(); // `id` URL parametresini al

  const handleOpenModal = (isEditing = false, itemDetails = null) => {
    setIsEdit(isEditing);
    if (itemDetails) {
      setEditingItem(itemDetails);
    } else {
      setEditingItem({
        id: null,
        name: "",
        holiday_category: "",
        start_date: today,
        end_date: today,
        leave_day_count: 0,
        is_leave_calculation: false,
        is_repeat: false,
      });
    }
    setIsModalOpen(true);
  };
  const handleCloseModal = () => setIsModalOpen(false);

  const editHandle = (name, id) => {
    handleOpenModal(true, { id, name });
    const itemToEdit = datas.find((item) => item.id === id);

    if (itemToEdit) {
      // Çalışma günü detaylarını düzenleme formunda kullanılmak üzere hazırlama
      const itemDetails = {
        id: itemToEdit.id,
        name: itemToEdit.name,
        holiday_category: itemToEdit.holiday_category,
        start_date: itemToEdit.start_date,
        end_date: itemToEdit.end_date,
        leave_day_count: itemToEdit.leave_day_count,
        is_leave_calculation: itemToEdit.is_leave_calculation,
        is_repeat: itemToEdit.is_repeat,
      };

      // Düzenleme modu ve ilgili detaylar ile modalı açma
      handleOpenModal(true, itemDetails);
    } else {
      toast.error("Düzenlemek istediğiniz veri bulunamadı.");
    }
  };

  const deleteHandle = async (title, id) => {
    const result = window.confirm(`${title} silmek istediğinize emin misiniz?`);
    if (result) {
      const response = await del(ENDPOINTS.HOLIDAYS, id);
      if (response.status === 204) {
        getDatas();
        toast.success("Resmi tatil başarıyla silindi");
      } else {
        toast.error("Bir hata oluştu");
      }
    }
  };

  const editSubmit = async (values) => {
    switch (leaveType) {
      case "Tam":
        values.leave_day_count = 1;
        break;
      case "Yarım":
        values.leave_day_count = 0.5;
        break;
      case "Birden Fazla":
        values.leave_day_count = calculateDaysDifference(
          values.start_date,
          values.end_date
        );

        break;
      default:
        values.leave_day_count = -1;
    }
    var formData = new FormData();
    formData.append("name", values.name);
    formData.append("holiday_category", id);
    formData.append("start_date", values.start_date);
    formData.append("end_date", values.end_date);
    formData.append("leave_day_count", values.leave_day_count);
    formData.append("is_leave_calculation", values.is_leave_calculation);
    formData.append("is_repeat", values.is_repeat);
    const response = await put(ENDPOINTS.HOLIDAYS + values.id + "/", formData);
    if (response.status === 200) {
      getDatas();
      handleCloseModal();
      toast.success("Resmi tatil başarıyla düzenlendi");
    } else {
      toast.error("Bir hata oluştu");
    }
  };
  const getDatas = async () => {
    const holidayCategory = await getById(id, ENDPOINTS.HOLIDAY_CATEGORY);
    setName(holidayCategory.name);
    const response = await get(ENDPOINTS.HOLIDAYS + "?holiday_category=" + id);
    setDatas(response.results);
  };
  useEffect(() => {
    getDatas();
  }, []);

  const handleSubmit = async (values) => {
    switch (leaveType) {
      case "Tam":
        values.leave_day_count = 1;
        break;
      case "Yarım":
        values.leave_day_count = 0.5;
        break;
      case "Birden Fazla":
        values.leave_day_count = calculateDaysDifference(
          values.start_date,
          values.end_date
        );
        break;
      default:
        values.leave_day_count = -1;
    }
    var formData = new FormData();
    formData.append("name", values.name);
    formData.append("holiday_category", id);
    formData.append("start_date", values.start_date);
    formData.append("end_date", values.end_date);
    formData.append("leave_day_count", values.leave_day_count);
    formData.append("is_leave_calculation", values.is_leave_calculation);
    formData.append("is_repeat", values.is_repeat);
    const response = await post(ENDPOINTS.HOLIDAYS, formData);
    if (response.status === 201) {
      getDatas();
      handleCloseModal();
      toast.success("Resmi tatil başarıyla eklendi");
    } else {
      toast.error("Bir hata oluştu");
    }
  };
  const dropdownButton = <FaEllipsisV className="cursor-pointer" />;

  return (
    <div className="bg-white dark:bg-navy-800 dark:text-white">
      <div className="flex items-center justify-between bg-white p-4 shadow dark:bg-navy-800 dark:text-white">
        <div className="flex items-center">
          <Link to={"/app/ayarlar/tatiller"}>
            {" "}
            <FaArrowLeft className="mr-2 cursor-pointer text-xl" />
          </Link>
          <span className="text-lg font-semibold">Ayarlar / {name}</span>
        </div>
        <button
          onClick={() => handleOpenModal()}
          className="flex items-center rounded bg-blue-500 py-2 px-4 text-white hover:bg-blue-700"
        >
          <FaPlus className="col-span-1" />
          <span className="col-span-1 ml-1">Yeni Ekle</span>
        </button>
      </div>
      <div className="p-1 pt-8">
        <div className="rounded-lg shadow">
          <table className="w-full text-left text-sm text-gray-700 ">
            <thead className="border-b-2 border-gray-200 bg-gray-50 dark:bg-navy-800 dark:text-white">
              <tr>
                <th className="px-6 py-3">Adı</th>
                <th className="px-6 py-3">Başlangıç Tarihi</th>
                <th className="px-6 py-3">Bitiş Tarihi</th>
                <th className="px-6 py-3">Süre</th>
                <th className="px-6 py-3 text-right">İşlemler</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white dark:bg-navy-800 dark:text-white">
              {/* Örnek satır */}
              {datas.map((data) => (
                <tr >
                  <td className="whitespace-nowrap px-6 py-4 text-gray-900 dark:bg-navy-800 dark:text-white">
                    {data.name}
                  </td>
                  <td className="whitespace-nowrap px-6 py-4 text-gray-900 dark:bg-navy-800 dark:text-white">
                    {data.start_date}
                  </td>
                  <td className="whitespace-nowrap px-6 py-4 text-gray-900 dark:bg-navy-800 dark:text-white">
                    {data.end_date}
                  </td>
                  <td className="whitespace-nowrap px-6 py-4 text-gray-900 dark:bg-navy-800 dark:text-white">
                    {data.leave_day_count}
                  </td>
                  <td className="whitespace-nowrap px-6 py-4 text-right text-sm font-medium">
                    <div className="flex justify-end items-center">
                      <Dropdown button={dropdownButton} classNames="right-0">
                        <div className="rounded bg-white p-2 shadow-lg dark:bg-navy-800 dark:text-white">
                          <ul className="text-sm">
                            <li
                              onClick={() => editHandle(data.name, data.id)}
                              className="cursor-pointer p-2 hover:bg-gray-100 dark:bg-navy-800 dark:text-white"
                            >
                              Düzenle
                            </li>
                            <li
                              onClick={() => deleteHandle(data.name, data.id)}
                              className="cursor-pointer p-2 hover:bg-gray-100 dark:bg-navy-800 dark:text-white"
                            >
                              Sil
                            </li>
                          </ul>
                        </div>
                      </Dropdown>
                    </div>
                  </td>
                </tr>
              ))}

              {/* Diğer satırlar benzer şekilde eklenir */}
            </tbody>
          </table>
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        style={modalStyle}
        contentLabel={isEdit ? "Süreci Düzenle" : "Yeni Süreç Ekle"}
        ariaHideApp={false}
      >
        <Formik
          initialValues={{
            id: editingItem.id,
            name: editingItem.name,
            holiday_category: id,
            start_date: editingItem.start_date || today,
            end_date: editingItem.end_date || today,
            leave_day_count: editingItem.leave_day_count,
            is_leave_calculation: editingItem.is_leave_calculation,
            is_repeat: editingItem.is_repeat,
            leaveDayType: "Tam",
          }}
          onSubmit={(values, { resetForm }) => {
            if (isEdit) {
              editSubmit(values);
            } else {
              handleSubmit(values);
            }
            resetForm();
            setIsModalOpen(false);
            setIsEdit(false);
          }}
        >
          {({
            isSubmitting,
            handleChange,
            handleBlur,
            values,
            setFieldValue,
          }) => (
            <Form className="space-y-4">
              <div className="-mx-2 flex flex-wrap">
                <div className="mb-4 w-1/2 px-2">
                  <InputField
                    label="Resmi tatil adı"
                    id="name"
                    name="name"
                    type="text"
                    placeholder="Resmi tatil adı"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                  />
                </div>
                <div className="mb-4 w-1/2 px-2">
                  {["Tam", "Yarım", "Birden Fazla"].map((type) => (
                    <button
                      key={type}
                      type="button"
                      className={`rounded-md px-4 py-2 text-sm transition-colors duration-200 ${
                        leaveType === type
                          ? "bg-blue-500 text-white"
                          : "bg-gray-200 text-gray-800 hover:bg-gray-300"
                      }`}
                      onClick={() => {
                        setLeaveType(type);
                        setFieldValue("leaveType", type);
                        if (type !== "Birden Fazla") {
                          setFieldValue("end_date", today);
                          setFieldValue("start_date", today);
                        }
                      }}
                    >
                      {type}
                    </button>
                  ))}
                </div>
              </div>
              <div className="mt-4">
                {/* Başlangıç Tarihi */}
                <Field
                  type="date"
                  name="start_date"
                  className="form-control col-6"
                  onChange={(e) => {
                    setFieldValue("start_date", e.target.value);
                    if (leaveType !== "Birden Fazla") {
                      setFieldValue("end_date", e.target.value);
                    }
                  }}
                />

                {/* Bitiş Tarihi */}
                <Field
                  type="date"
                  name="end_date"
                  className={`form-control col-6 ${
                    leaveType !== "Birden Fazla" ? "disabled" : ""
                  }`}
                  disabled={leaveType !== "Birden Fazla"}
                  onChange={(e) => setFieldValue("end_date", e.target.value)}
                />
              </div>
              <div className="mt-4">
                <FormikCheckbox
                  name="is_leave_calculation"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label="İzin talep için kullanılan gün sayısı hesaplamasına dahil edilsin"
                  color="blue"
                />
              </div>
              <div className="mt-4">
                <FormikCheckbox
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="is_repeat"
                  label="Her yıl tekrarla"
                  color="blue"
                />
              </div>
              <div className="flex justify-end space-x-2">
                <button
                  type="button"
                  onClick={() => setIsModalOpen(false)}
                  className="rounded-md bg-gray-300 px-4 py-2 text-sm text-gray-700 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                >
                  İptal
                </button>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="rounded-md bg-blue-600 px-4 py-2 text-sm text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  {isEdit ? "Kaydet" : "Ekle"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default PublicHolidaysDetail;
