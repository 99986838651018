import React from "react";
import Modal from "react-modal";
import { Formik, Form, Field, ErrorMessage } from "formik";

import { post } from "utils/api"; // API istekleri için
import { ENDPOINTS } from "utils/constants";
import * as Yup from "yup";
import InputField from "components/fields/InputField";
import Switch from "components/switch";
import toast from "react-hot-toast";
import { patch } from "utils/api";
import FormikDatePicker from "components/datepicker";
import { formatISODate } from "utils/helpers";
import useCustomModalStyles from "utils/useCustomModalStyles";

const PersonalInfoFamilyModal = ({
  isOpen,
  onClose,
  onRefresh,
  userId,
  currentData,
}) => {
  const modalStyle = useCustomModalStyles();
  const handleSubmit = async (values) => {
    var formData = new FormData();
    formData.append("marital_status", values.marital_status);
    formData.append("number_of_children", values.number_of_children);
    const response = await patch(ENDPOINTS.USERS + userId + "/", formData);
    if (response.status === 200) {
      onRefresh();
      onClose();
      toast.success("Başarılı bir şekilde değiştirildi");
    } else {
      toast.error("Bir hata oluştu");
    }
  };
  const initialValues = {
    marital_status: currentData?.marital_status || "",
    number_of_children: currentData?.number_of_children || "",
  };

  const validationSchema = Yup.object().shape({
    // birth_date: Yup.string().required("Bu alan zorunludur"),
  });
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={modalStyle}>
      <div className="mx-auto  rounded-lg bg-white p-6 shadow-lg">
        <button
          onClick={onClose}
          className="absolute top-0 right-0 m-2 text-2xl font-semibold"
        >
          ×
        </button>
        <h2 className="mb-4 text-center text-xl font-semibold text-gray-800">
          Aile
        </h2>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({
            values,
            setFieldValue,
            isSubmitting,
            handleBlur,
            handleChange,
            errors,
            touched,
          }) => (
            <Form className="space-y-4">
              <div className="flex flex-col md:flex-row md:items-center md:space-x-4">
                <div className="mb-4 flex-1">
                  <label
                    htmlFor="marital_status"
                    className="ml-3 text-sm font-bold text-navy-700 dark:text-white"
                  >
                    Medeni Hal
                  </label>
                  <select
                    name="marital_status"
                    value={values.marital_status}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="mt-1 block h-12 w-full rounded-md border border-gray-300 p-2 shadow-sm"
                  >
                    <option value=""></option>
                    <option value="Bekar">Bekar</option>
                    <option value="Evli">Evli</option>
                    <option value="Boşanmış">Boşanmış</option>
                    <option value="Belirtilmemiş">Belirtilmemiş</option>
                  </select>
                </div>
              </div>
              <div className="flex flex-col md:flex-row md:items-center md:space-x-4">
                <div className="mb-4 flex-1">
                  <label
                    htmlFor="number_of_children"
                    className="ml-3 text-sm font-bold text-navy-700 dark:text-white"
                  >
                    Çocuk Sayısı
                  </label>
                  <select
                    name="number_of_children"
                    value={values.number_of_children}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="mt-1 block h-12 w-full rounded-md border border-gray-300 p-2 shadow-sm"
                  >
                    {[...Array(19).keys()].map((number) => (
                      <option key={number + 1} value={number + 1}>
                        {number + 1}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <button
                type="submit"
                disabled={isSubmitting}
                className="border-transparent mt-2 flex w-full justify-center rounded-md border bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Kaydet
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default PersonalInfoFamilyModal;
