import InputField from "components/fields/InputField";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { post } from "utils/api";
import { ENDPOINTS } from "utils/constants";
import ReCAPTCHA from "react-google-recaptcha";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [captchaValue, setCaptchaValue] = useState(null);
  const recaptchaRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const onCaptchaChange = (value) => {
    setCaptchaValue(value);
  };
  const resetCaptcha = () => {
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
  };

  const handleSubmit = async () => {
    if (!captchaValue) {
      toast.error("Lütfen reCAPTCHA'yı doğrulayın.");
      return;
    }

    if (email) {
      setIsLoading(true);
      var formData = new FormData();
      formData.append("email", email);
      formData.append("captcha", captchaValue);

      try {
        const response = await post(ENDPOINTS.FORGOT_PASSWORD, formData);
        if (response?.status === 200) {
          toast.success(
            "Şifre sıfırlama bağlantısı e-posta adresinize gönderildi."
          );
          setIsLoading(false);
          navigate("/auth/giris-yap");
        } else {
          toast.error("Şifre sıfırlama bağlantısı gönderilemedi.");
        }
      } catch (error) {
        setIsLoading(false);
        if (error?.email) {
          toast.error(error.email[0]);
          resetCaptcha();
        }
      }
      setIsLoading(false);
    } else {
      toast.error("Lütfen geçerli bir e-posta adresi girin.");
    }
  };

  return (
    <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Şifremi Unuttum
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Şifre sıfırlama bağlantısını almak için e-posta adresinizi girin
        </p>

        <InputField
          variant="auth"
          extra="mb-3"
          label="E-posta Adresi*"
          placeholder="E-posta adresinizi girin"
          id="email"
          type="email"
          value={email}
          onChange={handleEmailChange}
          required="required"
        />
        <ReCAPTCHA
          sitekey="6LczMMYpAAAAAD7rzAe0I9TPeV7gMgpWYj389SzT"
          onChange={onCaptchaChange}
          ref={recaptchaRef}
        />
        <button
          className={`mt-2 w-full rounded-xl py-[12px] text-base font-medium text-white transition duration-200 ${
            isLoading
              ? "cursor-not-allowed bg-gray-400"
              : "bg-brand-500 hover:bg-brand-600 active:bg-brand-700"
          } dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
          onClick={handleSubmit}
          disabled={isLoading}
          type="button"
        >
          {isLoading ? "Gönderiliyor..." : "Gönder"}
        </button>
        <div className="mt-4">
          <Link
            to="/auth/giris-yap"
            className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
          >
            Giriş Yap
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
