import { format } from "date-fns";
import { FaCheck, FaTimes } from "react-icons/fa";
import formatDate from "utils/formatDate";
import { formatDateWithTime } from "utils/formatDate";
import ExpensePaidStatusToggle from "views/app/employees/components/ExpensePaidStatusToggle";

export const expensesColumsData = (refreshData) => [
  {
    Header: "id",
    accessor: "id",
    show: false,
  },
  {
    Header: "Ad Soyad",
    accessor: (row) => `${row.user.first_name} ${row.user.last_name}`,
    show: true,
  },
  {
    Header: "Birim",
    accessor: (row) => row.user.units?.map((unit) => unit.name).join(", "),
    show: true,
  },
  {
    Header: "Kimlik Numarası",
    accessor: "user.identification_number",
    show: true,
  },
  {
    Header: "Durum",
    accessor: "status",
    show: true,
  },
  {
    Header: "Toplam Tutar",
    accessor: (row) => {
      if (row.amount && row.currency)
        return `${row.amount} ${row.currency?.name}`;
      else return "0";
    },
    show: true,
  },
  {
    Header: "Açıklama",
    accessor: "description",
    show: true,
  },
  {
    Header: "Oluşturulma Tarihi",
    accessor: (row) => formatDateWithTime(row.create_time),
    show: true,
  },
  {
    Header: "Ödendi",
    Cell: ({ row }) => (
      <ExpensePaidStatusToggle
        rowId={row.original.id}
        isPaid={row.original.is_paid}
        refreshData={refreshData}
      />
    ),
  },
];

export const expensesFilesColumsData = [
  {
    Header: "id",
    accessor: "id",
    show: false,
  },
  {
    Header: "Ad Soyad",
    accessor: (row) => `${row.user?.first_name} ${row.user?.last_name}`,
    show: true,
  },
  {
    Header: "Birim",
    accessor: (row) => row.user?.units?.map((unit) => unit.name).join(", "),
    show: true,
  },
  {
    Header: "Kimlik Numarası",
    accessor: "user?.identification_number",
    show: true,
  },
  {
    Header: "Kategori",
    accessor: "expenses_category.name",
    show: true,
  },
  {
    Header: "Durum",
    accessor: "status",
    show: true,
  },
  {
    Header: "Toplam Tutar",
    accessor: (row) => {
      if (row.amount && row.currency)
        return `${row.amount} ${row.currency?.name}`;
      else return "0";
    },
    show: true,
  },
  {
    Header: "Vergi Oranı",
    accessor: (row) => (row.tax_rate ? `${row.tax_rate}%` : "0%"),
    show: true,
  },
  {
    Header: "Açıklama",
    accessor: "description",
    show: true,
  },
  {
    Header: "Fiş Tarihi",
    accessor: (row) => formatDate(row.date),
    show: true,
  },
  {
    Header: "Ödendi",
    accessor: (row) => (row.is_paid ? "Evet" : "Hayır"),
    show: true,
  },
];
export const expensesCategoryColumsData = [
  {
    Header: "id",
    accessor: "id",
    show: false,
  },
  {
    Header: "Adı",
    accessor: "name",
    show: true,
  },
  {
    Header: "Açıklama",
    accessor: "description",
    show: true,
  },
  {
    Header: "Durum",
    accessor: (row) => (row.is_active ? "Aktif" : "Pasif"),
    show: true,
  },
  {
    Header: "",
    id: "actions",
    accessor: "actions",
  },
];
