import React from "react";
import Modal from "react-modal";
import { Formik, Form, Field } from "formik";

import { post } from "utils/api"; // API istekleri için
import { ENDPOINTS } from "utils/constants";
import * as Yup from "yup";
import InputField from "components/fields/InputField";
import Switch from "components/switch";
import toast from "react-hot-toast";
import { patch } from "utils/api";
import AsyncSelect from "react-select/async";
import { get } from "utils/api";
import useCustomModalStyles from "utils/useCustomModalStyles";
const ProfileNameModal = ({
  isOpen,
  onClose,
  onRefresh,
  userId,
  currentData,
}) => {
  const modalStyle = useCustomModalStyles();
  const handleSubmit = async (values) => {
    var formData = new FormData();
    formData.append("first_name", values.first_name);
    formData.append("last_name", values.last_name);
    const response = await patch(ENDPOINTS.USERS + userId + "/", formData);
    if (response.status === 200) {
      onRefresh();
      onClose();
      toast.success("Başarılı bir şekilde değiştirildi");
    } else {
      toast.error("Bir hata oluştu");
    }
  };
  const initialValues = {
    first_name: currentData?.first_name || "",
    last_name: currentData?.last_name || "",
  };
  const validationSchema = Yup.object().shape({
    
  });
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={modalStyle}>
      <div className="mx-auto  rounded-lg bg-white p-6 shadow-lg">
        <button
          onClick={onClose}
          className="absolute top-0 right-0 m-2 text-2xl font-semibold"
        >
          ×
        </button>
        <h2 className="mb-4 text-center text-xl font-semibold text-gray-800">
          Düzenle
        </h2>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({
            values,
            setFieldValue,
            isSubmitting,
            handleBlur,
            handleChange,
            errors,
            touched,
          }) => (
            <Form className="space-y-4">
              <div className="flex flex-col md:flex-row md:items-center md:space-x-4">
                {/* <div className="mb-4 flex-1">
                  <InputField name="email" label="Şirket eposta adresi" type="text"/>
                </div> */}
                <div className="mb-4 flex-1">
                  <InputField
                    name="first_name"
                    label="Ad"
                    type="text"
                    value={values.first_name}
                  />
                </div>
                <div className="mb-4 flex-1">
                  <InputField
                    name="last_name"
                    label="Soyad"
                    type="text"
                    value={values.last_name}
                  />
                </div>
              </div>
          
              <button
                type="submit"
                disabled={isSubmitting}
                className="border-transparent mt-2 flex w-full justify-center rounded-md border bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Kaydet
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default ProfileNameModal;
