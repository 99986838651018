import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useAppSelector } from "store";
import { get } from "utils/api";
import { ENDPOINTS } from "utils/constants";
import DataTable from "components/datatable";
import { post } from "utils/api";
import { patch } from "utils/api";
import toast from "react-hot-toast";
import Dropdown from "components/dropdown";
import { FaEllipsisV } from "react-icons/fa";
import { del } from "utils/api";
import { companyFilesColumnsData } from "./variables/columnsData.";
import { Tooltip } from "react-tooltip";
import FolderView from "./components/FolderView";
import Modal from "react-modal";
import useCustomModalStyles from "utils/useCustomModalStyles";
const Files = () => {
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const currentUserRoleName = useAppSelector(
    (state) => state.auth.currentUser?.role?.name === "CompanyAdmin"
  );
  const modalStyle = useCustomModalStyles();
  const [datas, setDatas] = useState([]);
  const [folders, setFolders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const getFolders = async () => {
    const response = await get(ENDPOINTS.COMPANY_FOLDER + "?company=" + currentUser?.company?.id);
    setFolders(response.results);
  };
 
  const addFolder = async () => {
    const folderName = document.getElementById("folderName").value;
    const response = await post(ENDPOINTS.COMPANY_FOLDER, {
      name: folderName,
      company: currentUser?.company?.id,
    });
    if (response.status === 201) {
      getFolders();
      setIsModalOpen(false);
      toast.success("Klasör başarıyla eklendi");
    } else {
      toast.error("Bir hata oluştu");
    }
  };
  useEffect(() => {
    getFolders();
  }, []);

  return (
    <div className="text-navy-700 text-md mt-2 grid grid-cols-1 gap-5 rounded-[20px] bg-white bg-clip-border p-3 shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:text-white dark:shadow-none xl:grid-cols-1">
      {currentUserRoleName && (
        <div className="mt-6 flex gap-5 text-sm">
           <button
            onClick={() => setIsModalOpen(true)}
            className="flex flex-col items-center justify-center rounded-lg bg-blue-500 p-3 text-white hover:bg-blue-600"
          >
            Klasör Ekle
          </button>
        </div>
      )}

      <div className="flex flex-col">
      <FolderView initialFolders={folders} onRefresh={getFolders} />
      </div>
      {isModalOpen && (
        <Modal isOpen={isModalOpen} style={modalStyle} onRequestClose={() => setIsModalOpen(false)}>
          <div className="flex flex-col gap-4">
            <label htmlFor="folderName">Klasör Adı</label>
            <input
              type="text"
              id="folderName"
              name="folderName"
              className="border p-2 rounded"
              placeholder="Klasör adını giriniz"
            />
            <button onClick={addFolder} className="bg-blue-500 text-white p-2 rounded">Kaydet</button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Files;
