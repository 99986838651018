
const typeCodePriority = {
    holding: 1,
    company: 2,
    branch: 3,
    department: 4,
    title: 5,
  };
  const sortAndJoinUnits = (units) => {
    // Öncelik değerine göre sırala
    const sortedUnits = units.sort((a, b) => {
      const priorityA = typeCodePriority[a.unit_type.type_code] || 999;
      const priorityB = typeCodePriority[b.unit_type.type_code] || 999;
      return priorityA - priorityB;
    });
  
    // Sadece birim adlarını birleştir
    return sortedUnits.map(unit => unit.name).join(', ');
  };
const countUnitsByType = (units) => {
    return units.reduce((acc, unit) => {
        const type = unit.unit_type.type_code; // unit'in type_code'u
        acc[type] = (acc[type] || 0) + 1; // Her bir type için sayımı bir artır
        return acc;
    }, {});
};
const getUnitNamesByType = (units, typeCode) => {
    // İlgili type_code'a sahip unit'lerin isimlerini filtrele ve birleştir
    const names = units
      .filter(unit => unit.unit_type.type_code === typeCode)
      .map(unit => unit.name)
      .join(', ');
    return names || '-';
  };
  const typeCodeLabels = {
    holding: "Holding",
    company: "Şirket",
    branch: "Şube",
    department: "Departman",
    title: "Ünvan",
  };
export default sortAndJoinUnits;
export { getUnitNamesByType };
export { typeCodeLabels };
export { countUnitsByType };