import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  format,
  addDays,
  isWeekend,
  differenceInCalendarDays,
  setHours,
  setMinutes,
  startOfDay,
  startOfToday,
} from "date-fns";
import { get } from "utils/api";
import { ENDPOINTS } from "utils/constants";
import capitalizeString from "utils/helpers";
import { post } from "utils/api";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import Switch from "components/switch";
import { put } from "utils/api";
import useCustomModalStyles from "utils/useCustomModalStyles";
import Modal from "react-modal";
const NewTimeLog = ({
  isOpen,
  userId,
  onClose,
  onRefresh,
  timeLogId,
  displayAdminButtons = false,
}) => {
  const modalStyle = useCustomModalStyles();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const [workingHours, setWorkingHours] = useState([]); // Çalışma saatleri
  const [loading, setLoading] = useState(false);
  const [isApproved, setIsApproved] = useState(false);

  const [isEditMode, setIsEditMode] = useState(false);
  const [formData, setFormData] = useState(null);
  useEffect(() => {
    if (timeLogId) {
      setIsEditMode(true);
      fetchTimeLogData();
    } else {
      setIsEditMode(false);
      setDefaultValues();
    }
  }, [timeLogId]);
  const fetchTimeLogData = async () => {
    const response = await get(`${ENDPOINTS.TIMELOG}${timeLogId}/`);

    const data = await response;
    setFormData({
      hour: data.hour,
      minute: data.minute,
      startDate: format(new Date(data.start_datetime), "yyyy-MM-dd"),
      startTime: format(new Date(data.start_datetime), "HH:mm"),
      description: data.description,
      is_approved: data.is_approved,
    });
    setIsApproved(data.is_approved);
  };
  const setDefaultValues = () => {
    setFormData({
      hour: 0,
      minute: 0,
      startDate: format(startOfToday(), "yyyy-MM-dd"),
      startTime: "09:00",
      description: "",
      status: "",
      is_approved: isApproved,
    });
  };
  useEffect(() => {
    setWorkingHours(generateWorkingHours());
  }, []);
  const generateWorkingHours = () => {
    const hours = [];
    for (let hour = 0; hour <= 23; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const formattedHour = hour.toString().padStart(2, "0");
        const formattedMinute = minute.toString().padStart(2, "0");
        hours.push(`${formattedHour}:${formattedMinute}`);
      }
    }
    return hours;
  };

  const validationSchema = Yup.object().shape({
    startDate: Yup.date().required("Başlangıç tarihi gereklidir"),
    startTime: Yup.string().required("Başlangıç saati gereklidir"),
    description: Yup.string(),
    hour: Yup.number().required("Saat gereklidir"),
    minute: Yup.number().required("Dakika gereklidir"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    let status = "Onay Bekliyor";
    if (isApproved) {
      status = "Onaylandı";
    }
    const data = {
      start_datetime: `${values.startDate}T${values.startTime}:00`,
      description: values.description,
      hour: values.hour,
      minute: values.minute,
      user: userId,
      company: currentUser?.company?.id,
      is_approved: isApproved,
      status: status,
    };

    let response;
    if (isEditMode) {
      response = await put(`${ENDPOINTS.TIMELOG}${timeLogId}/`, data);
    } else {
      response = await post(ENDPOINTS.TIMELOG, data);
    }

    if (response.status === 201 || response.status === 200) {
      toast.success(
        `Mesai kaydı başarıyla ${isEditMode ? "güncellendi" : "oluşturuldu"}`
      );
      onClose();
      onRefresh();
    } else {
      toast.error(
        `Mesai kaydı ${
          isEditMode ? "güncellenirken" : "oluşturulurken"
        } bir hata oluştu`
      );
    }
    setSubmitting(false);
  };
  const changeApproved = (e) => {
    setIsApproved(e.target.checked);
  };

  if (!formData) return <div>Yükleniyor...</div>;

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={modalStyle}>
    <div className="mx-auto  rounded-lg bg-white p-7 shadow-lg dark:bg-navy-700 dark:text-white">
      <button
        onClick={onClose}
        className="absolute top-0 right-0 m-2 text-2xl font-semibold"
      >
        ×
      </button>
      <h2 className="mb-4 text-center text-xl font-semibold text-gray-800  dark:text-white">
        {isEditMode ? "Mesai Kaydı Düzenle" : "Yeni Mesai Kaydı Oluştur"}
      </h2>
      <Formik
        initialValues={formData}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ values, setFieldValue, isSubmitting }) => (
          <Form className="space-y-4">
            {/* Başlangıç Tarihi ve Saati */}
            <div className="flex justify-between">
              <div className="flex-1">
                <label
                  htmlFor="startDate"
                  className="block text-sm font-medium text-gray-700 dark:bg-navy-700 dark:text-white"
                >
                  Başlangıç Tarihi
                </label>
                <Field
                  type="date"
                  name="startDate"
                  className="dark:bg-navy-700 dark:text-white mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
                <ErrorMessage
                  name="startDate"
                  component="div"
                  className="text-sm text-red-600"
                />
              </div>
              <div className="ml-2 flex-1">
                <label
                  htmlFor="startTime"
                  className="block text-sm font-medium text-gray-700 dark:bg-navy-700 dark:text-white"
                >
                  Başlangıç Saati
                </label>
                <Field
                  as="select"
                  name="startTime"
                  className=" dark:bg-navy-700 dark:text-white mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                >
                  {workingHours.map((hour) => (
                    <option key={hour} value={hour}>
                      {hour}
                    </option>
                  ))}
                </Field>
              </div>
            </div>
            <div className="flex justify-between">
              <div className="ml-2 flex-1">
                <label
                  htmlFor="hour"
                  className="block text-sm font-medium text-gray-700 dark:bg-navy-700 dark:text-white"
                >
                  Saat
                </label>
                <Field
                  type="number"
                  name="hour"
                  className="dark:bg-navy-700 dark:text-white mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
                <ErrorMessage
                  name="hour"
                  component="div"
                  className="text-sm text-red-600"
                />
              </div>
              <div className="ml-2 flex-1">
                <label
                  htmlFor="minute"
                  className="block text-sm font-medium text-gray-700 dark:bg-navy-700 dark:text-white"
                >
                  Dakika
                </label>
                <Field
                  type="number"
                  name="minute"
                  className="dark:bg-navy-700 dark:text-white mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
                <ErrorMessage
                  name="minute"
                  component="div"
                  className="text-sm text-red-600"
                />
              </div>
            </div>
            {/* Açıklama */}
            <div>
              <label
                htmlFor="description"
                className="block text-sm font-medium text-gray-700 dark:bg-navy-700 dark:text-white "
              >
                Açıklama
              </label>
              <Field
                as="textarea"
                name="description"
                className="dark:bg-navy-700 dark:text-white mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
              <ErrorMessage
                name="description"
                component="div"
                className="text-sm text-red-600"
              />
            </div>
            {displayAdminButtons && (
              <>
                <div className="flex items-center">
                  <Switch checked={isApproved} onChange={changeApproved} />
                  <label
                    htmlFor="is_approved"
                    className="ml-2 block text-sm font-medium text-gray-700 "
                  >
                    Onay sürecini atla ve onayla
                  </label>
                </div>
              </>
            )}

            <button
              type="submit"
              disabled={isSubmitting}
              className="dark:border-none border-transparent flex w-full justify-center rounded-md border bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              {isEditMode ? "Güncelle" : "Kaydet"}
            </button>
          </Form>
        )}
      </Formik>
    </div>
    </Modal>
  );
};

export default NewTimeLog;
