import React, { useEffect, useState } from "react";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import tr from "date-fns/locale/tr";
import "react-big-calendar/lib/css/react-big-calendar.css";
import Modal from "react-modal";
import { Formik, Form, Field } from "formik";

import InputField from "components/fields/InputField";
import TextField from "components/fields/TextField";
import FormikDatePicker from "components/datepicker";
import FormikDateTimePicker from "components/datepicker/FormikDateTimePicker";
import { post } from "utils/api";
import { ENDPOINTS } from "utils/constants";
import toast from "react-hot-toast";
import { formatISODate } from "utils/helpers";
import { formatISODateTime } from "utils/helpers";
import { get } from "utils/api";
import { put } from "utils/api";
import { del } from "utils/api";
import { FaPlus, FaTrash } from "react-icons/fa";
import Select from "react-select";
import { useAppSelector } from "store";
import useCustomModalStyles from "utils/useCustomModalStyles";
import { useTheme } from "ThemeContext";

const locales = {
  "tr-TR": tr,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});
const messages = {
  today: "Bugün",
  previous: "Geri",
  next: "İleri",
  month: "Aylık Görünüm",
  week: "Haftalık Görünüm",
  day: "Günlük Görünüm",
  agenda: "Ajanda",
  date: "Tarih",
  time: "Saat",
  event: "Etkinlik",
  allDay: "Tüm gün",
  noEventsInRange: "Bu tarih aralığında etkinlik yok.",
  // İlave edilmesi gereken diğer mesajlar buraya eklenebilir
};
const defaultSelectedTypes = ["birthday", "event", "leave", "holiday"];
const options = [
  { value: "birthday", label: "Doğum Günleri", color: "#f00" },
  { value: "event", label: "Etkinlikler", color: "#00f" },
  { value: "leave", label: "İzinler", color: "#008000" },
  { value: "holiday", label: "Resmi Tatiller", color: "#888" },
];

const defaultSelectedOptions = options; // Varsayılan olarak tüm seçenekler seçili

const BCalendar = () => {
  const { isDarkMode } = useTheme();
  //diğer yerlerle bir tek burası çakışıyordu mecburen buraya internal olarak aldık ileride düzeltilmeli
  const selectStyle = {
    control: (provided) => ({
      ...provided,
      backgroundColor: isDarkMode
        ? "rgb(27 37 75 / var(--tw-bg-opacity))"
        : "white",
      borderColor: isDarkMode ? "gray" : "lightgray",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: isDarkMode
        ? "rgb(27 37 75 / var(--tw-bg-opacity))"
        : "white",
    }),
    option: (provided, state) => ({
      ...provided,
      color: isDarkMode ? "white" : "rgb(27 37 75 / var(--tw-bg-opacity))",
      backgroundColor: state.isFocused
        ? isDarkMode
          ? "rgb(27 37 65 / var(--tw-bg-opacity))"
          : "lightgray"
        : state.isSelected
        ? isDarkMode
          ? "darkblue"
          : "#d6d6d6"
        : isDarkMode
        ? "rgb(27 37 75 / var(--tw-bg-opacity))"
        : "white",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: isDarkMode ? "white" : "rgb(27 37 75 / var(--tw-bg-opacity))",
    }),
    multiValue: (styles, { data }) => ({
      ...styles,
      backgroundColor: data.color,
      // color: isDarkMode ? "white" : "rgb(27 37 75 / var(--tw-bg-opacity))",
      color: "white",
    }),
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: "white",
    }),
  };
  const modalStyle = useCustomModalStyles();
  const [events, setEvents] = useState([{}]);
  const [selectedTypes, setSelectedTypes] = useState(
    defaultSelectedOptions.map((option) => option.value)
  ); // Başlangıçta tüm tip değerlerini seçili hale getir
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const selectedValue = options.filter((option) =>
    selectedTypes.includes(option.value)
  );

  const handleChange = (selectedOptions) => {
    // selectedOptions'dan sadece 'value' değerlerini çıkarıp yeni bir dizi oluştur
    const values = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setSelectedTypes(values);
  };

  const [formInitialValues, setFormInitialValues] = useState({
    id: null,
    name: "",
    description: "",
    start_datetime: new Date(),
    end_datetime: new Date(),
  });

  // Filtreleme işlemi
  const filterEvents = () => {
    return events.filter((event) => selectedTypes.includes(event.type));
  };

  // Kullanıcının seçimini güncelleyen fonksiyon
  const handleTypeChange = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setSelectedTypes(value);
  };

  const fetchData = async () => {
    try {
      const [
        eventsResponse,
        leavesResponse,
        holidaysResponse,
        birthdaysResponse,
      ] = await Promise.all([
        get(ENDPOINTS.EVENTS),
        get(ENDPOINTS.LEAVE),
        get(ENDPOINTS.HOLIDAYS),
        get(ENDPOINTS.USER_BIRTHDAYS),
      ]);

      const convertedEvents = convertApiResponseToEvents(eventsResponse);
      const convertedLeaves = convertApiResponseToLeaves(leavesResponse);
      const convertedHolidays = convertApiResponseToHolidays(holidaysResponse);
      const convertedBirthdays =
        convertApiResponseToBirthdays(birthdaysResponse);

      const combinedEvents = [
        ...convertedEvents,
        ...convertedLeaves,
        ...convertedHolidays,
        ...convertedBirthdays,
      ];
      setEvents(combinedEvents);
    } catch (error) {
      console.error("Data fetching failed:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const convertApiResponseToEvents = (apiResponse) => {
    return apiResponse.results.map((item, index) => {
      const startDate = new Date(item.start_datetime);
      const endDate = new Date(item.end_datetime);

      // 'allDay' durumunu kontrol etmek için başlangıç ve bitiş tarihlerinin aynı gün olup olmadığına bakılır.
      const allDay =
        startDate.toISOString().split("T")[0] ===
        endDate.toISOString().split("T")[0];

      return {
        id: item.id,
        title: item.name || "Etkinlik",
        start: startDate || new Date(),
        end: endDate || new Date(),
        allDay: allDay,
        description: item.description || "Açıklama yok",
        type: "event",
      };
    });
  };
  const convertApiResponseToLeaves = (apiResponse) => {
    return apiResponse.results.map((item, index) => {
      const startDate = new Date(item.start_datetime);
      const endDate = new Date(item.end_datetime);

      // 'allDay' durumunu kontrol etmek için başlangıç ve bitiş tarihlerinin aynı gün olup olmadığına bakılır.
      const allDay =
        startDate.toISOString().split("T")[0] ===
        endDate.toISOString().split("T")[0];

      return {
        id: item.id,
        title:
          item.user?.first_name +
          " " +
          item.user?.last_name +
          " " +
          item.leave_type?.name,
        start: startDate || new Date(),
        end: endDate || new Date(),
        allDay: allDay,
        description: item.description || "Açıklama yok",
        type: "leave",
      };
    });
  };
  const convertApiResponseToHolidays = (apiResponse) => {
    return apiResponse.results.map((item, index) => {
      const startDate = new Date(item.start_date);
      const endDate = new Date(item.end_date);

      // 'allDay' durumunu kontrol etmek için başlangıç ve bitiş tarihlerinin aynı gün olup olmadığına bakılır.
      const allDay =
        startDate.toISOString().split("T")[0] ===
        endDate.toISOString().split("T")[0];

      return {
        id: item.id,
        title: item.name,
        start: startDate || new Date(),
        end: endDate || new Date(),
        allDay: allDay,
        type: "holiday",
      };
    });
  };
  const convertApiResponseToBirthdays = (apiResponse) => {
    return apiResponse.map((item, index) => {
      const startDate = new Date(item.birth_date);
      const endDate = new Date(item.birth_date);

      // 'allDay' durumunu kontrol etmek için başlangıç ve bitiş tarihlerinin aynı gün olup olmadığına bakılır.
      const allDay =
        startDate.toISOString().split("T")[0] ===
        endDate.toISOString().split("T")[0];

      return {
        id: item.id,
        title:
          item.user?.first_name + " " + item.user?.last_name + " doğum günü",
        start: startDate || new Date(),
        end: endDate || new Date(),
        allDay: allDay,
        type: "birthday",
      };
    });
  };

  const eventStyleGetter = (event) => {
    let backgroundColor = "#3174ad"; // Varsayılan renk
    let cursorStyle = "pointer"; // Varsayılan cursor stilini 'pointer' olarak ayarla

    if (event.type === "event") {
      backgroundColor = "#00f"; // Tatil için kırmızı
    } else if (event.type === "leave" || event.type === "holiday") {
      backgroundColor = event.type === "leave" ? "#008000" : "#888"; // İzin için yeşil, tatil için mavi
      cursorStyle = "default"; // Bu etkinlik türleri için cursor stilini 'not-allowed' olarak ayarla
    } else if (event.type === "birthday") {
      backgroundColor = "#f00";
      cursorStyle = "default";
    }

    return {
      style: {
        backgroundColor,
        cursor: cursorStyle, // Cursor stilini döndürülen stile ekle
        fontSize: "12px",
        textTransform: "capitalize",
      },
    };
  };

  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedSlotInfo, setSelectedSlotInfo] = useState(null);

  const addEventItem = () => {
    setSelectedEvent(null);
    setFormInitialValues({
      name: "",
      description: "",
      start_datetime: new Date(),
      end_datetime: new Date(),
      id: null,
    });
    openModal();
  };
  const handleSelectSlot = (slotInfo) => {
    setSelectedEvent(null);
    setFormInitialValues({
      name: "",
      description: "",
      start_datetime: slotInfo.start,
      end_datetime: slotInfo.end,
      id: null,
    });
    openModal();
  };
  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {}

  function closeModal() {
    setIsOpen(false);
    // Modal kapatıldığında formun başlangıç değerlerini resetle
    setFormInitialValues({
      id: null,
      name: "",
      description: "",
      start_datetime: new Date(),
      end_datetime: new Date(),
    });
  }
  const deleteHandle = async (id) => {
    const result = window.confirm(`Silmek istediğinize emin misiniz?`);
    if (result) {
      const response = await del(ENDPOINTS.EVENTS, id);
      if (response.status === 204) {
        fetchData();
        closeModal();
        toast.success("Başarıyla silindi");
      } else {
        toast.error("Bir hata oluştu");
      }
    }
  };
  const handleEventSelect = (event) => {
    if (event.type === "event") {
      setSelectedEvent(event);
      setFormInitialValues({
        id: event.id,
        name: event.title,
        description: event.description,
        start_datetime: event.start,
        end_datetime: event.end,
      });
      openModal();
    } else {
      // 'leave' veya 'holiday' türündeki etkinlikler için bir işlem yapma veya uyarı göster
    }
  };

  return (
    <>
      <div className="flex justify-between bg-white dark:bg-navy-800 dark:text-white">
        <div className="z-10 ml-5">
          <Select
            isMulti
            options={options}
            placeholder="Filtrele"
            styles={selectStyle}
            value={selectedValue}
            className="flex h-24 w-full items-center justify-center  text-sm outline-none"
            classNamePrefix="select"
            onChange={handleChange}
          />
        </div>
        {currentUser?.role?.name === "CompanyAdmin" && (
          <div className="">
            <button
              onClick={addEventItem}
              className="m-5 flex items-center rounded-full bg-blue-500 py-2 px-4 text-white hover:bg-blue-700"
            >
              <FaPlus className="mr-1" /> Etkinlik Ekle
            </button>
          </div>
        )}
      </div>
      <div>
        <Calendar
          localizer={localizer}
          events={filterEvents()}
          selectable={currentUser?.role?.name === "CompanyAdmin"}
          onSelectSlot={handleSelectSlot}
          startAccessor="start"
          endAccessor="end"
          className="bg-white dark:bg-navy-800 dark:text-white"
          style={{ height: "700px", padding: "20px" }}
          onSelectEvent={handleEventSelect}
          eventPropGetter={eventStyleGetter}
          culture="tr-TR"
          messages={messages}
          step={30} // 30 dakikalık adımlar
          timeslots={1}
        />
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={modalStyle}
          contentLabel="Example Modal"
        >
          <div className="p-4">
            <button
              onClick={closeModal}
              className="absolute top-0 right-0 m-2 text-lg font-semibold"
            >
              ×
            </button>
            <h2 className="mb-4 text-xl font-semibold">
              {selectedEvent ? selectedEvent.title : "Etkinlik Oluştur"}
            </h2>
            <Formik
              initialValues={formInitialValues}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                var formData = new FormData();
                formData.append("name", values.name);
                formData.append("description", values.description);
                formData.append(
                  "start_datetime",
                  formatISODateTime(values.start_datetime)
                );
                formData.append(
                  "end_datetime",
                  formatISODateTime(values.end_datetime)
                );
                if (selectedEvent && selectedEvent.id) {
                  const response = await put(
                    `${ENDPOINTS.EVENTS}${selectedEvent.id}/`,
                    formData
                  );
                  if (response.status === 200) {
                    toast.success("Etkinlik başarıyla güncellendi.");
                    fetchData();
                    closeModal();
                  } else {
                    toast.error("Etkinlik güncellenirken bir hata oluştu.");
                  }
                } else {
                  const response = await post(ENDPOINTS.EVENTS, formData);
                  if (response.status === 201) {
                    toast.success("Etkinlik başarıyla oluşturuldu.");
                    fetchData();
                    closeModal();
                  } else {
                    toast.error("Etkinlik oluşturulurken bir hata oluştu.");
                  }
                }
              }}
              enableReinitialize
            >
              {({
                isSubmitting,
                handleChange,
                handleBlur,
                values,
                setFieldValue,
              }) => (
                <Form>
                  {selectedEvent && selectedEvent.id && (
                    <div className="float-right">
                      <button
                        type="button"
                        className="flex items-center text-sm text-red-700"
                        onClick={() => deleteHandle(values.id)}
                      >
                        <FaTrash className="mr-1" /> Etkinliği Sil?
                      </button>{" "}
                    </div>
                  )}
                  <div className="mb-4">
                    <InputField
                      name="name"
                      label="Etkinlik Adı"
                      placeholder="Etkinlik adını giriniz"
                      onChange={handleChange}
                      type="text"
                      onBlur={handleBlur}
                      value={values.name}
                    />
                  </div>
                  <div className="mb-4">
                    <TextField
                      name="description"
                      label="Açıklama"
                      placeholder="Etkinlik açıklamasını giriniz"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.description}
                    />
                  </div>
                  <div className="mb-4">
                    <label className="ml-3 block text-sm font-bold text-navy-700">
                      Başlangıç Tarihi
                    </label>
                    <FormikDateTimePicker
                      name="start_datetime"
                      selected={values.start_datetime}
                      label="Başlangıç Tarihi"
                      onChange={(date) => setFieldValue("start_datetime", date)}
                    />
                  </div>
                  <div className="mb-4">
                    <label className="ml-3 block text-sm font-bold text-navy-700">
                      Bitiş Tarihi
                    </label>
                    <FormikDateTimePicker
                      name="end_datetime"
                      label="Bitiş Tarihi"
                      selected={values.end_datetime}
                      onChange={(date) => setFieldValue("end_datetime", date)}
                    />
                  </div>
                  <div className="mt-4 flex justify-end">
                    <button
                      type="button"
                      onClick={closeModal}
                      className="mr-2 rounded bg-gray-500 py-2 px-4 font-bold text-white hover:bg-gray-700"
                    >
                      İptal
                    </button>
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="rounded bg-blue-500 py-2 px-4 font-bold text-white hover:bg-blue-700"
                    >
                      Kaydet
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default BCalendar;
