import React from "react";
import Modal from "react-modal";
import { Formik, Form, Field } from "formik";

import { post } from "utils/api"; // API istekleri için
import { ENDPOINTS } from "utils/constants";
import * as Yup from "yup";
import { ErrorMessage } from "formik";
import AsyncSelect from "react-select/async";
import FormikDatePicker from "components/datepicker";
import InputField from "components/fields/InputField";
import CurrencyField from "components/fields/CurrencyField";
import { formatISODate } from "utils/helpers";
import Switch from "components/switch";
import { useAppSelector } from "store";
import toast from "react-hot-toast";
import { aidTypes } from "utils/constants";

import { useTheme } from "ThemeContext";
import useCustomModalStyles from "utils/useCustomModalStyles";

const AddEmployeeModal = ({ isOpen, onClose, onRefresh }) => {
  const { isDarkMode } = useTheme();
  const modalStyles = useCustomModalStyles();
  const handleSubmit = async (values) => {
    var formData = new FormData();
    formData.append("first_name", values.first_name);
    formData.append("last_name", values.last_name);
    formData.append("email", values.email);
    formData.append("send_mail", values.send_mail);
    formData.append("identification_number", values.identification_number);
    try{
      const response = await post(ENDPOINTS.CREATE_EMPLOYEE, formData);
      if (response.status === 201) {
        onRefresh();
        onClose();
        toast.success("Çalışan başarılı bir şekilde eklendi. Diğer bilgilerini eklemek için çalışanın sayfasına gidiniz.");
      } else {
        toast.error("Bir hata oluştu");
      }
    }
    catch(e){
      console.log("error", e.error.includes("Kullanıcı sınırına"));
      try{
        if(e.email && e?.email[0]?.includes("Bu alan benzersiz olmalıdır")){
          toast.error("Bu email adresi sistemde kayıtlı! Lütfen farklı bir email adresi deneyiniz.");
        }
        else if(e.error && e.error.includes("Kullanıcı sınırına")){
          toast.error("Çalışan sınırına ulaşıldı. Lütfen daha fazla çalışan eklemek için paketinizi yükseltin. beyazik.com/magaza adresinden paketinizi yükseltebilirsiniz.");
        }
        else if(e.error && e.error.includes("Abonelik süreniz dolmuş")){
          toast.error("Abonelik süreniz dolmuş, lütfen bizimle iletişime geçin.");
        }
        else if(e.includes("WorkingDays matching query does not exist.")){
          toast.error("Varsayılan bir çalışma takvimi yok. Lütfen Kurallar > Çalışma takvimi menüsünden varsayılan bir çalışma takvimi seçiniz.");
        }
       
      }
      catch(e){
        toast.error("Bir hata oluştu");
      }
     
    }
    
  };
  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    send_mail: false,
    identification_number: "",
  };
  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("Ad alanı zorunludur"),
    last_name: Yup.string().required("Soyad alanı zorunludur"),
    email: Yup.string().email("Geçerli bir email adresi giriniz"),
    identification_number: Yup.string().length(11, "Kimlik numarası 11 karakter olmalıdır.").required("Kimlik numarası zorunludur"),
  });
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}  style={modalStyles}>
      <div className="mx-auto rounded-lg bg-white p-6 shadow-lg text-gray-800 dark:bg-navy-700 dark:text-white">
        <button
          onClick={onClose}
          className="absolute top-0 right-0 m-2 text-2xl font-semibold"
        >
          ×
        </button>
        <h2 className="mb-4 text-center text-xl font-semibold">
          Çalışan Ekle
        </h2>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({
            values,
            setFieldValue,
            isSubmitting,
            handleBlur,
            handleChange,
          }) => (
            <Form className="space-y-4">
              <div className="flex flex-col md:flex-row md:items-center md:space-x-4">
                <div className="flex-1">
                  <InputField name="first_name" label="Ad*" type="text" placeholder="Çalışan adı"/>
                </div>
                <div className="flex-1">
                  <InputField name="last_name" label="Soyad*" type="text" placeholder="Çalışan soyadı"/>
                </div>
              </div>
              <div className="flex flex-col md:flex-row md:items-center md:space-x-4">
                <div className="flex-1">
                  <InputField name="email" label="İş Epostası*" type="email" placeholder="İş eposta adresi" />
                </div>
                <div className="flex-1">
                  <InputField name="identification_number" label="Kimlik Numarası*" type="text" placeholder="Kimlik numarası" />
                </div>
              </div>
              <div className="mb-4 flex w-full">
                <Switch
                  name="send_mail"
                  label="Çalışana bilgilendirme maili gönder"
                  checked={values.send_mail}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              
              <button
                type="submit"
                disabled={isSubmitting}
                className="border-transparent mt-2 flex w-full justify-center rounded-md border bg-indigo-600 dark:border-none py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Kaydet
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default AddEmployeeModal;
