import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  format,
  addDays,
  isWeekend,
  differenceInCalendarDays,
  setHours,
  setMinutes,
  startOfDay,
  startOfToday,
} from "date-fns";
import { get } from "utils/api";
import { ENDPOINTS } from "utils/constants";
import capitalizeString from "utils/helpers";
import { post } from "utils/api";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import Switch from "components/switch";
import { put } from "utils/api";
import AsyncSelect from "react-select/async";
import InputField from "components/fields/InputField";
import FormikDatePicker from "components/datepicker";
import { useTheme } from "ThemeContext";
import useCustomSelectStyles from "utils/useCustomSelectStyles";

const NewAssets = ({ userId, onClose, onRefresh, assetId }) => {
  const selectStyle = useCustomSelectStyles();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const [loading, setLoading] = useState(false);
  const { isDarkMode } = useTheme();
  const [isEditMode, setIsEditMode] = useState(false);
  const [formData, setFormData] = useState(null);
  useEffect(() => {
    if (assetId) {
      setIsEditMode(true);
      fetchData();
    } else {
      setIsEditMode(false);
      setDefaultValues();
    }
  }, [assetId]);

  const loadCategoryOptions = async (inputValue) => {
    const response = await get(
      ENDPOINTS.ASSET_CATEGORY + "?search=" + inputValue
    );
    return response.results.map((item) => ({
      label: item.name,
      value: item.id,
    }));
  };

  const fetchData = async () => {
    const response = await get(`${ENDPOINTS.ASSETS}${assetId}/`);

    const data = await response;
    setFormData({
      category: data.category,
      serial_number: data.serial_number,
      date_of_issue: format(new Date(data.date_of_issue), "yyyy-MM-dd"),
      date_of_return: data.date_of_return
        ? format(new Date(data.date_of_return), "yyyy-MM-dd")
        : "",
      description: data.description,
    });
  };
  const setDefaultValues = () => {
    setFormData({
      category: "",
      serial_number: "",
      date_of_issue: format(startOfToday(), "yyyy-MM-dd"),
      date_of_return: format(addDays(startOfToday(), 1), "yyyy-MM-dd"),
      description: "",
    });
  };

  const validationSchema = Yup.object().shape({
    // category: Yup.string().required("Kategori gereklidir"),
    serial_number: Yup.string().required("Seri numarası gereklidir"),
    date_of_issue: Yup.date().required("Veriliş tarihi gereklidir"),
    description: Yup.string(),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    const data = {
      category: values.category.id,
      serial_number: values.serial_number,
      date_of_issue: format(new Date(values.date_of_issue), "yyyy-MM-dd"),
      date_of_return: values.date_of_return
        ? format(new Date(values.date_of_return), "yyyy-MM-dd")
        : null,
      description: values.description,
      user: userId,
      company: currentUser?.company?.id,
    };

    let response;
    if (isEditMode) {
      response = await put(`${ENDPOINTS.ASSETS}${assetId}/`, data);
    } else {
      response = await post(ENDPOINTS.ASSETS, data);
    }

    if (response.status === 201 || response.status === 200) {
      toast.success(
        `Zimmet başarıyla ${isEditMode ? "güncellendi" : "oluşturuldu"}`
      );
      onClose();
      onRefresh();
    } else {
      toast.error(
        `Zimmet ${
          isEditMode ? "güncellenirken" : "oluşturulurken"
        } bir hata oluştu`
      );
    }
    setSubmitting(false);
  };
  if (!formData) return <div>Yükleniyor...</div>;

  return (
    <div className="mx-auto  rounded-lg dark:bg-navy-700 dark:text-white bg-white p-7 shadow-lg">
      <button
        onClick={onClose}
        className="absolute top-0 right-0 m-2 text-2xl font-semibold"
      >
        ×
      </button>
      <h2 className="mb-4 text-center text-xl font-semibold text-gray-800 dark:text-white">
        {isEditMode ? "Zimmet Düzenle" : "Yeni Zimmet Oluştur"}
      </h2>
      <Formik
        initialValues={formData}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({
          values,
          setFieldValue,
          isSubmitting,
          handleBlur,
          handleChange,
        }) => (
          <Form className="space-y-4">
            <div className="flex justify-between">
              <div className="flex-1">
                <label
                  htmlFor="startDate"
                  className="block text-sm font-medium text-gray-700"
                >
                  Kategori
                </label>
                <AsyncSelect
                  styles={selectStyle}
                  cacheOptions
                  defaultOptions
                  loadOptions={loadCategoryOptions}
                  onChange={(option) =>
                    setFieldValue("category", {
                      id: option.value,
                      name: option.label,
                    })
                  }
                  name="category"
                  isClearable
                  placeholder="Seçiniz"
                  value={
                    values.category
                      ? {
                          value: values.category.id,
                          label: values.category.name,
                        }
                      : null
                  }
                />
                <ErrorMessage
                  name="category"
                  component="div"
                  className="text-sm text-red-600"
                />
              </div>
            </div>
            <div className="flex justify-between">
              <div className="flex-1">
                <label
                  htmlFor="startDate"
                  className="block text-sm font-medium text-gray-700"
                >
                  Veriliş Tarihi
                </label>
                <FormikDatePicker
                  name="date_of_issue"
                  selected={values.date_of_issue}
                />
                <ErrorMessage
                  name="date_of_issue"
                  component="div"
                  className="text-sm text-red-600"
                />
              </div>
              <div className="ml-2 flex-1">
                <label
                  htmlFor="startDate"
                  className="block text-sm font-medium text-gray-700"
                >
                  İade Tarihi
                </label>
                <FormikDatePicker
                  name="date_of_return"
                  selected={values.date_of_return}
                />
                <ErrorMessage
                  name="date_of_return"
                  component="div"
                  className="text-sm text-red-600"
                />
              </div>
            </div>
            <div className="flex justify-between">
              <div className="flex-1">
                <InputField
                  label="Seri Numarası"
                  id="serial_number"
                  name="serial_number"
                  type="text"
                  placeholder="Seri numarası giriniz"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.serial_number}
                />
                <ErrorMessage
                  name="serial_number"
                  component="div"
                  className="text-sm text-red-600"
                />
              </div>
            </div>
            {/* Açıklama */}
            <div>
              <label
                htmlFor="description"
                className="block text-sm font-medium text-gray-700"
              >
                Açıklama
              </label>
              <Field
                as="textarea"
                name="description"
                className="p-2 mt-1 block w-full rounded-md border-gray-600 dark:bg-navy-700 dark:text-white"
              />
              <ErrorMessage
                name="description"
                component="div"
                className="text-sm text-red-600"
              />
            </div>
            <button
              type="submit"
              disabled={isSubmitting}
              className="border-transparent flex w-full justify-center rounded-md border bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              {isEditMode ? "Güncelle" : "Kaydet"}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default NewAssets;
