import DataTable from "components/datatable";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTable } from "react-table";
import { useAppSelector } from "store";
import { get } from "utils/api";
import { ENDPOINTS } from "utils/constants";
import { leaveColumsData } from "../variables/columnsData";
import Dropdown from "components/dropdown";
import { FaEdit, FaEllipsisV, FaPlus } from "react-icons/fa";
import { del } from "utils/api";
import toast from "react-hot-toast";
import NewLeave from "views/app/leaves/NewLeave";
import Modal from "react-modal";

import formatDate from "utils/formatDate";
import ReactTooltip, { Tooltip } from "react-tooltip";
import { patch } from "utils/api";
import BASE_URL from "utils/constants";
import { getDownload } from "utils/api";
import useCustomModalStyles from "utils/useCustomModalStyles";

const Leaves = () => {
  const modalStyle = useCustomModalStyles();
  const dropdownButton = <FaEllipsisV className="cursor-pointer" />;
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const currentUserRoleName = useAppSelector(
    (state) => state.auth.currentUser?.role?.name === "CompanyAdmin"
  );
  const [userData, setUserData] = useState([]);
  const [leaveData, setLeaveData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isAdditionLeave, setIsAdditionLeave] = useState(false);
  const [isOpenChangeDateModal, setIsOpenChangeDateModal] = useState(false);
  const { id } = useParams();
  const closeModal = () => setIsOpenModal(false);
  const refreshData = () => getLeaveData();
  const [additionLeave, setAdditionLeave] = useState(0);
  const leaveColums = leaveColumsData(refreshData);
  const [leavePeriodData, setLeavePeriodData] = useState([]);
  const [leaveNewPeriodDate, setLeaveNewPeriodDate] = useState("");
  const [leaveNewEndPeriodDate, setLeaveNewEndPeriodDate] = useState("");
  const handleDownloadLeave = (id, type) => async () => {
    try {
      const response = await getDownload(ENDPOINTS.DOWNLOAD_LEAVE(id, type));
      const blob = response.data;
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = downloadUrl;
      if(type === "word")
        a.download = `leave_form.docx`; // Örnek dosya adı
      else if(type === "pdf")
        a.download = `leave_form.pdf`; // Örnek dosya adı
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (e) {
      console.error(e);
    }
  };
  const changeStatusLeave = (id, status) => async () => {
    var formData = new FormData();
    formData.append("status", status);
    if(status === "Onaylandı")
      formData.append("is_approved", true);
    const response = await patch(ENDPOINTS.LEAVE + `${id}/`, formData);
    if (response.status === 200) {
      getLeaveData();
      toast.success("Başarıyla güncellendi");
    } else {
      toast.error("Bir hata oluştu");
    }
  };
  const getLeavePeriods = async () => {
    setLoading(true);
    const response = await get(
      ENDPOINTS.LEAVE_PERIOD +
        "?user__id=" +
        id +
        "&period_type=current&ordering=-create_time"
    );
    setLeavePeriodData(response.results[0]);
    setLeaveNewPeriodDate(response.results[0].start);
    setLoading(false);
  };
  const getLeaveData = async () => {
    setLoading(true);
    const response = await get(ENDPOINTS.LEAVE + `?user__id=${id}&ordering=-create_time`);
    const updatedData = response?.results.map((data) => ({
      ...data,
      actions: (
        <div className="whitespace-nowrap px-6 py-4 text-right text-sm font-medium">
          <div className="flex items-center justify-end">
            <Dropdown button={dropdownButton} classNames="right-0">
              <div className="rounded bg-white dark:bg-navy-700 dark:text-white p-2 shadow-lg">
                <ul className="text-sm">
                  {/* <li
                    onClick={() => editHandle(data.id)}
                    className="cursor-pointer p-2 hover:bg-gray-100"
                  >
                    Önizleme
                  </li> */}
                   <li
                    onClick={changeStatusLeave(data.id, "Onaylandı")}
                    className="cursor-pointer p-2 hover:bg-gray-100 dark:hover:bg-navy-800"
                  >
                    İzni Onayla
                  </li>
                  <li
                    onClick={changeStatusLeave(data.id, "Reddedildi")}
                    className="cursor-pointer p-2 hover:bg-gray-100 dark:hover:bg-navy-800"
                  >
                    İzni Reddet
                  </li>
                  <li
                    onClick={handleDownloadLeave(data.id, "word")}
                    className="cursor-pointer p-2 hover:bg-gray-100 dark:hover:bg-navy-800"
                  >
                    İndir (Word)
                  </li>
                  <li
                    onClick={handleDownloadLeave(data.id, "pdf")}
                    className="cursor-pointer p-2 hover:bg-gray-100 dark:hover:bg-navy-800"
                  >
                    İndir (PDF)
                  </li>
                  <li
                    onClick={() => deleteHandle(data.id)}
                    className="cursor-pointer p-2 hover:bg-gray-100 dark:hover:bg-navy-800"
                  >
                    Sil
                  </li>
                </ul>
              </div>
            </Dropdown>
          </div>
        </div>
      ),
    }));
    setLeaveData(updatedData);
    setLoading(false);
  };

  const getUserDatas = async () => {
    setLoading(true);
    const response = await get(ENDPOINTS.USERS + `${id}/`);
    setUserData(response);
    setLoading(false);
  };
  const changeLeavePeriodDate = async () => {
    const response = await patch(
      ENDPOINTS.LEAVE_PERIOD + `${leavePeriodData.id}/`,
      { start: leaveNewPeriodDate, end: leaveNewEndPeriodDate }
    );
    if (response.status === 200) {
      getLeavePeriods();
      getLeaveData();
      setIsOpenChangeDateModal(false);
      toast.success("Başarıyla güncellendi");
    } else {
      toast.error("Bir hata oluştu");
    }
  };
  const changeAdditionLeave = async () => {
    const response = await patch(
      ENDPOINTS.LEAVE_PERIOD + `${leavePeriodData.id}/addition-leave/`,
      { addition_leave: additionLeave}
    );
    if (response.status === 200) {
      getLeavePeriods();
      getLeaveData();
      setIsAdditionLeave(false);
      setAdditionLeave(0);
      toast.success("Başarıyla güncellendi");
    } else {
      toast.error("Bir hata oluştu");
    }
  };
  const handleStartDateChange = (e) => {
    const newStartDate = e.target.value;
    const startDate = new Date(newStartDate);
    startDate.setFullYear(startDate.getFullYear() + 1);
    startDate.setDate(startDate.getDate() - 1);
    const newEndDate = startDate.toISOString().split("T")[0];
    setLeaveNewPeriodDate(newStartDate);
    setLeaveNewEndPeriodDate(newEndDate);
  };
  const handleAdditionLeaveChange = (e) => {
    setAdditionLeave(e.target.value);
  };
  
  useEffect(() => {
    getUserDatas();
    getLeaveData();
    getLeavePeriods();
  }, [id]);

  const deleteHandle = async (id) => {
    const result = window.confirm(`Silmek istediğinize emin misiniz?`);
    if (result) {
      const response = await del(ENDPOINTS.LEAVE, id);
      if (response.status === 204) {
        getLeaveData();
        toast.success("Başarıyla silindi");
      } else {
        toast.error("Bir hata oluştu");
      }
    }
  };

  // const editHandle = (rowData) => {
  //   // Düzenleme işlemi
  //   console.log("Editing:", rowData);
  // };
  const columnsDataWithActions = currentUserRoleName
  ? [...leaveColums, { label: "Eylemler", accessor: "actions" }]
  : leaveColums;
  return (
    <>
      <div className="flex flex-col gap-0">
        <div className="col-span-12">
          <div className="rounded-lg border border-gray-200 shadow">
            <div className="p-3">
              <div className="text-lg font-medium">
                Kullanılabilir İzin Bakiyesi /{" "}
                <span className="text-gray-600">Yıllık İzin</span>
              </div>
            </div>
            <div className="p-3">
              <div className="mb-2 text-right text-sm">
                Güncel Hak Ediş Dönemi:{" "}
                <span className="font-semibold">
                  {formatDate(leavePeriodData?.start)} -{" "}
                  {formatDate(leavePeriodData?.end)}
                </span>
              </div>
              <div
                className={`mb-2 ${
                  leavePeriodData?.balance > 0
                    ? "text-green-500"
                    : "text-red-600"
                }`}
              >
                {leavePeriodData?.balance || 0} gün
              </div>
              <div className="h-2 w-full rounded bg-gray-200">
                <div
                  className={`h-2 rounded ${
                    leavePeriodData?.balance > 0 ? "bg-green-500" : "bg-red-500"
                  }`}
                  style={{
                    width: `${
                      leavePeriodData?.balance > 0
                        ? (leavePeriodData?.balance / leavePeriodData?.total) *
                          100
                        : 100
                    }%`,
                  }}
                ></div>
              </div>
              <div className="mt-2 flex justify-between text-sm">
                <div className="flex items-center">
                  <span className="mr-2 font-semibold">Ücretli:</span>
                  <span>Toplam Hak Edilen</span>
                  <span className="ml-1 font-semibold">
                    {leavePeriodData?.total || 0}
                  </span>
                </div>
                <div className="flex items-center">
                  <div className="mr-2 h-2 w-2 rounded-full bg-yellow-500"></div>
                  <span className="mr-2">Dönemde İleri Tarihli</span>
                  <span className="font-semibold">
                    {leavePeriodData?.will_earn || 0}
                  </span>
                </div>
                <div className="flex items-center">
                  <div className="mr-2 h-2 w-2 rounded-full bg-red-500"></div>
                  <span className="mr-2">Toplam Kullanılan</span>
                  <span className="font-semibold">{leavePeriodData?.used}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-6 flex gap-5 text-sm">
        {currentUser?.role?.name === "CompanyAdmin" && (
          <>
            <button
              onClick={() => setIsOpenModal(true)}
              className="flex flex-col items-center justify-center rounded-lg bg-blue-500 p-3 text-white hover:bg-blue-600"
            >
              İzin Ekle
            </button>
            <button
              onClick={() => setIsOpenChangeDateModal(true)}
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Çalışanın izin hakkı hesaplamaları bu tarihten itibaren yapılacaktır."
              className="flex flex-col items-center justify-center rounded-lg bg-red-700 p-3 text-white hover:bg-red-800"
            >
              İzne Esas Hak Ediş Tarihi Değiştir (
              {formatDate(leavePeriodData?.start)})
            </button>
            <button
              onClick={() => setIsAdditionLeave(true)}
              data-tooltip-id="addition-leave"
              data-tooltip-content="Eksi veya artı bir değer girerek izin hakkı ekleyip veya çıkarabilirsiniz."
              className="flex flex-col items-center justify-center rounded-lg bg-blue-500 p-3 text-white hover:bg-blue-600"
            >
              Ek İzin Ekle / Eksilt
            </button>
            <Tooltip id="my-tooltip" />
            <Tooltip id="addition-leave" />
            {/* <button className="flex flex-col items-center justify-center rounded-lg bg-gray-700 p-3 text-white hover:bg-gray-800">
              Yıllık İzin Mutabakat Formu İndir
            </button> */}
          </>
        )}
      </div>
      <div className="flex flex-col">
        <DataTable
          columnsData={columnsDataWithActions}
          tableData={leaveData}
          title="İzinler"
          hiddenColumns={["id"]}
          loading={loading}
        />
      </div>

      <Modal
        isOpen={isOpenModal}
        onRequestClose={() => {
          setIsOpenModal(false);
        }}
        style={modalStyle}
        contentLabel={"Yeni Pozisyon Ekle"}
        ariaHideApp={false}
      >
        <NewLeave
        isOpen={true}
          userId={id}
          onClose={closeModal}
          onRefresh={refreshData}
          displayAdminButtons="true"
        />
      </Modal>
      
      <Modal
        isOpen={isAdditionLeave}
        onRequestClose={() => {
          setIsAdditionLeave(false);
        }}
        style={modalStyle}
        contentLabel={"Ek İzin Ekle"}
        ariaHideApp={false}
      >
        <div className="flex flex-col gap-4">
          <h2 className="text-lg font-semibold">Ek İzin Ekle / Eksilt</h2>
          <div className="flex flex-col gap-2">
            <label htmlFor="start">Gün +/- <small>(Örneğin 10 gün, -10 gün, 1,5 gün veya -1,5 gün vb.)</small></label>
            <input
              type="number"
              id="additionLeave"
              value={additionLeave}
              onChange={handleAdditionLeaveChange}
              className="border border-gray-300 p-2 rounded-lg"
            />
            <button
              onClick={changeAdditionLeave}
              className="rounded-lg bg-blue-500 p-2 text-white"
            >
              Ekle
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={isOpenChangeDateModal}
        onRequestClose={() => {
          setIsOpenChangeDateModal(false);
        }}
        style={modalStyle}
        contentLabel={"İzin Dönemi Değiştir"}
        ariaHideApp={false}
      >
        <div className="flex flex-col gap-4">
          <h2 className="text-lg font-semibold">İzin Dönemi Değiştir</h2>
          <div className="flex flex-col gap-2">
            <label htmlFor="start">Başlangıç Tarihi</label>
            <input
              type="date"
              id="start"
              value={leaveNewPeriodDate}
              onChange={handleStartDateChange}
            />
            <button
              onClick={changeLeavePeriodDate}
              className="rounded-lg bg-blue-500 p-2 text-white"
            >
              Değiştir
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Leaves;
