import {
  differenceInDays,
  isSameDay,
  isBefore,
  isAfter,
  format,
} from "date-fns";
import calculateDuration from "utils/calculateDuration";
import { DEFAULT_AVATAR } from "utils/constants";
import { CDN_URL } from "utils/constants";
import formatDate from "utils/formatDate";
import { formatDateWithTime } from "utils/formatDate";
import { getInitialsAndBackgroundColor } from "utils/helpers";

export const upComingLeavesColumnsData = [
  {
    Header: "Avatar",
    accessor: (row) => {
      if (row.user?.avatar?.image) {
        return {
          type: 'image',
          content: `${CDN_URL + row.user.avatar.image}`
        };
      } else {
        const { initials, backgroundColor } = getInitialsAndBackgroundColor(row.user?.first_name + " " + row.user?.last_name);
        return {
          type: 'initials',
          content: initials,
          backgroundColor
        };
      }
    },
  },
  {
    Header: "User",
    accessor: (row) => `${row.user.first_name} ${row.user?.last_name}`,
  },
  {
    Header: "LeaveDay",
    accessor: row => {
      return row.duration + " g";
    }
  },
  {
    Header: "StartDateTime",
    accessor: (row) => {
      return `${formatDate(row?.start_datetime, false)}`;
    },
  },
];

export const holidaysColumnData = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "LeaveDayCount",
    accessor: (row) => {
      return row.leave_day_count + " g";
    },
  },
  {
    Header: "Date",
    accessor: (row) => {
      if (row.start_date) {
        return `${formatDate(row?.start_date, false)}`;
      }
    },
  },
];

export const eventsColumnsData = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "EventsDayCount",
    accessor: (row) => {
      return `${calculateDuration(row.start_datetime, row.end_datetime)}`;
    },
  },
  {
    Header: "StartDateTime",
    accessor: (row) => {
      if (row.start_datetime) {
        return `${formatDate(row?.start_datetime, false)}`;
      }
    },
  },
];
export const birthdayColumnsData = [
  {
    Header: "Avatar",
    accessor: (row) => {
      if (row.user?.avatar?.image) {
        return {
          type: 'image',
          content: `${CDN_URL + row.user.avatar.image}`
        };
      } else {
        const { initials, backgroundColor } = getInitialsAndBackgroundColor(row.user?.first_name + " " + row.user?.last_name);
        return {
          type: 'initials',
          content: initials,
          backgroundColor
        };
      }
    },
  },
  {
    Header: "User",
    accessor: (row) => `${row.user?.first_name} ${row.user?.last_name}`,
  },
  {
    Header: "Date",
    accessor: (row) => {
      return `${formatDate(row?.birth_date, false)}`;
    },
  },
];

export const myLeavesColumnsData = [
  {
    Header: "Avatar",
    accessor: (row) => {
      if(row.status === "Onaylandı"){
        return "text-green-500";
      }
      else if(row.status === "Reddedildi"){
        return "text-red-500";

      }
      else if(row.status === "Onay Bekliyor"){
        return "text-yellow-500";
      }
    },
  },

  {
    Header: "LeaveType",
    accessor: "leave_type.name",
  },
  {
    Header: "LeaveDay",
    accessor: (row) => {
      return row.duration;
      // const startDate = new Date(row.start_datetime);
      // const endDate = new Date(row.end_datetime);

      // // Çalışma saatleri
      // const workStartTime = 9;
      // const workEndTime = 18;

      // // Yarım gün izin için belirlenen saat
      // const halfDayLimit = 13;

      // // İki tarih arasındaki gün farkını bul
      // const daysDifference = differenceInDays(endDate, startDate);

      // // İlk günü de hesaba kat
      // let leaveDays = daysDifference + 1;

      // // Eğer sadece bir gün izin alındıysa
      // if (isSameDay(startDate, endDate)) {
      //   // İzin başlangıç ve bitiş saatleri
      //   const startHour = startDate.getHours();
      //   const endHour = endDate.getHours();

      //   // Tam gün izin ise
      //   if (startHour <= workStartTime && endHour >= workEndTime) {
      //     leaveDays = 1;
      //   } else if (startHour <= workStartTime && endHour <= halfDayLimit) {
      //     // Öğle saatinden önce biten yarım gün izin ise
      //     leaveDays = 0.5;
      //   } else if (startHour >= halfDayLimit && endHour >= workEndTime) {
      //     // Öğle saatinden sonra başlayan yarım gün izin ise
      //     leaveDays = 0.5;
      //   }
      // } else {
      //   // İki tarih farklı günlerse başlangıç günü ve bitiş günü ayrı ayrı kontrol et
      //   if (startDate.getHours() > halfDayLimit) {
      //     // İlk gün öğle saatinden sonra başlıyorsa
      //     leaveDays -= 0.5;
      //   }

      //   if (endDate.getHours() < halfDayLimit) {
      //     // Son gün öğle saatinden önce bitiyorsa
      //     leaveDays -= 0.5;
      //   }
      // }
      // return leaveDays;
    },
  },

  {
    Header: "StartDateTime",
    accessor: (row) => {
      const startDate = new Date(row.start_datetime);

      const formattedStartDate = format(startDate, "dd-MM-yyyy");

      return `${formattedStartDate}`;
    },
  },
];

export const myEventsColumnsData = [
  {
    Header: "Avatar",
    accessor: (row) => {
      if(row.status === "Tamamlandı"  || row.status ==="Muaf"){
        return "text-green-500";
      }
      else if(row.status === "İptal Edildi" || row.status === "Katılmadı"){
        return "text-red-500";

      }
      else if(row.status === "Devam Ediyor" || row.status === "Beklemede"){
        return "text-yellow-500";
      }
    },
  },
  {
    Header: "Name",
    accessor: "training.name",
  },
  {
    Header: "Date",
    accessor: (row) => {
      return `${formatDateWithTime(row?.training?.start_datetime, false)}` + "-" + `${formatDateWithTime(row?.training?.end_datetime, false)}`;
      
    },
  },
];
