import * as Yup from 'yup';
export const companyUnitFormValidationSchema = Yup.object().shape({
    name: Yup.string().required('Birim adı gerekli'),
    phone: Yup.string().required('Telefon numarası gerekli'),
    holiday_category: Yup.string().required('Tatil takvimi seçimi gerekli').nullable(),
  });

export const addUnitTypeFormValidationSchema = Yup.object().shape({
    name: Yup.string().required('Birim adı gerekli'),
  });
export const addTrainingFormValidationSchema = Yup.object().shape({
  name: Yup.string().required("Eğitim adı zorunludur"),
  start_datetime: Yup.string().required("Başlangıç tarihi zorunludur"),
  end_datetime: Yup.string().required("Bitiş tarihi zorunludur"),
  instructor_company_details: Yup.string().required(
    "Eğitim veren şirket zorunludur"
  ),
  instructor_name: Yup.string().required("Eğitmen zorunludur"),
  location: Yup.string().required("Verildiği yer zorunludur"),
  repeat_frequency: Yup.number().required(
    "Geçerlilik süresi zorunludur"
  ).typeError(
    "Bu alan için sayısal bir değer girilmelidir"
  ),
  description: Yup.string().required("Detaylar zorunludur"),
});