import Dropdown from "components/dropdown";
import InputField from "components/fields/InputField";
import TextField from "components/fields/TextField";
import { Field, Formik, Form } from "formik";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import Switch from "react-switch";
import { get } from "utils/api";
import * as Yup from "yup";

import { ENDPOINTS } from "utils/constants";
import { companyUnitFormValidationSchema } from "../../validations/SettingsValidationSchema";
import BASE_URL from "utils/constants";
import { set } from "date-fns";
import { useTheme } from "ThemeContext";
const UnitForm = ({
  onSubmit,
  onCancel,
  unitType,
  unitTypeId,
  initialValues: externalInitialValues,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formFields, setFormFields] = useState([]);
  const [selectOptions, setSelectOptions] = useState({}); // Seçenekleri saklamak için bir state
  const [initialValues, setInitialValues] = useState({});
  const { isDarkMode } = useTheme();
  const selectStyle = {
    control: (provided) => ({
      ...provided,
      backgroundColor: isDarkMode
        ? "rgb(27 37 75 / var(--tw-bg-opacity))"
        : "white",
      borderColor: isDarkMode ? "gray" : "lightgray",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: isDarkMode
        ? "rgb(27 37 75 / var(--tw-bg-opacity))"
        : "white",
    }),
    option: (provided, state) => ({
      ...provided,
      color: isDarkMode ? "white" : "rgb(27 37 75 / var(--tw-bg-opacity))",
      backgroundColor: state.isFocused
        ? isDarkMode
          ? "rgb(27 37 65 / var(--tw-bg-opacity))"
          : "lightgray"
        : state.isSelected
        ? isDarkMode
          ? "darkblue"
          : "#d6d6d6"
        : isDarkMode
        ? "rgb(27 37 75 / var(--tw-bg-opacity))"
        : "white",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: isDarkMode ? "white" : "rgb(27 37 75 / var(--tw-bg-opacity))",
    }),
    multiValue: (styles, { data }) => ({
      ...styles,
      backgroundColor: data.color,
      // color: isDarkMode ? "white" : "rgb(27 37 75 / var(--tw-bg-opacity))",
      color: "white",
    }),
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: "white",
    }),
  };
//   const initialValues = formFields.reduce(
//     (acc, field) => {
//       acc[field.name] = "";
//       return acc;
//     },
//     { form_type: unitType },
//     { unit_type: unitTypeId }
//   );

useEffect(() => {
  console.log('selectedUnitId', unitTypeId);
  console.log('selectedUnitType', unitType);
    setIsLoading(true);
    const getFormFields = async () => {
      let response = await get(`${ENDPOINTS.FORMFIELD_GROUP}?name=COMPANY_UNIT`);
      if (response && response.results) {
        let fields = response.results[0].form_fields;

        if (unitType === "department" || unitType === "title") {
          fields = fields.filter((field) =>
            ["name", "phone", "holiday_category"].includes(field.name)
          );
        }

        setFormFields(fields);
        loadStaticOptions(fields);
      }
      setIsLoading(false);
    };

    getFormFields();
  }, [unitType, unitTypeId]);

  useEffect(() => {
  const calcInitialValues = formFields.reduce((acc, field) => {
      acc[field.name] = externalInitialValues && externalInitialValues[field.name]
                          ? externalInitialValues[field.name]
                          : "";
      return acc;
    }, {unitType,unitTypeId });
  
    // Eğer externalInitialValues içinde holiday_category varsa ve bu bir id ise
    if(externalInitialValues && externalInitialValues['holiday_category']) {
      calcInitialValues['holiday_category'] = externalInitialValues['holiday_category'].id || externalInitialValues['holiday_category'];
    }
  
    setInitialValues(calcInitialValues);
  }, [formFields, unitType, unitTypeId, externalInitialValues]);

  const loadStaticOptions = async (fields) => {
    // Tüm select alanları için options yükleyin
    const newSelectOptions = {};
    for (const field of fields) {
      if (field.type === "select" && field.optionEndpoint) {
        const response = await get(`${BASE_URL}${field.optionEndpoint}`);
        if (response && response.results) {
          newSelectOptions[field.name] = response.results.map((item) => ({
            value: item.id,
            label: item.name,
          }));
        }
      }
    }
    setSelectOptions(newSelectOptions);
  };

  const renderField = (field, formik) => {
    const { handleChange, handleBlur, values, setFieldValue, errors, touched } =
      formik;

    switch (field.type) {
      case "text":
      case "email":
      case "textarea":
        const Component = field.type === "textarea" ? TextField : InputField;
        return (
          <div key={field.id} className={field.className || ""}>
            <Component
              label={field.label}
              id={field.name}
              name={field.name}
              type={field.type}
              placeholder={field.placeholder}
              value={values[field.name]}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                errors[field.name] && touched[field.name]
                  ? errors[field.name]
                  : undefined
              }
            />
          </div>
        );
      case "select":
        const selectedOption = selectOptions[field.name]?.find(option => option.value === values[field.name]) || null;
        return (
          <div key={field.id} className={field.className || ""}>
            <label className="mt-2 ml-3 text-sm font-bold text-navy-700 dark:text-white">
              {field.label}
            </label>
            <Select
              styles={selectStyle}
              options={selectOptions[field.name] || []}
              value={selectedOption} // Seçili değer
              onChange={(option) => setFieldValue(field.name, option ? option.value : '')}

              placeholder={field.placeholder}
              isDisabled={field.disabled}
              isLoading={isLoading}
              isClearable={field.isClearable}
              isSearchable={field.isSearchable}
            />
            {errors[field.name] && touched[field.name] && (
              <div className="text-sm text-red-500">{errors[field.name]}</div>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={companyUnitFormValidationSchema}
      onSubmit={onSubmit}
    >
      {(formikProps) => (
        <Form className="space-y-4">
          {formFields.map((field) => renderField(field, formikProps))}
          <div className="flex justify-end space-x-2">
            <button
              type="button"
              onClick={onCancel}
              className="rounded-md bg-gray-300 px-4 py-2 text-sm text-gray-700 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
            >
              İptal
            </button>
            <button
              type="submit"
              className="rounded-md bg-blue-600 px-4 py-2 text-sm text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Kaydet
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default UnitForm;
