import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { get } from "utils/api";
import { ENDPOINTS } from "utils/constants";

const WorkSchedule = () => {
  const [datas, setDatas] = useState([]);
  const { id } = useParams();
  const getDatas = async () => {
    try {
      const response = await get(ENDPOINTS.USERS + id + "/");
      setDatas(response);
    } catch (error) {
      console.log("Error", error);
    }
  };
  useEffect(() => {
    getDatas();
  }, []);
  const handleSubmit = async () => {
    try {
      const response = await get(ENDPOINTS.WORKING_DAYS);
      console.log(response);
    } catch (error) {
      console.log("Error", error);
    }
  };
  console.log(datas);
  return (
    <div className="p-4">
      {/* <button
        onClick={handleSubmit}
        className="flex flex-col items-center justify-center rounded-lg bg-blue-500 p-2 text-sm text-white hover:bg-blue-600"
      >
        + Çalışma Takvimi Ata
      </button> */}
      <div className="mt-4 mb-4">
        Geçerli çalışma takvimi:{" "}
        <b>{datas?.working_days ? (<Link to={"/app/ayarlar/kurallar/calisma-zamanlari"}>{datas?.working_days?.name}</Link>) : "-"}</b>
      </div>
    </div>
  );
};

export default WorkSchedule;
