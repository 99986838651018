import React, { useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import {
  FaArrowLeft,
  FaSearch,
  FaEllipsisV,
  FaCheck,
  FaArrowRight,
  FaPlus,
  FaInfoCircle,
} from "react-icons/fa";
import Dropdown from "components/dropdown";
import { Link } from "react-router-dom";
import { get } from "utils/api";
import { ENDPOINTS } from "utils/constants";
import { DEFAULT_AVATAR } from "utils/constants";
import { Form, Field, Formik } from "formik";

import Modal from "react-modal";
import InputField from "components/fields/InputField";
import FormikCheckbox from "components/checkbox/FormikCheckbox";
import { post } from "utils/api";
import toast from "react-hot-toast";
import { del } from "utils/api";
import useCustomModalStyles from "utils/useCustomModalStyles";
import useCustomSelectStyles from "utils/useCustomSelectStyles";
import BASE_URL from "utils/constants";
import { CDN_URL } from "utils/constants";
import capitalizeString from "utils/helpers";
import { patch } from "utils/api";

const ApprovalProcessStep = ({ step, index }) => (
  <div className="mr-2 flex items-center">
    <div className="relative mr-1">
      <div className="absolute -top-2 -right-2 rounded-full bg-blue-500 px-1 text-xs text-white">
        {index + 1}
      </div>
      {/* Örnek avatar, gerçek avatarınızı buraya ekleyebilirsiniz */}
      <div className="flex h-6 w-6 items-center justify-center rounded-full bg-gray-300">
        {step.icon}
      </div>
    </div>
    <span>{step.name}</span>
  </div>
);

const ApprovalProcessCard = ({ id, title, steps, isDefault, onDeleted }) => {
  const setDefault = async () => {
    const result = window.confirm(`Varsayılan olarak ayarlamak istediğinize emin misiniz?`);
    if (result) {
      const response = await patch(ENDPOINTS.APPROVAL_PROCESS + id + "/set-default/", {
        is_default: true,
    })
    if (response.status === 200) {
      toast.success("Varsayılan olarak ayarlandı");
      window.location.reload();
    } else {
      toast.error("Bir hata oluştu");
    }}
  };
  if (!id) {
    console.log("ID değeri tanımlı değil!");
    return;
  }
  const deleteApprovalProcess = async () => {
    const result = window.confirm(`${title} silmek istediğinize emin misiniz?`);
    if (result) {
      try {
        // API'den silme isteği gönder
        const response = await del(ENDPOINTS.APPROVAL_PROCESS, id);
        if (response.status === 204) {
          toast.success("Onay süreci başarıyla silindi.");
          window.location.reload();
        } else {
          toast.error("Onay süreci silinirken bir hata oluştu.");
        }
      } catch (error) {
        console.error("Silme işlemi sırasında bir hata oluştu:", error);
        toast.error("Onay süreci silinirken bir hata oluştu.");
      }
    }
  };
  const dropdownButton = <FaEllipsisV className="cursor-pointer" />;
  const dropdownContent = (
    <div className="rounded bg-white p-2 shadow-lg">
      <ul className="text-sm">
      <li
          onClick={setDefault}
          className="cursor-pointer p-2 hover:bg-gray-100"
        >
          Varsayılan Yap
        </li>
        <li
          onClick={deleteApprovalProcess}
          className="cursor-pointer p-2 hover:bg-gray-100"
        >
          Sil
        </li>
      </ul>
    </div>
  );

  return (
    <div className="mb-4 rounded border p-4">
      <div className="mb-2 flex items-center justify-between">
        <span className="font-semibold">
          {title}
          {id === -1 && (
            <span className="ml-2 text-xs text-gray-500">
              (Eklenen bir süreç olmadığında sistem için varsayılan)
            </span>
          )}
          {isDefault && (
            <>
              <span className="ml-2 text-blue-500">(Varsayılan)</span>
            </>
          )}
        </span>
        {id !== -1 && (
          <Dropdown button={dropdownButton} classNames="right-0">
            {dropdownContent}
          </Dropdown>
        )}
      </div>
      <div className="flex items-center">
        {steps.map((step, index) => (
          <React.Fragment key={index}>
            <ApprovalProcessStep step={step} index={index} />
            {index < steps.length - 1 && <FaArrowRight className="mx-2" />}
          </React.Fragment>
        ))}
        <FaArrowRight className="mx-2" />
        <FaCheck className="ml-2 text-green-500" />
        <span className="ml-1"> Onaylandı</span>
      </div>
    </div>
  );
};
const ApprovalProcesses = () => {
  
  const [processes, setProcesses] = useState([
    {
      id: -1,
      title: "Varsayılan Onay Süreci",
      isDefault: false,
      steps: [
        {
          type: "user",
          name: "Çalışan",
          image: DEFAULT_AVATAR,
          icon: <img src={DEFAULT_AVATAR} alt="Çalışan" />,
        },
        {
          type: "user",
          name: "Yönetici",
          image: DEFAULT_AVATAR,
          icon: <img src={DEFAULT_AVATAR} alt="Yönetici" />,
        },
      ],
    },
    // İlk durumda mevcut süreçler
  ]);
  const fetchApprovalProcesses = async () => {
    const response = await get(
      ENDPOINTS.APPROVAL_PROCESS + "?ordering=-create_time"
    );
    let apiProcesses = response.results.map((process) => ({
      id: process.id,
      title: process.name,
      isDefault: process.is_default, // is_default değerini ekleyin
      steps: [
        {
          type: "user",
          name: "Çalışan",
          image: DEFAULT_AVATAR,
          icon: <img src={DEFAULT_AVATAR} alt="Çalışan" />,
        }, // Başlangıç adımı
        ...process.user.map((user) => ({
          type: "user",
          name: capitalizeString(user.first_name) + " " + capitalizeString(user.last_name),
          image: CDN_URL + user?.avatar?.image || DEFAULT_AVATAR,
          icon: (
            <img
              src={CDN_URL + user?.avatar?.image || DEFAULT_AVATAR}
              alt={user.username}
            />
          ),
        })),
      ],
    }));
    // is_default true olanları öne al ve aynı is_default değerine sahip ögeleri create_time'a göre sırala
    apiProcesses = apiProcesses.sort((a, b) => b.is_default - a.is_default);

    setProcesses((prevProcesses) => {
      const updatedProcesses = [...prevProcesses];
      apiProcesses.forEach((apiProcess) => {
        if (
          !updatedProcesses.some(
            (process) => process.title === apiProcess.title
          )
        ) {
          updatedProcesses.push(apiProcess);
        }
      });
      return updatedProcesses;
    });
  };
  const handleDeleted = async () => {
    fetchApprovalProcesses(); // Verileri yeniden fetch et
  };

  useEffect(() => {
    fetchApprovalProcesses();
  }, []); // key değiştiğinde useEffect'i tetikle
  return (
    <div>
      <div className="flex items-center rounded border p-2 mb-2">
        <FaSearch className="mr-2 text-gray-400" />
        <input
          type="text"
          placeholder="Ara"
          className="flex-grow focus:outline-none dark:text-white dark:bg-navy-800"
        />
      </div>
      {processes.map((process, index) => (
        <ApprovalProcessCard
          key={process.id}
          id={process.id}
          title={process.title}
          steps={process.steps}
          isDefault={process.isDefault}
          onDeleted={handleDeleted}
        />
      ))}
    </div>
  );
};
const loadUserOptions = async (inputValue) => {
  try {
    const response = await get(`${ENDPOINTS.USERS}?search=${inputValue}`);
    return response.results.map((user) => ({
      label: user.username, // veya user'ınızın göstermek istediğiniz özelliği
      value: user.id, // genellikle benzersiz bir kullanıcı ID'si
    }));
  } catch (error) {
    console.error("Error loading options:", error);
    return [];
  }
};
const AsyncSelectField = ({ field, form, options, ...props }) => {
  const selectStyle = useCustomSelectStyles();
  const handleChange = (selectedOption) => {
    const value = selectedOption
      ? selectedOption.map((option) => option.value)
      : [];
    form.setFieldValue(field.name, value);
  };

  // Bu kısmı düzeltin
  const value = field.value.map((selectedId) => {
    const option = options.find((option) => option.value === selectedId);
    return (
      option || { label: `User not found: ${selectedId}`, value: selectedId }
    );
  });

  return (
    <AsyncSelect
      {...field}
      {...props}
      cacheOptions
      defaultOptions
      loadOptions={loadUserOptions}
      onChange={handleChange}
      isMulti={true}
      value={value}
      styles={selectStyle}
    />
  );
};
const ApprovalProcess = () => {
  const modalStyle = useCustomModalStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [key, setKey] = useState(0); // Yeniden render için kullanılacak key state'i
  const [userOptions, setUserOptions] = useState([]);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);
  useEffect(() => {
    // Component yüklendiğinde kullanıcıları yükle
    loadUserOptions("").then((users) => setUserOptions(users));
  }, []);
  const postDataToAPI = async (values) => {
    try {
      var formData = new FormData();
      formData.append("name", values.name);
      values.users.forEach((user) => {
        if (user) formData.append("user_ids", user);
      });
      formData.append("is_default", values.isDefault);
      const response = await post(ENDPOINTS.APPROVAL_PROCESS, formData);
      if (response.status === 201) {
        handleCloseModal();
        toast.success("Onay süreci başarıyla oluşturuldu");
        setKey((prevKey) => prevKey + 1); // key state'ini güncelleyerek ApprovalProcesses component'ini yeniden çağır
      }
    } catch (error) {
      console.log("Posting data failed:", error);
      toast.error("Onay süreci oluşturulurken bir hata oluştu");
      // Hata yönetimi
    }
  };
  return (
    <div className="bg-white dark:bg-navy-800 dark:text-white">
      <div className="flex items-center justify-between bg-white p-4 shadow dark:bg-navy-800 dark:text-white">
        <div className="flex items-center">
          <Link to={"/app/ayarlar/kurallar"}>
            {" "}
            <FaArrowLeft className="mr-2 cursor-pointer text-xl" />
          </Link>
          <span className="text-lg font-semibold">
            Ayarlar / Onay Süreçleri
          </span>
        </div>
        <button
          onClick={handleOpenModal}
          className="flex items-center rounded bg-blue-500 py-2 px-4 text-white hover:bg-blue-700"
        >
          <FaPlus className="col-span-1" />
          <span className="col-span-1 ml-1">Yeni Süreç Ekle</span>
        </button>
      </div>
      <div className="p-4">
        <ApprovalProcesses key={key} />
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        style={modalStyle}
        contentLabel="Yeni Süreç Ekle"
        ariaHideApp={false}
      >
        <Formik
          initialValues={{ name: "", users: [], isDefault: false }}
          onSubmit={(values, actions) => {
            postDataToAPI(values);
            handleCloseModal();
          }}
        >
          {({ isSubmitting, handleChange, handleBlur, values }) => (
            <Form className="space-y-4">
              <div className="-mx-2 flex flex-wrap">
                <div className="mb-4 w-full px-2">
                  <InputField
                    label="Süreç Adı (*)"
                    id="name"
                    name="name"
                    type="text"
                    placeholder="Süreç adı giriniz"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="mb-4 w-full px-2">
                  <Field name="users">
                    {({ field, form }) => (
                      <AsyncSelectField
                        field={field}
                        form={form}
                        options={userOptions}
                        isMulti={true}
                        placeholder="Kullanıcı ara..."
                      />
                    )}
                  </Field>
                </div>
                <div className="mb-4 w-full px-2">
                  <FormikCheckbox
                    name="isDefault"
                    label="Varsayılan Yap"
                    color="blue"
                  />
                </div>
              </div>

              <div className="flex justify-end space-x-2">
                <button
                  type="button"
                  onClick={handleCloseModal}
                  className="rounded-md bg-gray-300 px-4 py-2 text-sm text-gray-700 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                >
                  İptal
                </button>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="rounded-md bg-blue-600 px-4 py-2 text-sm text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  Kaydet
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default ApprovalProcess;
