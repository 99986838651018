import React, { useState } from "react";
import { useField, useFormikContext } from "formik";

const FileField = ({ name, multiple = false, accept = "*", ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [files, setFiles] = useState([]);

  const handleChange = (e) => {
    const selectedFiles = e.target.files;
    if (multiple) {
      setFiles([...selectedFiles]);
      // Formik state'ini güncelle
      setFieldValue(name, [...selectedFiles]);
    } else {
      setFiles(selectedFiles[0]);
      // Formik state'ini güncelle
      setFieldValue(name, selectedFiles[0]);
    }
  };

  return (
    <>
      <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
        Dosya Seç
      </label>
      <input
        type="file"
        {...props}
        onChange={handleChange}
        multiple={multiple}
        accept={accept}
        className="mt-1 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
      />
    </>
  );
};

export default FileField;
