import React, { useEffect, useMemo, useState } from "react";
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
} from "react-table";
import Card from "components/card";
import { Link } from "react-router-dom";
import Loading from "components/loading";
import Modal from "react-modal";
import useCustomModalStyles from "utils/useCustomModalStyles";
const DataTable = ({
  columnsData,
  tableData,
  hiddenColumns,
  title,
  linkPath,
  linkableColumn,
  loading,
  modalContent,
  onRowClick
}) => {
  const customStyle = useCustomModalStyles();
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const [showModal, setShowModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const handleRowClick = (row) => {
    setSelectedRowData(row);
    setShowModal(true);
    if (onRowClick) {
      onRowClick(row);
    }
  };
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 8, hiddenColumns },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = tableInstance;
  const [searchInput, setSearchInput] = useState(globalFilter);
  const handleSearchChange = (e) => {
    const value = e.target.value || undefined; // Boş string yerine undefined kullan
    setGlobalFilter(value); // Tabloyu filtrele
    setSearchInput(value); // Input durumunu güncelle
  };
  return (
    <Card extra="w-full h-full">
      {loading ? (
        <Loading />
      ) : (
        <div className="mt-8 min-w-[600px] sm:min-w-0">
          {/* Arama Input'u */}
          <input
            value={searchInput || ""}
            onChange={handleSearchChange}
            className="mb-4 rounded-lg border p-2 text-sm dark:border-none dark:bg-navy-700 dark:text-white ml-3"
            placeholder="Filtrele..."
          />
          <div className="overflow-x-auto">
            <table
              {...getTableProps()}
              className="w-full min-w-full table-auto"
            >
              <thead className="bg-gray-50 dark:bg-navy-700">
                {headerGroups.map((headerGroup, index) => (
                  <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                    {headerGroup.headers.map((column, index) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        key={index}
                        className="border-b border-gray-200 px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500 dark:border-gray-600 dark:bg-navy-700 dark:text-white"
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody
                {...getTableBodyProps()}
                className="divide-y divide-gray-200 bg-white dark:divide-gray-600 dark:bg-navy-700"
              >
                {page.map((row, index) => {
                  prepareRow(row);
                  return (
                    <tr
                      {...row.getRowProps()}
                      key={index}
                      className="hover:bg-gray-100 dark:hover:bg-navy-600"
                      onClick={() => handleRowClick(row.original)}
                    >
                      {row.cells.map((cell, index) => {
                        let CellContent = <>{cell.render("Cell")}</>;

                        if (
                          cell.column.id === linkableColumn &&
                          typeof linkPath === "function"
                        ) {
                          const path = linkPath(row.original);
                          CellContent = (
                            <Link
                              to={path}
                              className="text-blue-600 hover:text-blue-900 dark:hover:text-blue-400"
                            >
                              {CellContent}
                            </Link>
                          );
                        }

                        return (
                          <td
                            {...cell.getCellProps()}
                            key={index}
                            className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900 dark:text-gray-300"
                          >
                            {CellContent}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {showModal && modalContent && (
        <Modal isOpen={showModal} onRequestClose={() => setShowModal(false)} style={customStyle}>
          {modalContent(selectedRowData)}
        </Modal>
      )}
          </div>
          {page.length === 0 && (
            <div className="py-5 text-center text-sm text-gray-500 dark:text-gray-400">
              Gösterilecek kayıt yok.
            </div>
          )}
          {/* Sayfalama Kontrolleri */}
          <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 dark:bg-navy-700 dark:text-white sm:px-6">
            <div className="flex flex-1 justify-between sm:hidden">
              <button
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
                className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50 dark:bg-navy-700 dark:text-white"
              >
                Önceki
              </button>
              <button
                onClick={() => nextPage()}
                disabled={!canNextPage}
                className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50 dark:bg-navy-700 dark:text-white"
              >
                Sonraki
              </button>
            </div>
            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
              <div>
                <p className="text-gray-700ü text-sm dark:text-white">
                  Sayfa <span className="font-medium">{pageIndex + 1}</span> /{" "}
                  <span className="font-medium">{pageOptions.length}</span>
                </p>
              </div>
              <div>
                <nav
                  className="relative z-0 inline-flex -space-x-px rounded-md shadow-sm dark:bg-navy-600 dark:text-white "
                  aria-label="Pagination"
                >
                  <button
                    onClick={() => gotoPage(0)}
                    disabled={!canPreviousPage}
                    className="relative inline-flex  items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50 dark:bg-navy-600 dark:text-white"
                  >
                    <span>İlk</span>
                  </button>
                  <button
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                    className="relative inline-flex  items-center border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50 dark:bg-navy-600 dark:text-white"
                  >
                    <span>Önceki</span>
                  </button>
                  <button
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                    className="relative inline-flex  items-center border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50 dark:bg-navy-600 dark:text-white"
                  >
                    <span>Sonraki</span>
                  </button>
                  <button
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}
                    className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50 dark:bg-navy-600 dark:text-white"
                  >
                    <span>Son</span>
                  </button>
                </nav>
              </div>
              <div>
                <select
                  value={pageSize}
                  onChange={(e) => setPageSize(Number(e.target.value))}
                  className="relative m-0 block w-full rounded border border-solid border-gray-300 bg-white bg-clip-padding bg-no-repeat px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-blue-600 focus:bg-white focus:text-gray-700 focus:outline-none dark:bg-navy-600 dark:text-white"
                >
                  {[5, 10, 20].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      {pageSize} göster
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      )}
    </Card>
  );
};

export default DataTable;
