import React, { useEffect, useRef, useState } from "react";
import Dropdown from "components/dropdown";
import { FiAlignJustify } from "react-icons/fi";
import { Link } from "react-router-dom";
import navbarimage from "assets/img/layout/Navbar.png";
import { BsArrowBarUp, BsCalendar2Plus } from "react-icons/bs";
import { FiSearch } from "react-icons/fi";
import { RiMoonFill, RiSunFill } from "react-icons/ri";
import { useDispatch } from "react-redux";
import store, { useAppSelector } from "../../store";
import * as Yup from "yup";
import Modal from "react-modal";
import {
  IoMdNotificationsOutline,
  IoMdInformationCircleOutline,
} from "react-icons/io";
import { setAccessToken, setCurrentUser } from "../../store/authSlice";
import { CDN_URL } from "utils/constants";
import { DEFAULT_AVATAR } from "utils/constants";
import { getInitialsAndBackgroundColor } from "utils/helpers";
import { get } from "utils/api";
import { ENDPOINTS } from "utils/constants";
import { momentTime } from "utils/helpers";
import { useTheme } from "ThemeContext";
import useCustomModalStyles from "utils/useCustomModalStyles";
import { Form, Formik } from "formik";
import InputField from "components/fields/InputField";
import TextField from "components/fields/TextField";
import { post } from "utils/api";
import toast from "react-hot-toast";
import { FaPlus, FaTrash } from "react-icons/fa";
import { del } from "utils/api";
import Cookies from 'js-cookie';
const Navbar = (props) => {
  const NotificationIcon = ({ count }) => (
    <div className="relative">
      <IoMdNotificationsOutline className="h-4 w-4 text-gray-600 dark:text-white" />
      {count > 0 && (
        <span className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 flex h-4 w-4 items-center justify-center rounded-full bg-red-500 text-xs text-white">
          {count}
        </span>
      )}
    </div>
  );
  const searchRef = useRef(null);
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const modalStyle = useCustomModalStyles();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [notificationDetailModal, setNotificationDetailModal] = useState(false);
  const [notificationDetail, setNotificationDetail] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [notifications, setNotifications] = useState([]);
  const { initials, backgroundColor } = getInitialsAndBackgroundColor(
    currentUser?.first_name + " " + currentUser?.last_name
  );
  const backgroundImageUrl = currentUser?.avatar?.image
    ? CDN_URL + currentUser.avatar.image
    : null;
  const { onOpenSidenav, brandText } = props;
  const { isDarkMode, toggleDarkMode } = useTheme();

  const dispatch = useDispatch();
  useEffect(() => {
    // Dışa tıklama olayını yakalamak için handler
    function handleClickOutside(event) {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setSearchResults([]); // Dışarı tıklandığında sonuçları gizle
      }
    }
    // Document'e event listener ekleniyor
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Component unmount olduğunda event listener'ı temizle
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchRef]);
  const getNotifications = async () => {
    const response = await get(
      ENDPOINTS.NOTIFICATIONS +
        "?company__id=" +
        currentUser?.company?.id +
        "&limit=10&ordering=-create_time"
    );
    setNotifications(response.results);
  };

  useEffect(() => {
    getNotifications();
  }, []);
  const Logout = () => {
    Cookies.remove('cardsOrder');
    dispatch(setAccessToken(null));
    dispatch(setCurrentUser(null));
    window.location.href = "/";
  };
  const handleChange = async (event) => {
    const value = event.target.value;
    setInputValue(value);

    if (value.length >= 3) {
      const response = await get(ENDPOINTS.USERS + `?search=${value}`);
      setSearchResults(response.results);
    }
  };
  const showNotificationDetail = (id) => {
    setNotificationDetailModal(true);
    const notification = notifications.find((n) => n.id === id);
    setNotificationDetail(notification);
  };
  const notificationSubmit = async (values) => {
    var formData = new FormData();
    formData.append("title", values.title);
    formData.append("description", values.description);
    formData.append("company", currentUser?.company?.id);
    try {
      const response = await post(ENDPOINTS.NOTIFICATIONS, formData);
      if (response.status === 201) {
        toast.success("Bildirim başarıyla oluşturuldu");
        setIsOpenModal(false);
        getNotifications();
      }
    } catch (error) {
      toast.error(
        "Bildirim ekleme sırasında bir hata oluştu. Lütfen tekrar deneyin."
      );
    }
  };
  const deleteNotification = async (id) => {
    try {
      const result = window.confirm(`Silmek istediğinize emin misiniz?`);
      if (result) {
      const response = await del(ENDPOINTS.NOTIFICATIONS, id);
      if (response.status === 204) {
        toast.success("Bildirim başarıyla silindi");
        getNotifications();
      }}

    } catch (error) {
      toast.error(
        "Bildirim silme sırasında bir hata oluştu. Lütfen tekrar deneyin."
      );
    }
  };
  return (
    <nav className="sticky top-0 z-40 flex flex-row flex-wrap items-center justify-between rounded-xl bg-white/10 p-2 backdrop-blur-xl dark:bg-[#0b14374d]">
      <div className="ml-[6px]">
        <div className="h-6 w-[260px] pt-1">
          <a
            className="text-sm font-normal text-navy-700 hover:underline dark:text-white dark:hover:text-white"
            href=" "
          >
            Kontrol Paneli
            <span className="mx-1 text-sm text-navy-700 hover:text-navy-700 dark:text-white">
              {" "}
              /{" "}
            </span>
          </a>
          <Link
            className="text-sm font-normal capitalize text-navy-700 hover:underline dark:text-white dark:hover:text-white"
            to="#"
          >
            {brandText}
          </Link>
        </div>
        <p className="shrink text-[33px] capitalize text-navy-700 dark:text-white">
          <Link
            to="#"
            className="font-bold capitalize hover:text-navy-700 dark:hover:text-white"
          >
            {brandText}
          </Link>
        </p>
      </div>

      <div className="relative mt-[3px] flex h-[61px] w-[355px] flex-grow items-center justify-around gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:w-[365px] md:flex-grow-0 md:gap-1 xl:w-[365px] xl:gap-2"
      style={{
        width: currentUser?.role?.name === "CompanyAdmin" ? "365px" : "140px",
      }}
      >
        {currentUser?.role?.name === "CompanyAdmin" && (
          <>
          <div
            ref={searchRef}
            className="flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[225px]"
          >
            <p className="pl-3 pr-2 text-xl">
              <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
            </p>
            <input
              value={inputValue}
              type="text"
              placeholder="Çalışan ara..."
              onChange={handleChange}
              className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
            />
            {searchResults.length > 0 && (
              <ul className="absolute top-16 z-10 max-h-60 w-full overflow-auto rounded-md bg-white py-1 shadow-lg dark:bg-navy-800">
                {searchResults.map((user) => (
                  <Link to={`/app/calisanlar/${user.id}`}>
                    <li
                      key={user.id}
                      className="px-4 py-2 text-sm text-navy-700 hover:bg-gray-100 dark:text-white dark:hover:bg-navy-700"
                    >
                      <span className="capitalize">
                        {user.first_name} {user.last_name}
                      </span>{" "}
                      ({user.email})
                    </li>
                  </Link>
                ))}
              </ul>
            )}
          </div>
          <div className="flex h-full items-center rounded-full text-navy-700"></div>
          </>
        )}

        <span
          className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden"
          onClick={onOpenSidenav}
        >
          <FiAlignJustify className="h-5 w-5" />
        </span>
        {/* start Notification */}
        <Dropdown
          button={
            <div className="cursor-pointer">
            <NotificationIcon count={notifications.length} />
            </div>
          }
          animation="origin-[65%_0%] md:origin-top-right transition-all duration-300 ease-in-out"
          children={
            <div className="flex w-[360px] flex-col gap-3 rounded-[20px] bg-white p-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none sm:w-[460px]">
              <div className="flex items-center justify-between">
                <p className="text-base font-bold text-navy-700 dark:text-white">
                  Bildirimler ({notifications.length})
                </p>
                {currentUser?.role?.name === "CompanyAdmin" && (
                  <button
                    onClick={() => setIsOpenModal(true)}
                    className="ml-4 rounded-lg bg-blue-500 py-2 px-4 text-white"
                    title="Bildirim Ekle"
                  >
                    <FaPlus />
                  </button>
                )}

                {/* <p className="text-sm font-bold text-navy-700 dark:text-white">
                  Tümünü Gör
                </p> */}
              </div>
              {notifications.length > 0 ? (
                notifications.map((notification) => (
                  <>
                    <div
                      className="flex w-full items-center justify-between "
                      key={notification?.id}
                    >
                      <div className="flex h-full w-[85px] items-center justify-center rounded-xl bg-gradient-to-b from-brandLinear to-brand-500 py-4 text-2xl text-white">
                        <BsCalendar2Plus />
                      </div>
                      <div
                        className="ml-2 flex h-full w-full cursor-pointer flex-col justify-center rounded-lg px-1 text-sm"
                        onClick={() => showNotificationDetail(notification?.id)}
                      >
                        <p className="mb-1 text-left text-base font-bold text-gray-900 dark:text-white">
                          {notification.title}
                        </p>
                        <p className="font-base text-left text-xs text-gray-900 dark:text-white">
                          {momentTime(notification.create_time)}
                        </p>
                      </div>
                      {currentUser?.role?.name === "CompanyAdmin" && (
                        <button
                          className="ml-4 rounded-lg bg-red-500 py-2 px-2 text-white text-xs"
                          onClick={() => deleteNotification(notification?.id)}
                          title="Bildirimi Sil"
                        >
                          <FaTrash />
                        </button>
                      )}
                    </div>
                  </>
                ))
              ) : (
                <p className="text-sm italic text-navy-700 dark:text-white">
                  Henüz bildirim yok..
                </p>
              )}
            </div>
          }
          classNames={"py-2 top-4 -left-[230px] md:-left-[440px] w-max"}
        />
        {/* start Horizon PRO */}
        <Dropdown
          button={
            <p className="cursor-pointer">
              <IoMdInformationCircleOutline className="h-4 w-4 text-gray-600 dark:text-white" />
            </p>
          }
          children={
            <div className="flex w-[350px] flex-col gap-2 rounded-[20px] bg-white p-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
              {currentUser?.role?.name === "CompanyAdmin" && (
                <Link
                  target="blank"
                  to={`/app/ayarlar/odeme-bilgileri`}
                  className="px-full linear flex cursor-pointer items-center justify-center rounded-xl bg-brand-500 py-[11px] font-bold text-white transition duration-200 hover:bg-brand-600 hover:text-white active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:bg-brand-200"
                >
                  Ödeme Ayarları
                </Link>
              )}

              <a
                target="blank"
                href="https://beyazik.com/blog"
                className="px-full linear flex cursor-pointer items-center justify-center rounded-xl border py-[11px] font-bold text-navy-700 transition duration-200 hover:bg-gray-200 hover:text-navy-700 dark:!border-white/10 dark:text-white dark:hover:bg-white/20 dark:hover:text-white dark:active:bg-white/10"
              >
                Blog
              </a>
              <a
                target="blank"
                href="https://beyazik.com/iletisim"
                className="hover:bg-black px-full linear flex cursor-pointer items-center justify-center rounded-xl py-[11px] font-bold text-navy-700 transition duration-200 hover:text-navy-700 dark:text-white dark:hover:text-white"
              >
                Yardım & Destek
              </a>
            </div>
          }
          classNames={"py-2 top-6 -left-[250px] md:-left-[330px] w-max"}
          animation="origin-[75%_0%] md:origin-top-right transition-all duration-300 ease-in-out"
        />
        <div className="cursor-pointer text-gray-600" onClick={toggleDarkMode}>
          {isDarkMode ? (
            <RiSunFill className="h-4 w-4 text-gray-600 dark:text-white" />
          ) : (
            <RiMoonFill className="h-4 w-4 text-gray-600 dark:text-white" />
          )}
        </div>
        {/* Profile & Dropdown */}
        <Dropdown
          button={
            backgroundImageUrl ? (
              <img
                className="h-10 w-10 cursor-pointer rounded-full"
                src={backgroundImageUrl}
                alt="avatar"
              />
            ) : (
              <div
                className="flex h-10 w-10 cursor-pointer items-center justify-center rounded-full"
                style={{ backgroundColor: backgroundColor }}
              >
                <span className="text-sm font-bold text-white">{initials}</span>
              </div>
            )
          }
          children={
            <div className="flex w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
              <div className="p-4">
                <div className="flex items-center gap-2">
                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                    👋 Hoşgeldin, {currentUser?.first_name}
                  </p>{" "}
                </div>
              </div>
              <div className="h-px w-full bg-gray-200 dark:bg-white/20 " />

              <div className="flex flex-col p-4">
                <Link
                  to={"/app/calisanlar/" + currentUser?.id}
                  className="text-sm text-gray-800 dark:text-white hover:dark:text-white"
                >
                  Hesabım
                </Link>
                <Link
                  to={"/app/parola-degistir/"}
                  className="mt-3 text-sm text-gray-800 dark:text-white hover:dark:text-white"
                >
                  Parola Değiştir
                </Link>
                <Link
                  onClick={Logout}
                  className="mt-3 text-sm font-medium text-red-500 hover:text-red-500"
                >
                  Çıkış Yap
                </Link>
              </div>
            </div>
          }
          classNames={"py-2 top-8 -left-[180px] w-max"}
        />
      </div>
      <Modal
        isOpen={isOpenModal}
        onRequestClose={() => setIsOpenModal(false)}
        style={modalStyle}
      >
        <div className="p-4">
          <button
            onClick={() => setIsOpenModal(false)}
            className="absolute top-0 right-0 m-2 text-lg font-semibold"
          >
            ×
          </button>
          <h2 className="mb-4 text-xl font-semibold">
            Yeni Bildirim Ekle{" "}
            <span className="mb-3 text-center text-xs font-normal italic">
              *Eklediğiniz bildirimi tüm çalışanlarınız görüntüler.
            </span>
          </h2>

          <Formik
            initialValues={{ title: "", description: "" }}
            onSubmit={notificationSubmit}
            enableReinitialize={true}
            validationSchema={Yup.object({
              title: Yup.string().required("Bildirim başlığı zorunludur"),
              description: Yup.string().required(
                "Bildirim açıklaması zorunludur"
              ),
            })}
          >
            <Form>
              <InputField
                label="Başlık"
                name="title"
                placeholder="Bildirim başlığı"
              />
              <TextField
                label="Açıklama"
                name="description"
                placeholder="Bildirim açıklaması"
              />

              <button
                type="submit"
                className="mt-4 rounded bg-blue-500 px-4 py-2 text-white"
              >
                Kaydet
              </button>
            </Form>
          </Formik>
        </div>
      </Modal>
      <Modal
        isOpen={notificationDetailModal}
        onRequestClose={() => setNotificationDetailModal(false)}
        style={modalStyle}
      >
        <div className="p-4">
          <button
            onClick={() => setNotificationDetailModal(false)}
            className="absolute top-0 right-0 m-2 text-lg font-semibold"
          >
            ×
          </button>
          <div className="flex flex-col gap-2">
            <p className="text-lg font-semibold capitalize">
              {notificationDetail?.title}
            </p>
            <p className="text-sm">{notificationDetail?.description}</p>
          </div>
        </div>
      </Modal>
    </nav>
  );
};

export default Navbar;
