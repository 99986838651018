import React, { useState } from "react";
import toast from "react-hot-toast";
import { FaFolder, FaFileAlt, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "store";
import { del } from "utils/api";
import { get } from "utils/api";
import { ENDPOINTS } from "utils/constants";

const FolderView = ({ initialFolders, onRefresh }) => {
  const currentUserRoleName = useAppSelector(
    (state) => state.auth.currentUser?.role?.name === "CompanyAdmin"
  );
  const navigate = useNavigate(); // useHistory yerine useNavigate kullan

  const handleFolderClick = async (folderId) => {
    navigate(`/app/dosyalar/${folderId}`); // history.push yerine navigate kullan
  };
  const deleteHandle = async (id) => {
    const result = window.confirm(`Silmek istediğinize emin misiniz?`);
    if (result) {
      const response = await del(ENDPOINTS.COMPANY_FOLDER, id);
      if (response.status === 204) {
        onRefresh();
        toast.success("Başarıyla silindi");
      } else {
        toast.error("Bir hata oluştu");
      }
    }
  };

  return (
    <div className="flex flex-col gap-3">
      {initialFolders.length > 0 ? (initialFolders.map((folder) => (
        <div
          key={folder.id}
          onClick={() => handleFolderClick(folder.id)}
          className="flex cursor-pointer items-center justify-between rounded-lg border border-gray-200 p-3 shadow-sm hover:bg-gray-100 dark:hover:bg-navy-700"
        >
          <div className="flex items-center gap-3">
            <FaFolder className="text-yellow-500" />
            <span>{folder.name}</span>
          </div>
          {currentUserRoleName && (
            <FaTrash
              onClick={(e) => {
                e.stopPropagation();
                deleteHandle(folder.id);
              }}
              className="cursor-pointer text-red-500 hover:text-red-700"
            />
          )}
        </div>
      ))): (
        <p className="text-sm italic text-gray-500">Kayıt yok</p>
      
      )}
    </div>
  );
};

export default FolderView;
