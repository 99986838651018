import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  addDays,
  differenceInCalendarDays,
  setHours,
  setMinutes,
} from "date-fns";
import { get } from "utils/api";
import { ENDPOINTS } from "utils/constants";
import { post } from "utils/api";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import Switch from "components/switch";
import LeaveFormikDateTimePicker from "components/datepicker/LeaveFormikDateTimePicker";
import { formatISODateTime } from "utils/helpers";
import useCustomModalStyles from "utils/useCustomModalStyles";
import Modal from "react-modal";
const NewLeave = ({
  isOpen,
  userId,
  onClose,
  onRefresh,
  displayAdminButtons = false,
}) => {
  const modalStyle = useCustomModalStyles();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const [users, setUsers] = useState([]); // Kullanıcılar (yerine bakacak kişiler)
  const [leaveTypes, setLeaveTypes] = useState([]); // İzin türleri
  const [loading, setLoading] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [isSendMail, setIsSendMail] = useState(false);
  const [workingHours, setWorkingHours] = useState({});
  const [excludedDates, setExcludedDates] = useState([]);
  const [initialValues, setInitialValues] = useState({
    leaveType: "",
    totalDays: 1,
    startDateTime: setHours(setMinutes(addDays(new Date(), 1), 0), 9),
    endDateTime: setHours(setMinutes(addDays(new Date(), 1), 0), 18),
    description: "",
    replacementPerson: "",
    returnDateTime: addDays(new Date(), 2),
    is_approved: isApproved,
  });

  const getLeaveTypes = async () => {
    setLoading(true);
    const response = await get(ENDPOINTS.LEAVE_TYPE + "?ordering=-id");
    setLeaveTypes(response?.results);
    setLoading(false);
  };

  const getUsers = async () => {
    setLoading(true);
    const response = await get(ENDPOINTS.USERS + "?ordering=-id");
    const updatedUsers = response?.results.filter(
      (user) => user.id !== currentUser?.id
    );
    setUsers(updatedUsers);
    setLoading(false);
  };

  const getNextWorkingDay = (date, nonWorkingDays) => {
    let nextDay = new Date(date);
    while (nonWorkingDays.includes(nextDay.getDay())) {
      nextDay.setDate(nextDay.getDate() + 1);
    }
    return nextDay;
  };

  const getWorkingHours = () => {
    const todayIndex = new Date().getDay(); // 0: Pazar, 1: Pazartesi, ..., 6: Cumartesi
    const days = [
      "Pazar",
      "Pazartesi",
      "Salı",
      "Çarşamba",
      "Perşembe",
      "Cuma",
      "Cumartesi",
    ];
    const workingDayDetails =
      currentUser?.working_days?.working_day_details || [];

    const todayName = days[todayIndex];
    let todayShift = workingDayDetails.find(
      (day) => day.day === todayName && day.is_visible
    );
    if(todayShift === undefined) {
      todayShift = workingDayDetails.find(
        (day) => day.day === "Pazartesi" && day.is_visible
      );
    }
    const shiftStart = todayShift
      ? todayShift.shift_start.split(":")
      : ["09", "00"];
    const shiftEnd = todayShift
      ? todayShift.shift_end.split(":")
      : ["18", "00"];

    const nonWorkingDays = workingDayDetails
      .filter((day) => !day.is_visible)
      .map((day) => days.indexOf(day.day));

    const nextWorkingDay = getNextWorkingDay(
      addDays(new Date(), 1),
      nonWorkingDays
    );

    setExcludedDates(
      nonWorkingDays.map((dayIndex) => {
        const diff = dayIndex - todayIndex;
        const offset = diff >= 0 ? diff : diff + 7;
        return addDays(new Date(), offset);
      })
    );

    return {
      startHour: parseInt(shiftStart[0], 10),
      startMinute: parseInt(shiftStart[1], 10),
      endHour: parseInt(shiftEnd[0], 10),
      endMinute: parseInt(shiftEnd[1], 10),
      nextWorkingDay,
      nonWorkingDays,
    };
  };

  const calculateTotalDaysAndReturnDateTime = (
    startDateTime,
    endDateTime,
    nonWorkingDays
  ) => {
    const todayIndex = new Date().getDay(); // 0: Pazar, 1: Pazartesi, ..., 6: Cumartesi
    const days = [
      "Pazar",
      "Pazartesi",
      "Salı",
      "Çarşamba",
      "Perşembe",
      "Cuma",
      "Cumartesi",
    ];
    const workingDayDetails =
      currentUser?.working_days?.working_day_details || [];

    const todayName = days[todayIndex];
    const todayShift = workingDayDetails.find(
      (day) => day.day === todayName && day.is_visible
    );

    const shiftStart = todayShift
      ? todayShift.shift_start.split(":")
      : ["09", "00"];
    const shiftEnd = todayShift
      ? todayShift.shift_end.split(":")
      : ["18", "00"];

    const startDate = new Date(startDateTime);
    const endDate = new Date(endDateTime);
    const startMinutesFromNine =
      (startDate.getHours() - 9) * 60 + startDate.getMinutes();
    const endMinutesFromNine =
      (endDate.getHours() - 9) * 60 + endDate.getMinutes();
    const rules = [
      [[0, 30], 0.06],
      [[31, 60], 0.11],
      [[61, 90], 0.17],
      [[91, 120], 0.22],
      [[121, 150], 0.28],
      [[151, 180], 0.33],
      [[181, 210], 0.39],
      [[211, 240], 0.44],
      [[241, 270], 0.5],
      [[271, 300], 0.56],
      [[301, 330], 0.61],
      [[331, 360], 0.67],
      [[361, 390], 0.72],
      [[391, 420], 0.78],
      [[421, 450], 0.83],
      [[451, 480], 0.89],
      [[481, 510], 0.94],
      [[511, 540], 1.0],
    ];

    const totalMinutes = endMinutesFromNine - startMinutesFromNine;
    let totalDays = 0;
    for (let [minuteRange, duration] of rules) {
      if (totalMinutes >= minuteRange[0] && totalMinutes <= minuteRange[1]) {
        totalDays = duration;
        break;
      }
    }

    if (totalDays === undefined || totalDays === 0) {
      totalDays = 1.0;
    }

    let dayCount = 0;
    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      if (!nonWorkingDays.includes(currentDate.getDay())) {
        dayCount++;
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }

    let returnDateTime = new Date(endDateTime);
    if (totalDays >= 1) {
      returnDateTime.setDate(returnDateTime.getDate() + 1);
    }
   
// Mesai saatlerinin dışında ise ertesi günün mesai başlangıcına ayarla
if (
  endDateTime.getHours() >= shiftEnd[0] ||
  (endDateTime.getHours() === shiftEnd[0] && endDateTime.getMinutes() >= shiftEnd[1])
) {
  // Mesai saatlerinden sonra ise, ertesi günün mesai başlangıç saatine ayarla
  returnDateTime = addDays(new Date(endDateTime), 1);
  returnDateTime.setHours(
    shiftStart[0],
    shiftStart[1],
    0,
    0
  );
} else if (
  endDateTime.getHours() < shiftStart[0] ||
  (endDateTime.getHours() === shiftStart[0] && endDateTime.getMinutes() < shiftStart[1])
) {
  // Mesai saatlerinden önce ise, aynı günün mesai başlangıç saatine ayarla
  returnDateTime.setHours(
    shiftStart[0],
    shiftStart[1],
    0,
    0
  );
}
    returnDateTime = getNextWorkingDay(returnDateTime, nonWorkingDays);

    totalDays = totalDays + (dayCount - 1);

    return {
      totalDays: totalDays.toFixed(2),
      returnDateTime: new Date(returnDateTime),
    };
  };

  useEffect(() => {
    getLeaveTypes();
    getUsers();
    const hours = getWorkingHours();
    setWorkingHours(hours);
  }, [currentUser]);

  useEffect(() => {
    if (
      workingHours.startHour !== undefined &&
      workingHours.endHour !== undefined
    ) {
      setInitialValues((prevValues) => ({
        ...prevValues,
        startDateTime: setHours(
          setMinutes(
            workingHours.nextWorkingDay,
            workingHours.startMinute || 0
          ),
          workingHours.startHour || 9
        ),
        endDateTime: setHours(
          setMinutes(workingHours.nextWorkingDay, workingHours.endMinute || 0),
          workingHours.endHour || 18
        ),
        returnDateTime: getNextWorkingDay(
          setHours(
            setMinutes(
              workingHours.nextWorkingDay,
              workingHours.startMinute || 0
            ),
            workingHours.startHour || 9
          ),
          workingHours.nonWorkingDays
        ),
      }));
    }
  }, [workingHours]);

  const changeApproved = (e) => {
    setIsApproved(e.target.checked);
  };

  const changeSendMail = (e) => {
    setIsSendMail(e.target.checked);
  };

  const validationSchema = Yup.object().shape({
    leaveType: Yup.string().required("İzin türü gereklidir"),
    description: Yup.string().required("Açıklama gereklidir"),
    replacementPerson: Yup.string(),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    if (values.endDateTime < values.startDateTime) {
      toast.error("İzin başlangıç tarihi bitiş tarihinden büyük olamaz.");
      return;
    }
   
    const { totalDays, returnDateTime } = calculateTotalDaysAndReturnDateTime(
      values.startDateTime,
      values.endDateTime,
      workingHours.nonWorkingDays
    );
    if(totalDays === "0.00") {
      toast.error("İzin süresi 0'dan büyük olmalıdır.");
      return;
    }
    const finalValues = {
      ...values,
      totalDays,
      returnDateTime,
    };
    var formData = new FormData();
    formData.append("leave_type", finalValues.leaveType);
    formData.append("duration", finalValues.totalDays.toString());
    formData.append(
      "start_datetime",
      `${formatISODateTime(finalValues.startDateTime)}`
    );
    formData.append(
      "end_datetime",
      `${formatISODateTime(finalValues.endDateTime)}`
    );
    formData.append("description", finalValues.description);
    formData.append("replacement_user", finalValues.replacementPerson ?? null);
    formData.append(
      "return_to_work_datetime",
      `${formatISODateTime(finalValues.returnDateTime)}`
    );
    if (isApproved) {
      formData.append("status", "Onaylandı");
    } else {
      formData.append("status", "Onay Bekliyor");
    }
    formData.append("is_approved", isApproved);
    formData.append("user", userId);
    formData.append("company", currentUser?.id);
    formData.append("is_send_mail", isSendMail);

    const response = await post(ENDPOINTS.LEAVE, formData);

    if (response.status === 201) {
      toast.success("İzin talebiniz başarıyla oluşturuldu");
      onClose();
      onRefresh();
    } else {
      toast.error("İzin talebi oluşturulurken bir hata oluştu");
    }
    setSubmitting(false);
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={modalStyle}>
    <div className="mx-auto rounded-lg bg-white p-7 shadow-lg dark:bg-navy-700 dark:text-white">
      <button
        onClick={onClose}
        className="absolute top-0 right-0 m-2 text-2xl font-semibold"
      >
        ×
      </button>
      <h2 className="mb-4 text-center text-xl font-semibold text-gray-800 dark:text-white">
        İzin Talep Formu
      </h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values, setFieldValue, isSubmitting }) => (
          <Form className="space-y-4">
            {/* İzin Türü */}
            <div className="w-full">
              <label
                htmlFor="leaveType"
                className="block text-sm font-medium text-gray-700 dark:text-white"
              >
                İzin Türü
              </label>
              <Field
                as="select"
                name="leaveType"
                className="mt-1 block w-full rounded-md border-gray-300 p-1 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 dark:bg-navy-700 dark:text-white"
              >
                <option value="">Seçiniz</option>
                {leaveTypes.map((leaveType) => (
                  <option key={leaveType.id} value={leaveType.id}>
                    {leaveType.name}
                  </option>
                ))}
              </Field>
              <ErrorMessage
                name="leaveType"
                component="div"
                className="text-sm text-red-600"
              />
            </div>

            {/* İzin Süresi */}
            <div className="flex items-center">
              <label
                htmlFor="totalDays"
                className="mr-3 block text-sm font-medium text-gray-700 dark:text-white"
              >
                Toplam Gün
              </label>
              <button
                type="button"
                disabled
                onClick={() =>
                  setFieldValue("totalDays", Math.max(1, values.totalDays - 1))
                }
              >
                -
              </button>
              <Field
                type="number"
                disabled
                name="totalDays"
                className="mx-2 block w-20 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 dark:bg-navy-700 dark:text-white"
              />
              <button
                type="button"
                disabled
                onClick={() => setFieldValue("totalDays", values.totalDays + 1)}
              >
                +
              </button>
              <ErrorMessage
                name="totalDays"
                component="div"
                className="text-sm text-red-600"
              />
            </div>

            {/* Başlangıç ve Bitiş Tarihi */}
            <div className="grid grid-cols-1 gap-4 md:grid-cols-1 xl:grid-cols-1 lg:grid-cols-1 2xl:grid-cols-2">
              <div>
                <label
                  htmlFor="startDateTime"
                  className="block text-sm font-medium text-gray-700 dark:text-white"
                >
                  Başlangıç Tarihi
                </label>
                <LeaveFormikDateTimePicker
                  name="startDateTime"
                  value={values.startDateTime}
                  onChange={(value) => {
                    setFieldValue("startDateTime", value);
                    const { totalDays, returnDateTime } =
                      calculateTotalDaysAndReturnDateTime(
                        value,
                        values.endDateTime,
                        workingHours.nonWorkingDays
                      );
                    setFieldValue("totalDays", totalDays);
                    setFieldValue("returnDateTime", returnDateTime);
                  }}
                  excludeDates={excludedDates}
                />
                <ErrorMessage
                  name="startDateTime"
                  component="div"
                  className="text-sm text-red-600"
                />
              </div>
              <div>
                <label
                  htmlFor="endDateTime"
                  className="block text-sm font-medium text-gray-700 dark:text-white"
                >
                  Bitiş Tarihi
                </label>
                <LeaveFormikDateTimePicker
                  name="endDateTime"
                  value={values.endDateTime}
                  onChange={(value) => {
                    setFieldValue("endDateTime", value);
                    const { totalDays, returnDateTime } =
                      calculateTotalDaysAndReturnDateTime(
                        values.startDateTime,
                        value,
                        workingHours.nonWorkingDays
                      );
                    setFieldValue("totalDays", totalDays);
                    setFieldValue("returnDateTime", returnDateTime);
                  }}
                  excludeDates={excludedDates}
                />
                <ErrorMessage
                  name="endDateTime"
                  component="div"
                  className="text-sm text-red-600"
                />
              </div>
            </div>

            {/* Açıklama */}
            <div>
              <label
                htmlFor="description"
                className="block text-sm font-medium text-gray-700 dark:text-white"
              >
                Açıklama
              </label>
              <Field
                as="textarea"
                name="description"
                className="mt-1 block w-full rounded-md border-gray-300 p-2 text-sm shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 dark:bg-navy-700 dark:text-white"
                placeholder="İzin sebebinizi açıklayınız.."
              />
              <ErrorMessage
                name="description"
                component="div"
                className="text-sm text-red-600"
              />
            </div>

            {/* Yerine Bakacak Kişi */}
            <div>
              <label
                htmlFor="replacementPerson"
                className="block text-sm font-medium text-gray-700 dark:text-white"
              >
                Yerine Bakacak Kişi (Opsiyonel)
              </label>
              <Field
                as="select"
                name="replacementPerson"
                className="mt-1 block w-full rounded-md border-gray-300 p-1 capitalize shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 dark:bg-navy-700 dark:text-white"
              >
                <option value="">Seçiniz</option>
                {users.map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.first_name + " " + user.last_name}
                  </option>
                ))}
              </Field>
              <ErrorMessage
                name="replacementPerson"
                component="div"
                className="text-sm text-red-600"
              />
            </div>

            {/* İşe Dönüş Tarihi ve Saati */}
            <div className="flex justify-between">
              <div className="flex-1">
                <label
                  htmlFor="returnDateTime"
                  className="block text-sm font-medium text-gray-700 dark:text-white"
                >
                  İşe Dönüş Tarihi
                </label>
                <LeaveFormikDateTimePicker
                  name="returnDateTime"
                  value={values.returnDateTime}
                  onChange={(value) => {
                    setFieldValue(
                      "returnDateTime",
                      getNextWorkingDay(value, workingHours.nonWorkingDays)
                    );
                  }}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
                <ErrorMessage
                  name="returnDateTime"
                  component="div"
                  className="text-sm text-red-600"
                />
              </div>
            </div>
            {displayAdminButtons && (
              <>
                <div className="flex items-center">
                  <Switch checked={isApproved} onChange={changeApproved} />
                  <label
                    htmlFor="is_approved"
                    className="ml-2 block text-sm font-medium text-gray-700 dark:text-white"
                  >
                    Onay sürecini atla ve onayla
                  </label>
                </div>
                <div className="flex items-center">
                  <Switch checked={isSendMail} onChange={changeSendMail} />
                  <label
                    htmlFor="is_send_mail"
                    className="ml-2 block text-sm font-medium text-gray-700 dark:text-white"
                  >
                    Çalışana bilgilendirme epostası gönder
                  </label>
                </div>
              </>
            )}

            <button
              type="submit"
              disabled={isSubmitting}
              className="border-transparent text-md flex w-full justify-center rounded-md border bg-indigo-600 py-2 px-4 font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 dark:border-none"
            >
              İzin Talep Et
            </button>
          </Form>
        )}
      </Formik>
    </div>
    </Modal>
  );
};

export default NewLeave;
