const calculateDuration = (startDateTime, endDateTime) => {
    let startDate = new Date(startDateTime);
    let endDate = new Date(endDateTime);

    if (startDate > endDate) {
        return "Geçersiz tarih aralığı. Başlangıç tarihi bitiş tarihinden sonra olamaz.";
    }

    const workDayStart = 9; // 09:00
    const lunchBreakStart = 13; // 13:00
    const lunchBreakEnd = 14; // 14:00
    const workDayEnd = 18; // 18:00

    // Eğer etkinlik aynı gün içinde ise
    if (startDate.toDateString() === endDate.toDateString()) {
        const startHour = startDate.getHours();
        const endHour = endDate.getHours();
        const startMinutes = startDate.getMinutes();
        const endMinutes = endDate.getMinutes();

        let hours = endHour - startHour;
        let minutes = endMinutes - startMinutes;
        if (minutes < 0) {
            hours -= 1;
            minutes += 60;
        }

        // Eğer etkinlik tam olarak 09:00'dan 18:00'a kadar ise, 1 iş günü olarak hesapla
        if (startHour === workDayStart && endHour === workDayEnd && startMinutes === 0 && endMinutes === 0) {
            return "1g";
        } else {
            return hours + "s " + minutes + "d";
        }
    }

    let durationInDays = 0;

    while (startDate < endDate) {
        let currentDayEnd = new Date(startDate);
        currentDayEnd.setHours(workDayEnd, 0, 0, 0);

        if (currentDayEnd > endDate) {
            currentDayEnd = endDate;
        }

        let workHours = currentDayEnd.getHours() - startDate.getHours();
        let workMinutes = currentDayEnd.getMinutes() - startDate.getMinutes();

        if (startDate.getHours() < lunchBreakEnd && currentDayEnd.getHours() >= lunchBreakStart) {
            workHours -= 1; // 1 saat öğle molası çıkar
        }

        // Tam gün hesapla
        if (workHours >= 8) {
            durationInDays += 1;
        } else if (workHours >= 4) {
            durationInDays += 0.5;
        }

        startDate.setDate(startDate.getDate() + 1);
        startDate.setHours(workDayStart, 0, 0, 0);
    }

    return `${durationInDays}g`;
};

export default calculateDuration;
