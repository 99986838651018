import { format } from "date-fns";
import { FaCheck, FaTimes } from "react-icons/fa";
import { aidTypes } from "utils/constants";
import formatDate from "utils/formatDate";
import { getUnitNamesByType } from "utils/unitsHelper";
import ExpensePaidStatusToggle from "../components/ExpensePaidStatusToggle";
import store from "store";
import LeaveSignedStatusToggle from "../components/LeaveSignedStatusToggle";

const getIsAdminRole = () => {
  const currentState = store.getState();
  return currentState.auth.currentUser?.role?.name === "CompanyAdmin";
};

const isAdminRole = getIsAdminRole();

let positionColumsData = [
  {
    Header: "ID",
    accessor: "id",
    show: false,
  },
  {
    Header: "Başlangıç",
    accessor: (row) => {
      return row.is_default === true
        ? formatDate(row?.start_date) + " (Varsayılan)"
        : formatDate(row?.start_date);
    },
    show: true,
  },
  {
    Header: "Bitiş",
    accessor: (row) =>
      row.end_date ? formatDate(row?.end_date) : "Devam ediyor",
    show: true,
  },
  {
    Header: "Çalışma Şekli",
    accessor: "mode_of_operation.name",
    show: true,
  },
  {
    Header: "Yönetici",
    accessor: "manager.username",
    show: true,
  },
  // type_code'a göre dinamik sütunlar
  {
    Header: "Holding",
    accessor: (row) => getUnitNamesByType(row.unit, "holding"),
    show: true,
  },
  {
    Header: "Şirket",
    accessor: (row) => getUnitNamesByType(row.unit, "company"),
    show: true,
  },
  {
    Header: "Şube",
    accessor: (row) => getUnitNamesByType(row.unit, "branch"),
    show: true,
  },
  {
    Header: "Departman",
    accessor: (row) => getUnitNamesByType(row.unit, "department"),
    show: true,
  },
  {
    Header: "Ünvan",
    accessor: (row) => getUnitNamesByType(row.unit, "title"),
    show: true,
  },
];

let salaryColumsData = [
  {
    Header: "ID",
    accessor: "id",
    show: false,
  },
  {
    Header: "Geçerlilik Tarihi",
    accessor: (row) => formatDate(row.effective_on),
    show: true,
  },
  {
    Header: "Tutar",
    accessor: (row) => {
      return row.amount + " " + row.currency?.name + "/" + row.pay_period?.name;
    },
    show: true,
  },
  {
    Header: "Maaş Türü",
    accessor: (row) => {
      return row.is_gross ? "Brüt" : "Net";
    },
    show: true,
  },
  // {
  //   Header: "Ek Ödemeler",
  //   accessor: "manager.username",
  //   show: true,
  // },
];

const leaveColumsData = (refreshData) => {
  let colums = [
    {
      Header: "Başlangıç",
      accessor: (row) => formatDate(row.start_datetime),
    },
    {
      Header: "Bitiş",
      accessor: (row) => formatDate(row.end_datetime),
    },
    {
      Header: "Süre",
      accessor: (row) => row.duration + " gün",
    },
    {
      Header: "İzin Türü",
      accessor: "leave_type.name",
    },
    {
      Header: "Açıklama",
      accessor: "description",
    },
    {
      Header: "Oluşturma Tarihi",
      accessor: (row) => formatDate(row.create_time),
    },
    {
      Header: "Durum",
      accessor: (row) => (row.status),
    },
  ];
  if (isAdminRole) {
    colums.push(
      {
        Header: "İmzalandı",
        Cell: ({ row }) => (
          <LeaveSignedStatusToggle
            rowId={row.original.id}
            isSigned={row.original.is_signed}
            refreshData={refreshData}
          />
        ),
      },
      // {
      //   Header: "",
      //   accessor: "actions",
      //   show: true,
      // }
    );
  }
  return colums;
};

let timelogColumsData = [
  {
    Header: "Başlangıç",
    accessor: (row) => formatDate(row.start_datetime),
  },
  {
    Header: "Süre",
    accessor: (row) => row.hour + " saat " + row.minute + " dakika",
  },
  {
    Header: "Açıklama",
    accessor: "description",
  },
  {
    Header: "Durum",
    accessor: (row) => row.status,
  },
  {
    Header: "Oluşturma Tarihi",
    accessor: (row) => formatDate(row.create_time),
  },
];

let assetsColumsData = [
  {
    Header: "Kategori",
    accessor: (row) => row.category?.name,
  },
  {
    Header: "Seri Numarası",
    accessor: (row) => row.serial_number,
  },
  {
    Header: "Veriliş Tarihi",
    accessor: (row) => formatDate(row.date_of_issue),
  },
  {
    Header: "İade Tarihi",
    accessor: (row) => formatDate(row.date_of_return),
  },
  {
    Header: "Açıklama",
    accessor: "description",
  },
];


const paymentColumsData = (refreshData) => {
  let columns = [
    {
      Header: "Tür",
      accessor: (row) => {
        return aidTypes[row.type];
      },
    },
    {
      Header: "Tarih",
      accessor: (row) => {
        if (row.type === "expenseAdd") {
          return "";
        }
        return formatDate(row.date);
      },
    },
    {
      Header: "Durum",
      accessor: (row) => row.status,
    },
    {
      Header: "Tutar",
      accessor: (row) => {
        console.log("row", row);
        // Eğer satır tipi expenseAdd ise, dosyaları işle.
        if (row.type === "expenseAdd" && row.files) {
          // Para birimlerine göre gruplama ve toplama işlemi.
          const totals = row.files.reduce((acc, file) => {
            const currency = file.currency?.name || "Unknown"; // Eğer currency.name yoksa, 'Unknown' olarak ayarla.
            if (!acc[currency]) {
              acc[currency] = 0; // Para birimi ilk kez karşılaşıldığında, toplamı 0 olarak başlat.
            }
            acc[currency] += file.amount; // Mevcut para biriminin toplamına miktarı ekle.
            return acc;
          }, {});

          // Toplamı formatla ve string olarak döndür.
          return Object.entries(totals)
            .map(([currency, total]) => `${total} ${currency}`)
            .join(", ");
        }
        // Eğer satır tipi expenseAdd değilse, doğrudan miktarı ve para birimini döndür.
        return `${row.amount} ${row.currency?.name}`;
      },
    },
    {
      Header: "Brüt/Net",
      accessor: (row) => (row.is_gross ? "Brüt" : "Net"),
    },
    {
      Header: "Açıklama",
      accessor: (row) => row.description,
    },
    {
      Header: "Oluşturulma Tarihi",
      accessor: (row) => formatDate(row.create_time),
    },
  ];
  if (isAdminRole) {
    columns.push(
      {
        Header: "Ödendi Mi?",
        // accessor: (row) => (row.is_paid ? (<FaCheck />) : (<FaTimes />)),
        Cell: ({ row }) => (
          <ExpensePaidStatusToggle
            rowId={row.original.id}
            isPaid={row.original.is_paid}
            refreshData={refreshData}
          />
        ),
      },
    );
  }
  return columns;
};

let trainingColumnsData = [
  {
    Header: "Eğitim",
    accessor: (row) => row.training?.name,
  },
  {
    Header: "Durumu",
    accessor: (row) => row.status,
  },
  {
    Header: "Tamamlanma Tarihi",
    accessor: (row) => formatDate(row.completed_date),
  },
  {
    Header: "Değerlendirme",
    accessor: (row) => row.rating,
  },
  {
    Header: "Açıklama",
    accessor: "description",
  },
];

let userFilesColumnsData = [
  {
    Header: "Dosya Adı",
    accessor: (row) => {
      return (
        <a
          href={row.file}
          target="_blank"
          rel="noreferrer"
          className="text-blue-500"
        >
          {row.name}
        </a>
      );
    },
  },
  {
    Header: "Oluşturulma Tarihi",
    accessor: (row) => formatDate(row.create_time),
  },
];

export {
  salaryColumsData,
  leaveColumsData,
  timelogColumsData,
  assetsColumsData,
  paymentColumsData,
  trainingColumnsData,
  userFilesColumnsData,
  positionColumsData,
};
