import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { get } from "utils/api";
import { ENDPOINTS } from "utils/constants";
import { post } from "utils/api";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { put } from "utils/api";
import InputField from "components/fields/InputField";
import TextField from "components/fields/TextField";

const NewExpenseCategory = ({ userId, onClose, onRefresh, dataId }) => {
  const currentUser = useSelector((state) => state.auth.currentUser);
  const [isEditMode, setIsEditMode] = useState(false);
  const [formData, setFormData] = useState(null);
  useEffect(() => {
    if (dataId) {
      setIsEditMode(true);
      fetchData();
    } else {
      setIsEditMode(false);
      setDefaultValues();
    }
  }, [dataId]);

  const fetchData = async () => {
    const response = await get(
      `${ENDPOINTS.EXPENSES_CATEGORY}${dataId}/?ordering=-create_time`
    );

    const data = await response;
    setFormData({
      name: data.name,
      is_active: data.is_active,
      description: data.description,
    });
  };
  const setDefaultValues = () => {
    setFormData({
      name: "",
      description: "",
      is_active: true,
    });
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Harcama kategorisi adı zorunludur"),
    description: Yup.string().required("Açıklama zorunludur"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    const data = {
      name: values.name,
      description: values.description,
      is_active: values.is_active,
      user: userId,
      company: currentUser?.company?.id,
    };

    let response;
    if (isEditMode) {
      response = await put(`${ENDPOINTS.EXPENSES_CATEGORY}${dataId}/`, data);
    } else {
      response = await post(ENDPOINTS.EXPENSES_CATEGORY, data);
    }

    if (response.status === 201 || response.status === 200) {
      toast.success(
        `Harcama kategorisi başarıyla ${
          isEditMode ? "güncellendi" : "oluşturuldu"
        }`
      );
      onClose();
      onRefresh();
    } else {
      toast.error(
        `Harcama kategorisi ${
          isEditMode ? "güncellenirken" : "oluşturulurken"
        } bir hata oluştu`
      );
    }
    setSubmitting(false);
  };
  if (!formData) return <div>Yükleniyor...</div>;

  return (
    <div className="mx-auto  rounded-lg bg-white p-7 shadow-lg dark:bg-navy-700 dark:text-white">
      <button
        onClick={onClose}
        className="absolute top-0 right-0 m-2 text-2xl font-semibold"
      >
        ×
      </button>
      <h2 className="mb-4 text-center text-xl font-semibold text-gray-800 dark:text-white">
        {isEditMode ? "Harcama Kategorisi Düzenle" : "Harcama Kategorisi Ekle"}
      </h2>
      <Formik
        initialValues={formData}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ isSubmitting, handleChange, handleBlur, values }) => (
          <Form className="space-y-4">
            <div className="-mx-2 flex flex-wrap">
              <div className="mb-4 w-full px-2">
                <InputField
                  label="Kategori Adı"
                  id="name"
                  name="name"
                  type="text"
                  placeholder="Kategori Adı"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                />
              </div>
            </div>
            <div className="-mx-2 flex flex-wrap">
              <div className="mb-4 w-full px-2">
                <TextField
                  label="Açıklama"
                  id="description"
                  name="description"
                  type="text"
                  placeholder="Açıklama giriniz"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.description}
                />
              </div>
            </div>
            <div className="flex justify-end space-x-2">
              {/* <button
                type="button"
                onClick={() => setIsOpenModal(false)}
                className="rounded-md bg-gray-300 px-4 py-2 text-sm text-gray-700 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
              >
                İptal
              </button> */}
              <button
                type="submit"
                disabled={isSubmitting}
                className="rounded-md bg-blue-600 px-4 py-2 text-sm text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                Kaydet
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default NewExpenseCategory;
