// useHasPermission.js
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
const useHasRole = (userRole) => {
    const navigate = useNavigate();
    const location = useLocation();
  
    useEffect(() => {
      const defaultRoute = '/app/default';
      // Erişim izni olan rotaları tanımla
      const employeeRoutes = ["/app/calisanlar", "app/parola-degistir", "/app/dosyalar"];
  
      const canAccess = () => {
        if (userRole?.name === "CompanyAdmin") {
          return true;
        } else if (userRole?.name === "Employee") {
          return employeeRoutes.some(route => location.pathname.includes(route));
        }
        return false;
      };
  
      // Kullanıcı zaten güvenli bir rotadaysa veya hedef rotada ise yönlendirme yapma
      if (!canAccess() && location.pathname !== defaultRoute) {
        navigate(defaultRoute);
      }
    }, [userRole, location, navigate]);
  };
export default useHasRole;

