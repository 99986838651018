import InputField from "components/fields/InputField";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { FaArrowLeft, FaEllipsisV, FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import Modal from "react-modal";

import { get } from "utils/api";
import { ENDPOINTS } from "utils/constants";
import { post } from "utils/api";
import toast from "react-hot-toast";
import Dropdown from "components/dropdown";
import { del } from "utils/api";
import { put } from "utils/api";
import { patch } from "utils/api";
import useCustomModalStyles from "utils/useCustomModalStyles";
const EmploymentTerminationReason = () => {
  const modalStyle = useCustomModalStyles();

  const [isEdit, setIsEdit] = useState(false);
  const [editingItem, setEditingItem] = useState({ id: null, name: "" });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [datas, setDatas] = useState([]);
  const handleOpenModal = (
    isEditing = false,
    item = { id: null, name: "" }
  ) => {
    setIsEdit(isEditing);
    setEditingItem(item);
    setIsModalOpen(true);
  };
  const handleCloseModal = () => setIsModalOpen(false);

  const editHandle = (name, id) => {
    handleOpenModal(true, { id, name });
  };

  const deleteHandle = async (title, id) => {
    const result = window.confirm(`${title} silmek istediğinize emin misiniz?`);
    if (result) {
      const response = await del(ENDPOINTS.EMPLOYMENTTERMINATIONREASON, id);
      if (response.status === 204) {
        getDatas();
        toast.success("İşten ayrılma nedeni başarıyla silindi");
      } else {
        toast.error("Bir hata oluştu");
      }
    }
  };

  const editSubmit = async (values) => {
    var formData = new FormData();
    formData.append("name", values.name);
    const response = await patch(
      ENDPOINTS.EMPLOYMENTTERMINATIONREASON + values.id + "/",
      formData
    );
    if (response.status === 200) {
      getDatas();
      handleCloseModal();
      toast.success("İşten ayrılma nedeni başarıyla düzenlendi");
    } else {
      toast.error("Bir hata oluştu");
    }
  };
  const getDatas = async () => {
    const response = await get(ENDPOINTS.EMPLOYMENTTERMINATIONREASON);
    setDatas(response.results);
  };
  useEffect(() => {
    getDatas();
  }, []);

  const handleSubmit = async (values) => {
    var formData = new FormData();
    formData.append("name", values.name);
    const response = await post(
      ENDPOINTS.EMPLOYMENTTERMINATIONREASON,
      formData
    );
    if (response.status === 201) {
      getDatas();
      handleCloseModal();
      toast.success("İşten ayrılma nedeni başarıyla eklendi");
    } else {
      toast.error("Bir hata oluştu");
    }
  };
  const dropdownButton = <FaEllipsisV className="cursor-pointer" />;

  return (
    <div className="bg-white dark:bg-navy-800 dark:text-white">
      <div className="flex items-center justify-between bg-white p-4 shadow dark:bg-navy-800 dark:text-white">
        <div className="flex items-center">
          <Link to={"/app/ayarlar/kurallar"}>
            {" "}
            <FaArrowLeft className="mr-2 cursor-pointer text-xl" />
          </Link>
          <span className="text-lg font-semibold">
            Ayarlar / İşten Ayrılma Nedenleri
          </span>
        </div>
        <button
          onClick={() => handleOpenModal()}
          className="flex items-center rounded bg-blue-500 py-2 px-4 text-white hover:bg-blue-700"
        >
          <FaPlus className="col-span-1" />
          <span className="col-span-1 ml-1">Yeni Ekle</span>
        </button>
      </div>
      <div className="p-1 pt-8">
        <div className="rounded-lg shadow">
          <table className="w-full">
            <thead className="border-b-2 border-gray-200 bg-gray-50 dark:bg-navy-800 dark:text-white">
              <tr>
                <th className="px-6 py-3 text-left text-sm font-medium uppercase tracking-wider text-gray-500">
                  İşten Ayrılma Nedenleri
                </th>
                <th className="relative px-6 py-3">
                  <span className="sr-only">Düzenle</span>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white dark:bg-navy-700 dark:text-white">
              {/* Örnek satır */}
              {datas.map((data) => (
                <tr>
                  <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900  dark:text-gray-100">
                    {data.name}
                  </td>
                  <td className="whitespace-nowrap px-6 py-4 text-right text-sm font-medium">
                    <div className="flex justify-end">
                      <Dropdown button={dropdownButton} classNames="right-0">
                        <div className="rounded bg-white p-2 shadow-lg dark:bg-navy-700 dark:text-white">
                          <ul className="text-sm">
                            <li
                              onClick={() => editHandle(data.name, data.id)}
                              className="cursor-pointer p-2 hover:bg-gray-100 dark:hover:bg-navy-800"
                            >
                              Düzenle
                            </li>
                            <li
                              onClick={() => deleteHandle(data.name, data.id)}
                              className="cursor-pointer p-2 hover:bg-gray-100 dark:hover:bg-navy-800"
                            >
                              Sil
                            </li>
                          </ul>
                        </div>
                      </Dropdown>
                    </div>
                  </td>
                </tr>
              ))}

              {/* Diğer satırlar benzer şekilde eklenir */}
            </tbody>
          </table>
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => {
          setIsModalOpen(false);
          setIsEdit(false);
          setEditingItem({ id: null, name: "" }); // Modal kapatıldığında editingItem'ı sıfırla
        }}
        style={modalStyle}
        contentLabel={isEdit ? "Süreci Düzenle" : "Yeni Süreç Ekle"}
        ariaHideApp={false}
      >
        <Formik
          initialValues={{ id:editingItem.id, name: editingItem.name }}
          onSubmit={(values, { resetForm }) => {
            if (isEdit) {
              editSubmit(values);
            } else {
              handleSubmit(values);
            }
            resetForm();
            setIsModalOpen(false);
            setIsEdit(false);
            setEditingItem({ id: null, name: "" }); // İşlem sonrası editingItem'ı sıfırla
          }}
        >
          {({ isSubmitting, handleChange, handleBlur, values }) => (
            <Form className="space-y-4">
              <div className="-mx-2 flex flex-wrap">
                <div className="mb-4 w-full px-2">
                  <InputField
                    label="İşten Ayrılma Nedeni"
                    id="name"
                    name="name"
                    type="text"
                    placeholder="Neden giriniz"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                  />
                </div>
              </div>

              <div className="flex justify-end space-x-2">
                <button
                  type="button"
                  onClick={() => setIsModalOpen(false)}
                  className="rounded-md bg-gray-300 px-4 py-2 text-sm text-gray-700 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                >
                  İptal
                </button>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="rounded-md bg-blue-600 px-4 py-2 text-sm text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  {isEdit ? "Kaydet" : "Ekle"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default EmploymentTerminationReason;
