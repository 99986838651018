import React, { useEffect, useState } from "react";
import { ErrorMessage, useField, useFormikContext } from "formik";
import { NumericFormat } from "react-number-format";
import { fetchCurrency } from "services/shareService";

// Özel Input bileşeni tanımı
const CustomInput = React.forwardRef(({ state,allowEmptyFormatting,isNumericString, ...props }, ref) => (
  <input
    ref={ref}
    {...props}
    className={`mt-2 flex  h-12 w-full items-center justify-center rounded-xl border bg-white dark:bg-navy-700 p-3 text-sm outline-none`}
  />
));

const CurrencyField = ({
  label,
  decimalSeparator = ",",
  thousandSeparator = ".",
  disabled = false,
  ...props
}) => {
  const { setFieldValue,values  } = useFormikContext();
  const [field, meta] = useField(props);
  const [currencyList, setCurrencyList] = useState([]);

  const state = meta.touched ? (meta.error ? "error" : "success") : "";

  useEffect(() => {
    const getCurrencyList = async () => {
      const response = await fetchCurrency();
      setCurrencyList(response);
      // Eğer initialValues içinde currency değeri ayarlanmadıysa, burada ilk değeri set edebilirsiniz
      if (!values.currency && response.length > 0) {
        setFieldValue("currency", response[0].id.toString());
      }
    };
    getCurrencyList();
  }, [setFieldValue, values.currency]);
  return (
    <div>
      <label  className={`text-sm text-navy-700  dark:text-white ml-3 font-bold`}htmlFor={props.id || props.name}>{label}</label>
      <div className="flex items-center">
        <NumericFormat
          {...field}
          {...props}
          onValueChange={(values) => {
            setFieldValue(props.name, values.value);
          }}
          thousandSeparator={thousandSeparator}
          decimalSeparator={decimalSeparator}
          isNumericString={true}
          allowNegative={false}
          allowEmptyFormatting={true}
          decimalScale={2}
          fixedDecimalScale={true}
          customInput={CustomInput}
          disabled={disabled}
          state={state} // `state` prop'unu `CustomInput`'a geçir
        />
        <select
          className="dark:bg-navy-700 dark:text-white ml-2 h-12 rounded-md border p-2 mt-2 text-sm outline-none"
          onChange={(e) => {
            const selectedCurrency = currencyList.find(currency => currency.id.toString() === e.target.value);
            setFieldValue("currency", selectedCurrency ? selectedCurrency.id.toString() : '');
            setFieldValue("currencyName", selectedCurrency ? selectedCurrency.name : '');
          }}
          value={values.currency || ''}
          disabled={disabled}
        >
          {currencyList.map((currency) => (
            <option key={currency.id} value={currency.id}>
              {currency.name}
            </option>
          ))}
        </select>
      </div>
      {meta.touched && meta.error ? (
        <ErrorMessage name={props.name} component="div" className="text-red-600 text-sm" />
      ) : null}
    </div>
  );
};

export default CurrencyField;
