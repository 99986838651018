import { format } from 'date-fns';
import { tr } from "date-fns/locale";
export const holidayCategoryColumsData = [
  {
    Header: 'id',
    accessor: 'id',
    show:false,
  },
  // {
  //   Header: 'Create Time',
  //   accessor: (row) => {
  //     const create_time = new Date(row.create_time);

  //     const formattedDate = format(create_time, 'dd-MM-yyyy');

  //     return `${formattedDate}`;
  //   },
  // },
  {
    Header: 'Adı',
    accessor: 'name',
    show:true,
  },
];
export const trainingSettingsColumnsData = [
  {
    Header: 'id',
    accessor: 'id',
    show:false,
  },
  {
    Header: 'Eğitim Adı',
    accessor: 'name',
    show:true,
  },
  {
    Header: 'Eğitim Veren Kurum',
    accessor: 'instructor_company_details',
    show:true,
  },
  {
    Header: 'Eğitmen',
    accessor: 'instructor_name',
    show:true,
  },
  {
    Header: 'Geçerlilik Süresi (Ay)',
    accessor: 'repeat_frequency',
    show:true,
  },
  {
    Header: 'Başlangıç Tarihi',
    accessor: (row) => {
      const startDate = new Date(row.start_datetime);

      const formattedStartDate = format(startDate, "d MMMM yyyy HH:mm", {
        locale: tr,
      });

      return `${formattedStartDate}`;
    },
    show:true,
  },
  {
    Header: 'Bitiş Tarihi',
    accessor: (row) => {
      const endDate = new Date(row.end_datetime);

      const formattedEndDate = format(endDate, "d MMMM yyyy HH:mm", { locale: tr });

      return `${formattedEndDate}`;
    },
    show:true,
  },
  {
    Header: 'Verildiği Yer',
    accessor: 'location',
    show:true,
  },
  {
    Header: 'Detaylar',
    accessor: 'description',
    show:true,
  },
  {
    Header: "",
    id: "actions",
    accessor: "actions",
  },
]