import React from "react";
import {
  MdCheckBox,
  MdDateRange,
  MdLineStyle,
  MdAssignment,
  MdExitToApp,
} from "react-icons/md";
import { Link } from "react-router-dom";

const Rules = () => {
  const cardData = [
    {
      icon: <MdCheckBox />,
      title: "Onay Süreçleri",
      description: "Taleplerde kullanılacak onay süreçleri oluşturun.",
      link: "/app/ayarlar/kurallar/onay-surecleri",
    },
    {
      icon: <MdDateRange />,
      title: "Çalışma Takvimleri",
      description: "Çalışma günlerini ve saatlerini belirleyin.",
      link: "/app/ayarlar/kurallar/calisma-zamanlari",
    },
    // {
    //   icon: <MdLineStyle />,
    //   title: "Vize Süreçleri",
    //   description:
    //     "Vize belgesi taleplerinde kullanılacak süreçleri oluşturun.",
    //   link: "/app/ayarlar/kurallar/vize-surecleri",
    // },
    {
      icon: <MdAssignment />,
      title: "Zimmet Kategorileri",
      description:
        "Çalışana zimmet atanırken seçilmesi gereken kategorileri tanımlayın.",
      link: "/app/ayarlar/kurallar/zimmet-kategorileri",
    },
    {
      icon: <MdExitToApp />,
      title: "İşten Ayrılma Nedenleri",
      description:
        "Çalışan işten çıkarılırken seçilmesi gereken nedenleri tanımlayın.",
      link: "/app/ayarlar/kurallar/isten-ayrilma-nedenleri",
    },
  ];
  return (
    <div className="container mx-auto px-4">
      <div className="flex flex-wrap -mx-2">
        {cardData.map((card, index) => (
          <div key={index} className="mb-4 w-full md:w-1/2 px-2">
            <div className="flex items-center space-x-4 rounded-lg border p-4">
              <div className="text-lg text-blue-500">{card.icon}</div>
              <div className="flex-1">
                <div className="font-bold">{card.title}</div>
                <p>{card.description}</p>
              </div>
              <Link
                to={card.link}
                className="text-blue-500 transition duration-300 hover:text-blue-600"
              >
                Yönet
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Rules;
