import Card from "components/card";
import Progress from "components/progress";
import React, { useEffect, useMemo, useState } from "react";
import {
  MdAir,
  MdAirlineSeatFlat,
  MdAirplanemodeActive,
  MdCheckCircle,
  MdRefresh,
} from "react-icons/md";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { useAppSelector } from "store";
import { get } from "utils/api";
import { ENDPOINTS } from "utils/constants";
import formatDate from "utils/formatDate";
import { momentTime } from "utils/helpers";

function MyLeaves(props) {
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const [leaveUseData, setLeaveUseData] = useState([]);
  const { columnsData, tableData } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    tableInstance;

  const getLeaveUseData = async () => {
    const response = await get(
      ENDPOINTS.LEAVE_PERIOD + `?user__id=${currentUser?.id}`
    );
    setLeaveUseData(response?.results[0]);
  };
  useEffect(() => {
    getLeaveUseData();
  }, []);
  return (
    <Card extra={"h-[400px] w-full"} droppableId="MyLeaves" index="2">
      {/* Top Creator Header */}
      <div className="flex h-fit w-full items-center justify-between rounded-t-2xl px-4 pt-4 pb-[20px]">
        <div className="flex items-center">
          <MdAirplanemodeActive className="mr-2 text-left text-lg" />
          <div>
            <h4 className="text-lg font-bold text-navy-700 dark:text-white">
              İzin Bilgilerim
            </h4>
            <p className="text-sm text-gray-600 dark:text-white">Yıllık İzin</p>
          </div>
        </div>
        {/* <button
          disabled={true}
          onClick={() => getLeaveUseData()}
          className="linear rounded-[20px] bg-lightPrimary px-4 py-2 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
        >
          <MdRefresh className="mr-2 text-lg" />
        </button> */}
      </div>

      {/* Top Creator Heading */}
      <div className="w-full overflow-x-scroll px-4 md:overflow-x-hidden">
        <h4 className="ml-2 mb-1 text-xl text-blue-600 dark:text-white">
          {leaveUseData?.balance || 0} gün
        </h4>
        <Progress />
        <table
          {...getTableProps()}
          className="mt-3 w-full min-w-[200px] overflow-x-scroll"
        >
          {/* <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={index}
                  >
                    <div className="flex items-center justify-between pt-4 pb-2 text-start uppercase tracking-wide text-gray-600 sm:text-xs lg:text-xs">
                      {column.render("Header")}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead> */}

          <tbody {...getTableBodyProps()} className="px-4">
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let cellData = cell.render("Cell");
                    if (cell.column.Header === "Avatar") {
                      cellData = (
                        <div className="flex items-center gap-2">
                          <div className="rounded-full">
                            <MdCheckCircle
                              className={`text-lg ${cell.value}`}
                            />
                          </div>
                        </div>
                      );
                    } else if (cell.column.Header === "LeaveDay") {
                      cellData = (
                        <p className="text-sm font-medium text-gray-700 dark:text-white">
                          {cell.value} g
                        </p>
                      );
                    } else if (cell.column.Header === "StartDateTime") {
                      cellData = (
                        <p className="text-sm font-medium text-navy-700 dark:text-white">
                          {formatDate(cell?.value, false)}
                        </p>
                      );
                    } else {
                      cellData = (
                        <p className="text-sm font-medium text-navy-700 dark:text-white">
                          {cell.value}
                        </p>
                      );
                    }
                    return (
                      <td
                        className="py-2 text-sm"
                        {...cell.getCellProps()}
                        key={index}
                      >
                        {cellData}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Card>
  );
}

export default MyLeaves;
