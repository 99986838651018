import React, { useEffect, useRef, useState } from "react";
import {
  FaAviato,
  FaEdit,
  FaInfo,
  FaInfoCircle,
  FaUpload,
} from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { CDN_URL } from "utils/constants";
import formatDate from "utils/formatDate";
import { getRandomColor } from "utils/helpers";
import { getInitials } from "utils/helpers";
import { getRoleName } from "utils/helpers";
import ProfileContactModal from "./EmployeeDetailModals/ProfileContactModal";
import ProfileSetManagerModal from "./EmployeeDetailModals/ProfileSetManagerModal";
import { getInitialsAndBackgroundColor } from "utils/helpers";
import { useAppSelector } from "store";
import ProfileSetUnitManagerModal from "./EmployeeDetailModals/ProfileSetUnitManagerModal";
import { patch } from "utils/api";
import { ENDPOINTS } from "utils/constants";
import { post } from "utils/api";
import { useDispatch } from "react-redux";
import { setCurrentUser } from "store/authSlice";
import toast from "react-hot-toast";
import { get } from "utils/api";
import calculateWorkingDays from "utils/calculateWorkingDays";
import { Tooltip } from "react-tooltip";
import ProfileNameModal from "./EmployeeDetailModals/ProfileNameModal";
import ProfileJoinedDateModal from "./EmployeeDetailModals/ProfileJoinedDateModal";
const Profile = ({ data, onRefresh }) => {
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const { id } = useParams();
  const currentUserRoleName = useAppSelector(
    (state) => state.auth.currentUser?.role?.name === "CompanyAdmin"
  );
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenManagerModal, setIsOpenManagerModal] = useState(false);
  const [isOpenContactModal, setIsOpenContactModal] = useState(false);
  const [isOpenSetUnitModal, setIsOpenSetUnitModal] = useState(false);
  const [isOpenSetNameModal, setIsOpenSetNameModal] = useState(false);
  const [isOpenSetJoinedDate, setIsOpenSetJoinedDateModal] = useState(false);
  const { initials, backgroundColor } = getInitialsAndBackgroundColor(
    data?.first_name + " " + data?.last_name
  );
  const [isHovering, setIsHovering] = useState(false);
  const [positionData, setPositionData] = useState([]);
  const [positionUnitData, setPositionUnitData] = useState([]);
  const getPostionUnits = async () => {
    const response = await get(ENDPOINTS.POSITIONS_UNITS + `${id}/`);
    setPositionUnitData(response);
  };

  const getPositions = async () => {
    const response = await get(
      ENDPOINTS.POSITIONS + `?user__id=${id}&is_default=true`
    );
    setPositionData(response.results);
  };
  useEffect(() => {
    getPositions();
    getPostionUnits();
  }, [id]);
  const fileInputRef = useRef(null);

  const handleAvatarClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    console.log(file);
    const formData = new FormData();
    const formDataImage = new FormData();
    formDataImage.append("image", file);

    setIsLoading(true);
    setIsHovering(true);
    try {
      const response = await post(ENDPOINTS.IMAGES, formDataImage);
      if (response.status === 201) {
        formData.append("avatar", response.data.id);
        const result = await patch(ENDPOINTS.USERS + data?.id + "/", formData);
        if (result.status === 200) {
          onRefresh();
          toast.success("Profil resmi başarıyla güncellendi.");
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      setIsHovering(false);
    }
  };
  const calculateWorkDays = (start_date) => {
    const startDate = new Date(start_date);
    const currentDate = new Date();

    let years = currentDate.getFullYear() - startDate.getFullYear();
    let months = currentDate.getMonth() - startDate.getMonth();
    let days = currentDate.getDate() - startDate.getDate();

    // Gün farkı negatifse, bir önceki ayın gün sayısını ekleyin ve ay farkını azaltın
    if (days < 0) {
      const previousMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        0
      ).getDate();
      days += previousMonth;
      months--;
    }

    // Ay farkı negatifse, bir önceki yılın ay sayısını ekleyin ve yıl farkını azaltın
    if (months < 0) {
      months += 12;
      years--;
    }

    let result = "";
    if (years > 0) {
      result += years + (years === 1 ? " yıl " : " yıl ");
    }
    if (months > 0) {
      result += months + (months === 1 ? " ay " : " ay ");
    }
    if (days > 0) {
      result += days + (days === 1 ? " gün" : " gün");
    }

    return result.trim();
  };

  return (
    <div className="container mx-auto p-5">
      <div className="-mx-3 flex flex-wrap">
        {/* Sol Taraftaki Kartlar */}
        <div className="mb-6 w-full px-3 lg:w-7/12">
          {/* Kişisel Bilgiler Kartı */}
          <div className="mb-6 rounded-lg border bg-white p-4 shadow dark:bg-navy-800 dark:text-white">
          
            <div className="mb-4 flex items-center">
              {/* Avatar and Names */}
              <div
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
                onClick={handleAvatarClick}
                className={`relative flex h-20 w-20 items-center justify-center rounded-full text-xl text-white ${
                  data?.avatar ? "bg-white" : "bg-blue-400"
                }`}
                style={{ cursor: "pointer" }}
              >
                {data?.avatar?.image ? (
                  <img
                    src={CDN_URL + data?.avatar?.image}
                    className="mb-3 h-20 w-20 rounded-full"
                    alt={`${data?.first_name} avatar`}
                  />
                ) : (
                  <div
                    className="mb-3 flex h-20 w-20 items-center justify-center rounded-full"
                    style={{ backgroundColor: backgroundColor }}
                  >
                    <span className="text-xl font-bold text-white">
                      {initials}
                    </span>
                  </div>
                )}
                {isHovering && currentUserRoleName && (
                  <div className="bg-black absolute inset-0 flex items-center justify-center rounded-full bg-opacity-75">
                    <FaEdit className="text-white" size="1.5em" />
                  </div>
                )}
              </div>
              {currentUserRoleName && (
                <input
                  ref={fileInputRef}
                  type="file"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
              )}

              <div className="ml-4">
                <div className="text-lg font-semibold capitalize">
                  {data?.first_name} {data?.last_name} 
                  {/* {currentUserRoleName && (
                <button
                  onClick={() => setIsOpenSetNameModal(true)}
                  className="rounded py-1 px-3 text-sm text-blue-500"
                >
                  <FaEdit />
                </button>
              )} */}
                </div>
                <div className="text-sm text-gray-500">
                  {data?.title?.name ?? "-"}
                </div>
                
              </div>
            </div>

            {/* Job Details */}
            <div className="flex justify-between">
              <div className="pl-2">
                {/* Position Start Date and Mode of Operation */}
                <div className="pt-2 text-sm font-semibold text-gray-700">
                  İşe Başlama Tarihi
                  {currentUserRoleName && (
                <button
                  onClick={() => setIsOpenSetJoinedDateModal(true)}
                  className="rounded py-1 px-3 text-sm text-blue-500"
                >
                  <FaEdit />
                </button>
              )}
                </div>
                <div className="text-sm text-gray-600">
                  {data?.joined_date
                    ? formatDate(data?.joined_date ?? "-")
                    : "-"}
                </div>
                <div className="pt-2 text-sm font-semibold text-gray-700">
                  Pozisyona Başlama Tarihi
                </div>
                {positionData.length > 0 ? (
                  <div className="text-sm text-gray-600">
                    {formatDate(positionData[0].start_date ?? "-")}
                  </div>
                ) : (
                  <div
                    className="text-sm text-gray-600 "
                    data-tooltip-id="position-tooltip"
                    data-tooltip-content="Kariyer>Pozisyon menüsünden bir pozisyon girmelisiniz ve varsayılan olarak işaretlemelisiniz."
                  >
                    -
                    <Tooltip style={{ width: "250px" }} id="position-tooltip" />
                  </div>
                )}
                <div className="pt-2 text-sm font-semibold text-gray-700">
                  Çalışma Şekli
                </div>
                {positionData.length > 0 ? (
                  <div className="text-sm text-gray-600">
                    {positionData[0].mode_of_operation?.name ?? "-"}
                  </div>
                ) : (
                  <div
                    className="text-sm text-gray-600"
                    data-tooltip-id="position-tooltip"
                    data-tooltip-content="Kariyer>Pozisyon menüsünden bir pozisyon girmelisiniz ve 'Varsayılan' olarak işaretlemelisiniz."
                  >
                    -
                  </div>
                )}
                <div className="pt-2 text-sm font-semibold text-gray-700">
                  Çalışma Süresi
                </div>
                {positionData.length > 0 ? (
                  <div className="text-sm text-gray-600">
                    {calculateWorkDays(positionData[0].start_date)}
                  </div>
                ) : (
                  <div className="text-sm text-gray-600">-</div>
                )}
              </div>

              <div className="mr-5 max-w-[250px]">
                {/* Company, Branch, Department, Title */}
                {positionUnitData && (
                  <>
                    <div className="pt-2 text-sm font-semibold text-gray-700">
                      Şirket
                    </div>
                    <div className="text-sm text-gray-600 ">
                      {positionUnitData.company?.map((item) => item.name) ??
                        "-"}
                    </div>
                    <div className="pt-2 text-sm font-semibold text-gray-700">
                      Şube
                    </div>
                    <div className="text-sm text-gray-600">
                      {positionUnitData.branch?.map((item) => item.name) ?? "-"}
                    </div>
                    <div className="pt-2 text-sm font-semibold text-gray-700">
                      Departman
                    </div>
                    <div className="text-sm text-gray-600">
                      {positionUnitData.department?.map((item) => item.name) ??
                        "-"}
                    </div>
                    <div className="pt-2 text-sm font-semibold text-gray-700">
                      Ünvan
                    </div>
                    <div className="text-sm text-gray-600">
                      {positionUnitData.title?.map((item) => item.name) ?? "-"}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          {/* Yönettiği Birimler Kartı */}
          <div className="mb-6 rounded-lg border bg-white p-4  shadow dark:bg-navy-800 dark:text-white">
            <div className="mb-4 flex items-center justify-between">
              <h2 className="text-lg font-semibold">Yönettiği Birimler</h2>
              {currentUserRoleName && (
                <button
                  onClick={() => setIsOpenSetUnitModal(true)}
                  className="rounded border border-blue-500 py-1 px-3 text-sm text-blue-500"
                >
                  <i className="fas fa-edit mr-1"></i> Birime Ata
                </button>
              )}
            </div>
            {/* Birimler Listesi */}
            <div>
              {data?.units_managed?.length > 0 ? (
                <ul className="text-sm text-gray-600">
                  {data?.units_managed?.map((unit) => (
                    <li key={unit.id} className="mb-2">
                      {unit.name}
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="text-sm text-gray-600">
                  Çalışanın henüz yönettiği birim bulunmuyor.
                </p>
              )}
            </div>
          </div>

          {/* Astları Kartı */}
          {/* <div className="rounded-lg bg-white p-4 shadow dark:bg-navy-800 dark:text-white">
            <div className="mb-4 text-lg font-semibold">Astları</div>
            <p className="text-sm text-gray-600">
              Çalışanın henüz astı bulunmuyor.
            </p>
          </div> */}
        </div>

        {/* Sağ Taraftaki Kartlar */}
        <div className="mb-6 w-full px-3 lg:w-5/12">
          {/* Yönetici Bilgileri Kartı */}
          <div className="mb-6 rounded-lg border bg-white p-4 shadow dark:bg-navy-800 dark:text-white">
            <div className="mb-4 flex items-center justify-between">
              <h2 className="text-lg font-semibold">Yöneticisi</h2>
              {currentUserRoleName && (
                <button
                  onClick={() => setIsOpenManagerModal(true)}
                  className="rounded py-1 px-3 text-lg text-blue-500"
                >
                  <FaEdit />
                </button>
              )}
            </div>
            <div>
              {data?.manager ? (
                <p className="text-sm capitalize text-gray-600">
                  <Link
                    to={`/app/calisanlar/${data?.manager?.id}`}
                    target="_blank"
                  >
                    {" "}
                    {data?.manager?.first_name} {data?.manager?.last_name}
                  </Link>
                </p>
              ) : (
                <p className="text-sm text-gray-600">
                  Henüz bir yöneticisi yok.
                </p>
              )}
            </div>
          </div>

          {/* İletişim Bilgileri Kartı */}
          <div className="mb-6 rounded-lg border bg-white p-4 shadow dark:bg-navy-800 dark:text-white">
            <div className="mb-4 flex items-center justify-between">
              <h2 className="text-lg font-semibold">İletişim</h2>
              {currentUserRoleName && (
                <button
                  onClick={() => setIsOpenContactModal(true)}
                  className="rounded py-1 px-3 text-lg text-blue-500"
                >
                  <FaEdit />
                </button>
              )}
            </div>
            <div className="text-sm font-semibold text-gray-700">
              E-Posta (İş)
            </div>
            <div className="text-sm text-gray-600">{data?.email ?? "-"}</div>
            <div className="pt-2 text-sm font-semibold text-gray-700">
              Telefon (İş)
            </div>
            <div className="text-sm text-gray-600">{data?.phone ?? "-"}</div>

            <div className="pt-2 text-sm font-semibold text-gray-700">
              E-Posta (Kişisel)
            </div>
            <div className="text-sm text-gray-600">
              {data?.email_personal ?? "-"}
            </div>
            <div className="pt-2 text-sm font-semibold text-gray-700">
              Telefon (Kişisel)
            </div>
            <div className="text-sm text-gray-600">
              {data?.phone_personal ?? "-"}
            </div>
          </div>

          {/* Erişim Bilgileri Kartı */}

          <div className="rounded-lg border bg-white p-4 shadow dark:bg-navy-800 dark:text-white">
            <div className="mb-4 text-lg font-semibold">Erişim</div>
            <div className="text-sm font-semibold text-gray-700">
              Erişim Tipi
            </div>
            <div className="text-sm text-gray-600">
              {getRoleName(data?.role?.name ?? "-")}
            </div>
            <div className="pt-2 text-sm font-semibold text-gray-700">
              Son Giriş Zamanı
            </div>
            <div className="text-sm text-gray-600">-</div>
          </div>
        </div>
      </div>
      {isOpenManagerModal && (
        <ProfileSetManagerModal
          isOpen={isOpenManagerModal}
          onClose={() => setIsOpenManagerModal(false)}
          onRefresh={() => {
            onRefresh();
          }}
          userId={data?.id}
          currentData={data}
        />
      )}
      {isOpenContactModal && (
        <ProfileContactModal
          isOpen={isOpenContactModal}
          onClose={() => setIsOpenContactModal(false)}
          onRefresh={() => {
            onRefresh();
          }}
          userId={data?.id}
          currentData={data}
        />
      )}
       {isOpenSetNameModal && (
        <ProfileNameModal
          isOpen={isOpenSetNameModal}
          onClose={() => setIsOpenSetNameModal(false)}
          onRefresh={() => {
            onRefresh();
          }}
          userId={data?.id}
          currentData={data}
        />
      )}
      {isOpenSetJoinedDate && (
        <ProfileJoinedDateModal
          isOpen={isOpenSetJoinedDate}
          onClose={() => setIsOpenSetJoinedDateModal(false)}
          onRefresh={() => {
            onRefresh();
          }}
          userId={data?.id}
          currentData={data}
        />
      )}
      {isOpenSetUnitModal && (
        <ProfileSetUnitManagerModal
          isOpen={isOpenSetUnitModal}
          onClose={() => setIsOpenSetUnitModal(false)}
          onRefresh={() => {
            onRefresh();
          }}
          userId={data?.id}
          currentData={data}
        />
      )}
    </div>
  );
};

export default Profile;
