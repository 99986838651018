import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "store";
import { get } from "utils/api";
import { ENDPOINTS } from "utils/constants";
import {
  leaveTypeColumsData,
  leavesColumsData,
} from "../variables/columnsData";
import DataTable from "components/datatable";

import { Form, Formik } from "formik";
import InputField from "components/fields/InputField";
import Modal from "react-modal";
import { post } from "utils/api";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Dropdown from "components/dropdown";
import { FaEllipsisV } from "react-icons/fa";
import { del } from "utils/api";
import useCustomModalStyles from "utils/useCustomModalStyles";

const LeaveRule = () => {
  const modalStyle = useCustomModalStyles();
  const navigate = useNavigate();
  const dropdownButton = <FaEllipsisV className="cursor-pointer" />;
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const [leaveTypeData, setLeaveTypeData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isOpenNewLeaveTypeModal, setIsOpenNewLeaveTypeModal] = useState(false);
  const getLeaveTypes = async () => {
    setLoading(true);

    const response = await get(ENDPOINTS.LEAVE_TYPE + "?ordering=-create_time");

    const updatedData = response?.results.map((data) => ({
      ...data,
      actions: (
        <div className="whitespace-nowrap px-6 py-4 text-right text-sm font-medium">
          <div className="flex items-center justify-end">
            <Dropdown button={dropdownButton} classNames="right-0">
              <div className="rounded bg-white dark:bg-navy-700 dark:text-white p-2 shadow-lg">
                <ul className="text-sm">
                  <li
                    onClick={() => navigate("/app/izinler/izin-kurallari/" + data.id)}
                    className="cursor-pointer p-2 hover:bg-gray-100 dark:hover:bg-navy-600"
                  >
                    Düzenle
                  </li>

                  <li
                    onClick={() => deleteHandle(data.id)}
                    className="cursor-pointer p-2 hover:bg-gray-100 dark:hover:bg-navy-600"
                  >
                    Sil
                  </li>
                </ul>
              </div>
            </Dropdown>
          </div>
        </div>
      ),
    }));
    setLeaveTypeData(updatedData);
    setLoading(false);
  };
  useEffect(() => {
    getLeaveTypes();
  }, []);

  const deleteHandle = async (id) => {
    const result = window.confirm(`Silmek istediğinize emin misiniz?`);
    if (result) {
      const response = await del(ENDPOINTS.LEAVE_TYPE, id);
      if (response.status === 204) {
        getLeaveTypes();
        toast.success("Başarıyla silindi");
      } else {
        toast.error("Bir hata oluştu");
      }
    }
  };

  const handleSubmit = async (values) => {
    var formData = new FormData();
    formData.append("name", values.name);
    const response = await post(ENDPOINTS.LEAVE_TYPE, formData);
    if (response.status === 201) {
      toast.success("Başarıyla eklendi");
      navigate("/app/izinler/izin-kurallari/" + response.data.id);
    } else {
      toast.error("Bir hata oluştu");
    }
  };
  const generateLinkPath = (rowData) => {
    // rowData üzerinden dinamik link yolu oluştur
    return `/app/izinler/izin-kurallari/${rowData.id}`;
  };
  return (
    <div>
      <div className="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-1">
        <div className="mt-5">
          <button
            onClick={() => setIsOpenNewLeaveTypeModal(true)}
            className="rounded bg-blue-500 py-2 px-4 text-sm text-white hover:bg-blue-700"
          >
            Yeni İzin Tipi/Kuralı Ekle
          </button>
        </div>
        <div>
          <DataTable
            columnsData={leaveTypeColumsData}
            tableData={leaveTypeData}
            title="İzin Kuralları"
            hiddenColumns={["id"]}
            linkPath={generateLinkPath}
            linkableColumn="name"
            loading={loading}
          />
        </div>
      </div>
      <Modal
        isOpen={isOpenNewLeaveTypeModal}
        onRequestClose={() => {
          setIsOpenNewLeaveTypeModal(false);
        }}
        style={modalStyle}
        contentLabel={"Yeni İzin Tipi Ekle"}
        ariaHideApp={false}
      >
        <Formik
          initialValues={{ id: "", name: "" }}
          onSubmit={(values, { resetForm }) => {
            handleSubmit(values);
            resetForm();
            setIsOpenNewLeaveTypeModal(false);
          }}
        >
          {({ isSubmitting, handleChange, handleBlur, values }) => (
            <Form className="space-y-4">
              <div className="-mx-2 flex flex-wrap">
                <div className="mb-4 w-full px-2">
                  <InputField
                    label="İzin Tipi Adı"
                    id="name"
                    name="name"
                    type="text"
                    placeholder="İzin Tipi Adı (Örn: Yıllık İzin)"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                  />
                </div>
              </div>

              <div className="flex justify-end space-x-2">
                <button
                  type="button"
                  onClick={() => setIsOpenNewLeaveTypeModal(false)}
                  className="rounded-md bg-gray-300 px-4 py-2 text-sm text-gray-700 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                >
                  İptal
                </button>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="rounded-md bg-blue-600 px-4 py-2 text-sm text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  Kaydet
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default LeaveRule;
