import React from "react";
import Modal from "react-modal";
import { Formik, Form, Field } from "formik";

import { post } from "utils/api"; // API istekleri için
import { ENDPOINTS } from "utils/constants";
import * as Yup from "yup";
import { ErrorMessage } from "formik";
import AsyncSelect from "react-select/async";
import FormikDatePicker from "components/datepicker";
import InputField from "components/fields/InputField";
import CurrencyField from "components/fields/CurrencyField";
import { formatISODate } from "utils/helpers";
import Switch from "components/switch";
import { useAppSelector } from "store";
import toast from "react-hot-toast";
import { aidTypes } from "utils/constants";
import useCustomModalStyles from "utils/useCustomModalStyles";

const AdvanceDebtModal = ({
  isOpen,
  onClose,
  onRefresh,
  userId,
  displayAdminButtons = false,
}) => {
  const modalStyle = useCustomModalStyles();
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const handleSubmit = async (values) => {
    var formData = new FormData();
    let formattedAmount = values.amount.replace(/\./g, "").replace(/,/g, ".");
    let amountValue = parseFloat(formattedAmount).toFixed(2);

    formData.append("amount", amountValue);
    formData.append("date", formatISODate(values.date));
    formData.append("description", values.description);
    formData.append("is_paid", values.is_paid);
    formData.append("is_approved", values.is_approved);
    formData.append("include_bordro", values.include_bordro);
    formData.append("type", "advanceDebt");
    formData.append("user", userId);
    formData.append("company", currentUser?.company?.id);
    formData.append("currency", values.currency.id);
    formData.append("installment_count", values.installment_count);
    formData.append("creator", currentUser?.id);
    if (values.is_approved) {
      formData.append("status", "Onaylandı");
    } else {
      formData.append("status", "Onay Bekliyor");
    }
    const response = await post(ENDPOINTS.EXPENSES, formData);
    if (response.status === 201) {
      toast.success("Harcama kaydı başarılı bir şekilde eklendi");
      onClose();
      onRefresh();
    } else {
      toast.error("Bir hata oluştu");
    }
  };
  const initialValues = {
    amount: 0,
    date: new Date(),
    installment_count: 1,
    description: "",
    is_paid: false,
    is_approved: false,
    include_bordro: true,
    currency: { id: 1, name: "TL" },
  };
  const validationSchema = Yup.object().shape({
    amount: Yup.string().required("Miktar zorunludur"),
    date: Yup.date().required("Tarih zorunludur"),
    description: Yup.string().required("Açıklama zorunludur"),
  });
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={modalStyle}>
      <div className="mx-auto dark:bg-navy-700 dark:text-white rounded-lg bg-white p-6 shadow-lg">
        <button
          onClick={onClose}
          className="absolute top-0 right-0 m-2 text-2xl font-semibold"
        >
          ×
        </button>
        <h2 className="mb-4 text-center text-xl font-semibold dark:text-white text-gray-800">
          Avans / Borç Ekle
        </h2>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({
            values,
            setFieldValue,
            isSubmitting,
            handleBlur,
            handleChange,
          }) => (
            <Form className="space-y-4">
              <div className="flex flex-col md:flex-row md:items-center md:space-x-4">
                <div className="mb-1 flex-1">
                  <CurrencyField
                    label="Tutar"
                    name="amount"
                    onChange={(e) => {
                      setFieldValue("amount", e.target.value);
                    }}
                    onBlur={handleBlur}
                    value={values.amount}
                  />
                </div>
                <div className="flex-1">
                  <label
                    htmlFor="startDate"
                    className="ml-3 mb-1 block text-sm  font-bold text-navy-700 dark:text-white"
                  >
                    Tarih
                  </label>
                  <FormikDatePicker name="date" selected={values.date} />
                  <ErrorMessage
                    name="date"
                    component="div"
                    className="text-sm text-red-600"
                  />
                </div>
              </div>
              <div className="flex flex-col md:flex-row md:items-center md:space-x-4">
                <div className="mb-1 flex-1">
                  <label
                    htmlFor="is_gross"
                    className="ml-3 text-sm font-bold text-navy-700 dark:text-white"
                  >
                    Taksit Sayısı
                  </label>
                  <select
                    name="installment_count"
                    value={values.installment_count}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="dark:bg-navy-700 dark:text-white mt-1 block h-12 w-full rounded-md border border-gray-300 p-2 shadow-sm"
                  >
                    {[...Array(12).keys()].map((i) => (
                      <option key={i + 1} value={i + 1}>
                        {i + 1}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex-1">
                  <InputField
                    name="description"
                    label="Açıklama"
                    type="text"
                    placeholder="Açıklama giriniz.."
                  />
                </div>
              </div>
              {displayAdminButtons && (
                <>
                  <div className="mb-4 flex w-full">
                    <Switch
                      name="is_paid"
                      label="Ödendi"
                      checked={values.is_paid}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  <div className="mb-4 flex w-full">
                    <Switch
                      name="is_approved"
                      label="Onay sürecini atla ve onayla"
                      checked={values.is_approved}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  <div className="mb-4 flex w-full">
                    <Switch
                      name="include_bordro"
                      label="Bordroya dahil et"
                      checked={values.include_bordro}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </>
              )}

              <button
                type="submit"
                disabled={isSubmitting}
                className="dark:border-none border-transparent mt-2 flex w-full justify-center rounded-md border bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Kaydet
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default AdvanceDebtModal;
