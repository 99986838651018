const formatDate = (time, showYear = true) => {
  if(!time) return "";
  var formattedDate = "";
  try {
    const startDate = new Date(time);

    let options = { day: "numeric", month: "short" };
    if (showYear) {
      options.year = "numeric";
    }

    formattedDate = new Intl.DateTimeFormat("tr-TR", options).format(startDate);
  } catch (e) {
    // console.log(e);
  }
  return formattedDate;
};
const formatDateWithTime = (time, showYear = true, showSecond=false) => {
  if (!time) return "";
  var formattedDateTime = "";
  try {
    const dateTime = new Date(time);

    let options = {
      day: "numeric",
      month: "short",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false // 24 saat formatı için
    };
    if (showYear) {
      options.year = "numeric";
    }
    if (!showSecond) {
      delete options.second;
    }

    formattedDateTime = new Intl.DateTimeFormat("tr-TR", options).format(dateTime);
  } catch (e) {
    console.log(e);
  }
  return formattedDateTime;
};

export {formatDateWithTime};
export default formatDate;
