import PieChart from "components/charts/PieChart";
import { pieChartData, pieChartOptions } from "variables/charts";
import Card from "components/card";
import { useEffect, useState } from "react";
import { get } from "utils/api";
import { ENDPOINTS } from "utils/constants";
import toast from "react-hot-toast";
import { useAppSelector } from "store";

const PieChartCard = () => {
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const [unitDatas, setUnitDatas] = useState({ series: [], labels: [] });
  const [selectedCategory, setSelectedCategory] = useState("");
  const [positionUnits, setPositionUnits] = useState([]);
  const [colors, setColors] = useState([]);
  const generateColors = (labels) => {
    // Önceden tanımlanmış renkler (genişletilebilir)
    const baseColors = [
      "#6F2DBD", "#A663CC", "#B298DC", "#FFC857", "#E9724C", "#C5283D",
      "#481D24", "#255F85", "#1995AD", "#A1C6EA", "#8AA29E", "#B2C9AB",
      "#63B4B8", "#D7F2BA", "#ABB880", "#8BAAAD", "#6A4C93", "#FF1654",
      "#247BA0", "#70C1B3"
    ];
    // Bir harf haritası oluşturarak her harfe bir renk atayalım
    const alphabetColors = {};
    const alphabet = 'ABCÇDEFGHIİJKLMNOÖPQRSŞTUÜVWXYZ';
  
    alphabet.split('').forEach((letter, index) => {
      alphabetColors[letter] = baseColors[index % baseColors.length];
    });
  
    // Gelen label listesine göre renkleri atayalım
    const colors = labels.map(label => {
      const firstLetter = label.toUpperCase()[0]; // Label'ın ilk harfini al
      return alphabetColors[firstLetter] || '#333333'; // Harita'da yoksa varsayılan bir renk dön
    });
  
    return colors;
  };

  const defaultOptions = {
    colors: colors,
    chart: {
      width: "100%"
    },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    legend: {
      show: unitDatas.labels.length <= 0,
      position: "bottom",
    },
    dataLabels: {
      enabled: false,
    },
    hover: { mode: null },
    plotOptions: {
      pie: {
        expandOnClick: false,
      },
    },
    fill: {
      colors: colors,
    },
    tooltip: {
      enabled: true,
      theme: "dark",
      style: {
        fontSize: "12px",
        fontFamily: undefined,
        backgroundColor: "#000000"
      },
    },
  };
  const getPositionUnits = async () => {
    try{
      const response = await get(ENDPOINTS.POSITIONS_UNITS+`${currentUser?.id}/`);
      setPositionUnits(response);
       // İlk geçerli kategoriyi seç
       const validCategories = ["branch", "department", "title"];
       const firstValidCategory = validCategories.find(category => category in response);
       setSelectedCategory(firstValidCategory || "");
    }
    catch(error){
      console.log(error);
    }
  };
  useEffect(() => {
    getPositionUnits();
  }, []);
  useEffect(() => {
    const fetchUnitDatas = async () => {
      const response = await get(`${ENDPOINTS.UNIT_REPORTS}?category=${selectedCategory}`);
      const formattedData = response.map(item => item.user_count);
      const labels = response.map(item => item.name);
      setColors(generateColors(labels)); 
      setUnitDatas({ series: formattedData, labels });
    };
    fetchUnitDatas();
  }, [selectedCategory]);


  const options = { ...defaultOptions, labels: unitDatas.labels };
 


  return (
    <Card extra="rounded-[20px] p-3 h-[400px] w-full" droppableId="PieChartCard" index="8">
      <div className="flex flex-row justify-between px-3 pt-2">
        <div>
          <h4 className="text-lg font-bold text-navy-700 dark:text-white">
            Çalışan Dağılımı
          </h4>
        </div>

        <div className="mb-6 flex items-center justify-center">
          <select
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            className="mb-3 mr-1 flex items-center justify-center text-sm font-bold text-gray-600 hover:cursor-pointer dark:!bg-navy-800 dark:text-white"
          >
             {positionUnits.branch && (
              <option value="branch">Şube</option>
            )}
            {positionUnits.department && (
              <option value="department">Departman</option>
            )}
            {positionUnits.title && (
              <option value="title">Ünvan</option>
            )}
          </select>
        </div>
      </div>

      <div className="mb-auto flex h-[220px] w-full items-center justify-center">
      <PieChart options={options} series={unitDatas.series} />

      </div>
      <div className="flex flex-row !justify-between rounded-2xl px-6 py-3">
        <div className="flex flex-col items-center justify-center">
          <div className="flex items-center justify-center">
            <div className="h-2 w-2 rounded-full bg-brand-500" />
            <p className="ml-1 text-sm font-normal text-gray-600">Toplam Çalışan</p>
          </div>
          <p className="mt-px text-xl font-bold text-navy-700  dark:text-white">
            {unitDatas.series.reduce((acc, item) => acc + item, 0)}
          </p>
        </div>

        <div className="h-11 w-px bg-gray-300 dark:bg-white/10" />

        <div className="flex flex-col items-center justify-center">
          <div className="flex items-center justify-center">
            <div className="h-2 w-2 rounded-full bg-[#6AD2FF]" />
            <p className="ml-1 text-sm font-normal text-gray-600">Toplam Birim</p>
          </div>
          <p className="mt-px text-xl font-bold text-navy-700 dark:text-white">
            {unitDatas.labels.length}
          </p>
        </div>
      </div>
    </Card>
  );
};

export default PieChartCard;
