import Checkbox from "components/checkbox";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FaCheckCircle, FaEllipsisV, FaInbox, FaPlus } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { get } from "utils/api";
import { CDN_URL } from "utils/constants";
import { ENDPOINTS } from "utils/constants";
import { getInitialsAndBackgroundColor } from "utils/helpers";
import Modal from "react-modal";
import useCustomModalStyles from "utils/useCustomModalStyles";
import Dropdown from "components/dropdown";
import AsyncSelect from "react-select/async";
import useCustomSelectStyles from "utils/useCustomSelectStyles";
import { post } from "utils/api";
import { patch } from "utils/api";

const UserCard = ({ id, name, email, phone, image, onCheck, checked }) => {
  const { initials, backgroundColor } = getInitialsAndBackgroundColor(name);
  const avatarContent = image ? (
    <img
      src={image}
      className="mb-3 h-24 w-24 rounded-full object-cover"
      alt={`${name} avatar`}
      style={{ minHeight: "96px", minWidth: "96px" }} // Sabit boyut ayarlaması
    />
  ) : (
    <div
      className="mb-3 flex h-24 w-24 items-center justify-center rounded-full text-xl font-bold text-white"
      style={{
        backgroundColor: backgroundColor,
        minHeight: "96px",
        minWidth: "96px",
      }} // Sabit boyut ayarlaması
    >
      {initials}
    </div>
  );

  return (
    <div className="flex items-center gap-4 overflow-hidden rounded-lg bg-white p-4 shadow dark:bg-navy-700 dark:text-white">
      <Checkbox checked={checked} onChange={() => onCheck(id)} />

      {avatarContent}
      <div className="flex flex-col ">
        <span className="capitalize"> {name}</span>
        <span className="truncate text-sm text-gray-600 dark:text-gray-300">
          {email}
        </span>
      </div>
    </div>
  );
};

const Bulk = () => {
  const dropdownButton = <FaEllipsisV className="cursor-pointer" />;
  const bulkDropdownButton = (
    <>
      <div className="flex cursor-pointer items-center gap-1 rounded-lg bg-blue-500 p-3 text-sm text-white hover:bg-blue-600">
        {" "}
        <FaPlus className="cursor-pointer" /> İşlemler
      </div>
    </>
  );
  const customStyles = useCustomModalStyles();
  const selectStyle = useCustomSelectStyles();
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState({});
  const [modalIsOpen, setIsOpen] = useState(false);
  const [currentAction, setCurrentAction] = useState("");
  useEffect(() => {
    const getUsers = async () => {
      try {
        const response = await get(ENDPOINTS.USERS);
        setUsers(response.results);
        setSelectedUsers(
          response.results.reduce(
            (acc, user) => ({ ...acc, [user.id]: false }),
            {}
          )
        );
      } catch (error) {
        toast.error("Çalışanlar yüklenemedi");
        console.log(error);
      }
    };

    getUsers();
  }, []);

  const handleCheck = (id) => {
    setSelectedUsers({ ...selectedUsers, [id]: !selectedUsers[id] });
  };

  const handleCheckAll = () => {
    const allChecked = !Object.values(selectedUsers).every((value) => value);
    setSelectedUsers(
      Object.keys(selectedUsers).reduce(
        (acc, id) => ({ ...acc, [id]: allChecked }),
        {}
      )
    );
  };
  const handleOpenModal = (action) => {
    setCurrentAction(action);
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const loadUserTrainingOptions = async (inputValue) => {
    const response = await get(ENDPOINTS.TRAININGS + "?search=" + inputValue);
    return response.results.map((item) => ({
      label: item.name,
      value: item.id,
    }));
  };

  const loadWorkingDaysOptions = async (inputValue) => {
    const response = await get(
      ENDPOINTS.WORKING_DAYS + "?search=" + inputValue
    );
    return response.results.map((item) => ({
      label: item.name,
      value: item.id,
    }));
  };
  const loadManagerOptions = async (inputValue) => {
    const response = await get(ENDPOINTS.USERS + "?search=" + inputValue);
    return response.results.map((item) => ({
      label: item.first_name + " " + item.last_name,
      value: item.id,
    }));
  };
  const assignWorkingDaysSelect = async (selectedUserIds, workingDayId) => {
    try {
      const payload = {
        user_ids: selectedUserIds,
        working_days_id: workingDayId,
      };

      const response = await post(
        ENDPOINTS.USERS + "assign-working-days/",
        payload
      );
      toast.success("Çalışma takvimi seçili çalışanlara başarıyla atandı!", {
        duration: 4000,
      });
    } catch (error) {
      console.error("Çalışma takvimi atama işlemi başarısız:", error);
      toast.error("Çalışma takvimi atama işlemi başarısız!");
    } finally {
      handleCloseModal();
    }
  };
  const assignManagerSelect = async (selectedUserIds, managerId) => {
    try {
      if (selectedUserIds.includes(managerId.toString())) {
        toast.error("Seçtiğiniz yöneticiyi kendisine atayamazsınız!");
        return;
      }
      const payload = {
        user_ids: selectedUserIds,
        manager_id: managerId,
      };

      const response = await post(ENDPOINTS.USERS + "assign-manager/", payload);
      toast.success("Yönetici seçili çalışanlara başarıyla atandı!", {
        duration: 4000,
      });
    } catch (error) {
      console.error("Yönetici atama işlemi başarısız:", error);
      toast.error("Yönetici atama işlemi başarısız!");
    } finally {
      handleCloseModal();
    }
  };
  const assignSendLoginMail = async (selectedUserIds) => {
    try {
      const payload = {
        user_ids: selectedUserIds,
      };

      const response = await post(ENDPOINTS.SENDMAIL_USERINFO, payload);

      toast.success(
        "Giriş bilgileri seçili çalışanlara başarıyla gönderildi!",
        {
          duration: 4000,
        }
      );
    } catch (error) {
      console.error("Giriş bilgileri gönderme işlemi başarısız:", error);
      toast.error("Giriş bilgileri gönderme işlemi başarısız!");
    } finally {
      handleCloseModal();
    }
  };
  const assignTrainingToSelectedUsers = async (selectedUserIds, trainingId) => {
    try {
      // Örnek bir POST API çağrısı, gerçek uygulamada endpoint ve method farklı olabilir
      const payload = {
        user_ids: selectedUserIds,
        training_id: trainingId,
      };
      console.log("payload", payload);

      const response = await post(
        ENDPOINTS.USER_TRAININGS + "assign-training/",
        payload
      );

      toast.success("Eğitimler seçili çalışanlara başarıyla atandı!", {
        duration: 4000,
      });
    } catch (error) {
      console.error("Eğitim atama işlemi başarısız:", error);
      toast.error("Eğitim atama işlemi başarısız!");
    } finally {
      handleCloseModal();
    }
  };
  const ActionForms = ({ action }) => {
    switch (action) {
      case "assignTraining":
        return (
          <Formik
            initialValues={{ training: "" }}
            onSubmit={async (values, { setSubmitting }) => {
              // Seçili kullanıcıların ID'lerini topla
              const selectedUserIds = Object.entries(selectedUsers)
                .filter(([_, checked]) => checked)
                .map(([id, _]) => id);

              if (values.training && selectedUserIds.length > 0) {
                await assignTrainingToSelectedUsers(
                  selectedUserIds,
                  values.training.id
                );
              } else {
                toast.error(
                  "Eğitim seçimi yapılmadı veya hiç kullanıcı seçilmedi."
                );
              }
              setSubmitting(false);
            }}
            enableReinitialize={true}
          >
            {(formikProps) => (
              <Form>
                <div className="space-y-4">
                  <div className="flex justify-between">
                    <div className="flex-1">
                      <label
                        htmlFor="training"
                        className="block text-sm font-medium text-gray-700 dark:text-white"
                      >
                        Eğitim
                      </label>
                      <AsyncSelect
                        styles={selectStyle}
                        cacheOptions
                        defaultOptions
                        loadOptions={loadUserTrainingOptions}
                        onChange={(option) =>
                          formikProps.setFieldValue("training", {
                            id: option.value,
                            name: option.label,
                          })
                        }
                        name="training"
                        isClearable
                        placeholder="Seçiniz"
                        value={
                          formikProps.values.training
                            ? {
                                value: formikProps.values.training.id,
                                label: formikProps.values.training.name,
                              }
                            : null
                        }
                      />
                      <ErrorMessage
                        name="training"
                        component="div"
                        className="text-sm text-red-600"
                      />
                    </div>
                  </div>
                  <div className="flex justify-end space-x-2">
                    <button
                      type="button"
                      onClick={() => {
                        formikProps.resetForm();
                        handleCloseModal();
                      }}
                      className="rounded-md bg-gray-300 px-4 py-2 text-sm text-gray-700 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                    >
                      İptal
                    </button>
                    <button
                      type="submit"
                      disabled={formikProps.isSubmitting}
                      className="rounded-md bg-blue-600 px-4 py-2 text-sm text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                      Kaydet
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        );
      case "assignWorkingDays":
        return (
          <Formik
            initialValues={{ working_days: "" }}
            onSubmit={async (values, { setSubmitting }) => {
              const selectedUserIds = Object.entries(selectedUsers)
                .filter(([_, checked]) => checked)
                .map(([id, _]) => id);

              if (values.working_days && selectedUserIds.length > 0) {
                await assignWorkingDaysSelect(
                  selectedUserIds,
                  values.working_days.id
                );
              } else {
                toast.error(
                  "Çalışma takvimi seçimi yapılmadı veya hiç kullanıcı seçilmedi."
                );
              }
              setSubmitting(false);
            }}
            enableReinitialize={true}
          >
            {(formikProps) => (
              <Form>
                <div className="space-y-4">
                  <div className="flex justify-between">
                    <div className="flex-1">
                      <label
                        htmlFor="working_days"
                        className="block text-sm font-medium text-gray-700 dark:text-white"
                      >
                        Çalışma Takvimi
                      </label>
                      <AsyncSelect
                        styles={selectStyle}
                        cacheOptions
                        defaultOptions
                        loadOptions={loadWorkingDaysOptions}
                        onChange={(option) =>
                          formikProps.setFieldValue("working_days", {
                            id: option.value,
                            name: option.label,
                          })
                        }
                        name="working_days"
                        isClearable
                        placeholder="Seçiniz"
                        value={
                          formikProps.values.working_days
                            ? {
                                value: formikProps.values.working_days.id,
                                label: formikProps.values.working_days.name,
                              }
                            : null
                        }
                      />
                      <ErrorMessage
                        name="working_days"
                        component="div"
                        className="text-sm text-red-600"
                      />
                    </div>
                  </div>
                  <div className="flex justify-end space-x-2">
                    <button
                      type="button"
                      onClick={() => {
                        formikProps.resetForm();
                        handleCloseModal();
                      }}
                      className="rounded-md bg-gray-300 px-4 py-2 text-sm text-gray-700 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                    >
                      İptal
                    </button>
                    <button
                      type="submit"
                      disabled={formikProps.isSubmitting}
                      className="rounded-md bg-blue-600 px-4 py-2 text-sm text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                      Kaydet
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        );
      case "assignManager":
        return (
          <Formik
            initialValues={{ manager: "" }}
            onSubmit={async (values, { setSubmitting }) => {
              const selectedUserIds = Object.entries(selectedUsers)
                .filter(([_, checked]) => checked)
                .map(([id, _]) => id);

              if (values.manager && selectedUserIds.length > 0) {
                await assignManagerSelect(selectedUserIds, values.manager.id);
              } else {
                toast.error(
                  "Yönetici seçimi yapılmadı veya hiç kullanıcı seçilmedi."
                );
              }
              setSubmitting(false);
            }}
            enableReinitialize={true}
          >
            {(formikProps) => (
              <Form>
                <div className="space-y-4">
                  <div className="flex justify-between">
                    <div className="flex-1">
                      <label
                        htmlFor="manager"
                        className="block text-sm font-medium text-gray-700 dark:text-white"
                      >
                        Yönetici
                      </label>
                      <AsyncSelect
                        styles={selectStyle}
                        cacheOptions
                        defaultOptions
                        loadOptions={loadManagerOptions}
                        onChange={(option) =>
                          formikProps.setFieldValue("manager", {
                            id: option.value,
                            name: option.label,
                          })
                        }
                        name="manager"
                        isClearable
                        placeholder="Seçiniz"
                        value={
                          formikProps.values.manager
                            ? {
                                value: formikProps.values.manager.id,
                                label: formikProps.values.manager.name,
                              }
                            : null
                        }
                      />
                      <ErrorMessage
                        name="manager"
                        component="div"
                        className="text-sm text-red-600"
                      />
                    </div>
                  </div>
                  <div className="flex justify-end space-x-2">
                    <button
                      type="button"
                      onClick={() => {
                        formikProps.resetForm();
                        handleCloseModal();
                      }}
                      className="rounded-md bg-gray-300 px-4 py-2 text-sm text-gray-700 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                    >
                      İptal
                    </button>
                    <button
                      type="submit"
                      disabled={formikProps.isSubmitting}
                      className="rounded-md bg-blue-600 px-4 py-2 text-sm text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                      Kaydet
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        );
      case "assignSendLoginMail":
        return (
          <Formik
            initialValues={{ placeholderValue: "" }}
            onSubmit={async (values, { setSubmitting }) => {
              const selectedUserIds = Object.entries(selectedUsers)
                .filter(([_, checked]) => checked)
                .map(([id, _]) => id);

              if (selectedUserIds.length > 0) {
                await assignSendLoginMail(selectedUserIds);
              } else {
                toast.error("Hiç kullanıcı seçilmedi.");
              }
              setSubmitting(false);
            }}
            enableReinitialize={true}
          >
            {(formikProps) => (
              <Form>
                <div className="space-y-4">
                  <div className="text-sm font-medium text-gray-700 dark:text-white">
                    Giriş bilgileri gönderilecek kullanıcılar:
                  </div>
                  <ul>
                    {Object.entries(selectedUsers)
                      .filter(([_, checked]) => checked)
                      .map(([id, _]) => (
                        <li
                          key={id}
                          className="text-sm capitalize text-navy-800 dark:text-white"
                        >
                          {users.find((user) => user.id.toString() === id)
                            .first_name +
                            " " +
                            users.find((user) => user.id.toString() === id)
                              .last_name}
                        </li>
                      ))}
                  </ul>
                  <div className="flex justify-end space-x-2">
                    <button
                      type="button"
                      onClick={() => {
                        formikProps.resetForm();
                        handleCloseModal();
                      }}
                      className="rounded-md bg-gray-300 px-4 py-2 text-sm text-gray-700 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                    >
                      İptal
                    </button>
                    <button
                      type="submit"
                      disabled={formikProps.isSubmitting}
                      className="rounded-md bg-blue-600 px-4 py-2 text-sm text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                      {formikProps.isSubmitting ? "Gönderiliyor..." : "Gönder"}
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        );

      default:
        return <div>Bir işlem seçiniz</div>;
    }
  };
  return (
    <div className="p-8">
      <div className="mb-4 flex gap-4">
        <button
          className="flex items-center rounded-lg bg-red-500 px-4 py-2 text-sm text-white"
          onClick={handleCheckAll}
        >
          <FaCheckCircle className="mr-2" /> Tümünü Seç
        </button>
        <div className="w-1/2 items-center">
          <Dropdown button={bulkDropdownButton} className="right-0">
            <div className="mt-11 rounded-xl bg-white bg-navy-700 p-2 text-white shadow-lg">
              <ul className="text-sm">
                <li
                  onClick={() => handleOpenModal("assignWorkingDays")}
                  className="cursor-pointer p-2 hover:bg-navy-900"
                >
                  Çalışma Takvimi Ata
                </li>
                <li
                  onClick={() => handleOpenModal("assignTraining")}
                  className="cursor-pointer p-2 hover:bg-navy-900"
                >
                  Eğitim Ata
                </li>
                {/* <li
                  onClick={() => handleOpenModal("assignExpense")}
                  className="cursor-pointer p-2 hover:bg-navy-900"
                >
                  Ek Ödeme Ekle
                </li>
                <li
                  onClick={() => handleOpenModal("assignTimelog")}
                  className="cursor-pointer p-2 hover:bg-navy-900"
                >
                  Fazla Mesai Ekle
                </li> */}
                <li
                  onClick={() => handleOpenModal("assignSendLoginMail")}
                  className="cursor-pointer p-2 hover:bg-navy-900"
                >
                  Giriş Bilgilerini Gönder
                </li>
                <li
                  onClick={() => handleOpenModal("assignManager")}
                  className="cursor-pointer p-2 hover:bg-navy-900"
                >
                  Yönetici Ata
                </li>
              </ul>
            </div>
          </Dropdown>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-4">
        {users.map((user) => (
          <UserCard
            key={user.id}
            id={user.id}
            name={user?.first_name + " " + user?.last_name}
            email={user?.email}
            image={user?.avatar?.image ? CDN_URL + user.avatar.image : ""}
            phone={user?.phone}
            onCheck={handleCheck}
            checked={selectedUsers[user.id]}
          />
        ))}
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={handleCloseModal}
        style={customStyles}
        contentLabel="İşlem Modalı"
        ariaHideApp={false}
      >
        <div className="p-1">
          <button
            onClick={handleCloseModal}
            className="absolute top-0 right-0 m-2 text-lg font-semibold"
          >
            X
          </button>
          <h2 className="text-md mb-10 rounded bg-blue-600 p-2 text-center text-white">
            {(currentAction === "assignTraining" && "Eğitim Ata") ||
              (currentAction === "assignWorkingDays" &&
                "Çalışma Takvimi Ata") ||
              (currentAction === "assignManager" && "Yönetici Ata") ||
              (currentAction === "assignSendLoginMail" &&
                "Giriş Bilgilerini Gönder")}
          </h2>
          <ActionForms action={currentAction} />
        </div>
      </Modal>
    </div>
  );
};

export default Bulk;
