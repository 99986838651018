// ReceiptAddModal.jsx
import React from "react";
import Modal from "react-modal";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FaSave } from "react-icons/fa";
import toast from "react-hot-toast";
import AsyncSelect from "react-select/async";
import { ENDPOINTS } from "utils/constants";
import { get } from "utils/api";

import FileField from "components/fields/FileField";
import FormikDatePicker from "components/datepicker";
import CurrencyField from "components/fields/CurrencyField";
import InputField from "components/fields/InputField";
import useCustomModalStyles from "utils/useCustomModalStyles";
import { useTheme } from "ThemeContext";

const validationSchema = Yup.object().shape({
  // amount: Yup.number().required("Tutar zorunludur").positive("Tutar pozitif olmalıdır"),
  date: Yup.date().required("Tarih zorunludur"),
  tax_rate: Yup.number().required("Vergi oranı zorunludur").positive("Vergi oranı pozitif olmalıdır"),
});

const ReceiptAddModal = ({ isOpen, onClose, onSave }) => {
  const modalStyle = useCustomModalStyles();
  const {isDarkMode} = useTheme();
  const loadCategoryOptions = async (inputValue) => {
    const response = await get(
      ENDPOINTS.EXPENSES_CATEGORY + "?search=" + inputValue
    );
    return response.results.map((item) => ({
      label: item.name,
      value: item.id,
    }));
  };
  const initialValues = {
    file: "",
    expenses_category: "",
    amount: 0,
    date: new Date(),
    tax_rate: 20,
    description: "",
    currency: 1,
    currencyName: "TL",
  };

  const handleSubmit = (values, { resetForm }) => {
    console.log("Submitting form", values); // Bu satırı ekleyin

    onSave(values);
    resetForm();
    onClose();
    toast.success("Fiş başarıyla eklendi");
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={modalStyle}>
      <div className="mx-auto  rounded-lg bg-white p-2 dark:bg-navy-700 dark:text-white shadow-lg">
        <button
          onClick={onClose}
          className="absolute top-0 right-0 m-2 text-4xl font-semibold"
        >
          ×
        </button>
        <h2 className="mb-4 text-center text-2xl font-semibold text-gray-800 dark:text-white">
          Yeni Fiş Ekle
        </h2>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue, handleBlur, handleChange }) => (
            <Form className="space-y-4 p-4">
              <div className="mb-6 rounded-lg">
                <div className="mb-4 grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
                  <div className="col-span-3">
                    <FileField name={`file`} label="Fiş Yükle" accept=".pdf" />
                  </div>
                  <div className="col-span-3">
                    <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                      Kategori
                    </label>
                    <AsyncSelect
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: isDarkMode ? 'rgb(27 37 75 / var(--tw-bg-opacity))' : provided.backgroundColor,
                        borderColor: isDarkMode ? 'white' : provided.borderColor,
                      }),
                      menu: (provided) => ({
                        ...provided,
                        backgroundColor: isDarkMode ? 'rgb(27 37 75 / var(--tw-bg-opacity))' : provided.backgroundColor,
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        color: isDarkMode ? 'white' : 'black',
                        backgroundColor: state.isFocused ? 'lightgray' : isDarkMode ? 'rgb(27 37 75 / var(--tw-bg-opacity))' : 'white',
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: isDarkMode ? 'white' : 'black', // Seçili değerin rengi
                      })
                    }}
                      cacheOptions
                      defaultOptions
                      name={`expenses_category`}
                      loadOptions={loadCategoryOptions}
                      isClearable
                      placeholder="Seçiniz"
                      onChange={(option) =>
                        setFieldValue("expenses_category", {
                          id: option.value,
                          name: option.label,
                        })
                      }
                      value={
                        values.expenses_category
                          ? {
                              value: values.expenses_category.id,
                              label: values.expenses_category.name,
                            }
                          : null
                      }
                    />
                    <ErrorMessage
                      name="expenses_category"
                      component="div"
                      className="text-sm text-red-600"
                    />
                  </div>
                  <div className="col-span-3">
                    <CurrencyField
                      label="Tutar"
                      name="amount"
                      onChange={(e) => {
                        setFieldValue("amount", e.target.value);
                      }}
                      value={values.amount}
                    />
                  </div>
                  <div className="col-span-3">
                    <label className="ml-3 text-sm font-bold text-navy-700">
                        Tarih
                    </label>
                    <br />
                    <FormikDatePicker name="date" selected={values.date} />
                    <ErrorMessage
                      name="date"
                      component="div"
                      className="text-sm text-red-600"
                    />
                  </div>
                  <div className="col-span-3">
                    <select
                      name="tax_rate"
                      id="tax_rate"
                      className="mt-1 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.tax_rate}
                    >
                      <option value="1">1%</option>
                      <option value="5">5%</option>
                      <option value="6">6%</option>
                      <option value="8">8%</option>
                      <option value="10">10%</option>
                      <option value="10">13%</option>
                      <option value="18">18%</option>
                      <option value="20">20%</option>
                      <option value="24">24%</option>
                    </select>
                    <ErrorMessage
                      name="tax_rate"
                      component="div"
                      className="text-sm text-red-600"
                    />
                  </div>
                  <div className="col-span-3 mb-4">
                    <InputField
                      name={`description`}
                      label="Açıklama"
                      placeholder="Açıklama giriniz"
                    />
                  </div>
                </div>
              </div>
              <button
                type="submit"
                className="flex w-full items-center justify-center rounded-md bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
              >
                <FaSave className="mr-2" />
                Kaydet
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default ReceiptAddModal;
