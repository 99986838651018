import Card from "components/card";
import Progress from "components/progress";
import React, { useMemo } from "react";
import { MdAirplanemodeActive } from "react-icons/md";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { momentTime } from "utils/helpers";

function UpcomingLeaves(props) {
  const { columnsData, tableData } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    tableInstance;

  return (
    <Card extra={"h-[400px] w-full"} droppableId="UpcomingLeaves" index="3">
      {/* Top Creator Header */}
      <div className="flex h-fit w-full items-center justify-between rounded-t-2xl px-4 pt-4 pb-[20px]">
        <div className="flex items-center">
          <MdAirplanemodeActive className="mr-2 text-left text-lg" />
          <h4 className="text-lg font-bold text-navy-700 dark:text-white">
            Yaklaşan İzinler
          </h4>
        </div>
      </div>

      {/* Top Creator Heading */}
      <div className="w-full overflow-x-scroll px-4 md:overflow-x-hidden">
        <table
          {...getTableProps()}
          className="w-full min-w-[200px] overflow-x-scroll"
        >
          {/* <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={index}
                  >
                    <div className="flex items-center justify-between pt-4 pb-2 text-start uppercase tracking-wide text-gray-600 sm:text-xs lg:text-xs">
                      {column.render("Header")}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead> */}

          <tbody {...getTableBodyProps()} className="px-4">
            {page.length > 0 ? (
              page.map((row, index) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} key={index}>
                    {row.cells.map((cell, index) => {
                      let cellData = cell.render("Cell");
                      if (cell.column.Header === "Avatar") {
                        if (cell.value.type === "image") {
                          cellData = (
                            <div className="flex items-center gap-2">
                              <div className="h-[30px] w-[30px] rounded-full">
                                <img
                                  src={cell.value.content}
                                  className="h-full w-full rounded-full"
                                  alt=""
                                />
                              </div>
                            </div>
                          );
                        } else {
                          // 'initials' tipi için
                          cellData = (
                            <div className="flex items-center gap-2">
                              <div
                                className="flex h-[30px] w-[30px] items-center justify-center rounded-full"
                                style={{
                                  backgroundColor: cell.value.backgroundColor,
                                }}
                              >
                                <span className="text-sm font-medium text-white">
                                  {cell.value.content}
                                </span>
                              </div>
                            </div>
                          );
                        }
                      } else {
                        cellData = (
                          <p className="text-sm font-medium text-navy-700 dark:text-white">
                            {cell.value}
                          </p>
                        );
                      }
                      return (
                        <td
                          className="py-2 text-sm"
                          {...cell.getCellProps()}
                          key={index}
                        >
                          {cellData}
                        </td>
                      );
                    })}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={columns.length} className="py-5 text-center">
                  <p className="text-sm italic text-gray-500">Kayıt yok</p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Card>
  );
}

export default UpcomingLeaves;
