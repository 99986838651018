import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import React from 'react';
const prepareDataForExcel = (data, columns) => {
  return data.map(row => {
    let newRow = {};
    columns.forEach(column => {
      let value = typeof column.accessor === 'function' ? column.accessor(row) : row[column.accessor];
      let cellValue;

      // Eğer Cell tanımlanmışsa ve bu bir fonksiyon ise
      if (column.Cell && typeof column.Cell === 'function') {
        // Cell fonksiyonunu çalıştırarak sonucu al
        const cellElement = column.Cell({ value, row });

        // Eğer sonuç bir React elementi ise ve içinde metin varsa, metni al
        if (React.isValidElement(cellElement) && typeof cellElement.props.children === 'string') {
          cellValue = cellElement.props.children;
        } else if (typeof cellElement === 'string') { // Eğer sonuç doğrudan string ise
          cellValue = cellElement;
        } else {
          // Diğer durumlar için JSON string olarak kaydet
          cellValue = JSON.stringify(cellElement.props ? cellElement.props.children : cellElement);
        }
      } else {
        cellValue = value;
      }

      newRow[column.Header] = cellValue;
    });
    return newRow;
  });
};
  // Excel'e aktarma işlemi
  const exportToExcel = (columns, data, fileName) => {
    const formattedData = prepareDataForExcel(data, columns);
    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Report");
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
    saveAs(blob, `${fileName}.xlsx`);
  };
 export default exportToExcel;