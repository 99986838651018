import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { FaArrowLeft, FaFileAlt, FaTrash } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { useAppSelector } from "store";
import { del } from "utils/api";
import { post } from "utils/api";
import { get } from "utils/api";
import { ENDPOINTS } from "utils/constants";

const FileView = () => {
  const currentUserRoleName = useAppSelector(
    (state) => state.auth.currentUser?.role?.name === "CompanyAdmin"
  );
  const [files, setFiles] = useState([]);
  const fileInputRef = useRef(null);
  const { folderId } = useParams();
  const navigate = useNavigate();
  const fetchFiles = async () => {
    try {
      const response = await get(
        ENDPOINTS.COMPANY_FILES + "?folder=" + folderId
      );
      setFiles(response.results);
    } catch (error) {
      console.error("Error fetching files: ", error);
    }
  };
  useEffect(() => {
    fetchFiles();
  }, [folderId]);
  const handleFileUploadClick = () => {
    fileInputRef.current.click();
  };
  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append(`file`, file, file.name);
      formData.append("name", file.name);
      formData.append("folder", folderId);
      var addFileId = await post(ENDPOINTS.COMPANY_FILES, formData);
      if (addFileId.status === 201) {
        toast.success("Dosya başarıyla yüklendi");
        fetchFiles();
      }
    }
  };
  const deleteHandle = async (id) => {
    const result = window.confirm(`Silmek istediğinize emin misiniz?`);
    if (result) {
      const response = await del(ENDPOINTS.COMPANY_FILES, id);
      if (response.status === 204) {
        fetchFiles();
        toast.success("Başarıyla silindi");
      } else {
        toast.error("Bir hata oluştu");
      }
    }
  };
  return (
    <div className="text-md mt-2 grid grid-cols-1 gap-5 rounded-xl bg-white p-4 text-navy-700 shadow-lg shadow-gray-400 dark:bg-navy-800 dark:text-white xl:grid-cols-1">
      <div className="flex flex-col gap-4">
        {currentUserRoleName && (
          <div className="flex gap-4">
            <button
              onClick={() => navigate(-1)}
              className="flex items-center justify-center rounded-md bg-navy-500 p-2 text-white hover:bg-navy-600"
            >
              <FaArrowLeft className="mr-2" />
              Geri Dön
            </button>
            <button
              onClick={handleFileUploadClick}
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Yükleyeceğiniz dosyanın türkçe karakter içermediğinden emin olunuz."
              className="flex items-center justify-center rounded-md bg-blue-500 p-2 text-white hover:bg-blue-600"
            >
              Dosya Yükle
            </button>
            <Tooltip place="top" effect="solid" id="my-tooltip" />
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
          </div>
        )}
        {files.length > 0 ? (
          files.map((file) => (
            <div key={file.id} className="flex items-center justify-between p-2 rounded-md hover:bg-gray-100 dark:hover:bg-navy-700">
              <FaFileAlt className="text-blue-500" />
              <a href={file.file} target="_blank" rel="noreferrer" className="flex-1 ml-2 truncate">
                {file.name}
              </a>
              {currentUserRoleName && (
              <FaTrash
                className="cursor-pointer text-red-500 hover:text-red-700"
                onClick={() => deleteHandle(file.id)}
              />
                )}
            </div>
          ))
        ) : (
          <div>Dosya bulunamadı.</div>
        )}
      </div>
    </div>
  );
};

export default FileView;
