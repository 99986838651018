import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "store";
import { get } from "utils/api";
import { ENDPOINTS } from "utils/constants";
import { leavesColumsData } from "../variables/columnsData";
import DataTable from "components/datatable";
import ShowLeave from "../ShowLeave";
const LeaveList = () => {
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const [leaveData, setLeaveData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [statusFilter, setStatusFilter] = useState('Onaylandı');
  const getButtonStyle = (status) => {
    const baseStyle = 'mx-1 px-4 py-2 text-white text-sm rounded focus:outline-none';
    const activeStyle = status === statusFilter ? 'bg-blue-800' : 'bg-blue-500 hover:bg-blue-600';
    return `${baseStyle} ${activeStyle}`;
  };
  const getLeaves = async () => {
    setLoading(true);
    const url = statusFilter 
    ? `${ENDPOINTS.LEAVE}?ordering=-id&status=${statusFilter}`
    : `${ENDPOINTS.LEAVE}?ordering=-id`;
  const response = await get(url);
    setLeaveData(response?.results);
    setLoading(false);
  };
  useEffect(() => {
    getLeaves();
  }, [statusFilter]);
  const renderModalContent = (rowData) => (
    <ShowLeave leave={rowData} />
  );
  return (
    <div>
      {/* Tables & Charts */}
      <div className="flex my-4">
        <button onClick={() => setStatusFilter('Onaylandı')} className={getButtonStyle('Onaylandı')}>Onaylananlar</button>
        <button onClick={() => setStatusFilter('Onay Bekliyor')} className={getButtonStyle('Onay Bekliyor')}>Onay Bekleyenler</button>
        <button onClick={() => setStatusFilter('Reddedildi')} className={getButtonStyle('Reddedildi')}>Reddedilenler</button>
      </div>


      <div className="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-1">
        {/* Check Table */}
        {/* <div className="mt-5 text-right">
          <Link to={'/app/yeni-izin-talebi'} className="rounded bg-blue-500 py-2 px-4 font-bold text-white hover:bg-blue-700">
            Yeni İzin Talebi
          </Link>
        </div> */}

        <div>
          <DataTable
            columnsData={leavesColumsData}
            tableData={leaveData}
            title="İzinler"
            hiddenColumns={["id"]}
            loading={loading}
            modalContent={renderModalContent}
          />
        </div>
      </div>
    </div>
  );
};

export default LeaveList;
