import React, { useEffect, useState } from "react";
import DataTable from "components/datatable";
import { positionColumsData } from "../variables/columnsData";
import { get } from "utils/api";
import { ENDPOINTS } from "utils/constants";
import { useParams } from "react-router-dom";

import Modal from "react-modal";
import FormikDatePicker from "components/datepicker";
import { Field, Form, Formik } from "formik";
import AsyncSelect from "react-select/async";
import { post } from "utils/api";
import toast from "react-hot-toast";
import { formatISODate } from "utils/helpers";
import { useAppSelector } from "store";
import { positionAddValidationSchema } from "../validations/EmployeeValidationSchema";
import Dropdown from "components/dropdown";
import { FaEllipsisV } from "react-icons/fa";
import { del, patch, put } from "utils/api";
import { typeCodeLabels } from "utils/unitsHelper";
import useCustomModalStyles from "utils/useCustomModalStyles";
import useCustomSelectStyles from "utils/useCustomSelectStyles";
import { addDays } from "date-fns";

const Position = () => {
  const modalStyle = useCustomModalStyles();
  const selectStyle = useCustomSelectStyles();
  const dropdownButton = <FaEllipsisV className="cursor-pointer" />;
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const currentUserRoleName = useAppSelector(
    (state) => state.auth.currentUser?.role?.name === "CompanyAdmin"
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const [editingPositionId, setEditingPositionId] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const [predefinedOrder, setPredefinedOrder] = useState([]);
  const [unitOptions, setUnitOptions] = useState({});
  const [modalInitialValues, setModalInitialValues] = useState({
    holding: "",
    company: "",
    branch: "",
    department: "",
    title: "",
    start_date: null,
    end_date: null,
    manager: "",
    mode_of_operation: "",
  });

  const fetchPositionDetails = async (positionId) => {
    const managerData = await loadManagerOptions("");
    const modeOfOperationData = await loadModeOfOperationOptions("");

    const positionToEdit = data.find((item) => item.id === positionId);

    const selectedManager = managerData.find(
      (m) => m.value === positionToEdit?.manager?.id
    );
    const selectedModeOfOperation = modeOfOperationData.find(
      (m) => m.value === positionToEdit?.mode_of_operation?.id
    );

    setModalInitialValues({
      holding:
        positionToEdit?.unit?.find(
          (unit) => unit.unit_type.type_code === "holding"
        )?.id || "",
      company:
        positionToEdit?.unit?.find(
          (unit) => unit.unit_type.type_code === "company"
        )?.id || "",
      branch:
        positionToEdit?.unit?.find(
          (unit) => unit.unit_type.type_code === "branch"
        )?.id || "",
      department:
        positionToEdit?.unit?.find(
          (unit) => unit.unit_type.type_code === "department"
        )?.id || "",
      title: positionToEdit?.unit?.find(
        (unit) => unit.unit_type.type_code === "title"
      )?.id,
      start_date: positionToEdit?.start_date
        ? new Date(positionToEdit?.start_date)
        : null,
      end_date: positionToEdit?.end_date
        ? new Date(positionToEdit.end_date)
        : null,
      manager: selectedManager
        ? { label: selectedManager.label, value: selectedManager.value }
        : null,
      mode_of_operation: selectedModeOfOperation
        ? {
            label: selectedModeOfOperation.label,
            value: selectedModeOfOperation.value,
          }
        : null,
    });
  };

  const handleOpenModal = (positionId = null) => {
    setRefreshTrigger((prev) => prev + 1);

    if (positionId) {
      setEditMode(true);
      setEditingPositionId(positionId);
    } else {
      setEditMode(false);
      setEditingPositionId(null);
      setModalInitialValues({
        holding: "",
        company: "",
        branch: "",
        department: "",
        title: "",
        start_date: null,
        end_date: null,
        manager: "",
        mode_of_operation: "",
      });
    }
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (editingPositionId) {
      fetchPositionDetails(editingPositionId);
    }
  }, [editingPositionId]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setEditMode(false);
    setEditingPositionId(null);
  };

  useEffect(() => {
    const fetchUnitTypesAndUnits = async () => {
      setLoading(true);
      try {
        const response = await get(ENDPOINTS.UNIT_TYPE);
        const unitTypes = response.results || [];

        const optionsByTypeCode = {};
        const dynamicPredefinedOrder = [];
        unitTypes.forEach((unitType) => {
          const { type_code, units } = unitType;

          optionsByTypeCode[type_code] = units.map((unit) => ({
            label: unit.name,
            value: unit.id,
          }));

          if (currentUser?.company?.is_holding || type_code !== "holding") {
            dynamicPredefinedOrder.push(type_code);
          }
        });

        setUnitOptions(optionsByTypeCode);
        setPredefinedOrder(dynamicPredefinedOrder);
      } catch (error) {
        console.error("Error fetching unit types and units:", error);
      }
      setLoading(false);
    };

    fetchUnitTypesAndUnits();
  }, []);

  const loadModeOfOperationOptions = async (inputValue) => {
    const response = await get(
      ENDPOINTS.MODEOFOPERATION + "?search=" + inputValue
    );
    return response.results.map((item) => ({
      label: item.name,
      value: item.id,
    }));
  };

  const loadManagerOptions = async (inputValue) => {
    const response = await get(ENDPOINTS.USERS + "?search=" + inputValue);
    return response.results.map((item) => ({
      label: item.first_name + " " + item.last_name,
      value: item.id,
    }));
  };

  const setDefaultHandle = async (id) => {
    const response = await patch(ENDPOINTS.POSITIONS + id + "/set-default/", {
      is_default: true,
    });
    if (response.status === 200) {
      getDatas();
      toast.success("Başarıyla varsayılan yapıldı");
    } else {
      toast.error("Bir hata oluştu");
    }
  };

  const deleteHandle = async (id) => {
    const result = window.confirm(`Silmek istediğinize emin misiniz?`);
    if (result) {
      const response = await del(ENDPOINTS.POSITIONS, id);
      if (response.status === 204) {
        getDatas();
        toast.success("Başarıyla silindi");
      } else {
        toast.error("Bir hata oluştu");
      }
    }
  };

  const getDatas = async () => {
    setLoading(true);
    const response = await get(
      ENDPOINTS.POSITIONS + "?user__id=" + id + "&ordering=-create_time"
    );
    const updatedData = response?.results.map((data) => ({
      ...data,
      actions: (
        <div className="whitespace-nowrap px-6 py-4 text-right text-sm font-medium">
          <div className="flex items-center justify-end">
            <Dropdown button={dropdownButton} classNames="right-0">
              <div className="rounded bg-white p-2 shadow-lg dark:bg-navy-700 dark:text-white">
                <ul className="text-sm">
                  <li
                    onClick={() => setDefaultHandle(data.id)}
                    className="cursor-pointer p-2 hover:bg-gray-100 dark:hover:bg-navy-600"
                  >
                    Varsayılan Yap
                  </li>
                  <li
                    onClick={() => handleOpenModal(data.id)}
                    className="cursor-pointer p-2 hover:bg-gray-100 dark:hover:bg-navy-600"
                  >
                    Düzenle
                  </li>
                  <li
                    onClick={() => deleteHandle(data.id)}
                    className="cursor-pointer p-2 hover:bg-gray-100 dark:hover:bg-navy-600"
                  >
                    Sil
                  </li>
                </ul>
              </div>
            </Dropdown>
          </div>
        </div>
      ),
    }));
    setData(updatedData);
    setLoading(false);
  };

  useEffect(() => {
    getDatas();
  }, [refreshTrigger]);

  const initialValues = {
    holding: "",
    company: "",
    branch: "",
    department: "",
    title: "",
    start_date: null,
    end_date: null,
    manager: "",
    mode_of_operation: "",
  };

  const handleSubmit = async (values) => {
    var formData = new FormData();
    var units = [];
    for (var key in values) {
      if (
        key === "holding" ||
        key === "company" ||
        key === "branch" ||
        key === "department" ||
        key === "title"
      ) {
        if (values[key]) {
          units.push(values[key]);
        }
      }
    }
    units.forEach((unit) => {
      formData.append("unit", unit);
    });
    formData.append("user", id);
    formData.append("manager", values.manager?.value);
    formData.append("mode_of_operation", values.mode_of_operation?.value);
    formData.append("company", currentUser?.company?.id);
    if (editMode) {
      formData.append("start_date", formatISODate(values.start_date));
      if (values.end_date) {
        formData.append("end_date", formatISODate(values.end_date));
      }
      console.log("Update position", editingPositionId);
      const response = await put(
        ENDPOINTS.POSITIONS + editingPositionId + "/",
        formData
      );
      if (response.status === 200) {
        handleCloseModal();
        setRefreshTrigger((prev) => prev + 1);
        toast.success("Başarıyla güncellendi");
      } else {
        toast.error("Bir hata oluştu");
      }
    } else {
      formData.append(
        "start_date",
        formatISODate(addDays(values.start_date, 1))
      );
      if (values.end_date) {
        formData.append(
          "end_date",
          formatISODate(addDays(values.start_date, 1))
        );
      }

      const response = await post(ENDPOINTS.POSITIONS, formData);
      if (response.status === 201) {
        handleCloseModal();
        setRefreshTrigger((prev) => prev + 1);
      } else {
        console.log(response);
        toast.error("Bir hata oluştu");
      }
    }
  };

  const columnsDataWithActions = currentUserRoleName
    ? [...positionColumsData, { label: "Eylemler", accessor: "actions" }]
    : positionColumsData;
  return (
    <div className="p-4">
      {currentUserRoleName && (
        <>
          <button
            onClick={() => handleOpenModal()}
            className="mb-2 flex flex-col items-center justify-center rounded-lg bg-blue-500 p-2 text-sm text-white hover:bg-blue-600"
          >
            + Yeni Pozisyon Ekle
          </button>
          <small className="text-gray-500 dark:text-gray-400">
            Yeni bir pozisyon ekledikten sonra pozisyonun geçerli olması için kaydın en sağında bulunan menüden <b>Varsayılan </b> 
            yapmayı unutmayın.
          </small>
        </>
      )}

      <DataTable
        columnsData={columnsDataWithActions}
        tableData={data}
        title="Pozisyonlar"
        hiddenColumns={["id"]}
        loading={loading}
      />
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => {
          setIsModalOpen(false);
        }}
        style={modalStyle}
        contentLabel={"Yeni Pozisyon Ekle"}
        ariaHideApp={false}
      >
        <h2 className="mb-10 rounded border-b-2 p-2 text-center text-xl text-gray-800 dark:text-white">
          {editMode ? "Pozisyon Düzenle" : "Yeni Pozisyon Ekle"}
        </h2>
        <Formik
          initialValues={modalInitialValues}
          onSubmit={handleSubmit}
          enableReinitialize
          validationSchema={positionAddValidationSchema}
        >
          {({
            isSubmitting,
            handleChange,
            handleBlur,
            values,
            setFieldValue,
            errors,
            touched,
          }) => (
            <Form className="space-y-4">
              <div className="-mx-2 flex flex-wrap">
                {predefinedOrder.map((typeCode) => (
                  <div key={typeCode} className="mb-4 w-1/2 px-2">
                    <label htmlFor={typeCode} className="mb-2 block capitalize">
                      {typeCodeLabels[typeCode] || typeCode}{" "}
                    </label>
                    <Field name={typeCode}>
                      {({ field, form }) => (
                        <AsyncSelect
                          {...field}
                          styles={selectStyle}
                          cacheOptions
                          defaultOptions={unitOptions[typeCode] || []}
                          onChange={(option) =>
                            form.setFieldValue(
                              field.name,
                              option ? option.value : ""
                            )
                          }
                          isClearable
                          placeholder="Seçiniz"
                          value={(unitOptions[typeCode] || []).find(
                            (option) => option.value === form.values[typeCode]
                          )}
                        />
                      )}
                    </Field>
                  </div>
                ))}
                <div className="mb-4 w-1/2 px-2">
                  <label className="mb-2 block capitalize" htmlFor="manager">
                    Yönetici
                  </label>
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    styles={selectStyle}
                    loadOptions={loadManagerOptions}
                    onChange={(option) =>
                      setFieldValue("manager", {
                        label: option.label,
                        value: option.value,
                      })
                    }
                    name="manager"
                    isClearable
                    placeholder="Seçiniz"
                    value={
                      values.manager
                        ? {
                            label: values.manager.label,
                            value: values.manager.value,
                          }
                        : null
                    }
                  />
                  {errors.manager && touched.manager ? (
                    <div className="mt-1 text-xs text-red-500">
                      Yönetici seçmelisiniz
                    </div>
                  ) : null}
                </div>
                <div className="mb-4 w-1/2 px-2">
                  <label
                    className="mb-2 block capitalize"
                    htmlFor="mode_of_operation"
                  >
                    Çalışma Şekli
                  </label>
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    styles={selectStyle}
                    loadOptions={loadModeOfOperationOptions}
                    onChange={(option) =>
                      setFieldValue("mode_of_operation", {
                        value: option.value,
                        label: option.label,
                      })
                    }
                    onBlur={handleBlur}
                    name="mode_of_operation"
                    isClearable
                    placeholder="Seçiniz"
                    value={
                      values.mode_of_operation
                        ? {
                            label: values.mode_of_operation.label,
                            value: values.mode_of_operation.value,
                          }
                        : null
                    }
                  />
                  {errors.mode_of_operation && touched.mode_of_operation ? (
                    <div className="mt-1 text-xs text-red-500">
                      {errors.mode_of_operation}
                    </div>
                  ) : null}
                </div>
                <div className="mb-4 w-1/2 px-2">
                  <label className="mb-2 block capitalize" htmlFor="start_date">
                    Başlangıç Tarihi
                  </label>
                  <FormikDatePicker
                    name="start_date"
                    selected={values.start_date}
                  />
                  {errors.start_date && touched.start_date ? (
                    <div className="mt-1 text-xs text-red-500">
                      {errors.start_date}
                    </div>
                  ) : null}
                </div>
                <div className="mb-4 w-1/2 px-2">
                  <label className="mb-2 block capitalize" htmlFor="end_date">
                    Bitiş Tarihi
                  </label>
                  <FormikDatePicker
                    name="end_date"
                    selected={values.end_date}
                  />
                  {errors.end_date && touched.end_date ? (
                    <div className="mt-1 text-xs text-red-500">
                      {errors.end_date}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="flex justify-end space-x-2">
                <button
                  type="button"
                  onClick={handleCloseModal}
                  className="rounded-md bg-gray-300 px-4 py-2 text-sm text-gray-700 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                >
                  İptal
                </button>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="rounded-md bg-blue-600 px-4 py-2 text-sm text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  Kaydet
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default Position;
