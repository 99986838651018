import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "store";
import { get } from "utils/api";
import { ENDPOINTS } from "utils/constants";
import DataTable from "components/datatable";
import { timelogColumsData } from "../variables/columnsData";

const TimeLogList = () => {
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const [timelogData, setTimelogData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [statusFilter, setStatusFilter] = useState('Onaylandı');
  const getButtonStyle = (status) => {
    const baseStyle = 'mx-1 px-4 py-2 text-white text-sm rounded focus:outline-none';
    const activeStyle = status === statusFilter ? 'bg-blue-800' : 'bg-blue-500 hover:bg-blue-600';
    return `${baseStyle} ${activeStyle}`;
  };
  const getTimeLog = async () => {
    setLoading(true);
    const url = statusFilter 
    ? `${ENDPOINTS.TIMELOG}?ordering=-create_time&status=${statusFilter}`
    : `${ENDPOINTS.TIMELOG}?ordering=-create_time`;
  const response = await get(url);
    setTimelogData(response?.results);
    setLoading(false);
  };
  useEffect(() => {
    getTimeLog();
  }, [statusFilter]);
  // const renderModalContent = (rowData) => (
  //   <ShowLeave leave={rowData} />
  // );
  return (
    <div>
      {/* Tables & Charts */}
      <div className="flex my-4">
        <button onClick={() => setStatusFilter('Onaylandı')} className={getButtonStyle('Onaylandı')}>Onaylananlar</button>
        <button onClick={() => setStatusFilter('Onay Bekliyor')} className={getButtonStyle('Onay Bekliyor')}>Onay Bekleyenler</button>
        <button onClick={() => setStatusFilter('Reddedildi')} className={getButtonStyle('Reddedildi')}>Reddedilenler</button>
      </div>


      <div className="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-1">
        <div>
          <DataTable
            columnsData={timelogColumsData}
            tableData={timelogData}
            title="İzinler"
            hiddenColumns={["id"]}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default TimeLogList;
