import axios from 'axios';
import { get, post, put } from '../utils/api';
import { ENDPOINTS } from '../utils/constants';

// GET isteği örneği:
export const fetchCities = async () => {
  const data = await get(ENDPOINTS.CITY);
  const istanbul = data.results.find(city => city.id === 34);
  const izmir = data.results.find(city => city.id === 35);
  const otherCities = data.results.filter(city => city.id !== 34 && city.id !== 35);
  
  // İstanbul ve İzmir'i diğer şehirlerin önüne yerleştir
  const orderedCities = [istanbul, izmir, ...otherCities];
  return orderedCities;
};
export const fetchTownsByCity = async (cityId) => {
  const data = await get(ENDPOINTS.TOWN + "?city=" + cityId);
  return data;
};
export const fetchCurrency = async () => {
  const data = await get(ENDPOINTS.CURRENCY);
  return data.results;
};
