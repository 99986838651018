import React from "react";
import { FaUserAlt, FaWallet, FaSuitcase, FaFileAlt } from "react-icons/fa";
import { Link } from "react-router-dom";

const Reports = () => {

  return (
    <div className="container mx-auto p-6">
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 mb-4 cursor-pointer rounded-lg border bg-white p-10 p-4 text-center shadow transition duration-300 hover:shadow-lg dark:bg-navy-800 dark:text-white md:col-span-3 md:mb-0">
          <Link to={"/app/rapor-detay/employeeListReport"}>
            <div className="mb-3">
            <FaUserAlt className="mx-auto text-4xl text-blue-600" />
          </div>
          <h3 className="mb-2 text-lg font-bold">Çalışan Listesi</h3>
          <p className="text-md text-navy-700 dark:text-white">
            Çalışan listesi ve çalışanlarınızın bilgileriyle ilgili raporlar oluşturun.
          </p>
          </Link>
        </div>
        <div className="col-span-12 mb-4 cursor-pointer rounded-lg border bg-white p-10 p-4 text-center shadow transition duration-300 hover:shadow-lg dark:bg-navy-800 dark:text-white md:col-span-3 md:mb-0">
        <Link to={"/app/rapor-detay/salaryReport"}>
          <div className="mb-3">
            <FaWallet className="mx-auto text-4xl text-blue-600" />
          </div>
          <h3 className="mb-2 text-lg font-bold">Maaş Bilgileri</h3>
          <p className="text-md text-navy-700 dark:text-white">
            Çalışan maaşları ve maaşlarla ilgili raporlar oluşturun.
          </p>
          </Link>
        </div>
        <div className="col-span-12 mb-4 cursor-pointer rounded-lg border bg-white p-10 p-4 text-center shadow transition duration-300 hover:shadow-lg dark:bg-navy-800 dark:text-white md:col-span-3 md:mb-0">
        <Link to={"/app/rapor-detay/leaveReport"}>
          <div className="mb-3">
            <FaSuitcase className="mx-auto text-4xl text-blue-600" />
          </div>
          <h3 className="mb-2 text-lg font-bold">İzin Bilgileri</h3>
          <p className="text-md text-navy-700 dark:text-white">
            Çalışan izinleri ve izinlerle ilgili raporlar oluşturun.
          </p>
          </Link>
        </div>
        <div className="col-span-12 mb-4 cursor-pointer rounded-lg border bg-white p-10 p-4 text-center shadow transition duration-300 hover:shadow-lg dark:bg-navy-800 dark:text-white md:col-span-3 md:mb-0">
        <Link to={"/app/rapor-detay/expenseReport"}>
          <div className="mb-3">
            <FaFileAlt className="mx-auto text-4xl text-blue-600" />
          </div>
          <h3 className="mb-2 text-lg font-bold">Harcama Raporu</h3>
          <p className="text-md text-navy-700 dark:text-white">
            Çalışanların harcamaları ve harcamalarla ilgili raporlar oluşturun.
          </p>
          </Link>
        </div>
        <div className="col-span-12 mb-4 cursor-pointer rounded-lg border bg-white p-10 p-4 text-center shadow transition duration-300 hover:shadow-lg dark:bg-navy-800 dark:text-white md:col-span-3 md:mb-0">
        <Link to={"/app/rapor-detay/timesheetReport"}>
          <div className="mb-3">
            <FaFileAlt className="mx-auto text-4xl text-blue-600" />
          </div>
          <h3 className="mb-2 text-lg font-bold">Çalışan Zaman Raporu</h3>
          <p className="text-md text-navy-700 dark:text-white">
            Çalışanların işe giriş ve çıkış saatleriyle ilgili raporlar oluşturun.
          </p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Reports;
