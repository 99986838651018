import InputField from "components/fields/InputField";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { Link, useNavigate, useParams } from "react-router-dom";
import { post } from "utils/api";
import { ENDPOINTS } from "utils/constants";

const ResetPassword = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [new_password, setNewPassword] = useState("");
  const [new_password_confirm, setNewPasswordConfirm] = useState("");
  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
  };
  const handleNewPasswordConfirmChange = (event) => {
    setNewPasswordConfirm(event.target.value);
  };
  const validatePassword = (password) => {
    if (password.length < 8) {
      return "Parola en az 8 karakter olmalıdır.";
    }
    if (!/[a-zA-Z]/.test(password)) {
      return "Parola en az bir harf içermelidir.";
    }
    return null;
  };
  const handleSubmit = async () => {
    const reset_token = params.reset_token;
    const validationError = validatePassword(new_password);
    if (validationError) {
      toast.error(validationError);
      return;
    }
    if (reset_token && new_password === new_password_confirm) {
      try{
        const response = await post(ENDPOINTS.RESET_PASSWORD, {
          reset_token,
          new_password,
          new_password_confirm,
        });
        console.log(response);
        if (response?.status === 200) {
          toast.success("Şifreniz başarıyla değiştirildi.");
          navigate("/auth/giris-yap");
        } else {
          toast.error("Şifre değiştirilemedi");
        }
      }
      catch(error){
        if(error?.new_password?.includes("Bu parola çok geneldir.")){
          toast.error("Bu parola çok geneldir. Lütfen daha güçlü bir parola seçiniz.");
        }
        console.log(error);
      }
      
    } else {
      toast.error("Şifreler uyuşmuyor.");
    }
  };
  return (
    <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign in section */}
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Şifremi Sıfırla
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Yeni şifrenizi oluşturun
        </p>

        {/* Email */}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Yeni Şifre*"
          placeholder="Yeni şifrenizi girin"
          id="new_password"
          type="password"
          value={new_password}
          onChange={handleNewPasswordChange}
          required="required"
        />

        {/* Password */}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Tekrar yeni şifre*"
          placeholder="Yeni şifrenizi tekrar girin"
          id="new_password_confirm"
          type="password"
          value={new_password_confirm}
          onChange={handleNewPasswordConfirmChange}
          required="required"
        />
        <button
          className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          onClick={handleSubmit}
          type="button"
        >
          Gönder
        </button>
        <div className="mt-4">
          <Link
            to="/auth/giris-yap"
            className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
          >
            Giriş Yap
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
