import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const Tabs = ({ tabs }) => {
  const navigate = useNavigate();
  const location = useLocation();

  // Aktif tab'ı URL'den çıkar
  const getActiveTab = () => {
    const matchedTab = tabs.find(tab => location.pathname.includes(`/${tab.name}`));
    return matchedTab ? matchedTab.name : null;
  };

  const [activeTab, setActiveTab] = React.useState(getActiveTab());

  // URL'de belirli bir tab belirtilmemişse varsayılan tab'a yönlendir
  useEffect(() => {
    const pathSegments = location.pathname.split('/');
    if (pathSegments.length <= 3 || !getActiveTab()) { // "/app/ayarlar" veya "/app/ayarlar/" durumlarını kontrol eder
      navigate(`/app/ayarlar/${tabs[0].name}`, { replace: true });
    } else {
      setActiveTab(getActiveTab());
    }
  }, [location, navigate, tabs]);

  // Aktif tabı değiştirmek ve URL'i güncellemek için bu fonksiyonu kullan
  const handleSetActiveTab = (tabName) => {
    navigate(`/app/ayarlar/${tabName}`);
  };

  if (!tabs || tabs.length === 0) {
    return <div>Tablar yüklenemedi.</div>;
  }

  return (
    <div className="pb-4">
      <div className="flex overflow-x-auto no-scrollbar">
        {tabs.map((tab) => (
          <button
            key={tab.name}
            className={`px-4 py-2 whitespace-nowrap ${activeTab === tab.name ? "border-b-2 border-blue-500" : ""}`}
            onClick={() => handleSetActiveTab(tab.name)}
          >
            {tab.label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Tabs;
