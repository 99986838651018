import React from "react";
import { FaCheck, FaTimes } from "react-icons/fa";
import toast from "react-hot-toast";
import { patch } from "utils/api";
import { ENDPOINTS } from "utils/constants";

const LeaveSignedStatusToggle = ({ rowId, isSigned, refreshData }) => {
  const toggleStatus = async () => {
    try {
      var formData = new FormData();
      formData.append("is_signed", !isSigned);
      const response = await patch(ENDPOINTS.LEAVE + rowId + "/", formData);
      if (response.status === 200) {
        toast.success("Başarıyla güncellendi.");
        refreshData();
      } else {
        toast.error("Bir hata oluştu.");
      }
    } catch (error) {
      console.error("Güncelleme hatası:", error);
      toast.error("Bir hata oluştu.");
    }
  };

  return (
    <div
      onClick={toggleStatus}
      style={{ cursor: "pointer" }}
      title={isSigned ? "İmzalandı" : "İmzalanmadı"}
    >
      {isSigned ?<FaCheck className="text-green-500" /> : <FaCheck className="text-gray-300" />}
    </div>
  );
};

export default LeaveSignedStatusToggle;
