import React from "react";
import PropTypes from "prop-types";
import { formatDateWithTime } from "utils/formatDate";
import { patch } from "utils/api";
import toast from "react-hot-toast";
import { ENDPOINTS } from "utils/constants";
import { useNavigate } from "react-router-dom";

const ShowLeave = ({ leave, isEdit }) => {
  const navigate = useNavigate();
  const changeStatusLeave = (id, status) => async () => {
    var formData = new FormData();
    formData.append("status", status);
    if (status === "Onaylandı") formData.append("is_approved", true);
    const response = await patch(ENDPOINTS.LEAVE + `${id}/`, formData);
    if (response.status === 200) {
      toast.success("Başarıyla güncellendi");
      navigate("/app");
    } else {
      toast.error("Bir hata oluştu");
    }
  };
  return (
    <div className="mx-auto max-w-xl rounded-lg bg-white p-6 shadow-lg dark:bg-navy-700 dark:text-white">
      <h2 className="mb-6 text-center text-2xl font-semibold text-gray-800 dark:text-white">
        İzin Detayları
      </h2>
      <div className="space-y-4">
        <div className="flex items-center justify-between">
          <h3 className="text-base font-medium text-gray-700 dark:text-white">
            İzin Türü:
          </h3>
          <p className="text-base text-gray-600 dark:text-gray-200">
            {leave?.leave_type?.name}
          </p>
        </div>
        <div className="flex items-center justify-between">
          <h3 className="text-base font-medium text-gray-700 dark:text-white">
            Başlangıç Tarihi:
          </h3>
          <p className="text-base text-gray-600 dark:text-gray-200">
            {formatDateWithTime(leave.start_datetime)}
          </p>
        </div>
        <div className="flex items-center justify-between">
          <h3 className="text-base font-medium text-gray-700 dark:text-white">
            Bitiş Tarihi:
          </h3>
          <p className="text-base text-gray-600 dark:text-gray-200">
            {formatDateWithTime(leave.end_datetime)}
          </p>
        </div>
        <div className="flex items-center justify-between">
          <h3 className="text-base font-medium text-gray-700 dark:text-white">
            İşe Dönüş Tarihi:
          </h3>
          <p className="text-base text-gray-600 dark:text-gray-200">
            {formatDateWithTime(leave.return_to_work_datetime)}
          </p>
        </div>
        <div className="flex items-center justify-between">
          <h3 className="text-base font-medium text-gray-700 dark:text-white">
            Toplam Gün:
          </h3>
          <p className="text-base text-gray-600 dark:text-gray-200">
            {leave.duration} gün
          </p>
        </div>
        <div className="flex items-center justify-between">
          <h3 className="text-base font-medium text-gray-700 dark:text-white">
            Açıklama:
          </h3>
          <p className="text-base text-gray-600 dark:text-gray-200">
            {leave.description}
          </p>
        </div>
        <div className="flex items-center justify-between">
          <h3 className="text-base font-medium text-gray-700 dark:text-white">
            Durum:
          </h3>
          <p
            className={`text-base ${
              leave.status === "Onaylandı" ? "text-green-600" : "text-red-600"
            } dark:text-white`}
          >
            {leave.status}
          </p>
        </div>
        {isEdit && (
          <div className="mt-6 flex justify-center gap-4">
            <button
              className="rounded bg-green-500 px-4 py-2 text-white hover:bg-green-600"
              onClick={changeStatusLeave(leave.id, "Onaylandı")}
            >
              Onayla
            </button>
            <button
              className="rounded bg-red-500 px-4 py-2 text-white hover:bg-red-600"
              onClick={changeStatusLeave(leave.id, "Reddedildi")}
            >
              Reddet
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

ShowLeave.propTypes = {
  leave: PropTypes.shape({
    leave_type: PropTypes.object.isRequired,
    start_datetime: PropTypes.string.isRequired,
    end_datetime: PropTypes.string.isRequired,
    return_to_work_datetime: PropTypes.string,
    duration: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
  isEdit: PropTypes.bool,
};

export default ShowLeave;
