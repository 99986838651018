import Dropdown from "components/dropdown";
import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import SubTabHeader from "../SubTabHeader";
import FormikCheckbox from "components/checkbox/FormikCheckbox";
import { Form, Formik } from "formik";
import { get } from "utils/api";
import { ENDPOINTS } from "utils/constants";
import { useAppSelector } from "store";
import { put } from "utils/api";
import { patch } from "utils/api";
import toast from "react-hot-toast";
import InputField from "components/fields/InputField";
import Select from "react-select";
import { useTheme } from "ThemeContext";
const CompanyNotificationSettings = () => {
  const { isDarkMode } = useTheme();
  const selectStyle = {
    control: (provided) => ({
      ...provided,
      backgroundColor: isDarkMode
        ? "rgb(27 37 75 / var(--tw-bg-opacity))"
        : "white",
      borderColor: isDarkMode ? "gray" : "lightgray",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: isDarkMode
        ? "rgb(27 37 75 / var(--tw-bg-opacity))"
        : "white",
    }),
    option: (provided, state) => ({
      ...provided,
      color: isDarkMode ? "white" : "rgb(27 37 75 / var(--tw-bg-opacity))",
      backgroundColor: state.isFocused
        ? isDarkMode
          ? "rgb(27 37 65 / var(--tw-bg-opacity))"
          : "lightgray"
        : state.isSelected
        ? isDarkMode
          ? "darkblue"
          : "#d6d6d6"
        : isDarkMode
        ? "rgb(27 37 75 / var(--tw-bg-opacity))"
        : "white",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: isDarkMode ? "white" : "rgb(27 37 75 / var(--tw-bg-opacity))",
    }),
    multiValue: (styles, { data }) => ({
      ...styles,
      backgroundColor: data.color,
      // color: isDarkMode ? "white" : "rgb(27 37 75 / var(--tw-bg-opacity))",
      color: "white",
    }),
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: "white",
    }),
  };
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const dropdownContent = <div>Buraya istediğiniz içerik gelebilir</div>;
  const [editingItem, setEditingItem] = useState({});
  const getEditItem = async () => {
    const response = await get(
      ENDPOINTS.COMPANY + currentUser?.company?.id + "/"
    );
    setEditingItem(response?.settings);
  };
  useEffect(() => {
    getEditItem();
  }, []);
  const options = [
    { value: "1", label: "1.ay" },
    { value: "2", label: "2.ay" },
    { value: "3", label: "3.ay" },
    { value: "4", label: "4.ay" },
    { value: "5", label: "5.ay" },
    { value: "6", label: "6.ay" },
    { value: "7", label: "7.ay" },
    { value: "8", label: "8.ay" },
    { value: "9", label: "9.ay" },
    { value: "10", label: "10.ay" },
    { value: "11", label: "11.ay" },
    { value: "12", label: "12.ay" },
  ];
  const findOptionForTrialPeriodEnd = (trialPeriodEndValue) => {
    // options listesinde, editingItem.trial_period_end değerine karşılık gelen nesneyi bul
    return options.find(option => option.value === String(trialPeriodEndValue)) || null;
  };
  
  const editSubmit = async (values) => {
    var formData = new FormData();
    formData.append(
      "weekly_notification_email",
      values.weekly_notification_email
    );
    formData.append("trial_period_end", values.trial_period_end.value);
    formData.append("is_leave_notification", values.is_leave_notification);
    formData.append("is_shift_notification", values.is_shift_notification);
    formData.append(
      "is_birthday_notification",
      values.is_birthday_notification
    );
    const response = await patch(
      ENDPOINTS.COMPANY_SETTINGS_UPDATE.replace(
        ":company_id",
        currentUser?.company?.id
      ),
      formData
    );
    if (response.status === 200) {
      toast.success("Başarıyla güncellendi");
      getEditItem();
    } else {
      toast.error("Bir hata oluştu");
    }
  };
  return (
    <div>
      <Formik
        initialValues={{
          weekly_notification_email: editingItem.weekly_notification_email,
          trial_period_end: findOptionForTrialPeriodEnd(editingItem.trial_period_end),
          is_leave_notification: editingItem.is_leave_notification,
          is_shift_notification: editingItem.is_shift_notification,
          is_birthday_notification: editingItem.is_birthday_notification,
        }}
        onSubmit={(values, { resetForm }) => {
          editSubmit(values);
          resetForm();
        }}
        enableReinitialize
      >
        {({
          isSubmitting,
          handleChange,
          handleBlur,
          values,
          setFieldValue,
        }) => (
          <Form className="space-y-4">
            <SubTabHeader
              title="Bildirim Ayarları"
              backUrl="/app/ayarlar/sistem-ayarlari"
              headerButtonVisible={false}
            />
            <div className="dark:text-white container mx-auto bg-white p-4 dark:bg-navy-800">
              <div className="ml-5 p-6">
                {/* Ana Sayfa Bölümü */}
                <div className="mb-6 w-1/4">
                  <h2 className="mb-2 text-lg font-bold">Genel</h2>
                  <p className="mb-4 text-sm">
                    Bildirim ayarlarınızı buradan düzenleyebilirsiniz.
                  </p>
                  <div className="mb-4">
                    <InputField
                      label="Haftalık Bildirim Epostası"
                      name="weekly_notification_email"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.weekly_notification_email}
                      placeholder="Haftalık bildirimin gönderileceği e-posta adresi"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block ml-3 mb-1 text-sm font-bold text-navy-700 dark:text-white">
                      Deneme Süresi Bitiş
                    </label>
                    <Select
                      name="trial_period_end"
                      onChange={(option) => {
                        setFieldValue("trial_period_end", option ? option : "");
                      }}
                      styles={selectStyle}
                      value={values.trial_period_end}
                      onBlur={handleBlur}
                      placeholder="Deneme süresi bitiş tarihi"
                      isClearable={true}
                      options={options}
                    />
                  </div>
                </div>

                {/* Çalışanlar Bölümü */}
                <div className="mb-6">
                  <h2 className="mb-2 text-lg font-bold">İzin Bildirimleri</h2>
                  <FormikCheckbox
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="is_leave_notification"
                    color="blue"
                    label="İzinleri bildir"
                  />
                  <FormikCheckbox
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="is_shift_notification"
                    color="blue"
                    label="Mesaileri bildir"
                  />
                  <FormikCheckbox
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="is_birthday_notification"
                    color="blue"
                    label="Doğum günlerini kutla"
                  />
                </div>
              </div>
              <div className="flex justify-end space-x-2">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="rounded-md bg-blue-600 px-4 py-2 text-sm text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  Kaydet
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CompanyNotificationSettings;
