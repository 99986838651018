import InputField from "components/fields/InputField";
import Checkbox from "components/checkbox";
import React, { useEffect, useState } from "react";
import { getToken } from "../../services/authService";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store";
import { setAccessToken, setCompany, setCurrentUser } from "../../store/authSlice";
import { useLocation } from "react-router-dom/dist";
import {
  fetchUserByEmail,
  fetchUserByUsername,
  getCurrentCompany,
  getCurrentUser,
} from "../../services/userService";
import { toast } from "react-hot-toast";
import { checkInput } from "../../utils/helpers";
import Cookies from 'js-cookie';
function SignIn() {
  const location = useLocation();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useAppDispatch();
  const { accessToken, currentUser } = useAppSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(false); 
  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  const handleKeyDownLogin = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };
  const handleLogin = async () => {
    if (!username || !password) {
      toast.error("Kullanıcı adı ve şifre boş bırakılamaz");
      return;
    }
    setIsLoading(true); 
    const tokenResponse = await getToken(username, password);
    if (tokenResponse && tokenResponse.token) {
      // Token başarıyla alındıysa
      dispatch(setAccessToken(tokenResponse.token));
  
      const currentUser = await getCurrentUser();
      dispatch(setCurrentUser(currentUser));
  
      // OneSignal ile push notification bağlama
      window.OneSignal = window.OneSignal || [];
      window.OneSignal.push(function () {
        window.OneSignal.isPushNotificationsEnabled(function (isEnabled) {
          if (isEnabled) {
            window.OneSignal.setExternalUserId(currentUser.id.toString());
          } else {
            console.log("Push notifications are not enabled yet.");
          }
        });
      });
      setIsLoading(false); 
      navigate("/app");
    } else if (tokenResponse && tokenResponse.error === "phone_verification_required") {
      // Eğer telefon doğrulaması gerekiyorsa
      setIsLoading(false); 
      toast.error("Telefon numaranızı doğrulayın.");
      navigate("/auth/verify-phone", { state: { username: username } });
    } else {
      setIsLoading(false); 
      toast.error("Giriş yaparken bir hata oluştu. Lütfen tekrar deneyin.");
    }
  };
  useEffect(() => {
    if (currentUser && !currentUser.company) {
      toast.error("Lütfen şirket bilgilerinizi tamamlayınız. Bizimle iletişime geçiniz.");
    }
    if(currentUser && currentUser.company && currentUser.company.subscription){
      var end_date = new Date(currentUser.company.subscription.end_date);
      if(end_date < new Date()){
        toast.error("Üyeliğiniz sona ermiştir. Lütfen bizimle iletişime geçiniz.");
        Cookies.remove('cardsOrder');
        dispatch(setAccessToken(null));
        dispatch(setCurrentUser(null));
        window.location.href = "https://beyazik.com/magaza/";
      }
    }
  }, [currentUser]); // Bağımlılıklar: accessToken ve currentUser

  if (accessToken && currentUser && currentUser.company) {
    return (
      <Navigate to={location?.state?.returnUrl || "/app"} replace={true} />
    );
  }
  return (
    <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign in section */}
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Giriş Yap
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Hesabınıza giriş yapın ve devam edin.
        </p>

        {/* Email */}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Eposta*"
          placeholder="Eposta adresinizi girin"
          id="email"
          type="text"
          onKeyDown={handleKeyDownLogin}
          value={username}
          onChange={handleUsernameChange}
          required="required"
        />

        {/* Password */}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Parola*"
          placeholder="Parolanızı girin"
          id="password"
          type="password"
          onKeyDown={handleKeyDownLogin}
          value={password}
          onChange={handlePasswordChange}
          required="required"
        />
        {/* Checkbox */}
        <div className="mb-4 flex items-center justify-between px-2">
          <div className="flex items-center">
            <Checkbox />
            <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
              Beni hatırla
            </p>
          </div>
          <Link
            className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
            to="/auth/sifremi-unuttum"
          >
            Şifremi Unuttum?
          </Link>
        </div>
        <button
         className={`mt-2 w-full rounded-xl py-[12px] text-base font-medium text-white transition duration-200 ${
          isLoading ? 'bg-gray-400 cursor-not-allowed' : 'bg-brand-500 hover:bg-brand-600 active:bg-brand-700'
        } dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
        onClick={handleLogin}
          type="button"
          disabled={isLoading}
        >
          {isLoading ? "Giriş yapılıyor..." : "Giriş Yap"} 
        </button>
        <div className="mt-4">
          <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
            Hesabınız yok mu?
          </span>
          <Link
            to="/auth/kayit-ol"
            className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
          >
            Kayıt Olun
          </Link>
        </div>
      </div>
    </div>
  );
}
export default SignIn;
