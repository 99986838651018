import React from 'react';
import Slider from 'rc-slider';
import Tooltip from 'rc-tooltip';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';

const { Handle } = Slider;

const handleWithTooltip = ({ value, dragging, index, ...restProps }) => (
  <Tooltip
    prefixCls="rc-slider-tooltip"
    overlay={formatTime(value)}
    visible={dragging}
    placement="top"
    key={index}
  >
    <Handle value={value} {...restProps} />
  </Tooltip>
);

const formatTime = (value) => {
  const hours = Math.floor(value / 60);
  const minutes = value % 60;
  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
};

const TimeRangeSlider = ({ startTime, endTime, onTimeChange, step }) => {
  return (
    <div className="time-range-slider">
      <div className="slider-container">
        <label>Başlangıç saati: {formatTime(startTime)}</label>
        <Slider
          min={0}
          max={1439} // 24 saat * 60 dakika
          step={step} // Dinamik artış miktarı
          value={startTime}
          handle={handleWithTooltip}
          onChange={(value) => onTimeChange('startTime', value)}
        />
      </div>
      <div className="slider-container">
        <label>Bitiş saati: {formatTime(endTime)}</label>
        <Slider
          min={0}
          max={1439} // 24 saat * 60 dakika
          step={step} // Dinamik artış miktarı
          value={endTime}
          handle={handleWithTooltip}
          onChange={(value) => onTimeChange('endTime', value)}
        />
      </div>
    </div>
  );
};

export default TimeRangeSlider;
