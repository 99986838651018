import React, { useState } from "react";
import { FaEdit } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import PersonalInfoCitizenshipModal from "./EmployeeDetailModals/PersonalInfoCitizenshipModal";
import PersonalInfoEducationStatusModal from "./EmployeeDetailModals/PersonalInfoEducationStatusModal";
import PersonalInfoFamilyModal from "./EmployeeDetailModals/PersonalInfoFamilyModal";
import PersonalInfoAddressModal from "./EmployeeDetailModals/PersonalInfoAddressModal";
import PersonalInfoEmergencyModal from "./EmployeeDetailModals/PersonalInfoEmergencyModal";
import PersonalInfoBankInfoModal from "./EmployeeDetailModals/PersonalInfoBankInfoModal";
import { useAppSelector } from "store";
import { formatISODate } from "utils/helpers";

const PersonalInfo = ({ data, onRefresh }) => {
  const currentUserRoleName = useAppSelector(
    (state) => state.auth.currentUser?.role?.name === "CompanyAdmin"
  );
  const [isOpenCitizenshipModal, setIsOpenCitizenshipModal] = useState(false);
  const [isOpenEducationModal, setIsOpenEducationModal] = useState(false);
  const [isOpenFamilyModal, setIsOpenFamilyModal] = useState(false);
  const [isOpenAddressModal, setIsOpenAddressModal] = useState(false);
  const [isOpenEmergencyModal, setIsOpenEmergencyModal] = useState(false);
  const [isOpenBankaInfoModal, setIsOpenBankaInfoModal] = useState(false);
  return (
    <div className="container mx-auto p-4">
      <div className="-mx-3 flex flex-wrap">
        {/* Sol Taraftaki Kartlar */}
        <div className="mb-6 w-full px-3 lg:w-7/12">
          {/* Vatandaşlık Kartı */}
          <div className="mb-6 rounded-lg bg-white p-4 shadow dark:bg-navy-800 dark:text-white">
            <div className="mb-4 flex items-center justify-between">
              <h2 className="text-lg font-semibold">Vatandaşlık</h2>
              {currentUserRoleName && (
                <>
                  {" "}
                  <small className="hidden md:inline text-sm italic text-gray-500">
                    Bu bilgi, SGK'ya bildirirken gerekli olacaktır.
                  </small>
                  <button
                    onClick={() => setIsOpenCitizenshipModal(true)}
                    className="rounded py-2 px-3 text-blue-500"
                  >
                    <FaEdit className="text-lg" />
                  </button>
                </>
              )}
            </div>
            <CardItem title="Doğum Tarihi" value={data?.birth_date ?? "-"} />
            <CardItem title="Cinsiyet" value={data?.gender ?? "-"} />
            <CardItem
              title="Engel Derecesi"
              value={data?.disability_degree ?? "-"}
            />
            <CardItem title="Uyruğu" value={data?.nationality ?? "-"} />
            <CardItem
              title="Kimlik Numarası"
              value={data?.identification_number ?? "-"}
            />
          </div>

          {/* Eğitim Kartı */}
          <div className="mb-6 rounded-lg bg-white p-4 shadow dark:bg-navy-800 dark:text-white">
            <div className="mb-4 flex items-center justify-between">
              <h2 className="text-lg font-semibold">Eğitim</h2>
              
              {currentUserRoleName && (
              <>
              <small className="hidden md:inline text-sm italic text-gray-500">
                Bu bilgilere hibe ve yardım programları için ihtiyaç
                duyulacaktır.
              </small>
              
                <button
                  onClick={() => setIsOpenEducationModal(true)}
                  className="rounded py-2 px-3 text-blue-500"
                >
                  <FaEdit className="text-lg" />
                </button></>
              )}
            </div>
            <CardItem
              title="Eğitim Durumu"
              value={data?.educational_status ?? "-"}
            />
            <CardItem
              title="Tamamlanan En Yüksek Eğitim Seviyesi"
              value={data?.education_level ?? "-"}
            />
            <CardItem
              title="Son Tamamlanan Eğitim Kurumu"
              value={data?.last_completed_educational_institution ?? "-"}
            />
          </div>

          {/* Aile Kartı */}
          <div className="mb-6 rounded-lg bg-white p-4 shadow dark:bg-navy-800 dark:text-white">
            <div className="mb-4 flex items-center justify-between">
              <h2 className="text-lg font-semibold">Aile</h2>
              {currentUserRoleName && (
                <button
                  onClick={() => setIsOpenFamilyModal(true)}
                  className="rounded py-2 px-3 text-blue-500"
                >
                  <FaEdit className="text-lg" />
                </button>
              )}
            </div>
            <CardItem title="Medeni Hal" value={data?.marital_status ?? "-"} />
            <CardItem
              title="Çocuk Sayısı"
              value={data?.number_of_children ?? "-"}
            />
          </div>

          {/* Adres Kartı */}
          <div className="mb-6 rounded-lg bg-white p-4 shadow dark:bg-navy-800 dark:text-white">
            <div className="mb-4 flex items-center justify-between">
              <h2 className="text-lg font-semibold">Adres</h2>
              {currentUserRoleName && (
              <>
              <small className="hidden md:inline text-sm italic text-gray-500">
                Bu bilgi, SGK'ya bildirirken gerekli olacaktır.
              </small>
              
                <button
                  onClick={() => setIsOpenAddressModal(true)}
                  className="rounded py-2 px-3 text-blue-500"
                >
                  <FaEdit className="text-lg" />
                </button></>
              )}
            </div>
            <CardItem title="Adres" value={data?.address ?? "-"} />
            <CardItem title="Ülke" value={data?.country?.name ?? "-"} />
            <CardItem title="Şehir" value={data?.city?.name ?? "-"} />
            <CardItem title="İlçe" value={data?.town?.name ?? "-"} />
            <CardItem title="Posta Kodu" value={data?.zip_code ?? "-"} />
          </div>
        </div>
        {/* Sağ Taraftaki Kartlar */}
        <div className="w-full px-3 lg:w-5/12">
          {/* Özlük Doluluk Oranı Kartı */}
          {/* <div className="mb-6 rounded-lg bg-white p-4 shadow dark:bg-navy-800 dark:text-white">
            <div className="mb-4 flex items-center justify-between">
              <h2 className="text-lg font-semibold">Özlülük Doluluk Oranı</h2>
            </div>
            <div className="my-3 text-center">
              <span className="text-2xl font-semibold text-green-600">4%</span>
              <div className="h-2.5 w-full rounded-full bg-gray-200 dark:bg-gray-700">
                <div
                  className="h-2.5 rounded-full bg-green-600"
                  style={{ width: "4%" }}
                ></div>
              </div>
            </div>
            <div className="flex justify-between">
              <div>
                <span className="text-sm text-gray-600">Tamamlanan</span>
                <div className="text-lg text-gray-800">1</div>
              </div>
              <div>
                <span className="text-sm text-gray-600">Kalan</span>
                <div className="text-lg text-gray-800">24</div>
              </div>
            </div>
          </div> */}

          {/* İletişim Kartı */}
          {/* <div className="mb-6 rounded-lg bg-white p-4 shadow dark:bg-navy-800 dark:text-white">
            <div className="mb-4 flex items-center justify-between">
              <h2 className="text-lg font-semibold">İletişim</h2>
              <button className="rounded py-2 px-3 text-blue-500">
                <FaEdit className="text-lg" />
              </button>
            </div>
            <CardItem title="E-posta (Kişisel)" value={data?.email ?? "-"} />
            <CardItem title="Telefon (Kişisel)" value={data?.phone ?? "-"} />
          </div> */}
          {/* Acil Durum Kartı */}
          <div className="mb-6 rounded-lg bg-white p-4 shadow dark:bg-navy-800 dark:text-white">
            <div className="mb-4 flex items-center justify-between">
              <h2 className="text-lg font-semibold">Acil Durum</h2>
              {currentUserRoleName && (
                <button
                  onClick={() => setIsOpenEmergencyModal(true)}
                  className="rounded py-2 px-3 text-blue-500"
                >
                  <FaEdit className="text-lg" />
                </button>
              )}
            </div>
            <CardItem
              title="Adı Soyadı"
              value={data?.in_emergency_contact_name ?? "-"}
            />
            <CardItem
              title="Telefon"
              value={data?.in_emergency_contact_phone ?? "-"}
            />
            <CardItem
              title="Yakınlık Derecesi"
              value={data?.in_emergency_contact_relation ?? "-"}
            />
          </div>

          {/* Banka Hesabı Kartı */}
          <div className="mb-6 rounded-lg bg-white p-4 shadow dark:bg-navy-800 dark:text-white">
            <div className="mb-4 flex items-center justify-between">
              <h2 className="text-lg font-semibold">Banka Hesabı</h2>
              {currentUserRoleName && (
                <button
                  onClick={() => setIsOpenBankaInfoModal(true)}
                  className="rounded py-2 px-3 text-blue-500"
                >
                  <FaEdit className="text-lg" />
                </button>
              )}
            </div>
            <CardItem title="Banka Adı" value={data?.bank_name ?? "-"} />
            <CardItem
              title="Hesap Tipi"
              value={data?.bank_account_type ?? "-"}
            />
            <CardItem
              title="Hesap Numarası"
              value={data?.bank_account_number ?? "-"}
            />
            <CardItem title="IBAN" value={data?.bank_iban ?? "-"} />
          </div>
        </div>
      </div>
      {isOpenCitizenshipModal && (
        <PersonalInfoCitizenshipModal
          isOpen={isOpenCitizenshipModal}
          onClose={() => setIsOpenCitizenshipModal(false)}
          onRefresh={() => {
            onRefresh();
          }}
          userId={data?.id}
          currentData={data}
        />
      )}
      {isOpenEducationModal && (
        <PersonalInfoEducationStatusModal
          isOpen={isOpenEducationModal}
          onClose={() => setIsOpenEducationModal(false)}
          onRefresh={() => {
            onRefresh();
          }}
          userId={data?.id}
          currentData={data}
        />
      )}
      {isOpenFamilyModal && (
        <PersonalInfoFamilyModal
          isOpen={isOpenFamilyModal}
          onClose={() => setIsOpenFamilyModal(false)}
          onRefresh={() => {
            onRefresh();
          }}
          userId={data?.id}
          currentData={data}
        />
      )}
      {isOpenAddressModal && (
        <PersonalInfoAddressModal
          isOpen={isOpenAddressModal}
          onClose={() => setIsOpenAddressModal(false)}
          onRefresh={() => {
            onRefresh();
          }}
          userId={data?.id}
          currentData={data}
        />
      )}
      {isOpenEmergencyModal && (
        <PersonalInfoEmergencyModal
          isOpen={isOpenEmergencyModal}
          onClose={() => setIsOpenEmergencyModal(false)}
          onRefresh={() => {
            onRefresh();
          }}
          userId={data?.id}
          currentData={data}
        />
      )}
      {isOpenBankaInfoModal && (
        <PersonalInfoBankInfoModal
          isOpen={isOpenBankaInfoModal}
          onClose={() => setIsOpenBankaInfoModal(false)}
          onRefresh={() => {
            onRefresh();
          }}
          userId={data?.id}
          currentData={data}
        />
      )}
    </div>
  );
};

const CardItem = ({ title, value }) => (
  <div className="mb-3">
    <div className="text-sm font-semibold text-gray-700">{title}</div>
    <div className="text-sm text-gray-500">{value}</div>
  </div>
);

export default PersonalInfo;
