import * as Yup from 'yup';
export const positionAddValidationSchema = Yup.object().shape({
    start_date: Yup.date().required('Başlangıç tarihi gerekli'),
    manager: Yup.object({
      value: Yup.string().required('Yönetici ID gerekli'),
      label: Yup.string().required('Yönetici ismi gerekli')
    }).required('Yönetici gerekli'),
  });

  export const salaryAddValidationSchema = Yup.object().shape({
    amount : Yup.string().required('Maaş tutarı gerekli'),
    currency: Yup.string().required('Para birimi gerekli'),
    effective_on: Yup.date().required('Geçerli tarih gerekli'),
    pay_period: Yup.string().required('Ödeme periyodu gerekli'),
  });