import React from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Navbar from "components/navbar";
import Sidebar from "components/sidebar";
import Footer from "components/footer/Footer";
import routes from "routes.js";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import BasitModal from "components/modal";
import { closeModal } from "store/modalSlice";
import useHasPermission from "utils/useHasPermission";
import PrivateRoute from "PrivateRoute";
import useHasRole from "utils/useHasRole";
import MobileSidebarCard from "components/sidebar/components/MobileSidebarCard";

export default function Admin(props) {
  const currentUser = useSelector((state) => state.auth.currentUser);
  // useHasPermission(currentUser?.permissions);
  useHasRole(currentUser?.role);
  const dispatch = useDispatch();
  const modalState = useSelector((state) => state.modal);
  const { ...rest } = props;
  const location = useLocation();
  const [open, setOpen] = React.useState(true);
  const [currentRoute, setCurrentRoute] = React.useState("Anasayfa");
  
  React.useEffect(() => {
    // İlk yüklemede pencere genişliğine bağlı olarak sidebar durumunu ayarla
    const handleResize = () => {
      setOpen(window.innerWidth >= 1200);
    };
  
    // İlk yüklemede ve her pencere boyutu değişikliğinde çalıştır
    handleResize(); // İlk yüklemede çalıştır
    window.addEventListener("resize", handleResize);
  
    // Cleanup function
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  React.useEffect(() => {
    getActiveRoute(routes);
  }, [location.pathname]);

  const getActiveRoute = (routes) => {
    let activeRoute = "Anasayfa";
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(
          routes[i].layout + "/" + routes[i].path
        ) !== -1
      ) {
        setCurrentRoute(routes[i].name);
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes) => {
    
    return routes.map((prop, key) => {
      if (prop.layout === "/app") {
        return (
          <Route
            path={`/${prop.path}`}
            element={
              <PrivateRoute>
                {prop.component}
              </PrivateRoute>
            }
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  document.documentElement.dir = "ltr";
  return (
    <div className="flex h-full w-full">
      <MobileSidebarCard />
      <BasitModal
        isOpen={modalState.isOpen}
        onClose={() => dispatch(closeModal())}
        width={500}
        height={500}
      >
        {modalState.content}
      </BasitModal>
      <Sidebar open={open} onClose={() => setOpen(false)} />
      {/* Navbar & Main Content */}
      <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900">
        {/* Main Content */}
        <main
          className={`mx-[12px] h-full flex-none transition-all md:pr-2 xl:ml-[313px]`}
        >
          {/* Routes */}
          <div className="h-full">
            <Navbar
              onOpenSidenav={() => setOpen(true)}
              logoText={"BEYAZ IK"}
              brandText={currentRoute}
              secondary={getActiveNavbar(routes)}
              {...rest}
            />
            <div className="pt-5s mx-auto mb-auto h-full min-h-[84vh] p-2 md:pr-2">
              <Routes>
                {getRoutes(routes)}

                <Route
                  path="/"
                  element={<Navigate to="/app/default" replace />}
                />
              </Routes>
            </div>
            <div className="p-3">
              <Footer />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
