import React from "react";
import { Provider } from "react-redux";
import store from "./store";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import Modal from "react-modal";
import App from "./App";
import { Toaster } from "react-hot-toast";
import { ThemeProvider } from "ThemeContext";

Modal.setAppElement("#root");

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <ThemeProvider>
          <App />
          <Toaster />
        </ThemeProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);
