import Dropdown from "components/dropdown";
import React, { useEffect, useState } from "react";
import { FaArrowLeft, FaCircle, FaInfo, FaPlus, FaRemoveFormat, FaTimes, FaTrash } from "react-icons/fa";
import FormikCheckbox from "components/checkbox/FormikCheckbox";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { get } from "utils/api";
import InputField from "components/fields/InputField";
import Switch from "components/switch";
import { useParams } from "react-router-dom";
import { ENDPOINTS } from "utils/constants";
import { getById } from "utils/api";
import { post } from "utils/api";
import toast from "react-hot-toast";
import { put } from "utils/api";
import Modal from "react-modal";

import { patch } from "utils/api";
import useCustomModalStyles from "utils/useCustomModalStyles";
import { Tooltip } from "react-tooltip";
import { del } from "utils/api";

const RuleDetails = () => {
  const modalStyle = useCustomModalStyles();
  const [currentTab, setCurrentTab] = useState(0);
  const [leaveType, setLeaveType] = useState([]);
  const [isPaid, setIsPaid] = useState(true);
  const [isOpenSeniortyModal, setIsOpenSeniortyModal] = useState(false);
  const [selectedSeniorityIds, setSelectedSeniorityIds] = useState([]);

  const { id } = useParams();
  const getLeaveTypeById = async () => {
    const response = await getById(id, ENDPOINTS.LEAVE_TYPE);
    setLeaveType(response);
  };
  useEffect(() => {
    getLeaveTypeById();
  }, [id]);
  useEffect(() => {
    // leaveType.seniority'nin güncel değerine bağlı olarak selectedSeniorityIds'i başlatın
    setSelectedSeniorityIds(leaveType.seniority?.map((item) => item.id) || []);
  }, [leaveType.seniority]);

  const handleSenioritySubmit = async (values) => {
    const response = await post(ENDPOINTS.SENIORITY, values);
    if (response.status === 201) {
      toast.success("Kıdem başarıyla eklendi");
      const updatedSeniority = [...leaveType.seniority, response.data];
      setLeaveType({ ...leaveType, seniority: updatedSeniority });

      const updatedSelectedSeniorityIds = [
        ...selectedSeniorityIds,
        response.data.id,
      ];
      setSelectedSeniorityIds(updatedSelectedSeniorityIds);
      try {
        const updateResponse = await patch(ENDPOINTS.LEAVE_TYPE + id + "/", {
          seniority_ids: updatedSelectedSeniorityIds,
        });
        if (updateResponse.status === 200) {
          console.log("success", "Güncelleme başarılı");
        } else {
          console.log("error", "Güncelleme başarısız");
        }
      } catch (error) {
        toast.error("Bir hata oluştu: " + error.message);
      }

      setIsOpenSeniortyModal(false);
    } else {
      toast.error("Bir hata oluştu");
    }
  };
  const handleSubmit = async (values) => {
    const formData = new FormData();
    Object.keys(values).forEach((key) => {
      if (key !== "seniority") {
        formData.append(key, values[key]);
      }
    });
    formData.append("seniority_ids", selectedSeniorityIds.join(","));

    try {
      const response = await put(ENDPOINTS.LEAVE_TYPE + id + "/", formData);

      if (response.status === 200) {
        toast.success("İzin türü başarıyla güncellendi");
        getLeaveTypeById(); // Güncellenmiş verileri almak için
      } else {
        throw new Error("Güncelleme başarısız");
      }
    } catch (error) {
      toast.error("Bir hata oluştu: " + error.message);
    }
  };
  var seniorityIds = leaveType?.seniority?.map((item) => item.id);
  const initialValues = {
    name: leaveType.name || "",
    description: leaveType.description || "",
    internal_code: leaveType.internal_code || "",
    is_paid: leaveType.is_paid || true,
    day_limit: leaveType.day_limit || 14,
    age_exception: leaveType.age_exception || false,
    limitation: leaveType.limitation || "",
    overdrawn_policy: leaveType.overdrawn_policy || "",
    overdrawn_limit: leaveType.overdrawn_limit || 0,
    is_carryover: leaveType.is_carryover || false,
    age_exception_value: leaveType.age_exception_value || 6,
    accrual_frequency: leaveType.accrual_frequency || "",
    accrual_start_date: leaveType.accrual_start_date || "",
    least_day_count: leaveType.least_day_count || 0,
    most_day_count: leaveType.most_day_count || 0,
    is_message_required: leaveType.is_message_required || false,
    is_substitute_required: leaveType.is_substitute_required || false,
    show_on_calendar: leaveType.show_on_calendar || false,
    seniority: seniorityIds || [],
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("İzin türü adı zorunludur"),
  });
  const deleteSeniority = async (seniorityId) => {
    const result = window.confirm("Silmek istediğinize emin misiniz?");
    if (result) {
      try {
        const response = await del(ENDPOINTS.SENIORITY, seniorityId);
        if (response.status === 204) {
          const updatedSeniority = leaveType.seniority.filter(
            (item) => item.id !== seniorityId
          );
          setLeaveType({ ...leaveType, seniority: updatedSeniority });
  
          const updatedSelectedSeniorityIds = selectedSeniorityIds.filter(
            (item) => item !== seniorityId
          );
          setSelectedSeniorityIds(updatedSelectedSeniorityIds);
  
          const updateResponse = await patch(ENDPOINTS.LEAVE_TYPE + id + "/", {
            seniority_ids: updatedSelectedSeniorityIds,
          });
          if (updateResponse.status === 200) {
            console.log("success", "Güncelleme başarılı");
          } else {
            console.log("error", "Güncelleme başarısız");
          }
          
          getLeaveTypeById(); // Güncellenmiş verileri almak için
        } else {
          toast.error("Bir hata oluştu");
        }
      } catch (error) {
        toast.error("Bir hata oluştu: " + error.message);
      }
    }
  };
  return (
    <div className="mx-auto rounded-lg bg-white p-6 shadow-lg dark:bg-navy-700 dark:text-white">
      <h2 className="mb-4 text-2xl font-semibold">
        İzin Kuralları / {leaveType?.name}
      </h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ values, handleChange, handleBlur }) => {
          const tabs = [
            { title: "Genel", condition: true },
            { title: "Hakediş", condition: true },
            {
              title: "Hakediş Sıklığı",
              condition: values.limitation === "limited_to_year",
            },
            {
              title: "Devretme Politikası",
              condition: values.limitation === "limited_to_year",
            },
            {
              title: "Kıdem Ayarları",
              condition: values.limitation === "limited_to_year",
            },
            { title: "Talep Ayarları", condition: true },
          ].filter((tab) => tab.condition);
          return (
            <Form className="space-y-6">
              <div className="mb-4 flex gap-2">
                {tabs.map((tab, index) => (
                  <button
                    key={index}
                    type="button"
                    onClick={() => setCurrentTab(index)}
                    className={`rounded px-3 py-2 ${
                      currentTab === index
                        ? "bg-blue-600 text-white"
                        : "text-black bg-gray-200 dark:bg-gray-800 dark:text-white"
                    }`}
                  >
                    {tab.title}
                  </button>
                ))}
              </div>

              {currentTab === 0 && (
                <>
                  {/* Genel Sekmesi İçeriği */}
                  <div className="grid w-1/3 grid-cols-1 gap-4 md:grid-cols-1">
                    <InputField
                      label="Tür"
                      name="name"
                      type="text"
                      placeholder="İzin türünün adı"
                    />
                    <InputField
                      label="Açıklama"
                      name="description"
                      type="text"
                      placeholder="İzin türü açıklaması"
                    />
                    <InputField
                      label="Dahili Kod"
                      name="internal_code"
                      type="text"
                      placeholder="Dahili kod (opsiyonel)"
                    />
                  </div>
                  <button
                    type="button"
                    onClick={() => setCurrentTab(1)}
                    className="mt-4 rounded bg-blue-500 px-4 py-2 text-white"
                  >
                    Devam
                  </button>
                </>
              )}

              {currentTab === 1 && (
                <>
                  {/* Hakediş Sekmesi İçeriği */}
                  <div className="grid w-1/2 grid-cols-1 gap-4 md:grid-cols-2">
                    <div className="flex items-center">
                      <Switch
                        name="is_paid"
                        checked={values.is_paid}
                        label="Ücretli/Ücretsiz"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    <div className="">
                      <label
                        htmlFor="limitation"
                        className="ml-3 text-sm font-bold text-navy-700 dark:text-white"
                      >
                        Limit
                      </label>
                      <select
                        name="limitation"
                        value={values.limitation}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="mt-1 flex h-12 w-full items-center justify-center rounded-xl border bg-white p-3 text-sm outline-none dark:border-gray-700 dark:bg-navy-700 dark:text-white"
                      >
                        <option value="">Seçiniz</option>
                        <option value="unlimited">Limitsiz</option>
                        <option value="limited_to_event">
                          Her talep için limitli
                        </option>
                        <option value="limited_to_year">
                          Yıl içinde limitli
                        </option>
                      </select>
                    </div>
                    <div className="flex items-center">
                      <InputField
                        label="Gün (Yıllık izin hak edişleri kaç gün olacak?)"
                        name="day_limit"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        type="number"
                        placeholder="Yıllık izin gün limiti"
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="overdrawn_policy"
                        className="ml-3 text-sm font-bold text-navy-700 dark:text-white"
                      >
                        Eksi Bakiye
                      </label>
                      <select
                        name="overdrawn_policy"
                        value={values.overdrawn_policy}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="mt-1 flex h-12 w-full items-center justify-center rounded-xl border bg-white p-3 text-sm outline-none dark:border-gray-700 dark:bg-navy-700 dark:text-white"
                      >
                        <option value="">Seçiniz</option>
                        <option value="not_allowed">
                          Bakiye Eksiye Düşemez
                        </option>
                        <option value="unlimited_overdrawn">
                          Limitsiz eksi bakiye
                        </option>
                        <option value="limited_overdrawn">
                          Limitli Eksi Bakiye
                        </option>
                      </select>
                    </div>
                    {values.overdrawn_policy === "limited_overdrawn" && (
                      <div>
                        <InputField
                          label="Limit (gün)"
                          name="overdrawn_limit"
                          type="number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                    )}
                  </div>
                  <div className="grid w-1/2 grid-cols-1 gap-4 md:grid-cols-2">
                    <div className="flex items-center">
                      <Switch
                        name="age_exception"
                        checked={values.age_exception}
                        label="Yaş istisnası uygulansın"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <p
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="18 yaşından küçük 50 yaşından büyük çalışanların hak ediş alt limiti."
                      >
                        <FaInfo className="text-blue-500" />
                      </p>
                      <Tooltip id="my-tooltip" />
                    </div>
                    {values.age_exception && (
                      <div className="">
                        <InputField
                          label="Yaş istisnası için gün sayısı"
                          name="age_exception_value"
                          type="number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                    )}
                  </div>
                  <button
                    type="button"
                    onClick={() => setCurrentTab(2)}
                    className="mt-4 rounded bg-blue-500 px-4 py-2 text-white"
                  >
                    Devam
                  </button>
                </>
              )}

              {currentTab === 2 && values.limitation === "limited_to_year" && (
                <>
                  {/* Hakediş SIklığı İçeriği */}
                  <div className="grid w-1/3 grid-cols-1 gap-4 md:grid-cols-1">
                    <div>
                      <label
                        htmlFor="accrual_frequency"
                        className="ml-3 text-sm font-bold text-navy-700 dark:text-white"
                      >
                        Hak ediş sıklığı{" "}
                        <small>
                          (Çalışanların hangi sıklıkla izin hak edeceğini
                          belirleyin.)
                        </small>
                      </label>
                      <select
                        name="accrual_frequency"
                        value={values.accrual_frequency}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="mt-1 flex h-12 w-full items-center justify-center rounded-xl border bg-white p-3 text-sm outline-none dark:border-gray-700 dark:bg-navy-700 dark:text-white"
                      >
                        <option value="">Seçiniz</option>
                        <option value="monthly">Aylık</option>
                        <option value="yearly">Yıllık</option>
                        <option value="day">Günlük</option>
                      </select>
                    </div>
                    <div>
                      <label
                        htmlFor="accrual_start_date"
                        className="ml-3 text-sm font-bold text-navy-700 dark:text-white"
                      >
                        Başlangıç Tarihi{" "}
                        <small>
                          (Çalışanların izin hak edişlerinin tanımlanma dönemi
                          nedir?)
                        </small>
                      </label>
                      <select
                        name="accrual_start_date"
                        value={values.accrual_start_date}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="mt-1 flex h-12 w-full items-center justify-center rounded-xl border bg-white p-3 text-sm outline-none dark:border-gray-700 dark:bg-navy-700 dark:text-white"
                      >
                        <option value="">Seçiniz</option>
                        <option value="first_day_of_year">
                          Yılın ilk günü
                        </option>
                        <option value="calculation_date">
                          Belirlenen Hak Ediş Tarihi
                        </option>
                      </select>
                    </div>
                  </div>

                  <button
                    type="button"
                    onClick={() => setCurrentTab(3)}
                    className="mt-4 rounded bg-blue-500 px-4 py-2 text-white"
                  >
                    Devam
                  </button>
                </>
              )}
              {currentTab === 3 && values.limitation === "limited_to_year" && (
                <>
                  {/* Devretme İçeriği */}
                  <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                    <div>
                      <label
                        htmlFor="is_carryover"
                        className="ml-3 text-sm font-bold text-navy-700 dark:text-white"
                      >
                        Çalışanların kalan izni devredebilir mi?
                      </label>
                      <select
                        name="is_carryover"
                        value={values.is_carryover}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="mt-1 flex h-12 w-full items-center justify-center rounded-xl border bg-white p-3 text-sm outline-none dark:border-gray-700 dark:bg-navy-700 dark:text-white"
                      >
                        <option value="">Seçiniz</option>
                        <option value="true">
                          Bir sonraki yıla devredilsin
                        </option>
                        <option value="false">
                          İzin hakları sıfırlansın, devredilmesin
                        </option>
                      </select>
                    </div>
                  </div>

                  <button
                    type="button"
                    onClick={() => setCurrentTab(4)}
                    className="mt-4 rounded bg-blue-500 px-4 py-2 text-white"
                  >
                    Devam
                  </button>
                </>
              )}
              {currentTab === 4 && values.limitation === "limited_to_year" && (
                <>
                  {/* Kıdem İçeriği */}
                  <div className="grid grid-cols-1 gap-4 md:grid-cols-1">
                    <button
                      type="button"
                      onClick={() => setIsOpenSeniortyModal(true)}
                      className="mt-4 flex w-1/6 items-center rounded-md border bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700"
                    >
                      <FaPlus className="mr-2" /> Yeni Kıdem Ekle
                    </button>
                    <div className="flex items-center">
                      <table className="w-1/6 bg-gray-200 dark:border-gray-700 dark:bg-navy-700 dark:text-white">
                        <thead>
                          <tr>
                            <th className="p-2 text-left">Kıdem</th>
                            <th className="p-2 text-left">Hakediş</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {leaveType.seniority.map((item, index) => (
                            <tr key={index}>
                              <td className="p-2">{item.name} yıl</td>
                              <td className="p-2">+{item.value} gün</td>
                              <td className="p-2"><button onClick={() => deleteSeniority(item.id)}><FaTimes className="text-red-500 text-sm" /></button></td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <button
                    type="button"
                    onClick={() => setCurrentTab(5)}
                    className="mt-4 rounded bg-blue-500 px-4 py-2 text-white"
                  >
                    Devam
                  </button>
                </>
              )}
              {currentTab === tabs.length - 1 && (
                <>
                  {/* Talep Ayarları Sekmesi İçeriği */}
                  <div className="grid grid-cols-1 gap-4 md:grid-cols-1">
                    {values.limitation === "limited_to_year" && (
                      <div className="flex items-center gap-3">
                        <InputField
                          label="En az limit talebi (Tek seferde)"
                          name="least_day_count"
                          type="number"
                        />
                        <InputField
                          label="En çok limit talebi (Tek seferde)"
                          name="most_day_count"
                          type="number"
                        />
                      </div>
                    )}

                    <div className="mb-2 flex w-full">
                      <Switch
                        label="Talep girerken açıklama yazma zorunluluğu"
                        name="is_message_required"
                        checked={values.is_message_required}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    <div className="mb-2 flex w-full">
                      <Switch
                        label="Yerine bakacak kişi zorunluluğu"
                        name="is_substitute_required"
                        checked={values.is_substitute_required}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    {/* <div className="mb-2 flex w-full">
                      <Switch
                        label="Takvim üzerinde gösterilsin mi?"
                        name="show_on_calendar"
                        checked={values.show_on_calendar}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div> */}
                  </div>
                  <button
                    type="submit"
                    className="mt-4 rounded bg-blue-700 px-4 py-2 text-white"
                  >
                    Kaydet
                  </button>
                </>
              )}
            </Form>
          );
        }}
      </Formik>
      {isOpenSeniortyModal && (
        <Modal
          title="Kıdem Ekle"
          isOpen={isOpenSeniortyModal}
          onRequestClose={() => setIsOpenSeniortyModal(false)}
          style={modalStyle}
          ariaHideApp={false}
        >
          <div className="mx-auto  rounded-lg bg-white p-6 shadow-lg">
            <button
              onClick={() => setIsOpenSeniortyModal(false)}
              className="absolute top-0 right-0 m-2 text-2xl font-semibold"
            >
              ×
            </button>
            <Formik
              initialValues={{
                name: "",
                value: "",
              }}
              validationSchema={Yup.object({
                name: Yup.string().required("Kıdem adı"),
                value: Yup.number().required("Hakediş değeri zorunludur"),
              })}
              onSubmit={(values, { setSubmitting }) => {
                handleSenioritySubmit(values).then(() => setSubmitting(false));
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="grid grid-cols-1 gap-4 md:grid-cols-1">
                    <InputField
                      label="Kıdem (yıl)"
                      name="name"
                      type="number"
                      placeholder="5 yıl"
                    />
                    <InputField
                      label="Hakediş (gün)"
                      name="value"
                      type="number"
                      placeholder="+6 gün"
                    />
                  </div>
                  <button
                    type="submit"
                    className="mt-4 rounded bg-blue-500 px-4 py-2 text-white"
                    disabled={isSubmitting}
                  >
                    Kaydet
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default RuleDetails;
