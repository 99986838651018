import React from "react";
import BasicTabs from "components/tabs/BasicTabs";
import LeaveList from "./components/LeaveList";
import LeaveReports from "./components/LeaveReports";
import LeaveRules from "./components/LeaveRules";
import LeaveCalendar from "./components/LeaveCalendar";
import ApprovalProcess from "../settings/components/Rules/ApprovalProcess";

const tabs = [
  { name: "İzinler", content: <LeaveList /> },
  // { name: "Raporlar", content: <LeaveReports /> },
  { name: "Kurallar", content: <LeaveRules /> },
  { name: "Onay Süreçleri", content: <ApprovalProcess /> },
  // { name: "Takvim", content: <LeaveCalendar /> },
];
const Leaves = () => {
  return (
    <div>
      {/* Tables & Charts */}

      <div className="mt-2 grid grid-cols-1 gap-5 rounded-[20px] bg-white bg-clip-border p-3 shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:text-white dark:shadow-none xl:grid-cols-1">
        <div>
          <BasicTabs tabs={tabs} />
        </div>
      </div>
    </div>
  );
};

export default Leaves;
