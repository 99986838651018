import { useState } from "react";
import Banner from "./components/Banner";
import General from "./components/General";
import Notification from "./components/Notification";
import Project from "./components/Project";
import Storage from "./components/Storage";
import Upload from "./components/Upload";

const Card = ({ title, children }) => (
  <div className="mb-4 p-4 bg-white rounded shadow">
    <h3 className="text-lg font-semibold text-gray-800">{title}</h3>
    {children}
  </div>
);

const ProfileTab = ({ profileImage, handleImageUpload }) => (
  <div>
    <Card title="Yöneticim" extra={"h-[400px] w-full"}>
      {/* Yönetici bilgileri */}
      <p>Yönetici Adı: [Yöneticinin Adı]</p>
      <p>Pozisyon: [Pozisyon]</p>
      {/* Diğer yönetici bilgileri */}
    </Card>

    <Card title="İletişim" extra={"h-[400px] w-full"}>
      {/* İletişim bilgileri */}
      <p>E-posta: [E-posta]</p>
      <p>Telefon: [Telefon Numarası]</p>
      {/* Diğer iletişim bilgileri */}
    </Card>

    <Card title="Profil Özeti" extra={"h-[400px] w-full"}>
      <div className="flex">
        <div className="mr-4">
          <img src={profileImage || "default-avatar.jpg"} alt="Profile" className="h-24 w-24 rounded-full object-cover" />
        </div>
        <div>
          <p>Departman: [Departman]</p>
          <p>İşe Giriş Tarihi: [Giriş Tarihi]</p>
          <p>Çalışma Şekli: [Tam Zamanlı/Yarı Zamanlı]</p>
          {/* Diğer profil özeti bilgileri */}
        </div>
      </div>
    </Card>
  </div>
);

const PersonalInfoTab = () => (
  <div>
    <Card title="Vatandaşlık" extra={"h-[400px] w-full"}>
      <p>Doğum Tarihi: [Doğum Tarihi]</p>
      <p>Cinsiyet: [Cinsiyet]</p>
      <p>Uyruk: [Uyruk]</p>
      <p>TC Kimlik No: [TC Kimlik No]</p>
    </Card>

    <Card title="Eğitim" extra={"h-[400px] w-full"}>
      <p>Eğitim Durumu: [Eğitim Durumu]</p>
      <p>Eğitim Seviyesi: [Eğitim Seviyesi]</p>
      {/* Diğer eğitim bilgileri */}
    </Card>
  </div>
);

const PermissionsTab = () => (
  <Card title="İzinlerim">
    <table className="w-full text-left border-collapse">
      <thead>
        <tr>
          <th className="border p-2">İzin Türü</th>
          <th className="border p-2">Başlangıç Tarihi</th>
          <th className="border p-2">Bitiş Tarihi</th>
          <th className="border p-2">Durum</th>
        </tr>
      </thead>
      <tbody>
        {/* İzinlerin tablosu */}
        {/* Örnek bir satır */}
        <tr>
          <td className="border p-2">Yıllık İzin</td>
          <td className="border p-2">2024-01-10</td>
          <td className="border p-2">2024-01-20</td>
          <td className="border p-2">Onaylandı</td>
        </tr>
        {/* Diğer izin bilgileri */}
      </tbody>
    </table>
  </Card>
);

  const Profile = () => {
    const [profileImage, setProfileImage] = useState(null);
    const [activeTab, setActiveTab] = useState("profile");
  
    const handleImageUpload = (event) => {
      // Resim yükleme işlemleri
    };

  return (
    <div className="h-fit bg-white dark:bg-gray-900 p-4 rounded-t-2xl">

    <ul className="flex space-x-4 mt-4">
      {/* Tab başlıkları */}
      <li>
        <button onClick={() => setActiveTab("profile")}>Profilim</button>
      </li>
      <li>
        <button onClick={() => setActiveTab("personalInfo")}>Kişisel Bilgilerim</button>
      </li>
      <li>
        <button onClick={() => setActiveTab("permissions")}>İzinlerim</button>
      </li>
    </ul>
    <div className="mt-8">
      {activeTab === "profile" && <ProfileTab profileImage={profileImage} handleImageUpload={handleImageUpload} />}
      {activeTab === "personalInfo" && <PersonalInfoTab />}
      {activeTab === "permissions" && <PermissionsTab />}
    </div>
  </div>
  );
};

export default Profile;
