import React from "react";
import Modal from "react-modal";
import { Formik, Form, Field, ErrorMessage } from "formik";

import { post } from "utils/api"; // API istekleri için
import { ENDPOINTS } from "utils/constants";
import * as Yup from "yup";
import InputField from "components/fields/InputField";
import Switch from "components/switch";
import toast from "react-hot-toast";
import { patch } from "utils/api";
import FormikDatePicker from "components/datepicker";
import { formatISODate } from "utils/helpers";
import useCustomModalStyles from "utils/useCustomModalStyles";

const PersonalInfoCitizenshipModal = ({
  isOpen,
  onClose,
  onRefresh,
  userId,
  currentData,
}) => {
  const modalStyle = useCustomModalStyles();
  const handleSubmit = async (values) => {
    var formData = new FormData();
    formData.append("nationality", values.nationality);
    formData.append("identification_number", values.identification_number);
    formData.append("disability_degree", values.disability_degree);
    formData.append("gender", values.gender);
    formData.append("birth_date", formatISODate(values.birth_date));

    const response = await patch(ENDPOINTS.USERS + userId + "/", formData);
    if (response.status === 200) {
      onRefresh();
      onClose();
      toast.success("Başarılı bir şekilde değiştirildi");
    } else {
      toast.error("Bir hata oluştu");
    }
  };
  const initialValues = {
    nationality: currentData?.nationality || "",
    identification_number: currentData?.identification_number || "",
    disability_degree: currentData?.disability_degree || "",
    gender: currentData?.gender || "",
    birth_date: new Date(currentData?.birth_date) || "",
  };

  const validationSchema = Yup.object().shape({
    // birth_date: Yup.string().required("Bu alan zorunludur"),
  });
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={modalStyle}>
      <div className="mx-auto  rounded-lg bg-white p-6 shadow-lg">
        <button
          onClick={onClose}
          className="absolute top-0 right-0 m-2 text-2xl font-semibold"
        >
          ×
        </button>
        <h2 className="mb-4 text-center text-xl font-semibold text-gray-800">
          Vatandaşlık
        </h2>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({
            values,
            setFieldValue,
            isSubmitting,
            handleBlur,
            handleChange,
            errors,
            touched,
          }) => (
            <Form className="space-y-4">
              <div className="flex flex-col md:flex-row md:items-center md:space-x-4">
                <div className="mb-4 flex-1">
                  <label
                    htmlFor="birth_date"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Doğum Tarihi
                  </label>
                  <FormikDatePicker
                    name="birth_date"
                    selected={values.birth_date}
                    onChange={(date) => setFieldValue("birth_date", date)}
                  />
                  <ErrorMessage
                    name="birth_date"
                    component="div"
                    className="text-sm text-red-600"
                  />
                </div>
                <div className="mb-4 flex-1">
                  <label
                    htmlFor="gender"
                    className="ml-3 text-sm font-bold text-navy-700 dark:text-white"
                  >
                    Cinsiyet
                  </label>
                  <select
                    name="gender"
                    value={values.gender}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="mt-1 block h-12 w-full rounded-md border border-gray-300 p-2 shadow-sm"
                  >
                    <option value="">Belirtilmemiş</option>
                    <option value="Erkek">Erkek</option>
                    <option value="Kadın">Kadın</option>
                    <option value="Diğer">Diğer</option>
                  </select>
                </div>
              </div>
              <div className="flex flex-col md:flex-row md:items-center md:space-x-4">
                <div className="mb-4 flex-1">
                  <label
                    htmlFor="disability_degree"
                    className="ml-3 text-sm font-bold text-navy-700 dark:text-white"
                  >
                    Engel Derecesi
                  </label>
                  <select
                    name="disability_degree"
                    value={values.disability_degree}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="mt-1 block h-12 w-full rounded-md border border-gray-300 p-2 shadow-sm"
                  >
                    <option value="Yok">Yok</option>
                    <option value="1. derece">1. derece</option>
                    <option value="2. derece">2. derece</option>
                    <option value="3. derece">3. derece</option>
                  </select>
                </div>
                <div className="mb-4 flex-1">
                  <label
                    htmlFor="nationality"
                    className="ml-3 text-sm font-bold text-navy-700 dark:text-white"
                  >
                    Uyruğu
                  </label>
                  <select
                    name="nationality"
                    value={values.nationality}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="mt-1 block h-12 w-full rounded-md border border-gray-300 p-2 shadow-sm"
                  >
                    <option value="">Seçiniz</option>
                    <option value="Türkiye">Türkiye</option>
                    <option value="Afganistan">Afganistan</option>
                    <option value="Almanya">Almanya</option>
                    <option value="Amerika Birleşik Devletleri">
                      Amerika Birleşik Devletleri
                    </option>
                    <option value="Arnavutluk">Arnavutluk</option>
                    <option value="Avustralya">Avustralya</option>
                    <option value="Avusturya">Avusturya</option>
                    <option value="Azerbaycan">Azerbaycan</option>
                    <option value="Bahreyn">Bahreyn</option>
                    <option value="Bangladeş">Bangladeş</option>
                    <option value="Belçika">Belçika</option>
                    <option value="Birleşik Arap Emirlikleri">
                      Birleşik Arap Emirlikleri
                    </option>
                    <option value="Bosna-Hersek">Bosna-Hersek</option>
                    <option value="Brezilya">Brezilya</option>
                    <option value="Bulgaristan">Bulgaristan</option>
                    <option value="Cezayir">Cezayir</option>
                    <option value="Çek Cumhuriyeti">Çek Cumhuriyeti</option>
                    <option value="Çin">Çin</option>
                    <option value="Danimarka">Danimarka</option>
                    <option value="Endonezya">Endonezya</option>
                    <option value="Ermenistan">Ermenistan</option>
                    <option value="Estonya">Estonya</option>
                    <option value="Etiyopya">Etiyopya</option>
                    <option value="Fas">Fas</option>
                    <option value="Filipinler">Filipinler</option>
                    <option value="Filistin">Filistin</option>
                    <option value="Finlandiya">Finlandiya</option>
                    <option value="Fransa">Fransa</option>
                    <option value="Gana">Gana</option>
                    <option value="Güney Afrika Cumhuriyeti">
                      Güney Afrika Cumhuriyeti
                    </option>
                    <option value="Güney Kore">Güney Kore</option>
                    <option value="Gürcistan">Gürcistan</option>
                    <option value="Hindistan">Hindistan</option>
                    <option value="Hırvatistan">Hırvatistan</option>
                    <option value="Hollanda">Hollanda</option>
                    <option value="Irak">Irak</option>
                    <option value="İngiltere">İngiltere</option>
                    <option value="İran">İran</option>
                    <option value="İrlanda">İrlanda</option>
                    <option value="İspanya">İspanya</option>
                    <option value="İsrail">İsrail</option>
                    <option value="İsveç">İsveç</option>
                    <option value="İsviçre">İsviçre</option>
                    <option value="İtalya">İtalya</option>
                    <option value="İzlanda">İzlanda</option>
                    <option value="Jamaika">Jamaika</option>
                    <option value="Japonya">Japonya</option>
                    <option value="Kanada">Kanada</option>
                    <option value="Karadağ">Karadağ</option>
                    <option value="Katar">Katar</option>
                    <option value="Kazakistan">Kazakistan</option>
                    <option value="Kenya">Kenya</option>
                    <option value="Kırgızistan">Kırgızistan</option>
                    <option value="Kosova">Kosova</option>
                    <option value="Kuveyt">Kuveyt</option>
                    <option value="Letonya">Letonya</option>
                    <option value="Libya">Libya</option>
                    <option value="Lübnan">Lübnan</option>
                    <option value="Lüksemburg">Lüksemburg</option>
                    <option value="Macaristan">Macaristan</option>
                    <option value="Makedonya">Makedonya</option>
                    <option value="Malezya">Malezya</option>
                    <option value="Malta">Malta</option>
                    <option value="Meksika">Meksika</option>
                    <option value="Mısır">Mısır</option>
                    <option value="Moldova">Moldova</option>
                    <option value="Monako">Monako</option>
                    <option value="Mongolistan">Mongolistan</option>
                    <option value="Montenegro">Montenegro</option>
                    <option value="Nepal">Nepal</option>
                    <option value="Nijerya">Nijerya</option>
                    <option value="Norveç">Norveç</option>
                    <option value="Özbekistan">Özbekistan</option>
                    <option value="Pakistan">Pakistan</option>
                    <option value="Polonya">Polonya</option>
                    <option value="Portekiz">Portekiz</option>
                    <option value="Romanya">Romanya</option>
                    <option value="Rusya">Rusya</option>
                    <option value="Sırbistan">Sırbistan</option>
                    <option value="Singapur">Singapur</option>
                    <option value="Slovakya">Slovakya</option>
                    <option value="Slovenya">Slovenya</option>
                    <option value="Sri Lanka">Sri Lanka</option>
                    <option value="Sudan">Sudan</option>
                    <option value="Suriye">Suriye</option>
                    <option value="Suudi Arabistan">Suudi Arabistan</option>
                    <option value="Tayland">Tayland</option>
                    <option value="Tayvan">Tayvan</option>
                    <option value="Tunus">Tunus</option>
                    <option value="Türkmenistan">Türkmenistan</option>
                    <option value="Ukrayna">Ukrayna</option>
                    <option value="Umman">Umman</option>
                    <option value="Ürdün">Ürdün</option>
                    <option value="Venezuela">Venezuela</option>
                    <option value="Vietnam">Vietnam</option>
                    <option value="Yemen">Yemen</option>
                    <option value="Yeni Zelanda">Yeni Zelanda</option>
                    <option value="Yunanistan">Yunanistan</option>
                    <option value="Zambiya">Zambiya</option>
                    <option value="Zimbabve">Zimbabve</option>
                    <option value="Diğer">Diğer</option>
                  </select>
                </div>
                <div className="mb-4 flex-1">
                  <InputField
                    name="identification_number"
                    label="Kimlik Numarası"
                    type="text"
                  />
                </div>
              </div>
              <button
                type="submit"
                disabled={isSubmitting}
                className="border-transparent mt-2 flex w-full justify-center rounded-md border bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Kaydet
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default PersonalInfoCitizenshipModal;
